import ReactDOM from "react-dom";
import React from 'react';
import StatsLegend from './stats-legend.js';
import Dropdown from './dropdown.js';
import Filters from './filters.js';
import TeamProfileModal from './team-profile-modal.js';
import PlayerProfileModal from './player-profile-modal.js';
import {TEAM_CITY_TO_CODE, LEGENDS, TEAM_MODAL_STATS} from './linemate-react-common/src/constants.js';
import {API_HOST} from './react-web-constants.js';
import {getDictionaryValue, orderListByField, isDictEmpty, 
        buildQueryParams, mergeSubDictionaries, getPlayerLeaderStats, fetchPlayerLeaders,
        getLeaguePlayerLeaders, capitalizeFirstLetter} from './linemate-react-common/src/util.js';
import { getTeamLogoPath } from "./react-web-utils.js";

import './fonts.css';
import './leaderboard.css';
import HtmlHeaders from "./html-headers.js";
import Picker from "./picker.js";
import TabToggle from "./components/TabToggle.jsx";
import InputSelection from "./components/InputSelection.jsx";
import Table from "./components/Table.jsx";
import { buildStandings } from "./linemate-react-common/src/gameday-utils.js";
import Button from "./components/Button.jsx";
import { Buffer } from 'buffer';

let pos = { top: 0, left: 0, x: 0, y: 0 };

class NHLLeaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.teamStatsColumns = {
      offensive: {
        "%TYPE%": {
          "GP": "gamesPlayed",
          "G": "%qualifier%.%TYPE%.goals",
          "SOG": "%qualifier%.%TYPE%.shotsOnGoal",
          "SAT": "%qualifier%.%TYPE%.shotAttempts",
          "CF%": "%qualifier%.%TYPE%.corsiPercentage",
          "BLK": "%qualifier%.%TYPE%.offensiveBlocks",
          "PIM": "%qualifier%.%TYPE%.penaltyMinutes"
        },
        "SPECIAL TEAMS": {
          "PPG": "%qualifier%.offensive.powerplayGoals",
          "PP%": "%qualifier%.offensive.powerplayPercentage"
        }
      },
      defensive: {
        "%TYPE%": {
          "GP": "gamesPlayed",
          "G": "%qualifier%.%TYPE%.goals",
          "SOG": "%qualifier%.%TYPE%.shotsOnGoal",
          "SAT": "%qualifier%.%TYPE%.shotAttempts",
          "CF%": "%qualifier%.%TYPE%.corsiPercentage",
          "BLK": "%qualifier%.%TYPE%.offensiveBlocks",
          "PIM": "%qualifier%.%TYPE%.penaltyMinutes"
        },
        "SPECIAL TEAMS": {
          "SHG": "%qualifier%.offensive.shorthandedGoals",
          "SH%": "%qualifier%.offensive.penaltyKillPercentage"
        }
      }
    }

    this.skatersPlayerStatsColumns = {
      "GP": "gamesPlayed",
      "PTS": "%qualifier%.points",
      "G": "%qualifier%.goals",
      "A": "%qualifier%.assists",
      "SOG": "%qualifier%.shotsOnGoal",
      "SAT": "%qualifier%.shotAttempts",
      "TOI": "%qualifier%.timeOnIce",
      "+/-": "%qualifier%.plusMinus",
      "PPP": "%qualifier%.powerplayPoints",
      "SHP": "%qualifier%.shorthandedPoints",
      "PIM": "%qualifier%.penaltyMinutes",
      "BLK": "%qualifier%.defensiveBlocks"
    };

    this.goaliesPlayerStatsColumns = {
      "GP": "gamesPlayed",
      "WINS": "%qualifier%.wins",
      "SA": "%qualifier%.shotsAgainst",
      "SAVES": "%qualifier%.shotsSaved",
      "SV%": "%qualifier%.savePercentage",
      "GA": "%qualifier%.goalsAllowed",
      "SHUTOUTS": "%qualifier%.shutouts"
    };

    this.filters = {
      team: {
        timeframe: {
            isActive: false,
            options: Object.fromEntries(["23-24 SEASON", "22-23 SEASON", "21-22 SEASON", "LAST 5", "LAST 10"].map(x => [x, x])),
            selectedValue: "23-24 SEASON"
        },
        splits: {
            isActive: false,
            options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
            selectedValue: "HOME+AWAY"
        }
      },
      player: {
        team: {
            isActive: false,
            options: Object.fromEntries(Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague]).map(x => [x, x])),
            selectedValues: ["ALL"],
            type: "multipleSelection"
        },
        timeframe: {
            isActive: false,
            options: Object.fromEntries(["23-24 SEASON", "22-23 SEASON", "21-22 SEASON", "LAST 5", "LAST 10"].map(x => [x, x])),
            selectedValue: "23-24 SEASON"
        },
        position: {
          isActive: false,
          options: Object.fromEntries(["SKATERS", "FORWARDS", "C", "LW", "RW", "D", "G"].map(x => [x, x])),
          selectedValue: "SKATERS"
        },
        splits: {
            isActive: false,
            options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
            selectedValue: "HOME+AWAY"
        }
      }
    }

    this.pickerOptions = {
      "cumulativeStats": "Total",
      "averageStats": "Per Game"
    }

    this.tabs = {
      "team": "Team Stats",
      "player": "Player Stats",
      "standings": "Standings"
    }

    this.standingsPickerOptions = {
      division: "Division",
      conference: "Conference"
    }
    this.standingsColumns = {
      'Rank': '', 
      'Team': '', 
      'GP': 'gamesPlayed', 
      'W': 'standings.wins', 
      'L': 'standings.losses', 
      'OTL': 'standings.overtimeLosses', 
      'PTS': 'standings.points'
    }

    this.teamModalStats = TEAM_MODAL_STATS.nhl;

    this.legend = LEGENDS[this.props.activeLeague.toLowerCase()];

    this.defaultTeamSortingColumn = "G";
    this.teamSortingOrder = {
      offensive: {
        "GP": "desc",
        "G": "desc",
        "SOG": "desc",
        "SAT": "desc",
        "CF%": "desc",
        "BLK": "asc",
        "PIM": "asc",
        "PPG": "desc",
        "PP%": "desc"
      },
      defensive: {
        "GP": "desc",
        "G": "asc",
        "SOG": "asc",
        "SAT": "asc",
        "CF%": "asc",
        "BLK": "desc",
        "PIM": "desc",
        "SHG": "desc",
        "SH%": "desc"
      }
    };

    // For now just going to default it to ordering by the amount of games played, though we should probably customize it by position or stat type
    this.defaultPlayerSortingColumn = "GP";
    // Since we are only tracking offensive positions for now, and since all the stats are positively impacted by a higher number, generalzie to 'desc' for now
    this.defaultPlayerSortingOrder = "desc";

    this.windowScrolled = this.windowScrolled.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
    this.addClickableElement = this.addClickableElement.bind(this);

    this.legendClicked = this.legendClicked.bind(this);
    this.legendClosed = this.legendClosed.bind(this);
    this.childLegendClosed = this.childLegendClosed.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.headerClicked = this.headerClicked.bind(this);
    this.filterOptionSelected = this.filterOptionSelected.bind(this);
    this.generateFilterStyling = this.generateFilterStyling.bind(this);
    this.selectStatQualifier = this.selectStatQualifier.bind(this);
    this.filtersModalButtonClicked = this.filtersModalButtonClicked.bind(this);
    this.filtersModalCloseHandler = this.filtersModalCloseHandler.bind(this);

    this.mouseDownHandler = this.mouseDownHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.mouseUpHandler = this.mouseUpHandler.bind(this);

    this.openTeamProfile = this.openTeamProfile.bind(this);
    this.openPlayerProfile = this.openPlayerProfile.bind(this);
    this.closeProfile = this.closeProfile.bind(this);
    this.setStateFunction = this.setStateFunction.bind(this);

    this.rankColumnRef = React.createRef();
    this.typeFilterRef = React.createRef();
    this.typeMobileFilterRef = React.createRef();
    this.clickableElements = [this.typeFilterRef, this.typeMobileFilterRef];
    this.filterRefs = {};

    this.typeFilterClicked = this.typeFilterClicked.bind(this);
    this.typeFilterOptionSelected = this.typeFilterOptionSelected.bind(this);
    this.typeFilterContainerStyling = {};
    this.typeMobileFilterContainerStyling = {};

    this.tableScrolled = this.tableScrolled.bind(this);
    this.tableRef = React.createRef();
    this.tableGradientRef = React.createRef();

    const searchParams = new URLSearchParams(window.location.search);

    var tab = 'team';
    if (searchParams.has('tab')) {
      const category = searchParams.get('tab').toLowerCase();
      if (category === "player" || category === "team") {
        tab = category;
      }
    }

    var typeFilterOptions = ["OFFENSIVE", "DEFENSIVE"];
    var typeFilterSelection = "OFFENSIVE";
    if (searchParams.has('type') && typeFilterOptions.includes(searchParams.get('type').toUpperCase())) {
      typeFilterSelection = searchParams.get('type').toUpperCase();
    }

    var filters = this.filters[tab];
    if (searchParams.has('filters')) {
      try{
        const filterQueryParam = JSON.parse((Buffer.from(searchParams.get('filters'), 'base64')).toString('ascii'));
        Object.keys(filterQueryParam).map((key) => {
          if (key in filters && filterQueryParam[key] in filters[key].options) {
            filters[key].selectedValue = filterQueryParam[key];
          }
        });
      } catch(err) {
        console.log("Unable to parse filters", err);
      }
    }

    var statColumns = {};
    var mergedColumns = {};
    //var playerType = this.filters.player.
    if (tab === "team") {
      statColumns = this.teamStatsColumns[typeFilterSelection.toLowerCase()];
      mergedColumns = mergeSubDictionaries(statColumns);
    } else if(tab === "player"){
      if (filters.position.selectedValue === "G") {
        statColumns = this.goaliesPlayerStatsColumns;
      } else {
        statColumns = this.skatersPlayerStatsColumns;
      }
      mergedColumns = statColumns;
    }


    // We're don't fall back to the default sorting group if the column doesn't match the group, 
    // assuming we don't mind no specific stat ordering if the user tries to mess with the URL
    var sortingGroup = "%TYPE%";
    if (searchParams.has('group')) {
      const groupQueryParam = searchParams.get('group').toUpperCase();
      if (tab === "team" && groupQueryParam in this.teamStatsColumns[typeFilterSelection.toLowerCase()]) {
        sortingGroup = groupQueryParam;
      }
    }
    
    var sortingColumn = "";
    if (tab === "player") {
      sortingColumn = this.defaultPlayerSortingColumn;
    } else {
      sortingColumn = this.defaultTeamSortingColumn;
    }
    if (searchParams.has('stat')) {
      const sortingColumnQueryParam = searchParams.get('stat').toUpperCase();
      if (tab === "team" && sortingColumnQueryParam in this.teamStatsColumns[typeFilterSelection.toLowerCase()][sortingGroup]) {
        sortingColumn = sortingColumnQueryParam;
      } else if (tab === "player" && sortingColumnQueryParam in mergedColumns) {
        sortingColumn = sortingColumnQueryParam;
      }
    }

    var sortingOrder = "";
    if (tab === "player") {
      sortingOrder = this.defaultPlayerSortingOrder;
    } else {
      sortingOrder = this.teamSortingOrder.offensive[sortingColumn.toUpperCase()];
    }
    if (searchParams.has('order')) {
      const sortingOrderQueryParam = searchParams.get('order').toLowerCase();
      if (sortingOrderQueryParam === "asc" || sortingOrderQueryParam === "desc") {
        sortingOrder = searchParams.get('order').toLowerCase();
      }
    }

    this.state = {
      legendEnabled: false,
      tab: tab,
      legendItems: this.legend[tab],
      filterSticky: false,
      sortingGroup: sortingGroup,
      sortingColumn: sortingColumn,
      sortingOrder: sortingOrder,
      scrollToTop: false,

      statColumns: statColumns,
      mergedStatColumns: mergedColumns,

      // For the new leaderboard implementation
      data: {
        team: [],
        player: [],
      },
      // Keep the team and players loaded if we have them so that we don't re-load on tab switch for no reason
      //  and then separately keep track of which list of obejcts you want to show in the table
      activeData: [],
      // We could probably add the type filter in the 'filters' group but we already started it separately, will leave for later 
      // TODO
      typeFilterActive: false,
      typeFilterSelection: typeFilterSelection,
      typeFilterOptions: typeFilterOptions,
      qualifier: "cumulativeStats",

      filters: filters,

      filtersModalActive: false,

      teamProfileModalActive: null,
      playerProfileModalActive: null,
      // Used to keep a separate list of seasonal team related data to be used to determine the leader per stats for the profile pop-up
      // Using a dictionary to be able to select a specific team
      currentSeasonTeamsStats: {},
      playerLeaders: {},
      // Dictionary of player ID -> seasonal player record
      currentSeasonPlayerStats: {},
      pickerSelection: Object.keys(this.pickerOptions)[0],
      standings: {
        division: [],
        conference: []
      },
      standingsKey: Object.keys(this.standingsPickerOptions)[0]
    }
  }

  componentDidMount() {
    document.title = 'Leaderboard - Linemate';
    window.addEventListener('scroll', this.windowScrolled);
    window.addEventListener('resize', this.windowResized);
    window.addEventListener('mousedown', this.handleGeneralClick);
    const ele = document.getElementById('leaderboard-table-content');
    ele.addEventListener('mousedown', this.mouseDownHandler);
    this.tableScrolled();
    this.loadData(this.state);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowScrolled);
    window.removeEventListener('resize', this.windowResized);
    window.removeEventListener('mousedown', this.handleGeneralClick);
  }

  componentDidUpdate() {
    // Adding the filters as clickable elements with refs
    // This could probably be optimized by only doing it once at setup
    for (var key in this.state.filters) {
      if (!(key in this.filterRefs)) {
        this.filterRefs[key] = React.createRef();
        this.clickableElements.push(this.filterRefs[key]);
      }
    }

    if (this.state.scrollToTop) {
      window.scrollTo(0,0);
      this.setState(
        {
          scrollToTop: false
        }
      );
    }
  }

  setStateFunction(state) {
    this.setState(state);
  }

  windowScrolled() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 576) {
      return;
    }
    const node = ReactDOM.findDOMNode(this);
    var leaderboardFilters = node.querySelector('.leaderboard-filters');
    if (!leaderboardFilters) {
      leaderboardFilters = node.querySelector('.leaderboard-filters-sticky');
    }
    var tempState = {};
    if (window.scrollY > 124 && !this.state.filterSticky) {
      tempState = {
        // If we want to put the sticky filters back, just set this to true
        // filterSticky: true,
        filterSticky: false,
        typeFilterActive: false
      };
    } else if (window.scrollY < 124 && this.state.filterSticky) {
      tempState = {
        filterSticky: false,
        typeFilterActive: false
      };
    } else {
      tempState = {
        filterSticky: this.state.filterSticky,
        typeFilterActive: false
      };
    }

    if (isDictEmpty(tempState)) {
      return;
    }

    if (this.state.filterSticky !== tempState.filterSticky || this.state.typeFilterActive !== tempState.typeFilterActive) {
      this.setState(tempState);
    }
  }

  windowResized() {
    this.generateFilterStyling();
  }

  //https://htmldom.dev/drag-to-scroll/
  mouseDownHandler(e) {
    const ele = document.getElementById('leaderboard-table-content');
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';

    pos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };

    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
  }

  mouseMoveHandler(e) {
    const ele = document.getElementById('leaderboard-table-content');
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  }

  mouseUpHandler() {
    const ele = document.getElementById('leaderboard-table-content');
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');

    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  };

  // We should probably look into generalizing this behavior with
  // an input list of elements which have custom clicking behavior
  // as well as a callback function for those who don't
  // REVIEW TODO: instead of maintaining a single lsit of clickable elements,
  // we should contextualize it and have a new list dependent on what 'menu/dropdown' is opened at a given time

  // !!!TODO!!!: this is problematic, whenever we switch tabs more items are added to this.clickableElements and poluting it
  //  More stuff gets added just on any click which is also bad
  handleGeneralClick(event) {
    // console.log(this.clickableElements);
    var foundTarget = false;
    for(const element of this.clickableElements) {
      if (element.current && element.current.contains(event.target)) {
        foundTarget = true;
        break;
      }
    }
    if (!foundTarget) {
      this.setState({
        typeFilterActive: false
      });
    }
  }

  addClickableElement(element) {
    this.clickableElements.push(element);
  }

  tableScrolled() {
    const scrollWidth = document.getElementById("leaderboard-table-content").scrollWidth;
    const clientWidth = document.getElementById("leaderboard-table-content").clientWidth;
    const scrollLeft = document.getElementById("leaderboard-table-content").scrollLeft
    if ((scrollLeft + clientWidth) === scrollWidth) {
      // this.tableGradientRef.current.style.display = 'none';
    } else {
      // this.tableGradientRef.current.style.display = 'block';
    }
  }

  legendClicked() {
    document.body.style.overflow = "hidden";
    this.setState({
      legendEnabled: true
    });
  }

  legendClosed(event) {
    // Need to do this otherwise clicking anywhere on the child popup will trigger this
    if (event.target === event.currentTarget) {
      document.body.style.overflow = "visible";
      this.setState({
        legendEnabled: false
      });
    }
  }

  // We could probably just pass the regular legendClosed function and add the child buttons as acceptable events, but this will do for now
  childLegendClosed() {
    document.body.style.overflow = "visible";
    this.setState({
      legendEnabled: false
    });
  }

  selectTab(event) {
    const selection = event.currentTarget.dataset.selection;
    if (this.state.tab === selection) {
      return;
    }
    if (selection === "standings") {
      this.setState({tab: selection});
      return;
    }
    const sortingColumn = (selection === "team" ? this.defaultTeamSortingColumn : this.defaultPlayerSortingColumn);
    var state = this.state;

    state["filters"] = this.filters[selection];
    var statColumns = {};
    var mergedColumns = {};
    //var playerType = this.filters.player.
    if (selection === "team") {
      statColumns = this.teamStatsColumns[state.typeFilterSelection.toLowerCase()];
      mergedColumns = mergeSubDictionaries(statColumns);
    } else if(selection === "player"){
      if (state.filters.position.selectedValue === "G") {
        statColumns = this.goaliesPlayerStatsColumns;
      } else {
        statColumns = this.skatersPlayerStatsColumns;
      }
      mergedColumns = statColumns;
    }

    state["tab"] = selection;
    state["legendItems"] = this.legend[selection];
    state["activeData"] = [];
    state["sortingColumn"] = sortingColumn;
    state["sortingOrder"] = (selection === "team" ? this.teamSortingOrder.offensive[sortingColumn.toUpperCase()] : this.defaultPlayerSortingOrder.toUpperCase());
    state["statColumns"] = statColumns;
    state["mergedStatColumns"] = mergedColumns;
    if (selection === "team") {
      state["sortingGroup"] = "%TYPE%";
    }
    this.loadData(state);
  }

  headerClicked(event) {
    var group = event.currentTarget.dataset.group;
    const selection = event.currentTarget.dataset.selection;
    var tempState = this.state;
    if (this.state.tab === "team") {
      if (group === this.state.sortingGroup && selection === this.state.sortingColumn) {
        if (this.state.sortingOrder === "asc") {
          tempState["sortingOrder"] = "desc";
        } else {
          tempState["sortingOrder"] = "asc";
        }
      } else {
        tempState["sortingGroup"] = group;
        tempState["sortingColumn"] = selection;
        tempState["sortingOrder"] = this.teamSortingOrder[this.state.typeFilterSelection.toLowerCase()][selection.toUpperCase()];
      }
      const sortingColumnPath = tempState.statColumns[group][tempState.sortingColumn.toUpperCase()].replaceAll("%qualifier%", this.state.qualifier).replaceAll("%TYPE%", this.state.typeFilterSelection.toLowerCase());
      orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
      this.setState(tempState);
    } else {
      if (selection === this.state.sortingColumn) {
        if (this.state.sortingOrder === "asc") {
          tempState["sortingOrder"] = "desc";
        } else {
          tempState["sortingOrder"] = "asc";
        }
      } else {
        tempState["sortingColumn"] = selection;
        // TODO: stat specific sorting order
        tempState["sortingOrder"] = this.defaultPlayerSortingOrder;
      }
      if (tempState.activeData.length >= 50) {
        // console.log("Server side sorting");
        // If we have data size equal to the limit we have to assume it's possible there's more data and we need to query the API to re-sort
        this.loadData(tempState);
      } else {
        // console.log("Client side sorting");
        // If we have data size less than the limit, it means we have already filtered down the number of players and we can sort in place
        const sortingColumnPath = tempState.statColumns[selection].replaceAll("%qualifier%", tempState.qualifier);
        orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
        this.setState(tempState);
      }
    }
  }

  generateFilterStyling() {
    if (this.typeFilterRef.current) {
      this.typeFilterContainerStyling = {
        left: `${this.typeFilterRef.current.offsetLeft}px`,
        top: `${this.typeFilterRef.current.offsetTop + this.typeFilterRef.current.offsetHeight}px`,
        width: `${this.typeFilterRef.current.offsetWidth}px`
      };
    }
    if(this.typeMobileFilterRef.current) {
      this.typeMobileFilterContainerStyling = {
        left: `${this.typeMobileFilterRef.current.offsetLeft}px`,
        top: `${this.typeMobileFilterRef.current.offsetTop + this.typeMobileFilterRef.current.offsetHeight}px`,
        width: `${this.typeMobileFilterRef.current.offsetWidth}px`
      };
    }
    this.forceUpdate();
  }

  typeFilterClicked() {
    this.generateFilterStyling();
    this.setState(
        {
            typeFilterActive: !this.state.typeFilterActive
        }
    )
  }

  // The type filter is only present for team stats, shouldn't have to worry about checking for the tab
  typeFilterOptionSelected(event) {
    const selection = event.currentTarget.dataset.selection;
    var state = this.state;
    // Need to swap the special teams sorting column if we are currently sorting on one of them since they don't match between off/def in terms of name
    switch (state.sortingColumn) {
      case "PPG":
        state['sortingColumn'] = 'SHG';
        break;
      case "PP%":
        state['sortingColumn'] = 'SH%';
        break;
      case "SHG":
        state['sortingColumn'] = 'PPG';
        break;
      case "SH%":
        state['sortingColumn'] = 'PP%';
        break;
      default:
        break;
    }
    state["typeFilterSelection"] = selection;
    state["typeFilterActive"] = false;
    state["sortingOrder"] = this.teamSortingOrder[selection.toLowerCase()][state.sortingColumn.toUpperCase()];
    state["statColumns"] = this.teamStatsColumns[selection.toLowerCase()];
    const sortingColumnPath = state.statColumns[state.sortingGroup][state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", selection.toLowerCase());
    orderListByField(state.activeData, sortingColumnPath, state.sortingOrder); 
    this.setState(state, this.generateFilterStyling);
  }

  // Adding another argument here for multiple selection checkboxes to provide the latest values at all times
  //  This is needed as a workaround to a yet unknown issue with just using state + event dataset which would sometimes end up being outdated
  filterOptionSelected(event, optionalCheckedItems) {
    const selectedFilter = event.currentTarget.dataset.name;
    const selectedValue = event.currentTarget.dataset.selection;
    var state = this.state;
    state.filters[selectedFilter].isActive = false;
    if (state.filters[selectedFilter].type === "multipleSelection") {
      state.filters[selectedFilter].selectedValues = optionalCheckedItems;
    } else {
      state.filters[selectedFilter].selectedValue = selectedValue;
    }

    if (state.tab === "player") {
      const position = state.filters.position.selectedValue;

      var statColumns = {};
      if (position === "G") {
        statColumns = this.goaliesPlayerStatsColumns;
      } else {
        statColumns = this.skatersPlayerStatsColumns;
      }

      state["statColumns"] = statColumns;
      state["mergedStatColumns"] = statColumns;

      // For now setting to default:
      state["sortingColumn"] = this.defaultPlayerSortingColumn;
      state["sortingOrder"] = this.defaultPlayerSortingOrder;
    }

    this.loadData(state);
  }

  selectStatQualifier(event) {
    const selection = event.currentTarget.dataset.selection;
    var tempState = this.state;
    tempState.pickerSelection = selection;
    tempState['qualifier'] = selection;
    if (this.state.tab === "team") {
      const sortingColumnPath = tempState.statColumns[tempState.sortingGroup][tempState.sortingColumn.toUpperCase()].replaceAll("%qualifier%", tempState.qualifier).replaceAll("%TYPE%", tempState.typeFilterSelection.toLowerCase());
      orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
      this.setState(tempState);
    } else {
      if (tempState.activeData.length >= 50) {
        // console.log("Server side sorting");
        // If we have data size equal to the limit we have to assume it's possible there's more data and we need to query the API to re-sort
        this.loadData(tempState);
      } else {
        // console.log("Client side sorting");
        // If we have data size less than the limit, it means we have already filtered down the number of players and we can sort in place
        const sortingColumnPath = tempState.statColumns[tempState.sortingColumn.toUpperCase()].replaceAll("%qualifier%", tempState.qualifier);
        orderListByField(tempState.activeData, sortingColumnPath, tempState.sortingOrder);
        this.setState(tempState);
      }
    }
  }

  filtersModalButtonClicked() {
    // Assuming if you can click on the button your only option is to enable it (disabling it happens via another interaction)
    this.setState({filtersModalActive: true}, this.generateFilterStyling);
  }

  filtersModalCloseHandler() {
    this.setState({filtersModalActive: false});
  }

  openTeamProfile(event) {
    const teamCode = event.currentTarget.dataset.team;
    if (teamCode !== "") {
      this.setState({teamProfileModalActive: teamCode});
    }
  }

  openPlayerProfile(event) {
    const playerID = event.currentTarget.dataset.playerid;
    if (`${playerID}` in this.state.currentSeasonPlayerStats) {
      if (isDictEmpty(this.state.playerLeaders)) {
        fetchPlayerLeaders(API_HOST, this.props.activeLeague, {playerProfileModalActive: this.state.currentSeasonPlayerStats[`${playerID}`]}, this.setStateFunction);
      } else {
        this.setState({playerProfileModalActive: this.state.currentSeasonPlayerStats[`${playerID}`]});
      }
    } else {
      // Here we could be more efficient by doing both promises at the same time, 
      // but we already extracted fetchPlayerLeaders to get the players separately so we'll reuse it for the time being
      fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/bySRGUID/${playerID}`)
      .then(data => data.json())
      .then(result => {
        var currentSeasonPlayerStats = this.state.currentSeasonPlayerStats;
        currentSeasonPlayerStats[`${playerID}`] = result;
        var tempState = {
          playerProfileModalActive: result,
          currentSeasonPlayerStats: currentSeasonPlayerStats
        }
        if (isDictEmpty(this.state.playerLeaders)) {
          fetchPlayerLeaders(API_HOST, this.props.activeLeague, tempState, this.setStateFunction);
        } else {
          this.setState(tempState);
        }
      })
    }
  }

  closeProfile() {
    this.setState(
      {
        teamProfileModalActive: null,
        playerProfileModalActive: null
      }
    );
  }

  loadData(state) {
    // TODO: url encode
    // TODO: we can use a query params builder
    const filters = state.filters;
    const currentSeasonTimeframe = "SEASON_2023";
    var timeframe = "";
    const timeframeFilterValue = filters.timeframe.selectedValue;
    switch(timeframeFilterValue) {
        case "23-24 SEASON":
          timeframe = "SEASON_2023";
          break;
        case "22-23 SEASON":
          timeframe = "SEASON_2022";
          break;
        case "21-22 SEASON":
          timeframe = "SEASON_2021";
          break;
        case "LAST 5":
          timeframe = "LAST_5";
          break;
        case "LAST 10":
          timeframe = "LAST_10";
          break;
        default:
          break;
    }

    var split = "ALL";
    if (filters.splits.selectedValue !== "HOME+AWAY") {
        split = filters.splits.selectedValue;
    }

    const defaultFilterURL = API_HOST + '/api/' + this.props.activeLeague + '/v1/teams/all';
    if (state.tab === "team") {
      if (timeframe === currentSeasonTimeframe && split === "ALL") {
        if (isDictEmpty(state.currentSeasonTeamsStats)) {
          fetch(defaultFilterURL)
          .then(response => response.json())
          .then((data) => {
              state['activeData'] = data;
              var teamsDictionary = {};
              data.forEach(element => {
                teamsDictionary[element.code] = element;
              });
              state['currentSeasonTeamsStats'] = teamsDictionary;
              const sortingColumnPath = state.statColumns[state.sortingGroup][state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
              // const sortingColumnPath = state.statColumns[state.typeFilterSelection.toLowerCase()][state.sortingGroup][state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
              orderListByField(state.activeData, sortingColumnPath, state.sortingOrder);
              state.standings = buildStandings(data)
              this.setState(state, this.generateFilterStyling);
            }
          )
          .catch(error => {
            this.generateFilterStyling();
            console.log("Error loading nhl team leaderboard stats: " + error);
          });
        } else {
          // If we already have teams stats for the profiles, use that instead of making a separate call
          var teamsList = Object.values(state.currentSeasonTeamsStats);
          const sortingColumnPath = state.statColumns[state.sortingGroup][state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
          // const sortingColumnPath = state.statColumns[state.typeFilterSelection.toLowerCase()][state.sortingGroup][state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
          orderListByField(teamsList, sortingColumnPath, state.sortingOrder);
          state['activeData'] = teamsList;
          this.setState(state, this.generateFilterStyling);
        }
      } else {
        var promises = [
          // Team stats based on filters
          fetch(API_HOST + '/api/' + this.props.activeLeague + '/v1/teams/top?' + buildQueryParams({ indexed: false, timeframe: timeframe, split: split })).then(response => response.json())
        ];
        if (isDictEmpty(state.currentSeasonTeamsStats)) {
          // If we're getting data for a specific set of filters on initial load, meaning we don't have the stats loaded for the current season, also load those
          promises.push(fetch(defaultFilterURL).then(response => response.json()));
        }
        Promise.all(promises)
        .then(result => {
            state['activeData'] = result[0];
            const sortingColumnPath = state.statColumns[state.typeFilterSelection.toLowerCase()][state.sortingGroup][state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier).replaceAll("%TYPE%", state.typeFilterSelection.toLowerCase());
            orderListByField(state.activeData, sortingColumnPath, state.sortingOrder);
            if (result.length > 0) {
              var teamsDictionary = {};
              result[1].forEach(element => {
                teamsDictionary[element.code] = element;
              });
              state['currentSeasonTeamsStats'] = teamsDictionary;
            }
            this.setState(state, this.generateFilterStyling);
          }
        )
        .catch(error => {
          this.generateFilterStyling();
          console.log("Error loading nhl team leaderboard stats: " + error);
        });
      }
    } else if (state.tab === "player") {
      // Player stats

      // TODO: need position group for the leaders API
      const positionValue = filters.position.selectedValue;
      //["SKATERS", "FORWARDS", "C", "LW", "RW", "D", "G"],
      var position = "";
      if (positionValue === "SKATERS") {
        position = "C,LW,RW,D";
      } else if (positionValue === "FORWARDS") {
        position = "C,LW,RW";
      } else {
        position = positionValue;
      }

      var positionGroup = "";
      switch(positionValue) {
        case "SKATERS":
        case "FORWARDS":
          positionGroup = positionValue;
          break;
        case "C":
        case "LW":
        case "RW":
          positionGroup = "FORWARDS";
          break;
        case "D":
          positionGroup = "DEFENSEMEN";
        case "G":
          positionGroup = "GOALIES";
        default:
          break;
      }

      const teamFilterValues = filters.team.selectedValues;
      var team = "";
      if (teamFilterValues.indexOf("ALL") === -1 && teamFilterValues.indexOf("") === -1 && teamFilterValues.length > 0) {
        team = teamFilterValues.map(x => TEAM_CITY_TO_CODE[this.props.activeLeague][x]);
      }
      const sortingColumnPath = state.statColumns[state.sortingColumn.toUpperCase()].replaceAll("%qualifier%", state.qualifier);
      fetch(API_HOST + '/api/' + this.props.activeLeague + '/v1/players/top?' + buildQueryParams({
        team: team, 
        position: position,
        timeframe: timeframe,
        split: split,
        stat: sortingColumnPath,
        order: state.sortingOrder.toUpperCase()
      }))
      .then(response => {
          return response.json();
        }
      )
      .then((data) => {
          // console.log(" Fetched player data", data);
          state['activeData'] = data;
          this.setState(state, this.generateFilterStyling);
        }
      )
      .catch(error => {
        this.generateFilterStyling();
        console.log("Error loading player leaderboard stats: " + error);
      });
    }
  }

  render() {
    // console.log("Rendering");
    const windowWidth = window.innerWidth;
    const headerTextClasses = "font size-12 spaced";
    const contentTextClasses = "font size-14";
    var filtersClass = "full leaderboard-filters";
    const isFilterSticky = this.state.filterSticky;
    if (isFilterSticky && windowWidth > 576) {
      filtersClass = "full leaderboard-filters-sticky";
    }
    const columnCount = Object.keys(this.state.mergedStatColumns).length;

    var rankColumnWidthInPixels = 50;
    var nameColumnWidthInPixels = 100;
    if (windowWidth <= 576) {
      rankColumnWidthInPixels = 30;
    }
    if (this.state.tab === "player") {
      nameColumnWidthInPixels = 140;
    }
    const statColumnWidthInPixels = 75;

    const rankColumnStyle = {
      width: `${rankColumnWidthInPixels}px`,
      left: '0',
      position: 'sticky',
      zIndex: '2'
    };
    const nameColumnStyle = {
      width: `${nameColumnWidthInPixels}px`,
      left: `${rankColumnWidthInPixels}px`,
      position: 'sticky',
      zIndex: '2',
      boxShadow: '10px 0px 5px -10px #888888',
      WebkitBoxShadow: '10px 0px 5px -10px #888888',
      MozBoxShadow: '10px 0px 5px -10px #888888'
    };
    const statColumnStyle = {
      // The css has left: 0, what could that be for?
      width: `${statColumnWidthInPixels}px`,
      zIndex: '1'
    };

    const teamCodeField = this.state.tab.toLowerCase() === "team" ? "code" : "teamCode";

    var tableHeight = 0;
    var tableTopOffset = 0;
    if (this.tableRef.current) {
      tableHeight = this.tableRef.current.offsetHeight;
      tableTopOffset = this.tableRef.current.offsetTop;
    }
    return(
      <>
        <HtmlHeaders canonicalRef={`https://www.linemate.io/${this.props.activeLeague}/leaderboard`}/>
        {
          this.state.teamProfileModalActive !== null && Object.keys(this.state.currentSeasonTeamsStats).length > 0 ?
            <TeamProfileModal team={this.state.currentSeasonTeamsStats[this.state.teamProfileModalActive]} 
                              activeLeague={this.props.activeLeague} closeProfileFn={this.closeProfile}
                              stats={this.teamModalStats} allStandings={Object.values(this.state.currentSeasonTeamsStats)} /> 
            :
            <></>            
        }
        {
          this.state.playerProfileModalActive !== null && !isDictEmpty(this.state.playerLeaders)
          ?
            <PlayerProfileModal player={this.state.playerProfileModalActive} activeLeague={this.props.activeLeague} 
                                closeProfileFn={this.closeProfile} stats={getPlayerLeaderStats(this.props.activeLeague, this.state.playerProfileModalActive)} 
                                leaders={getLeaguePlayerLeaders(this.state.playerLeaders, this.props.activeLeague, this.state.playerProfileModalActive)}/> 
          : 
          <></>
        }
        <div className="content-container" onScroll={this.windowScrolled}>
          <div className="section-heading">
            <p className="font size-30 bold">Leaderboard</p>
            {
              (this.state.tab === "team" || this.state.tab === "player") && (
                <Picker options={this.pickerOptions} selection={this.state.pickerSelection} selectionHandlerFn={this.selectStatQualifier} fullWidthThreshold={990}/>
              )
            }
            {
              this.state.tab === "standings" && (
                <Picker options={this.standingsPickerOptions} selection={this.state.standingsKey} selectionHandlerFn={(event) => this.setState({standingsKey: event.currentTarget.dataset.selection})} fullWidthThreshold={990}/>
              )
            }
          </div>
          <TabToggle options={this.tabs} selection={this.state.tab} selectionHandler={(selection) => this.selectTab({currentTarget: {dataset: {selection: selection}}})} includeLegend={true} onLegendOpen={this.legendClicked}/>
          {
            this.state.tab === "standings" ?
            <div className="leaderboard-standings-wrapper">
            {
              Object.keys(this.state.standings[this.state.standingsKey]).sort().map((key) =>
                <div key={key}>
                  <p className="text-style-h-3-medium">{key}</p>
                  <Table 
                    columns={Object.keys(this.standingsColumns)} 
                    rowCount={this.state.standings[this.state.standingsKey][key].length} 
                    columnStyling={(columnIndex, columnName) => {
                      if (columnIndex === 1) {
                        return {width: 80};
                      }
                    }}
                    dataExtractor={(rowIndex, columnIndex, columnName) => {
                      if (columnIndex === 0) {
                        return rowIndex + 1;
                      }
                      const team = this.state.standings[this.state.standingsKey][key][rowIndex]
                      if (columnIndex === 1) {
                        const teamCode = team.code;
                        return (
                          // Wrapping the content of the cell in a div allows us to then use flex inside
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={getTeamLogoPath(this.props.activeLeague, teamCode)} height={32} width={32} alt={teamCode} style={{marginRight: 8}}/>
                            <p className="text-style-label-medium">{teamCode}</p>
                          </div>
                        )
                      }
                      return getDictionaryValue(team, this.standingsColumns[columnName]);
                    }}
                  />
                </div>
              )
            }
            </div>
            :
            <>
              <div className={filtersClass}>
                  <div className="betting-leaderboard-filter-wrapper">
                    {
                        Object.keys(this.state.filters).map((filter) => 
                            <div key={`leaderboard-${this.state.tab}-${filter}`} className='betting-leaderboard-filter-wrapper-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                                <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                <div>
                                    <InputSelection 
                                        enabled={true} 
                                        type={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? "multiple" : "single"}
                                        typography="md" 
                                        options={this.state.filters[filter].options} 
                                        selection={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? this.state.filters[filter].selectedValues : this.state.filters[filter].selectedValue} 
                                        selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}}, selection)}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
              </div>
              <div className="screener-btn-group-lg mobile" style={{width: '100%', margin: '0', marginBottom: '24px'}}>
                <button className="screener-btn-filter-lg" onClick={this.filtersModalButtonClicked}>
                  <img src="assets/filters.svg" width="16" height="16" alt=""/> 
                  <p>Filters</p>
                </button>
              </div>
              <div className="screener-modal-overlay" style={this.state.filtersModalActive ? {display: 'block'} : {display: 'none'}}
                                                  onClick={this.filtersModalCloseHandler}></div>
                {
                  this.state.filtersModalActive ?
                  <div className="screener-filters-modal" style={this.state.filtersModalActive ? {display: 'block'} : {display: 'none'}}>
                      <div className="screener-modal-header">
                          <h3>Filters</h3>
                          <div className="screener-close-modal" onClick={this.filtersModalCloseHandler}>
                              <img src="assets/playbook/close-major.svg" width="20" height="20" alt=""/>
                          </div>
                      </div>
                      <div className="screener-modal-body leaderboard-filters-modal">
                        <Picker options={this.pickerOptions} selection={this.state.pickerSelection} selectionHandlerFn={this.selectStatQualifier} fullWidthThreshold={990}/>
                        <div className="betting-leaderboard-filter-wrapper">
                            {
                                Object.keys(this.state.filters).map((filter) => 
                                    <div key={`leaderboard-${this.state.tab}-${filter}`} className='betting-leaderboard-filter-wrapper-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                                        <div>
                                            <InputSelection 
                                                enabled={true} 
                                                type={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? "multiple" : "single"}
                                                typography="md" 
                                                options={this.state.filters[filter].options} 
                                                selection={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? this.state.filters[filter].selectedValues : this.state.filters[filter].selectedValue} 
                                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}}, selection)}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                      </div>
                      <div className="screener-modal-footer">
                        <div className="screener-modal-btn-group">
                            <div>
                                <Button text="Cancel" typography="md" type="secondary" enabled={true} onClick={this.filtersModalCloseHandler}/>
                            </div>
                            <div>
                                <Button text="Apply Filters" typography="md" type="primary" enabled={true} onClick={this.filtersModalCloseHandler}/>
                            </div>
                        </div>
                      </div>
                  </div>
                  :
                  <>
                  </>
                }
              <div id="leaderboard-table-content" className="leaderboard-table-content" onScroll={this.tableScrolled}>
                <table ref={this.tableRef} className="leaderboard-table">
                  <colgroup>
                    <col style={rankColumnStyle}/>
                    <col style={nameColumnStyle}/>
                    {
                      this.state.tab === "team" ?
                      <>
                      {
                        Object.keys(this.state.statColumns).map((group, groupIndex) =>
                        <>
                        {
                          Object.keys(this.state.statColumns[group]).map((stat, statIndex) =>
                          <col key={stat + statIndex} style={statColumnStyle}/>
                          )
                        }
                        </>
                        )
                      }
                      </>
                      :
                      <>
                      {
                          Object.keys(this.state.statColumns).map((stat, statIndex) =>
                          <col key={stat + statIndex} style={statColumnStyle}/>
                          )
                      }
                      </>
                    }
                  </colgroup>
                  <thead>
                    {
                      this.state.tab !== "team" ? <></> :
                      <>
                      {/* When scrolling the colspan header doesn't behave too nicely. Might want to consider manually 
                          adding columns in the top header to build the equivalent of colspan but that would allow for smoother scrolling animation */}
                        <tr className="leaderboard-table-group-tr">
                          <th className="leaderboard-table-sticky-column" colspan="2" style={{borderBottom: 0, boxShadow: 'rgb(136 136 136) 10px 0px 5px -10px', zIndex: '10'}}></th>
                          {
                            Object.keys(this.state.statColumns).map((stat, index) =>
                              <th key={stat + index} style={{paddingLeft: '0', borderLeft: '1px solid var(--color-border-default)', borderBottom: '0', zIndex: (index + 1)}} 
                                  colspan={Object.keys(this.state.statColumns[stat]).length} >
                                <p className="font size-12 spaced" style={{width: '100%', textAlign: 'center', display: 'block'}}>
                                {
                                  stat.replaceAll("%qualifier%", this.state.qualifier).replaceAll("%TYPE%", this.state.typeFilterSelection)
                                }
                                </p>
                              </th>
                            )
                          }
                        </tr>
                      </>
                    }
                    <tr className="leaderboard-table-stats-tr">
                      <th className={headerTextClasses + " align-center"} ref={this.rankColumnRef} style={this.state.tab === "team" ? Object.assign({borderTop: 0}, rankColumnStyle) : rankColumnStyle}>
                        <span className="full" style={{paddingLeft: '0', paddingRight: '0'}}>
                          Rank
                        </span>
                        <span className="mobile" style={{paddingLeft: '0', paddingRight: '0'}}>
                          #
                        </span>
                      </th>
                      <th className={headerTextClasses + " align-left"} style={this.state.tab === "team" ? Object.assign({borderTop: 0}, nameColumnStyle) : nameColumnStyle}>{this.state.tab}</th>
                      {
                        this.state.tab === "team" ? 
                        <>
                        {
                          Object.keys(this.state.statColumns).map((group, groupIndex) =>
                            <>
                            {
                              Object.keys(this.state.statColumns[group]).map((stat, statIndex) =>
                              <th key={stat} className={
                                this.state.sortingColumn === stat && this.state.sortingGroup === group ?
                                headerTextClasses + " align-center clickable unselectable bold" :
                                headerTextClasses + " align-center clickable unselectable"
                              } data-selection={stat} data-group={group} onClick={this.headerClicked} 
                                style={ statIndex === 0 ? Object.assign({borderTop: 0, borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : Object.assign({borderTop: 0}, statColumnStyle)}>
                                <span className="thead-tooltip-wrapper">
                                  <span className="font size-14 undersized">
                                    {this.state.legendItems[stat.toUpperCase()]}
                                  </span>
                                  {stat}
                                  {
                                    this.state.sortingColumn === stat && this.state.sortingGroup === group ?
                                    <img src={"assets/order-" + this.state.sortingOrder + "-icon.svg"} /> :
                                    <>
                                    </>
                                  }
                                </span>
                              </th>
                              )
                            }
                            </>
                          )
                        }
                        </>
                        :
                        <>
                        {
                          Object.keys(this.state.statColumns).map((stat, statIndex) =>
                          <th key={stat} className={
                            this.state.sortingColumn === stat?
                            headerTextClasses + " align-center clickable unselectable bold" :
                            headerTextClasses + " align-center clickable unselectable"
                          } data-selection={stat} onClick={this.headerClicked} 
                            style={ statIndex === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : statColumnStyle}>
                            <span className="thead-tooltip-wrapper">
                              <span className="font size-14 undersized">
                                {this.state.legendItems[stat.toUpperCase()]}
                              </span>
                              {stat}
                              {
                                this.state.sortingColumn === stat?
                                <img src={"assets/order-" + this.state.sortingOrder + "-icon.svg"} /> :
                                <>
                                </>
                              }
                            </span>
                          </th>
                          )
                        }
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.activeData.map((entry,index) =>
                      <tr key={index + 1}>
                        <td className={contentTextClasses + " align-center"} style={Object.assign({paddingLeft: '0', paddingRight: '0'}, rankColumnStyle)}>{index + 1}</td>
                        <td className={contentTextClasses + " align-left clickable"} style={nameColumnStyle} 
                            data-team={this.state.tab.toLowerCase() === "team" ? entry.code : ""}
                            data-playerid={this.state.tab.toLowerCase() === "player" ? entry.SRGUID : ""}
                            onClick={this.state.tab.toLowerCase() === "team" ? this.openTeamProfile : this.openPlayerProfile}>
                          <div style={{display: 'inline-block', marginRight: '5px'}}>
                            <img src={getTeamLogoPath(this.props.activeLeague, entry[teamCodeField])} height={32} width={32} alt="" style={{marginRight: '5%'}}/>
                          </div>
                          <div className="full" style={this.state.tab.toLowerCase() === "team" ? {} : {textOverflow: 'ellipsis', width: '90px', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
                            {
                              this.state.tab.toLowerCase() === "team" ?
                              entry.code :
                              entry.info.firstName.charAt(0) + ". " + entry.info.lastName
                            }
                          </div>
                          <div className="mobile" style={this.state.tab.toLowerCase() === "team" ? {} : {textOverflow: 'ellipsis', width: '90px', overflow: 'hidden', whiteSpace: 'nowrap', verticalAlign: 'middle'}}>
                            {
                              this.state.tab.toLowerCase() === "team" ?
                              entry.code :
                              entry.info.firstName.charAt(0) + ". " + entry.info.lastName
                            }
                          </div>
                        </td>
                        {
                          this.state.tab === "team" ? 
                          <>
                          {
                            Object.keys(this.state.statColumns).map((group, groupIndex) =>
                            <>
                            {
                              Object.keys(this.state.statColumns[group]).map((stat, statIndex) =>
                              <td key={stat}
                                  className={contentTextClasses + " align-center"}
                                  style={this.state.sortingColumn === stat && this.state.sortingGroup === group ? 
                                  (statIndex === 0 ? Object.assign({background: 'var(--color-surface-sorted)', borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : Object.assign({background: 'var(--color-surface-sorted)'}, statColumnStyle) )  : 
                                  (statIndex === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : statColumnStyle)}>
                                  {getDictionaryValue(entry, this.state.statColumns[group][stat].replaceAll("%qualifier%", this.state.qualifier).replaceAll("%TYPE%", this.state.typeFilterSelection.toLowerCase())) || "0"}
                              </td>
                              )
                            }
                            </>
                            )
                          }
                          </>
                          :
                          <>
                          {
                            Object.keys(this.state.statColumns).map((stat, statIndex) =>
                            <td key={stat}
                                className={contentTextClasses + " align-center"}
                                style={this.state.sortingColumn === stat ? 
                                (statIndex === 0 ? Object.assign({background: 'var(--color-surface-sorted)', borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : Object.assign({background: 'var(--color-surface-sorted)'}, statColumnStyle) )  : 
                                (statIndex === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyle) : statColumnStyle)}>
                                {getDictionaryValue(entry, this.state.statColumns[stat].replaceAll("%qualifier%", this.state.qualifier)) || "0"}
                            </td>
                            )
                          }
                          </>
                        }
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>
            </>
          }
        </div>
        <span className="legend-modal" onClick={this.legendClosed} style={this.state.legendEnabled ? {} : {display: 'none'}}>
          <StatsLegend legendCloseHandler={this.childLegendClosed} legendItems={this.state.legendItems} category={this.state.tab} />
        </span>
      </>
    );
  }
}


export default NHLLeaderboard;
