import React from 'react';
import './fonts.css';
import './generic.css';
import './glossary.css';
import './betting-page.css';
import './screener.css';
import { decimalPrecision } from './linemate-react-common/src/util';
import { getPlayerTitle, getTeamLogoPath } from './react-web-utils';


/**
 * Props
 * - activeLeague
 * - tab: team|player
 * - teamCode
 * - lineValue
 * - betType
 * - playerRecord: optional player record if in player tab
 * - updateLine: function to handle a request to change the line value
 * - lineChangeHandler: event handler for when the line gets updated
 * - hits: hits for the over
 * - misses: misses for the over
 * - pushes
 * - gamesCount
 * - average
 * - device: desktop|mobile
 */
class HitRateBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const underHits = this.props.gamesCount - this.props.hits - this.props.pushes;
    var overPercentage = 0;
    var underPercentage = 0;

    if (this.props.gamesCount !== 0) {
        underPercentage = decimalPrecision(underHits/this.props.gamesCount * 100, 1);
        overPercentage = decimalPrecision(this.props.hits/this.props.gamesCount * 100, 1);
    }
    return(
        <>
            <div class="betting-content-card-trends-body-info-wrapper mobile">
                {
                    this.props.tab === "team" ? 
                    <div class="betting-content-card-flex-img-left">
                        <img src={getTeamLogoPath(this.props.activeLeague, this.props.teamCode)} height={24} width={24} />
                        <p class="font size-18 weight-600">Hit Rate</p>
                    </div>
                    :
                    <div class="betting-content-card-flex-img-left">
                        <img src={getTeamLogoPath(this.props.activeLeague, this.props.teamCode)} height={24} width={24} />
                        <p class="font size-18 weight-600">{`${getPlayerTitle(this.props.playerRecord, this.props.activeLeague, false, false)} - Hit Rate`}</p>
                    </div>
                }
                {/* <p class="font size-18 weight-600 dark display-block">Hit Rate</p> */}
                <div class="betting-content-card-trends-body-info-hit-rate" style={this.props.betType === "Spread" ? {width: '100%'} : {width: '50%'}}>
                    <p class="font size-14 display-block full-width align-center">{this.props.betType === "Spread" ? "Covered" : "Under"}</p>
                    <div class="betting-content-card-trends-body-info-hit-rate-text-fields" style={this.props.betType === "Spread" ? {left: '50%', transform: 'translateX(-50%'} : {}}>
                        <p class="font size-20 weight-600">{this.props.betType === "Spread" ? `${overPercentage}%` : `${underPercentage}%`}</p>
                        <div class="betting-content-card-trends-body-info-hit-rate-fractional">
                            <p class="font size-16 color-placeholder">{`${this.props.betType === "Spread" ? this.props.hits : underHits}/${this.props.gamesCount}`}</p>
                        </div>
                    </div>
                </div>
                {/* Optional 'over' value not applicable to spread */}
                <div class="betting-content-card-trends-body-info-hit-rate-over" style={this.props.betType === "Spread" ? {display: 'none'} : {display: 'inline-block'}}>
                    <p class="font size-14 display-block full-width align-center">Over</p>
                    <div class="betting-content-card-trends-body-info-hit-rate-text-fields">
                        <p class="font size-20 weight-600">{`${overPercentage}%`}</p>
                        <div class="betting-content-card-trends-body-info-hit-rate-fractional">
                            <p class="font size-16 color-placeholder">{`${this.props.hits}/${this.props.gamesCount}`}</p>
                        </div>
                    </div>
                </div>
                <div class="betting-content-card-trends-body-info-line">
                    <p class="font size-18 weight-600 display-block full-width align-center">Line</p>
                    <div class="betting-content-card-trends-body-info-line-selection">
                        <div class="betting-content-card-trends-body-info-line-selection-input">
                            {/* Leaving the two images next to each other since they are absolute to allow for the div > nth child to work */}
                            <img class="clickable" src="assets/input-minus-icon.svg" alt="" data-change={-0.5} onClick={this.props.updateLine}/>
                            <img class="clickable" src="assets/input-plus-icon.svg" alt="" data-change={0.5} onClick={this.props.updateLine}/>
                            <input type="number" value={this.props.lineValue} onChange={this.props.lineChangeHandler}/>
                        </div>
                    </div>
                </div>
            </div>
            {this.props.children}
        {
            this.props.tab === "team" ? <></> :
            <div class="betting-content-card-trends-body-info-def-rank mobile">
                <div class="betting-content-card-flex-img-left">
                    <p class="font size-18 weight-600">Average</p>
                </div>
                <p class="font size-14 grey display-block full-width align-center" style={{marginTop: '16px'}}>{this.props.betType}</p>
                {
                    <p class="font size-24 weight-600 display-block full-width align-center" style={{marginTop: '12px'}}>{this.props.average}</p>
                }
            </div>
        }
        <div class={`betting-content-card-trends-body-info-wrapper desktop ${this.props.tab === "team" ? " centered " : ""}`}
            style={this.props.betType === "Spread" ? {width: '560px'} : {width: '75%'}}>
            <div style={this.props.betType === "Spread" ? {width: '50%', height: '100%'} : {width: '66%', height: '100%'}}>
                {
                    this.props.tab === "team" ? 
                    <div class="betting-content-card-flex-img-left">
                        <img src={getTeamLogoPath(this.props.activeLeague, this.props.teamCode)} height={24} width={24} />
                        <p class="font size-18 weight-600">Hit Rate</p>
                    </div>
                    :
                    <div class="betting-content-card-flex-img-left">
                        <img src={getTeamLogoPath(this.props.activeLeague, this.props.teamCode)} height={24} width={24} />
                        <p class="font size-18 weight-600">{`${getPlayerTitle(this.props.playerRecord, this.props.activeLeague, false, false)} - Hit Rate`}</p>
                    </div>
                }
                <div class="betting-content-card-trends-body-info-hit-rate" style={this.props.betType === "Spread" ? {width: '100%'} : {width: '50%'}}>
                    <p class="font size-14 display-block full-width align-center">{this.props.betType === "Spread" ? "Covered" : "Under"}</p>
                    <div class="betting-content-card-trends-body-info-hit-rate-text-fields">
                        <p class="font size-24 weight-600">{this.props.betType === "Spread" ? `${overPercentage}%` : `${underPercentage}%`}</p>
                        <div class="betting-content-card-trends-body-info-hit-rate-fractional">
                            <p class="font size-18 color-placeholder">{`${this.props.betType === "Spread" ? this.props.hits : underHits}/${this.props.gamesCount}`}</p>
                        </div>
                    </div>
                </div>
                {/* Optional 'over' value not applicable to spread */}
                <div class="betting-content-card-trends-body-info-hit-rate-over" style={this.props.betType === "Spread" ? {display: 'none'} : {display: 'inline-block', width: '50%'}}>
                    <p class="font size-14 display-block full-width align-center">Over</p>
                    <div class="betting-content-card-trends-body-info-hit-rate-text-fields">
                        <p class="font size-24 weight-600">{`${overPercentage}%`}</p>
                        <div class="betting-content-card-trends-body-info-hit-rate-fractional">
                            <p class="font size-18 color-placeholder">{`${this.props.hits}/${this.props.gamesCount}`}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="betting-content-card-trends-body-info-line" style={this.props.betType === "Spread" ? {width: '50%'} : {width: '33%'}}>
                <p class="font size-18 weight-600 display-block full-width align-center">Line</p>
                <div class="betting-content-card-trends-body-info-line-selection">
                    <div class="betting-content-card-trends-body-info-line-selection-input">
                        {/* Leaving the two images next to each other since they are absolute to allow for the div > nth child to work */}
                        <img class="clickable" src="assets/input-minus-icon.svg" alt="" data-change={-0.5} onClick={this.props.updateLine}/>
                        <img class="clickable" src="assets/input-plus-icon.svg" alt="" data-change={0.5} onClick={this.props.updateLine}/>
                        <input type="number" value={this.props.lineValue} onChange={this.props.lineChangeHandler}/>
                    </div>
                </div>
            </div>
        </div>
        {
            this.props.tab === "team" ? <></> :
            <div class="betting-content-card-trends-body-info-def-rank desktop">
                <div class="betting-content-card-flex-img-left">
                    <p class="font size-18 weight-600">Average</p>
                </div>
                <p class="font size-14 display-block full-width align-center" style={{marginTop: '16px'}}>{this.props.betType}</p>
                {
                    <p class="font size-24 weight-600 display-block full-width align-center" style={{marginTop: '12px'}}>{this.props.average}</p>
                }
            </div>
        }
        </>   
    )
  }
}

export default HitRateBox;
