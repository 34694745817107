import React from 'react';

import './Landing.scss'
import '../components/App.scss'

import GlobalContext from '../components/GlobalContext';
import { SUPPORTED_LEAGUES, SUPPORTED_LEAGUES_METADATA } from '../linemate-react-common/src/constants';
import Button from '../components/Button';
import { capitalizeFirstLetter, generateDummyPlay } from '../linemate-react-common/src/util';
import { getLeagueLogoPath, getTheme } from '../react-web-utils';
import Picker from '../picker';
import TrendContent from '../components/TrendContent';

const features = ['discover', 'trends', 'workstation']
const featureText = {
    discover: 'Finding a confident play from thousands of bet types should always be this easy.',
    trends: 'Every trending play with statistical headlines. Gain an edge with insights at your fingertips.',
    workstation: 'Every trending play with statistical headlines. Gain an edge with insights at your fingertips.'
}
const dummyCardData = {
    ligue1: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "AWAY_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 5 of his last 7 games', annotation: '71%'},
            {description: 'Hit in 2 of his last 2 games vs FCM', annotation: '100%'},
            {description: 'Hit in 2 of his last 3 away games', annotation: '67%'},
            {description: 'FCM rank 16th in Goals against', annotation: '16th'}
        ],
        team: {code: 'LIL'},
        opposingTeam: {code: 'FCM'},
        player: {firstName: 'J',lastName: 'David'},
        market: "ANYTIME_GOAL_SCORER",
        outcome: 'over',
        line: 0.5,
        odds: {american: -135,decimal: 1.74,fractional: "37/50"},
        home: false
    },
    bundesliga: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "HOME_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 4 of his last 6 games', annotation: '67%'},
            {description: 'Hit in 3 of his last 4 games vs VFB', annotation: '75%'},
            {description: 'Hit in 2 of his last 3 home games', annotation: '93%'},
            {description: 'KOE rank 17th in Goals against', annotation: '17th'}
        ],
        team: {code: 'LEV'},
        opposingTeam: {code: 'KOE'},
        player: {firstName: 'F',lastName: 'Wirtz'},
        market: "ANYTIME_GOAL_SCORER",
        outcome: 'over',
        line: 0.5,
        odds: {american: +195,decimal: 2.95,fractional: "39/20"},
        home: true
    },
    mlb: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "AWAY_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 5 of his last 5 games', annotation: '100%'},
            {description: 'Hit in 2 of his last 2 games vs CWS', annotation: '100%'},
            {description: 'Hit in 4 of his last 6 away games', annotation: '67%'},
            {description: 'CWS rank 20th in Hits against', annotation: '20th'}
        ],
        team: {code: 'LAD'},
        opposingTeam: {code: 'CWS'},
        player: {firstName: 'S',lastName: 'Ohtani'},
        market: "HITTER_HITS",
        outcome: 'over',
        line: 0.5,
        odds: {american: -110,decimal: 1.91,fractional: "91/100"},
        home: false
    },
    epl: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "HOME_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 3 of his last 4 games', annotation: '75%'},
            {description: 'Hit in 4 of his last 4 games vs BUR', annotation: '100%'},
            {description: 'Hit in 3 of his last 3 home games', annotation: '100%'},
            {description: 'BUR rank 19th in Goals Against', annotation: '19th'}
        ],
        team: {code: 'MCI'},
        opposingTeam: {code: 'BUR'},
        player: {firstName: 'Phil',lastName: 'Foden'},
        market: "ANYTIME_GOAL_SCORER",
        outcome: 'over',
        line: 0.5,
        odds: {american: -150,decimal: 1.67,fractional: "67/100"},
        home: true
    },
    nfl: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "AWAY_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 2 of his last 2 games', annotation: '100%'},
            {description: 'Hit in 3 of his last 4 games vs KC', annotation: '75%'},
            {description: 'Hit in 13 of his last 14 away games', annotation: '93%'},
            {description: 'KC rank 26th in Pass TD Against', annotation: '26th'}
        ],
        team: {code: 'BUF'},
        opposingTeam: {code: 'KC'},
        player: {firstName: 'Josh',lastName: 'Allen'},
        market: "PASSING_TOUCHDOWNS",
        outcome: 'over',
        line: 1.5,
        odds: {american: -105,decimal: 1.95,fractional: "19/20"},
        home: false
    },
    nba: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "HOME_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 6 of his last 7 games', annotation: '86%'},
            {description: 'Hit in 4 of his last 4 games vs SAS', annotation: '100%'},
            {description: 'Hit in 11 of his last 14 home games', annotation: '79%'},
            {description: 'SAS rank 29th in Points Against', annotation: '29th'}
        ],
        team: {code: 'PHI'},
        opposingTeam: {code: 'SAS'},
        player: {firstName: 'Joel',lastName: 'Embiid'},
        market: "POINTS",
        outcome: 'over',
        line: 34.5,
        odds: {american: -110,decimal: 1.91,fractional: "91/100"},
        home: true
    },
    nhl: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "AWAY_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 5 of his last 5 games', annotation: '100%'},
            {description: 'Hit in 3 of his last 3 games vs MTL', annotation: '100%'},
            {description: 'Hit in 8 of his last 9 away games', annotation: '89%'},
            {description: 'MTL rank 30th in Goals Against', annotation: '30th'}
        ],
        team: {code: 'BOS'},
        opposingTeam: {code: 'MTL'},
        player: {firstName: 'D',lastName: 'Pastrnak'},
        market: "POINTS",
        outcome: 'over',
        line: 0.5,
        odds: {american: +104,decimal: 2.04,fractional: "26/25"},
        home: false
    },
    laliga: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "AWAY_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 6 of his last 6 games', annotation: '100%'},
            {description: 'Hit in 4 of his last 5 games vs ALM', annotation: '80%'},
            {description: 'Hit in 3 of his last 3 away games', annotation: '100%'},
            {description: 'ALM rank 18th in Shots on Target against', annotation: '18th'}
        ],
        team: {code: 'RMA'},
        opposingTeam: {code: 'ALM'},
        player: {firstName: 'V',lastName: 'Junior'},
        market: "ONE_OR_MORE_SHOTS_ON_TARGET",
        outcome: 'over',
        line: 0.5,
        odds: {american: -196,decimal: 1.51,fractional: "51/100"},
        home: false
    },
    seriea: {
        narratives: ["RECENT_FORM", "HEAD_TO_HEAD", "HOME_SPLIT", "OPPONENT"],
        insights: [
            {description: 'Hit in 3 of his last 3 games', annotation: '100%'},
            {description: 'Hit in 2 of his last 2 games vs SAS', annotation: '100%'},
            {description: 'Hit in 12 of his last 17 home games', annotation: '71%'},
            {description: 'SAS rank 20th in Shots on Target against', annotation: '20th'}
        ],
        team: {code: 'INT'},
        opposingTeam: {code: 'SAS'},
        player: {firstName: 'L',lastName: 'Martinez'},
        market: "ANYTIME_GOAL_SCORER",
        outcome: 'over',
        line: 0.5,
        odds: {american: -150,decimal: 1.67,fractional: "67/100"},
        home: true
    }
}

// Implementation of auto-scroll with loop. Would be cool to have something like that in the future but no time for now
// https://codepen.io/vincentorback/pen/eeWwBq
class Landing extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.planPickerOptions = {
            free: 'Free',
            paid: 'Linemate+'
        }

        this.state = {
            featureToggleSelection: features[0],
            planPickerSelection: Object.keys(this.planPickerOptions)[1]
        }
    }

    // TODO: for width < 480px we set card width to 100% of available space so may need to recalculate for <480px window size
    componentDidMount() {
        document.title = 'Linemate';
        const trendToScroll = document.getElementsByClassName('landing-page-showcase-subscription-trends-trend')[4];
        const viewPortWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

        // xPosition of target trend - half screen size + half card size
        const xPositionToScroll = trendToScroll.getBoundingClientRect().x - (viewPortWidth / 2) + (424 / 2);
        document.getElementsByClassName('landing-page-showcase-subscription-trends')[0].scrollTo(xPositionToScroll, 0)
    }

    // TODO: should we redirect logged users away from landing page? 
    // TODO: fix EPL (and maybe some other) league icons to have a theme-specific image
    // TODO: keep the height of badges constant when switching between free and paid
    render() {
        return (
            <div className='landing-page-container'>
                <div className='landing-page-hero'>
                    <div className='landing-page-headline'>
                        <div className='landing-page-headline-title'>
                            <p className='color-fig-default'>Find&nbsp;</p>
                            <p>your</p>
                            <p className='color-fig-default'>&nbsp;next bet</p>
                        </div>
                        <p className='text-style-h-3-normal color-fig-subtle'>Discover free betting trends and insights.</p>
                        <div className='landing-page-headline-buttons'>
                            <div className='landing-page-headline-get-started-button-wrapper'>
                                <Button text="Get started" typography="xl" type="primary" enabled={true} onClick={() => window.location.href = `/${this.context.configuration.metadata.defaultLeague}`}/>
                            </div>
                            <div className='landing-page-headline-download-app-button-wrapper'>
                                <Button text="Download app" typography="xl" type="secondary" enabled={true} onClick={() => window.location.href = `/download`}/>
                            </div>
                        </div>
                    </div>
                    <div className='landing-page-features'>
                        <div className='landing-page-features-toggles'>
                        {
                            features.map((feature) => 
                                <div className='landing-page-features-toggles-toggle unselectable' data-selected={this.state.featureToggleSelection === feature} onClick={() => this.setState({featureToggleSelection: feature})}>
                                    <p className='text-style-body-normal'>{capitalizeFirstLetter(feature)}</p>
                                </div>
                            )
                        }
                        </div>
                        <p className='text-style-label-normal color-fig-subtle'>{featureText[this.state.featureToggleSelection]}</p>
                        {/* Because the image is theme based, if the user switches theme the image will stay from the old theme until they either refresh the page or switch feature */}
                        {/* This isn't perfect, it would be nice to handle the theme switch but can't be bothered */}
                        <img src={`assets/landing/hero-image-${this.state.featureToggleSelection}-${getTheme()}.png`} alt={this.state.featureToggleSelection}/>
                    </div>
                </div>
                <div className='landing-page-leagues'>
                    <p className='color-fig-default'>Covering all major leagues and more</p>
                    <div className='landing-page-leagues-icons'>
                    {
                        SUPPORTED_LEAGUES.map((league) =>
                            <img key={league} src={getLeagueLogoPath(league)} height={80} width={80} alt={league}/>
                        )
                    }
                    </div>
                </div>
                <div className='landing-page-showcase'>
                    <div className='landing-page-showcase-headline'>
                        <div className='landing-page-showcase-headline-title'>
                            <p className='color-fig-default'>Start saving time with Linemate+</p>
                        </div>
                        <p className='text-style-h-3-normal color-fig-subtle'>Unlocked access to daily plays with multiple merging trends. Time is money and we want you in a scoring position.</p>
                        <div className='landing-page-showcase-headline-buttons'>
                            <div className='landing-page-showcase-headline-get-started-button-wrapper'>
                                {/* TODO: do we redirect to pricing after signup here? We're already having a link to pricing */}
                                <Button text="Start free trial" typography="xl" type="primary" enabled={true} onClick={() => window.location.href = `/signup`}/>
                            </div>
                            <div className='landing-page-showcase-headline-download-app-button-wrapper'>
                                <Button text="View pricing" typography="xl" type="secondary" enabled={true} onClick={() => window.location.href = `/pricing`}/>
                            </div>
                        </div>
                    </div>
                    <div className='landing-page-showcase-subscription'>
                        <Picker options={this.planPickerOptions} selection={this.state.planPickerSelection} selectionHandlerFn={(event) => this.setState({planPickerSelection: event.currentTarget.dataset.selection})}/>
                        <div className='landing-page-showcase-subscription-trends hide-scroll'>
                        {
                            Object.keys(dummyCardData).map((league) => {
                                const card = dummyCardData[league]
                                return (
                                    <div className='landing-page-showcase-subscription-trends-trend-wrapper'>
                                        <p className='text-style-label-normal color-fig-default'>{SUPPORTED_LEAGUES_METADATA[league].title} trending play</p>
                                        <div key={league} className='landing-page-showcase-subscription-trends-trend'>
                                            <TrendContent 
                                                league={league}
                                                card={generateDummyPlay(
                                                    'player', 
                                                    card.home, 
                                                    this.state.planPickerSelection === 'paid' ? card.insights : card.insights.slice(0, 1), 
                                                    card.outcome, 
                                                    card.market, 
                                                    card.line, 
                                                    card.odds, 
                                                    this.state.planPickerSelection === 'paid' ? card.narratives : card.narratives.slice(0, 1), 
                                                    card.team, 
                                                    card.opposingTeam, 
                                                    card.player
                                                )}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className='landing-page-footer'>
                    <div className='landing-page-footer-text'>
                        <p className='color-fig-subtle'>Ready for better plays?</p>
                        <p className='color-fig-default'>Start your free trial</p>
                    </div>
                    <div className='landing-page-footer-buttons'>
                        <div className='landing-page-showcase-headline-get-started-button-wrapper'>
                            {/* TODO: do we redirect to pricing after signup here? We're already having a link to pricing */}
                            <Button text="Start free trial" typography="xl" type="primary" enabled={true} onClick={() => window.location.href = `/signup`}/>
                        </div>
                        <div className='landing-page-headline-download-app-button-wrapper'>
                            <Button text="Download app" typography="xl" type="secondary" enabled={true} onClick={() => window.location.href = `/download`}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Landing;