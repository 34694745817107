import React from 'react';

import './Trend.scss'
import './App.scss'
import GlobalContext from './GlobalContext';
import Button from './Button';
import TrendContent from './TrendContent';
import { base64Encode } from '../linemate-react-common/src/util';

/**
 * Props
 * - title: string
 * - subtitle: string?
 * - endpoint
 * - cards: array
 * - type: string?
 * - filtersToExclude:[string]?
 * - loaded: bool
 */
class Trend extends React.Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.increaseIndex = this.increaseIndex.bind(this);
    this.decreaseIndex = this.decreaseIndex.bind(this);

    this.state = {
        index: 0
    }
  }

  increaseIndex() {
    this.setState((previousState) => ({index: Math.min(previousState.index + 1, this.props.cards.length - 1)}))
  }

  decreaseIndex() {
    this.setState((previousState) => ({index: Math.max(0, previousState.index - 1)}))
  }

  render() {
    const hasContent = this.props.loaded && this.props.cards.length > 0;
    const card = hasContent ? this.props.cards[this.state.index] : null;
    const feed = base64Encode(JSON.stringify({title: this.props.title, subtitle: this.props.subtitle, endpoint: this.props.endpoint, filtersToExclude: this.props.filtersToExclude || []}))
    const feedHref = `/${this.context.league}/trends?feed=${feed}${this.props.type ? `&type=${this.props.type}` : ""}`
    return (
      <div className='trend-container'>
        <div className='trend-header'>
          <div className='trend-header-left'>
              <p className='text-style-h-3-medium trend-header-left-title'>{this.props.title}</p>
              <p className='text-style-h-4-medium trend-header-left-title'>{this.props.title}</p>
              {this.props.subtitle && (<p className='text-style-h-3-medium trend-header-left-subtitle'>{this.props.subtitle}</p>)}
              {this.props.subtitle && (<p className='text-style-h-4-medium trend-header-left-subtitle'>{this.props.subtitle}</p>)}
          </div>
          <div className='trend-header-right'>
            {
              hasContent && (
                <>
                  <a href={feedHref} className='text-style-body-normal'>View All</a>
                  <a href={feedHref} className='text-style-label-normal'>View All</a>
                </>
              )
            }
            <div className='trend-header-right-button'>
                <Button icon='assets/chevron-left.svg' type='secondary' enabled={hasContent && this.state.index > 0} onClick={this.decreaseIndex}/>
            </div>
            <div className='trend-header-right-button'>
                <Button icon='assets/chevron-right.svg' type='secondary' enabled={hasContent && this.state.index < this.props.cards.length - 1} onClick={this.increaseIndex}/>
            </div>
          </div>
        </div>
        {
          card && (
            <a className='trend-card' href={`${feedHref}&index=${this.state.index}`}>
              <TrendContent card={card}/>
            </a>
          )
        }
        {
          // Mobile
          hasContent && (
            <div className='trend-section-mobile'>
            {
              this.props.cards.map((card, index) =>
                <div className='trend-card-mobile' href={`${feedHref}&index=${index}`}>
                  <TrendContent card={card}/>
                </div>
              )
            }
            </div>
          )
        }
        {
          !card && (
            <div className='trend-card-no-content'>
              <div className='trend-card-no-content-text'>
                <img src='assets/play.svg' alt="play"/>
                <div>
                  <p className='text-style-h-4-medium'>No play available</p>
                  <p className='text-style-label-normal'>Please check back later</p>
                </div>
              </div>
              <div className='trend-card-no-content-button-wrapper'>
                <Button text='Explore more trends' type='secondary' enabled={true} typography='md' onClick={() => window.location.href = `/${this.context.league}/trends`}/>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default Trend;