import _ from 'lodash';
import { getDictionaryValueRawResult, swapDictionary } from './core-utils.js';

export const TOURNAMENT_SOCCER_LEAGUES = {
  euro: {
    title: 'Euro',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  }
}

export const IN_YEAR_SOCCER_LEAGUES = {
  "brazil-serie-a": {
    title: 'Brasileiro Série A',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  },
  mls: {
    title: 'MLS',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  }
}

export const CROSS_YEAR_SOCCER_LEAGUES = {
  bundesliga: {
    title: 'Bundesliga',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  },
  epl: {
    title: 'EPL',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  },
  laliga: {
    title: 'LaLiga',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  },
  ligue1: {
    title: 'Ligue 1',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  },
  seriea: {
    title: 'Serie A',
    sport: 'soccer',
    webIcon: 'assets/soccer-icon.svg',
    webIconSelected: 'assets/soccer-icon.svg',
    appIconPrimary: 'icons/sports/soccer',
    appIconSecondary: 'icons/sports/soccer-secondary'
  }
};

export const SOCCER_LEAGUES = Object.assign({}, CROSS_YEAR_SOCCER_LEAGUES, IN_YEAR_SOCCER_LEAGUES, TOURNAMENT_SOCCER_LEAGUES)


export const MIXED_LEAGUES = {
  mlb: {
    title: 'MLB',
    sport: 'baseball',
    webIcon: 'assets/baseball-icon.svg',
    webIconSelected: 'assets/baseball-icon-white.svg',
    appIconPrimary: 'icons/sports/baseball',
    appIconSecondary: 'icons/sports/baseball-secondary'
  },
  nba: {
    title: 'NBA',
    sport: 'basketball',
    webIcon: 'assets/basketball-icon.svg',
    webIconSelected: 'assets/basketball-icon-white.svg',
    appIconPrimary: 'icons/sports/basketball',
    appIconSecondary: 'icons/sports/basketball-secondary'
  },
  wnba: {
    title: 'WNBA',
    sport: 'basketball',
    webIcon: 'assets/basketball-icon.svg',
    webIconSelected: 'assets/basketball-icon-white.svg',
    appIconPrimary: 'icons/sports/basketball',
    appIconSecondary: 'icons/sports/basketball-secondary'
  },
  nfl: {
    title: 'NFL',
    sport: 'football',
    webIcon: 'assets/football-icon.svg',
    webIconSelected: 'assets/football-icon-white.svg',
    appIconPrimary: 'icons/sports/football',
    appIconSecondary: 'icons/sports/football-secondary'
  },
  nhl: {
    title: 'NHL',
    sport: 'hockey',
    webIcon: 'assets/hockey-icon.svg',
    webIconSelected: 'assets/hockey-icon-white.svg',
    appIconPrimary: 'icons/sports/hockey',
    appIconSecondary: 'icons/sports/hockey-secondary'
  }
};
export const SUPPORTED_LEAGUES = Object.keys(MIXED_LEAGUES).concat(Object.keys(SOCCER_LEAGUES).sort());
export const SUPPORTED_LEAGUES_METADATA = Object.assign({}, MIXED_LEAGUES, SOCCER_LEAGUES);


// Review this, we may be able to remove it since we don't even have MLB here
export const LEAGUE_STAT_MAPPING = {
  nhl: {
    "default": {
      "gp": "gamesPlayed",
      "w": "standings.wins",
      "l": "standings.losses",
      "otl": "standings.overtimeLosses",
      "p%": "standings.pointPercentage",
      "diff": "standings.differential", // TODO handle this in last5, last10, splits, etc. Do we need this?
      "cf%": "stats.corsiPercentage",
      "blk": "stats.defensiveBlocks",
      "eg": "stats.evenGoals",
      "gf": "stats.goals",
      "ga": "stats.goalsAllowed",
      "hit": "stats.hits",
      "miss": "stats.missedShots",
      "sh%": "stats.penaltyKillPercentage",
      "pim": "stats.penaltyMinutes",
      "ppg": "stats.powerplayGoals",
      "pp%": "stats.powerplayPercentage",
      "sv%": "stats.savePercentage",
      "shg": "stats.shorthandedGoals",
      "sat": "stats.shotAttempts",
      "sat-a": "stats.shotAttemptsAgainst",
      "sa": "stats.shotsAgainst",
      "sog": "stats.shotsOnGoal",
      "sog-a": "stats.shotsAgainst",
      "hdc": "stats.highDangerChances", // TODO
      "hdc-a": "stats.highDangerChancesAgainst", // TODO
      "xg": "stats.expectedGoals", // TODO
      "xga": "stats.expectedGoalsAgainst" // TODO
    },
    "team": {
      "pts": "standings.points",
    },
    "player": {
      "pts": "stats.points",
      "g": "stats.goals",
      "a": "stats.assists",
      "+/-": "stats.plusMinus",
      "gaa": "stats.goalsAllowed",
      "sa": "stats.shotsAgainst",
      "wins": "stats.wins",
      "shutouts": "stats.shutouts",
      "toi": "stats.timeOnIce",
      "saves": "stats.shotsSaved",
      "ppp": "stats.powerplayPoints",
      "shp": "stats.shorthandedPoints"
    }
  },
  nfl: {
    "default": {
      "pf": "stats.offensive.points",
      "plays": "stats.offensive.plays",
      "net-yds": "stats.offensive.totalYards",
      "td": "stats.offensive.totalTouchdowns",
      "rz%": "stats.offensive.redzoneSuccessRate", // TODO: calculate rz%,
      "pa": "stats.defensive.points",
      "plays-a": "stats.defensive.plays",
      "net-yds-a": "stats.defensive.totalYards",
      "td-a": "stats.defensive.totalTouchdowns",
      "rz%-a": "stats.defensive.redzoneSuccessRate",

      "pts": "stats.offensive.points",
      "gp": "gamesPlayed",

      "score%": "stats.offensive.scorePercentage", // TODO
      "plays": "stats.offensive.plays", // TODO
      "pass-att": "stats.offensive.passingAttempts",
      "pass-yds": "stats.offensive.passingYards",
      "pass-td": "stats.offensive.passingTouchdowns",
      "pass-compl": "stats.offensive.passingCompletions",

      "rush-yds": "stats.offensive.rushingYards",
      "rush-att": "stats.offensive.rushingAttempts",
      "rush-td": "stats.offensive.rushingTouchdowns",
      "total-td": "stats.offensive.totalTouchdowns",

      "1d": "stats.offensive.firstDowns",
      "3d%": "stats.offensive.thirdDownConversionPercentage",
      "4d%": "stats.offensive.fourthDownConversionPercentage",
      "int": "stats.offensive.interceptions",
      "to": "stats.offensive.turnovers",
      "sacks": "stats.offensive.sacks",
      "fuml": "stats.offensive.fumbles",

      "pts-a": "stats.defensive.points",
      "score%-a": "stats.defensive.scorePercentage",
      "plays-a": "stats.defensive.plays",

      "pass-yds-a": "stats.defensive.passingYards",
      "pass-att-a": "stats.defensive.passingAttempts",
      "pass-compl-a": "stats.defensive.passingCompletions",
      "pass-td-a": "stats.defensive.passingTouchdowns",

      "rush-yds-a": "stats.defensive.rushingYards",
      "rush-att-a": "stats.defensive.rushingAttempts",
      "rush-td-a": "stats.defensive.rushingTouchdowns",

      "total-td-a": "stats.defensive.totalTouchdowns",

      "1d-a": "stats.defensive.firstDowns",
      "3d%-a": "stats.defensive.thirdDownConversionPercentage",
      "4d%-a": "stats.defensive.fourthDownConversionPercentage",

      "int-a": "stats.defensive.interceptions",
      "to-a": "stats.defensive.turnovers",
      "sacks-a": "stats.defensive.sacks",
      "fuml-a": "stats.defensive.fumbles"
    },
    "player": {
      "pass-att": "stats.passingAttempts",
      "comp": "stats.passingCompletions",
      "pass-yds": "stats.passingYards",
      "pass-td": "stats.passingTouchdowns",
      "int": "stats.interceptions",
      "rush-att": "stats.rushingAttempts",
      "rush-yds": "stats.rushingYards",
      "rush-yds/att": "stats.rushingAverage", // TODO: needs to be set as a stat to be averaged
      "rush-td": "stats.rushingTouchdowns",
      "fuml": "stats.fumbles",
      "tgt": "stats.targets",
      "rec": "stats.receptions",
      "rec-yds": "stats.receivingYards",
      "rec-td": "stats.receivingTouchdowns",
      "pts": "stats.", // TODO: track all the kicker stats
      "fga": "stats.",
      "fgm": "stats.",
      "fg%": "stats.",
      "xp%": "stats.",
      "long-rush": "stats.longestRun",
      "100+": "stats.TBD", // TODO,
      "snaps": "stats.TBD", // TODO
      "snap%": "stats.TBD", // TODO
      "rz-rush-att": "stats.TBD", // TODO
      "rz-rush-td": "stats.TBD",// TODO
      "pass rtg": "stats.rating" ,
      "comp%": "stats.completionPercentage",
      "pass-yds/att": "stats.TBD", //TODO
      "pass-yds/comp": "stats.TBD", //TODO
      "sack yds": "stats.sackedYards",
      "long-pass": "stats.longestCompletion",
      "300+": "stats.TBD", //TODO
      "rz-pass-att": "stats.TBD", //TODO
      "rz-pass-td": "stats.TBD", //TODO
      "rz-comp": "stats.TBD", //TODO
      "rz-comp%": "stats.TBD", //TODO,
      "adot": "stats.TBD", //TODO
      "ay": "stats.TBD", //TODO
      "yds/rec": "stats.TBD", //TODO
      "long-rec": "stats.longestReception",
      "rz-tgt": "stats.TBD", //TODO
      "rz-rec": "stats.TBD", //TODO
      "rz-rec-td": "stats.TBD", //TODO
      "xpa": "stats.TBD", //TODO
      "xpm": "stats.TBD", //TODO
      "fga/g": "stats.TBD", //TODO
      "0-19": "stats.TBD", //TODO
      "20-29": "stats.TBD", //TODO
      "30-39": "stats.TBD", //TODO
      "40-49": "stats.TBD", //TODO
      "50+": "stats.TBD",  //TODO
    }
  },
  nba: {
    "default": {
      "pf": "stats.points",
      "ast": "stats.assists",
      "reb": "stats.rebounds",
      "stl": "stats.steals",
      "to": "stats.turnovers",
      "pa": "stats.pointsAgainst",
      "ast-a": "stats.assistsAgainst",
      "reb-a": "stats.reboundsAgainst",
      "stl-a": "stats.stealsAgainst",
      "to-a": "stats.turnoversAgainst",
      "pts": "stats.points",

      "gp": "gamesPlayed",
      "ftm": "stats.freeThrowsMade",
      "fta": "stats.freeThrowAttempts",
      "ft%": "stats.freeThrowPercentage",
      "fgm": "stats.fieldGoalsMade",
      "fga": "stats.fieldGoalAttempts",
      "fg%": "stats.fieldGoalPercentage",
      "3ptm": "stats.threePointsMade",
      "3pta": "stats.threePointAttempts",
      "3pt%": "stats.threePointPercentage",
      "ts%": "stats.trueShootingPercentage",
      "efg%": "stats.effectiveFieldGoalPercentage",
      "blk": "stats.blocks",
      "ortg": "stats.offensiveRating",
      "drtg": "stats.defensiveRating",

      "ftm-a": "stats.freeThrowsMadeAgainst",
      "fta-a": "stats.freeThrowAttemptsAgainst",
      "ft%-a": "stats.freeThrowPercentageAgainst",
      "fgm-a": "stats.fieldGoalsMadeAgainst",
      "fga-a": "stats.fieldGoalAttemptsAgainst",
      "fg%-a": "stats.fieldGoalPercentageAgainst",
      "3ptm-a": "stats.threePointsMadeAgainst",
      "3pta-a": "stats.threePointAttemptsAgainst",
      "3pt%-a": "stats.threePointPercentageAgainst",
      "ts%-a": "stats.trueShootingPercentageAgainst",
      "efg%-a": "stats.effectiveFieldGoalPercentageAgainst",
      "blk-a": "stats.blocksAgainst",
      "ortg-a": "stats.offensiveRatingAgainst",
      "drtg-a": "stats.defensiveRatingAgainst",

      "mp": "stats.minutesPlayed",
      "pie": "stats.playerImpactEstimate"
    }
  },
  wnba: {
    "default": {
      "pf": "stats.points",
      "ast": "stats.assists",
      "reb": "stats.rebounds",
      "stl": "stats.steals",
      "to": "stats.turnovers",
      "pa": "stats.pointsAgainst",
      "ast-a": "stats.assistsAgainst",
      "reb-a": "stats.reboundsAgainst",
      "stl-a": "stats.stealsAgainst",
      "to-a": "stats.turnoversAgainst",
      "pts": "stats.points",

      "gp": "gamesPlayed",
      "ftm": "stats.freeThrowsMade",
      "fta": "stats.freeThrowAttempts",
      "ft%": "stats.freeThrowPercentage",
      "fgm": "stats.fieldGoalsMade",
      "fga": "stats.fieldGoalAttempts",
      "fg%": "stats.fieldGoalPercentage",
      "3ptm": "stats.threePointsMade",
      "3pta": "stats.threePointAttempts",
      "3pt%": "stats.threePointPercentage",
      "ts%": "stats.trueShootingPercentage",
      "efg%": "stats.effectiveFieldGoalPercentage",
      "blk": "stats.blocks",
      "ortg": "stats.offensiveRating",
      "drtg": "stats.defensiveRating",

      "ftm-a": "stats.freeThrowsMadeAgainst",
      "fta-a": "stats.freeThrowAttemptsAgainst",
      "ft%-a": "stats.freeThrowPercentageAgainst",
      "fgm-a": "stats.fieldGoalsMadeAgainst",
      "fga-a": "stats.fieldGoalAttemptsAgainst",
      "fg%-a": "stats.fieldGoalPercentageAgainst",
      "3ptm-a": "stats.threePointsMadeAgainst",
      "3pta-a": "stats.threePointAttemptsAgainst",
      "3pt%-a": "stats.threePointPercentageAgainst",
      "ts%-a": "stats.trueShootingPercentageAgainst",
      "efg%-a": "stats.effectiveFieldGoalPercentageAgainst",
      "blk-a": "stats.blocksAgainst",
      "ortg-a": "stats.offensiveRatingAgainst",
      "drtg-a": "stats.defensiveRatingAgainst",

      "mp": "stats.minutesPlayed",
      "pie": "stats.playerImpactEstimate"
    }
  }
};

// Splits filter
export const HOME_AWAY_SPLIT = "home + away";

// For soccer we won't use city, we'll use name
export const TEAM_CODE_TO_CITY = {
  nhl: {
    "ANA": "Anaheim",
    "ARI": "Arizona",
    "BOS": "Boston",
    "BUF": "Buffalo",
    "CGY": "Calgary",
    "CAR": "Carolina",
    "CHI": "Chicago",
    "COL": "Colorado",
    "CBJ": "Columbus",
    "DAL": "Dallas",
    "DET": "Detroit",
    "EDM": "Edmonton",
    "FLO": "Florida",
    "LAK": "Los Angeles",
    "MIN": "Minnesota",
    "MTL": "Montreal",
    "NSH": "Nashville",
    "NJD": "New Jersey",
    "NYI": "New York I.",
    "NYR": "New York R.",
    "OTT": "Ottawa",
    "PHI": "Philadelphia",
    "PIT": "Pittsburgh",
    "SJS": "San Jose",
    "SEA": "Seattle",
    "STL": "St. Louis",
    "TBL": "Tampa Bay",
    "TOR": "Toronto",
    "VAN": "Vancouver",
    "VGK": "Vegas",
    "WSH": "Washington",
    "WPJ": "Winnipeg",
    "UTA": "Utah"
  },
  nba: {
    "ATL": "Atlanta",
    "BOS": "Boston",
    "BRO": "Brooklyn",
    "CHA": "Charlotte",
    "CHI": "Chicago",
    "CLE": "Cleveland",
    "DAL": "Dallas",
    "DEN": "Denver",
    "DET": "Detroit",
    "GSW": "Golden State",
    "HOU": "Houston",
    "IND": "Indiana",
    "LAC": "Los Angeles C.",
    "LAL": "Los Angeles L.",
    "MEM": "Memphis",
    "MIA": "Miami",
    "MIL": "Milwaukee",
    "MIN": "Minnesota",
    "NOP": "New Orleans",
    "NYK": "New York",
    "OKL": "Oklahoma City",
    "ORL": "Orlando",
    "PHI": "Philadelphia",
    "PHX": "Phoenix",
    "POR": "Portland",
    "SAC": "Sacramento",
    "SAS": "San Antonio",
    "TOR": "Toronto",
    "UTA": "Utah",
    "WAS": "Washington"
  },
  wnba: {
    "ATL": "Atlanta",
    "CHI": "Chicago",
    "CON": "Connecticut",
    "DAL": "Dallas",
    "IND": "Indiana",
    "LVA": "Las Vegas",
    "LAS": "Los Angeles",
    "MIN": "Minnesota",
    "NYL": "New York",
    "PHO": "Phoenix",
    "SEA": "Seattle",
    "WAS": "Washington"
  },
  nfl: {
    "ARI": "Arizona",
    "ATL": "Atlanta",
    "BAL": "Baltimore",
    "BUF": "Buffalo",
    "CAR": "Carolina",
    "CHI": "Chicago",
    "CIN": "Cincinnati",
    "CLE": "Cleveland",
    "DAL": "Dallas",
    "DEN": "Denver",
    "DET": "Detroit",
    "GB": "Green Bay",
    "HOU": "Houston",
    "IND": "Indianapolis",
    "JAX": "Jacksonville",
    "KC": "Kansas City",
    "LV": "Las Vegas",
    "LAC": "Los Angeles C.",
    "LA": "Los Angeles R.",
    "MIA": "Miami",
    "MIN": "Minnesota",
    "NE": "New England",
    "NO": "New Orleans",
    "NYG": "New York G.",
    "NYJ": "New York J.",
    "PHI": "Philadelphia",
    "PIT": "Pittsburgh",
    "SF": "San Francisco",
    "SEA": "Seattle",
    "TB": "Tampa Bay",
    "TEN": "Tennessee",
    "WAS": "Washington"
  },
  mlb: {
    "ARI": "Arizona",
    "ATL": "Atlanta",
    "BAL": "Baltimore",
    "BOS": "Boston",
    "CHC": "Chicago C.",
    "CIN": "Cincinnati",
    "CLE": "Cleveland",
    "COL": "Colorado",
    "CWS": "Chicago W.",
    "DET": "Detroit",
    "HOU": "Houston",
    "KC": "Kansas City",
    "LAA": "Los Angeles A.",
    "LAD": "Los Angeles D.",
    "MIA": "Miami",
    "MIL": "Milwaukee",
    "MIN": "Minnesota",
    "NYM": "New York M.",
    "NYY": "New York Y.",
    "OAK": "Oakland",
    "PHI": "Philadelphia",
    "PIT": "Pittsburgh",
    "SD": "San Diego",
    "SEA": "Seattle",
    "SF": "San Francisco",
    "STL": "St. Louis",
    "TB": "Tampa Bay",
    "TEX": "Texas",
    "TOR": "Toronto",
    "WSH": "Washington"
  },
  bundesliga: {
    "BMU": "Bayern Munich",
    "UNI": "Union Berlin",
    "RBL": "RB Leipzig",
    "SCF": "SC Freiburg",
    "SGE": "Eintracht Frankfurt",
    "BVB": "Borussia Dortmund",
    "WOB": "VfL Wolfsburg",
    "LEV": "Bayer Leverkusen",
    "BMG": "Borussia Monchengladbach",
    "SVW": "Werder Bremen",
    "KOE": "1. FC Cologne",
    "M05": "FSV Mainz",
    "TSG": "TSG Hoffenheim",
    "FCA": "FC Augsburg",
    "VFB": "VfB Stuttgart",
    "BOC": "VfL Bochum",
    "BSC": "Hertha BSC",
    "SCH": "Schalke 04",
    "FCH": "Heidenheim",
    "D98": "Darmstadt",
    "HOK": "Holstein Kie",
    "STP": "FC St. Pauli"
  },
  epl: {
    "ARS": "Arsenal FC",
    "MCI": "Manchester City",
    "NEW": "Newcastle United",
    "MUN": "Manchester United",
    "TOT": "Tottenham Hotspur",
    "FUL": "Fulham FC",
    "BRI": "Brighton & Hove Albion",
    "BRE": "Brentford FC",
    "LIV": "Liverpool FC",
    "CHE": "Chelsea FC",
    "AVL": "Aston Villa",
    "CRY": "Crystal Palace",
    "NFO": "Nottingham Forest",
    "LEI": "Leicester City",
    "LEE": "Leeds United",
    "WHU": "West Ham United",
    "BOU": "AFC Bournemouth",
    "WOL": "Wolverhampton Wanderers",
    "EVE": "Everton FC",
    "SOU": "Southampton FC",
    "BUR ": "Burnley",
    "SHU": "Sheffield United",
    "LUT": "Luton Town",
    "IPS": "Ipswich Town"
  },
  laliga: {
    "FCB": "FC Barcelona",
    "RMA": "Real Madrid",
    "RSO": "Real Sociedad San Sebastian",
    "ATM": "Atletico Madrid",
    "VIL": "Villarreal CF",
    "RBB": "Real Betis Seville",
    "OSA": "CA Osasuna",
    "ATH": "Athletic Bilbao",
    "RVC": "Rayo Vallecano",
    "MAL": "RCD Mallorca",
    "GIR": "Girona FC",
    "VCF": "Valencia CF",
    "ESP": "Espanyol Barcelona",
    "ALM": "UD Almeria",
    "SEV": "Sevilla FC",
    "GET": "Getafe CF",
    "VLL": "Real Valladolid",
    "RCC": "RC Celta de Vigo",
    "CAD": "Cadiz CF",
    "ELC": "Elche CF",
    "GCF": "Granada",
    "LPA": "Las Palmas",
    "ALA": "Alavés",
    "LEG": "Club Deportivo Leganés"
  },
  ligue1: {
    "PSG": "Paris Saint-Germain",
    "RCL": "RC Lens",
    "OM": "Olympique Marseille",
    "REN": "Stade Rennes",
    "ASM": "AS Monaco",
    "LIL": "Lille OSC",
    "FCL": "FC Lorient",
    "CFA": "Clermont Foot 63",
    "OL": "Olympique Lyon",
    "SDR": "Stade Reims",
    "NIC": "OGC Nice",
    "TFC": "Toulouse FC",
    "FCN": "FC Nantes",
    "EST": "ESTAC Troyes",
    "MHS": "Montpellier HSC",
    "ACA": "AC Ajaccio",
    "STB": "Stade Brest 29",
    "RCS": "Strasbourg Alsace",
    "AUX": "AJ Auxerre",
    "ANG": "Angers SCO",
    "HAC": "Le Havre",
    "FCM": "Metz",
    "STE": "AS Saint-Étienne"
  },
  seriea: {
    "NAP": "SSC Napoli",
    "ACM": "AC Milan",
    "INT": "Inter Milano",
    "LAZ": "Lazio Rome",
    "ROM": "AS Roma",
    "ATA": "Atalanta BC",
    "UDI": "Udinese Calcio",
    "TOR": "Torino FC",
    "EMP": "Empoli FC",
    "JUV": "Juventus Turin",
    "FIO": "ACF Fiorentina",
    "BFC": "Bologna FC",
    "MON": "AC Monza",
    "LEC": "US Lecce",
    "SAL": "US Salernitana",
    "SPE": "Spezia Calcio",
    "SAS": "Sassuolo Calcio",
    "VER": "Hellas Verona",
    "SAM": "Sampdoria Genoa",
    "CRE": "US Cremonese",
    "CAG": "Cagliari",
    "FRO": "Frosinone",
    "GEN": "Genoa",
    "COM": "Como",
    "PAR": "Parma",
    "VEN": "Venezia FC"
  },
  "brazil-serie-a": {
    "ATL": "Atletico Mineiro MG",
    "BAH": "EC Bahia BA",
    "BOT": "Botafogo FR RJ",
    "BRA": "Red Bull Bragantino SP",
    "CAP": "CA Paranaense PR",
    "COR": "SC Corinthians SP",
    "CRI": "Criciuma EC SC",
    "CRU": "Cruzeiro EC MG",
    "CUI": "Cuiaba Esporte Clube MT",
    "FLA": "CR Flamengo RJ",
    "FLU": "Fluminense FC RJ",
    "FOR": "Fortaleza EC CE",
    "GOI": "AC Goianiense GO",
    "GPA": "Gremio FB Porto Alegrense RS",
    "INT": "SC Internacional RS",
    "JUV": "EC Juventude RS",
    "PAL": "SE Palmeiras SP",
    "SPA": "Sao Paulo FC SP",
    "VDG": "CR Vasco da Gama RJ",
    "VIT": "EC Vitoria BA"
  },
  mls: {
    "ATL": "Atlanta",
    "ATX": "Austin",
    "CHA": "Charlotte",
    "CHI": "Chicago",
    "CIN": "Cincinnati",
    "CLB": "Columbus",
    "COL": "Colorado",
    "DAL": "Dallas",
    "DC": "DC",
    "HOU": "Houston",
    "LAG": "Los Angeles G",
    "LAN": "Los Angeles FC",
    "MIA": "Miami",
    "MIN": "Minnesota",
    "MON": "Montreal",
    "NE": "New England",
    "NSC": "Nashville",
    "NY": "New York RB",
    "NYC": "New York FC",
    "ORL": "Orlando",
    "PHI": "Philadelphia",
    "POR": "Portland",
    "RSL": "Salt Lake",
    "SAI": "Saint Louis",
    "SEA": "Seattle",
    "SJ": "San Jose",
    "SKC": "Kansas City",
    "TOR": "Toronto",
    "VAN": "Vancouver"
  },
  euro: {
    "ALB": "Albania",
    "AUT": "Austria",
    "BEL": "Belgium",
    "CHE": "Switzerland",
    "HRV": "Croatia",
    "CZE": "Czechia",
    "DNK": "Denmark",
    "DEU": "Germany",
    "ENG": "England",
    "ESP": "Spain",
    "FRA": "France",
    "GEO": "Georgia",
    "HUN": "Hungary",
    "ITA": "Italy",
    "NLD": "Netherlands",
    "POL": "Poland",
    "PRT": "Portugal",
    "ROU": "Romania",
    "SCO": "Scotland",
    "SVN": "Slovenia",
    "SRB": "Serbia",
    "SVK": "Slovakia",
    "TUR": "Turkiye",
    "UKR": "Ukraine"
  }
}

// For soccer we won't use city, we'll use name
export const TEAM_CITY_TO_CODE = {
  nhl: {
    "Anaheim": "ANA",
    "Arizona": "ARI",
    "Boston": "BOS",
    "Buffalo": "BUF",
    "Calgary": "CGY",
    "Carolina": "CAR",
    "Chicago": "CHI",
    "Colorado": "COL",
    "Columbus": "CBJ",
    "Dallas": "DAL",
    "Detroit": "DET",
    "Edmonton": "EDM",
    "Florida": "FLO",
    "Los Angeles": "LAK",
    "Minnesota": "MIN",
    "Montreal": "MTL",
    "Nashville": "NSH",
    "New Jersey": "NJD",
    "New York I.": "NYI",
    "New York R.": "NYR",
    "Ottawa": "OTT",
    "Philadelphia": "PHI",
    "Pittsburgh": "PIT",
    "San Jose": "SJS",
    "Seattle": "SEA",
    "St. Louis": "STL",
    "Tampa Bay": "TBL",
    "Toronto": "TOR",
    "Vancouver": "VAN",
    "Vegas": "VGK",
    "Washington": "WSH",
    "Winnipeg": "WPJ",
    "Utah": "UTA"
  },
  nba: {
    "Atlanta": "ATL",
    "Boston": "BOS",
    "Brooklyn": "BRO",
    "Charlotte": "CHA",
    "Chicago": "CHI",
    "Cleveland": "CLE",
    "Dallas": "DAL",
    "Denver": "DEN",
    "Detroit": "DET",
    "Golden State": "GSW",
    "Houston": "HOU",
    "Indiana": "IND",
    "Los Angeles C.": "LAC",
    "Los Angeles L.": "LAL",
    "Memphis": "MEM",
    "Miami": "MIA",
    "Milwaukee": "MIL",
    "Minnesota": "MIN",
    "New Orleans": "NOP",
    "New York": "NYK",
    "Oklahoma City": "OKL",
    "Orlando": "ORL",
    "Philadelphia": "PHI",
    "Phoenix": "PHX",
    "Portland": "POR",
    "Sacramento": "SAC",
    "San Antonio": "SAS",
    "Toronto": "TOR",
    "Utah": "UTA",
    "Washington": "WAS"
  },
  wnba: {
    "Atlanta": "ATL",
    "Chicago": "CHI",
    "Connecticut": "CON",
    "Dallas": "DAL",
    "Indiana": "IND",
    "Las Vegas": "LVA",
    "Los Angeles": "LAS",
    "Minnesota": "MIN",
    "New York": "NYL",
    "Phoenix": "PHO",
    "Seattle": "SEA",
    "Washington": "WAS"
  },
  nfl: {
    "Arizona": "ARI",
    "Atlanta": "ATL",
    "Baltimore": "BAL",
    "Buffalo": "BUF",
    "Carolina": "CAR",
    "Chicago": "CHI",
    "Cincinnati": "CIN",
    "Cleveland": "CLE",
    "Dallas": "DAL",
    "Denver": "DEN",
    "Detroit": "DET",
    "Green Bay": "GB",
    "Houston": "HOU",
    "Indianapolis": "IND",
    "Jacksonville": "JAX",
    "Kansas City": "KC",
    "Las Vegas": "LV",
    "Los Angeles C.": "LAC",
    "Los Angeles R.": "LA",
    "Miami": "MIA",
    "Minnesota": "MIN",
    "New England": "NE",
    "New Orleans": "NO",
    "New York G.": "NYG",
    "New York J.": "NYJ",
    "Philadelphia": "PHI",
    "Pittsburgh": "PIT",
    "San Francisco": "SF",
    "Seattle": "SEA",
    "Tampa Bay": "TB",
    "Tennessee": "TEN",
    "Washington": "WAS"
  },
  mlb: {
    "Arizona": "ARI",
    "Atlanta": "ATL",
    "Baltimore": "BAL",
    "Boston": "BOS",
    "Chicago C.": "CHC",
    "Cincinnati": "CIN",
    "Cleveland": "CLE",
    "Colorado": "COL",
    "Chicago W.": "CWS",
    "Detroit": "DET",
    "Houston": "HOU",
    "Kansas City": "KC",
    "Los Angeles A.": "LAA",
    "Los Angeles D.": "LAD",
    "Miami": "MIA",
    "Milwaukee": "MIL",
    "Minnesota": "MIN",
    "New York M.": "NYM",
    "New York Y.": "NYY",
    "Oakland": "OAK",
    "Philadelphia": "PHI",
    "Pittsburgh": "PIT",
    "San Diego": "SD",
    "Seattle": "SEA",
    "San Francisco": "SF",
    "St. Louis": "STL",
    "Tampa Bay": "TB",
    "Texas": "TEX",
    "Toronto": "TOR",
    "Washington": "WSH"
  },
  bundesliga: {
    "Bayern Munich": "BMU",
    "Union Berlin": "UNI",
    "RB Leipzig": "RBL",
    "SC Freiburg": "SCF",
    "Borussia Dortmund": "BVB",
    "Eintracht Frankfurt": "SGE",
    "VfL Wolfsburg": "WOB",
    "Borussia Monchengladbach": "BMG",
    "Werder Bremen": "SVW",
    "Bayer Leverkusen": "LEV",
    "FSV Mainz": "M05",
    "1. FC Cologne": "KOE",
    "TSG Hoffenheim": "TSG",
    "FC Augsburg": "FCA",
    "VfB Stuttgart": "VFB",
    "VfL Bochum": "BOC",
    "Hertha BSC": "BSC",
    "Schalke 04": "SCH",
    "Heidenheim": "FCH",
    "Darmstadt": "D98",
    "Holstein Kie": "HOK",
    "FC St. Pauli": "STP"
  },
  epl: {
    "Arsenal FC": "ARS",
    "Manchester City": "MCI",
    "Newcastle United": "NEW",
    "Manchester United": "MUN",
    "Tottenham Hotspur": "TOT",
    "Fulham FC": "FUL",
    "Brighton & Hove Albion": "BRI",
    "Brentford FC": "BRE",
    "Liverpool FC": "LIV",
    "Chelsea FC": "CHE",
    "Aston Villa": "AVL",
    "Crystal Palace": "CRY",
    "Nottingham Forest": "NFO",
    "Leicester City": "LEI",
    "Leeds United": "LEE",
    "West Ham United": "WHU",
    "AFC Bournemouth": "BOU",
    "Wolverhampton Wanderers": "WOL",
    "Everton FC": "EVE",
    "Southampton FC": "SOU",
    "Burnley": "BUR",
    "Sheffield United": "SHU",
    "Luton Town": "LUT",
    "Ipswich Town": "IPS"
  },
  laliga: {
    "FC Barcelona": "FCB",
    "Real Madrid": "RMA",
    "Real Sociedad San Sebastian": "RSO",
    "Atletico Madrid": "ATM",
    "Villarreal CF": "VIL",
    "Real Betis Seville": "RBB",
    "CA Osasuna": "OSA",
    "Athletic Bilbao": "ATH",
    "Rayo Vallecano": "RVC",
    "RCD Mallorca": "MAL",
    "UD Almeria": "ALM",
    "Girona FC": "GIR",
    "Sevilla FC": "SEV",
    "Valencia CF": "VCF",
    "Real Valladolid": "VLL",
    "RC Celta de Vigo": "RCC",
    "Espanyol Barcelona": "ESP",
    "Cadiz CF": "CAD",
    "Getafe CF": "GET",
    "Elche CF": "ELC",
    "Granada": "GCF",
    "Las Palmas": "LPA",
    "Alavés": "ALA",
    "Club Deportivo Leganés": "LEG"
  },
  ligue1: {
    "Paris Saint-Germain": "PSG",
    "RC Lens": "RCL",
    "Olympique Marseille": "OM",
    "AS Monaco": "ASM",
    "Stade Rennes": "REN",
    "FC Lorient": "FCL",
    "Lille OSC": "LIL",
    "Clermont Foot 63": "CFA",
    "Olympique Lyon": "OL",
    "OGC Nice": "NIC",
    "Stade Reims": "SDR",
    "Toulouse FC": "TFC",
    "FC Nantes": "FCN",
    "Montpellier HSC": "MHS",
    "ESTAC Troyes": "EST",
    "Stade Brest 29": "STB",
    "AC Ajaccio": "ACA",
    "Strasbourg Alsace": "RCS",
    "AJ Auxerre": "AUX",
    "Angers SCO": "ANG",
    "Le Havre": "HAC",
    "Metz": "FCM",
    "AS Saint-Étienne": "STE"
  },
  seriea: {
    "SSC Napoli": "NAP",
    "Inter Milano": "INT",
    "AC Milan": "ACM",
    "Lazio Rome": "LAZ",
    "Atalanta BC": "ATA",
    "AS Roma": "ROM",
    "Udinese Calcio": "UDI",
    "Torino FC": "TOR",
    "Bologna FC": "BFC",
    "Empoli FC": "EMP",
    "AC Monza": "MON",
    "ACF Fiorentina": "FIO",
    "Juventus Turin": "JUV",
    "US Salernitana": "SAL",
    "US Lecce": "LEC",
    "Sassuolo Calcio": "SAS",
    "Spezia Calcio": "SPE",
    "Hellas Verona": "VER",
    "Sampdoria Genoa": "SAM",
    "US Cremonese": "CRE",
    "Cagliari": "CAG",
    "Frosinone": "FRO",
    "Genoa": "GEN",
    "Como": "COM",
    "Parma": "PAR",
    "Venezia FC": "VEN"
  },
  "brazil-serie-a": {
    "Atletico Mineiro MG": "ATL",
    "EC Bahia BA": "BAH",
    "Botafogo FR RJ": "BOT",
    "Red Bull Bragantino SP": "BRA",
    "CA Paranaense PR": "CAP",
    "SC Corinthians SP": "COR",
    "Criciuma EC SC": "CRI",
    "Cruzeiro EC MG": "CRU",
    "Cuiaba Esporte Clube MT": "CUI",
    "CR Flamengo RJ": "FLA",
    "Fluminense FC RJ": "FLU",
    "Fortaleza EC CE": "FOR",
    "AC Goianiense GO": "GOI",
    "Gremio FB Porto Alegrense RS": "GPA",
    "SC Internacional RS": "INT",
    "EC Juventude RS": "JUV",
    "SE Palmeiras SP": "PAL",
    "Sao Paulo FC SP": "SPA",
    "CR Vasco da Gama RJ": "VDG",
    "EC Vitoria BA": "VIT"
  },
  mls: {
    "Atlanta": "ATL",
    "Austin": "ATX",
    "Charlotte": "CHA",
    "Chicago": "CHI",
    "Cincinnati": "CIN",
    "Columbus": "CLB",
    "Colorado": "COL",
    "Dallas": "DAL",
    "DC": "DC",
    "Houston": "HOU",
    "Los Angeles G": "LAG",
    "Los Angeles FC": "LAN",
    "Miami": "MIA",
    "Minnesota": "MIN",
    "Montreal": "MON",
    "New England": "NE",
    "Nashville": "NSC",
    "New York RB": "NY",
    "New York FC": "NYC",
    "Orlando": "ORL",
    "Philadelphia": "PHI",
    "Portland": "POR",
    "Salt Lake": "RSL",
    "Saint Louis": "SAI",
    "Seattle": "SEA",
    "San Jose": "SJ",
    "Kansas City": "SKC",
    "Toronto": "TOR",
    "Vancouver": "VAN"
  },
  euro: {
    "Albania": "ALB",
    "Austria": "AUT",
    "Belgium": "BEL",
    "Switzerland": "CHE",
    "Croatia": "HRV",
    "Czechia": "CZE",
    "Denmark": "DNK",
    "Germany": "DEU",
    "England": "ENG",
    "Spain": "ESP",
    "France": "FRA",
    "Georgia": "GEO",
    "Hungary": "HUN",
    "Italy": "ITA",
    "Netherlands": "NLD",
    "Poland": "POL",
    "Portugal": "PRT",
    "Romania": "ROU",
    "Scotland": "SCO",
    "Slovenia": "SVN",
    "Serbia": "SRB",
    "Slovakia": "SVK",
    "Turkiye": "TUR",
    "Ukraine": "UKR"
  }
}

// MSF Doesn't give the full team name and city under the player, only the code
// We could fill it in ourselves by getting it from the standings in the back end but this is a quicker solution
export const TEAM_CODE_TO_NAME = {
  nhl: {
    "ANA": "Ducks",
    "ARI": "Coyotes",
    "BOS": "Bruins",
    "BUF": "Sabres",
    "CGY": "Flames",
    "CAR": "Hurricanes",
    "CHI": "Blackhawks",
    "COL": "Avalanche",
    "CBJ": "Blue Jackets",
    "DAL": "Stars",
    "DET": "Red Wings",
    "EDM": "Oilers",
    "FLO": "Panthers",
    "LAK": "Kings",
    "MIN": "Wild",
    "MTL": "Canadiens",
    "NSH": "Predators",
    "NJD": "Devils",
    "NYI": "Islanders",
    "NYR": "Rangers",
    "OTT": "Senators",
    "PHI": "Flyers",
    "PIT": "Penguins",
    "SJS": "Sharks",
    "SEA": "Kraken",
    "STL": "Blues",
    "TBL": "Lightning",
    "TOR": "Maple Leafs",
    "VAN": "Canucks",
    "VGK": "Golden Knights",
    "WSH": "Capitals",
    "WPJ": "Jets",
    "UTA": "Utah Hockey Club"
  },
  nba: {
    "ATL": "Hawks",
    "BOS": "Celtics",
    "BRO": "Nets",
    "CHA": "Hornets",
    "CHI": "Bulls",
    "CLE": "Cavaliers",
    "DAL": "Mavericks",
    "DEN": "Nuggets",
    "DET": "Pistons",
    "GSW": "Warriors",
    "HOU": "Rockets",
    "IND": "Pacers",
    "LAC": "Clippers",
    "LAL": "Lakers",
    "MEM": "Grizzlies",
    "MIA": "Heat",
    "MIL": "Bucks",
    "MIN": "Timberwolves",
    "NOP": "Pelicans",
    "NYK": "Knicks",
    "OKL": "Thunder",
    "ORL": "Magic",
    "PHI": "76ers",
    "PHX": "Suns",
    "POR": "Trail Blazers",
    "SAC": "Kings",
    "SAS": "Spurs",
    "TOR": "Raptors",
    "UTA": "Jazz",
    "WAS": "Wizards"
  },
  wnba: {
    "ATL": "Dream",
    "CHI": "Sky",
    "CON": "Sun",
    "DAL": "Wings",
    "IND": "Fever",
    "LAS": "Sparks",
    "LVA": "Aces",
    "MIN": "Lynx",
    "NYL": "Liberty",
    "PHO": "Mercury",
    "SEA": "Storm",
    "WAS": "Mystics"
  },
  nfl: {
    "ARI": "Cardinals",
    "ATL": "Falcons",
    "BAL": "Ravens",
    "BUF": "Bills",
    "CAR": "Panthers",
    "CHI": "Bears",
    "CIN": "Bengals",
    "CLE": "Browns",
    "DAL": "Cowboys",
    "DEN": "Broncos",
    "DET": "Lions",
    "GB": "Packers",
    "HOU": "Texans",
    "IND": "Colts",
    "JAX": "Jaguars",
    "KC": "Chiefs",
    "LV": "Raiders",
    "LAC": "Chargers",
    "LA": "Rams",
    "MIA": "Dolphins",
    "MIN": "Vikings",
    "NE": "Patriots",
    "NO": "Saints",
    "NYG": "Giants",
    "NYJ": "Jets",
    "PHI": "Eagles",
    "PIT": "Steelers",
    "SF": "49ers",
    "SEA": "Seahawks",
    "TB": "Buccaneers",
    "TEN": "Titans",
    "WAS": "Football Team"
  },
  mlb: {
    "ARI": "Diamondbacks",
    "ATL": "Braves",
    "BAL": "Orioles",
    "BOS": "Red Sox",
    "CHC": "Cubs",
    "CIN": "Reds",
    "CLE": "Guardians",
    "COL": "Rockies",
    "CWS": "White Sox",
    "DET": "Tigers",
    "HOU": "Astros",
    "KC": "Royals",
    "LAA": "Angels",
    "LAD": "Dodgers",
    "MIA": "Marlins",
    "MIL": "Brewers",
    "MIN": "Twins",
    "NYM": "Mets",
    "NYY": "Yankees",
    "OAK": "Athletics",
    "PHI": "Phillies",
    "PIT": "Pirates",
    "SD": "Padres",
    "SEA": "Mariners",
    "SF": "Giants",
    "STL": "Cardinals",
    "TB": "Rays",
    "TEX": "Rangers",
    "TOR": "Blue Jays",
    "WSH": "Nationals"
  },
  bundesliga: {
    "BMU": "Bayern Munich",
    "UNI": "Union Berlin",
    "RBL": "RB Leipzig",
    "SCF": "SC Freiburg",
    "SGE": "Eintracht Frankfurt",
    "BVB": "Borussia Dortmund",
    "WOB": "VfL Wolfsburg",
    "LEV": "Bayer Leverkusen",
    "BMG": "Borussia Monchengladbach",
    "SVW": "Werder Bremen",
    "KOE": "1. FC Cologne",
    "M05": "FSV Mainz",
    "TSG": "TSG Hoffenheim",
    "FCA": "FC Augsburg",
    "VFB": "VfB Stuttgart",
    "BOC": "VfL Bochum",
    "BSC": "Hertha BSC",
    "SCH": "Schalke 04",
    "FCH": "Heidenheim",
    "D98": "Darmstadt",
    "HOK": "Holstein Kie",
    "STP": "FC St. Pauli"
  },
  epl: {
    "ARS": "Arsenal FC",
    "MCI": "Manchester City",
    "NEW": "Newcastle United",
    "MUN": "Manchester United",
    "TOT": "Tottenham Hotspur",
    "FUL": "Fulham FC",
    "BRI": "Brighton & Hove Albion",
    "BRE": "Brentford FC",
    "LIV": "Liverpool FC",
    "CHE": "Chelsea FC",
    "AVL": "Aston Villa",
    "CRY": "Crystal Palace",
    "NFO": "Nottingham Forest",
    "LEI": "Leicester City",
    "LEE": "Leeds United",
    "WHU": "West Ham United",
    "BOU": "AFC Bournemouth",
    "WOL": "Wolverhampton Wanderers",
    "EVE": "Everton FC",
    "SOU": "Southampton FC",
    "BUR ": "Burnley",
    "SHU": "Sheffield United",
    "LUT": "Luton Town",
    "IPS": "Ipswich Town"
  },
  laliga: {
    "FCB": "FC Barcelona",
    "RMA": "Real Madrid",
    "RSO": "Real Sociedad San Sebastian",
    "ATM": "Atletico Madrid",
    "VIL": "Villarreal CF",
    "RBB": "Real Betis Seville",
    "OSA": "CA Osasuna",
    "ATH": "Athletic Bilbao",
    "RVC": "Rayo Vallecano",
    "MAL": "RCD Mallorca",
    "GIR": "Girona FC",
    "VCF": "Valencia CF",
    "ESP": "Espanyol Barcelona",
    "ALM": "UD Almeria",
    "SEV": "Sevilla FC",
    "GET": "Getafe CF",
    "VLL": "Real Valladolid",
    "RCC": "RC Celta de Vigo",
    "CAD": "Cadiz CF",
    "ELC": "Elche CF",
    "GCF": "Granada",
    "LPA": "Las Palmas",
    "ALA": "Alavés",
    "LEG": "Club Deportivo Leganés"
  },
  ligue1: {
    "PSG": "Paris Saint-Germain",
    "RCL": "RC Lens",
    "OM": "Olympique Marseille",
    "REN": "Stade Rennes",
    "ASM": "AS Monaco",
    "LIL": "Lille OSC",
    "FCL": "FC Lorient",
    "CFA": "Clermont Foot 63",
    "OL": "Olympique Lyon",
    "SDR": "Stade Reims",
    "NIC": "OGC Nice",
    "TFC": "Toulouse FC",
    "FCN": "FC Nantes",
    "EST": "ESTAC Troyes",
    "MHS": "Montpellier HSC",
    "ACA": "AC Ajaccio",
    "STB": "Stade Brest 29",
    "RCS": "Strasbourg Alsace",
    "AUX": "AJ Auxerre",
    "ANG": "Angers SCO",
    "HAC": "Le Havre",
    "FCM": "Metz",
    "STE": "AS Saint-Étienne"
  },
  seriea: {
    "NAP": "SSC Napoli",
    "ACM": "AC Milan",
    "INT": "Inter Milano",
    "LAZ": "Lazio Rome",
    "ROM": "AS Roma",
    "ATA": "Atalanta BC",
    "UDI": "Udinese Calcio",
    "TOR": "Torino FC",
    "EMP": "Empoli FC",
    "JUV": "Juventus Turin",
    "FIO": "ACF Fiorentina",
    "BFC": "Bologna FC",
    "MON": "AC Monza",
    "LEC": "US Lecce",
    "SAL": "US Salernitana",
    "SPE": "Spezia Calcio",
    "SAS": "Sassuolo Calcio",
    "VER": "Hellas Verona",
    "SAM": "Sampdoria Genoa",
    "CRE": "US Cremonese",
    "CAG": "Cagliari",
    "FRO": "Frosinone",
    "GEN": "Genoa",
    "COM": "Como",
    "PAR": "Parma",
    "VEN": "Venezia FC"
  },
  "brazil-serie-a": {
    "ATL": "Atletico Mineiro MG",
    "BAH": "EC Bahia BA",
    "BOT": "Botafogo FR RJ",
    "BRA": "Red Bull Bragantino SP",
    "CAP": "CA Paranaense PR",
    "COR": "SC Corinthians SP",
    "CRI": "Criciuma EC SC",
    "CRU": "Cruzeiro EC MG",
    "CUI": "Cuiaba Esporte Clube MT",
    "FLA": "CR Flamengo RJ",
    "FLU": "Fluminense FC RJ",
    "FOR": "Fortaleza EC CE",
    "GOI": "AC Goianiense GO",
    "GPA": "Gremio FB Porto Alegrense RS",
    "INT": "SC Internacional RS",
    "JUV": "EC Juventude RS",
    "PAL": "SE Palmeiras SP",
    "SPA": "Sao Paulo FC SP",
    "VDG": "CR Vasco da Gama RJ",
    "VIT": "EC Vitoria BA"
  },
  mls: {
    "ATL": "Atlanta United FC",
    "ATX": "Austin FC",
    "CHA": "Charlotte FC",
    "CHI": "Chicago Fire",
    "CIN": "FC Cincinnati",
    "CLB": "Columbus Crew",
    "COL": "Colorado Rapids",
    "DAL": "FC Dallas",
    "DC": "DC United",
    "HOU": "Houston Dynamo",
    "LAG": "Los Angeles Galaxy",
    "LAN": "Los Angeles FC",
    "MIA": "Inter Miami CF",
    "MIN": "Minnesota United FC",
    "MON": "CF Montreal",
    "NE": "New England Revolution",
    "NSC": "Nashville SC",
    "NY": "New York Red Bulls",
    "NYC": "New York City FC",
    "ORL": "Orlando City SC",
    "PHI": "Philadelphia Union",
    "POR": "Portland Timbers",
    "RSL": "Real Salt Lake",
    "SAI": "Saint Louis City SC",
    "SEA": "Seattle Sounders",
    "SJ": "San Jose Earthquakes",
    "SKC": "Sporting Kansas City",
    "TOR": "Toronto FC",
    "VAN": "Vancouver Whitecaps FC"
  },
  euro: {
    "ALB": "Albania",
    "AUT": "Austria",
    "BEL": "Belgium",
    "CHE": "Switzerland",
    "HRV": "Croatia",
    "CZE": "Czechia",
    "DNK": "Denmark",
    "DEU": "Germany",
    "ENG": "England",
    "ESP": "Spain",
    "FRA": "France",
    "GEO": "Georgia",
    "HUN": "Hungary",
    "ITA": "Italy",
    "NLD": "Netherlands",
    "POL": "Poland",
    "PRT": "Portugal",
    "ROU": "Romania",
    "SCO": "Scotland",
    "SVN": "Slovenia",
    "SRB": "Serbia",
    "SVK": "Slovakia",
    "TUR": "Turkiye",
    "UKR": "Ukraine"
  }
}

// TODO
export const COLOR_CODES = {
  nhl: {
    "ANA": {"primary": "#F47A38", "secondary": "#F69560", "tertiary": "#F8AF88"},
    "ARI": {"primary": "#8C2633", "secondary": "#A3515C", "tertiary": "#BA7D85"},
    "BOS": {"primary": "#FFB81C", "secondary": "#E59E00", "tertiary": "#FFD477"},
    "BUF": {"primary": "#002654", "secondary": "#335176", "tertiary": "#667D98"},
    "CGY": {"primary": "#C8102E", "secondary": "#D34058", "tertiary": "#DE7082"},
    "CAR": {"primary": "#CC0000", "secondary": "#D94040", "tertiary": "#E37373"},
    "CHI": {"primary": "#CF0A2C", "secondary": "#D93B56", "tertiary": "#E26C80"},
    "COL": {"primary": "#6F263D", "secondary": "#8C5164", "tertiary": "#A97D8B"},
    "CBJ": {"primary": "#002654", "secondary": "#335176", "tertiary": "#667D98"},
    "DAL": {"primary": "#006847", "secondary": "#33866C", "tertiary": "#66A491"},
    "DET": {"primary": "#CE1126", "secondary": "#D84151", "tertiary": "#E2707D"},
    "EDM": {"primary": "#041E42", "secondary": "#364B68", "tertiary": "#68788E"},
    "FLO": {"primary": "#C8102E", "secondary": "#D34058", "tertiary": "#DE7082"},
    "LAK": {"primary": "#111111", "secondary": "#414141", "tertiary": "#707070"},
    "MIN": {"primary": "#154734", "secondary": "#446C5D", "tertiary": "#739185"},
    "MTL": {"primary": "#AF1E2D", "secondary": "#BF4B57", "tertiary": "#CF7881"},
    "NSH": {"primary": "#FFB81C", "secondary": "#FFC649", "tertiary": "#FFD477"},
    "NJD": {"primary": "#CE1126", "secondary": "#D84151", "tertiary": "#E2707D"},
    "NYI": {"primary": "#00539B", "secondary": "#3375AF", "tertiary": "#6698C3"},
    "NYR": {"primary": "#0038A8", "secondary": "#3360B9", "tertiary": "#6688CB"},
    "OTT": {"primary": "#C52032", "secondary": "#D14D5B", "tertiary": "#DC7984"},
    "PHI": {"primary": "#F74902", "secondary": "#F96D35", "tertiary": "#FA9267"},
    "PIT": {"primary": "#FCB514", "secondary": "#FDC443", "tertiary": "#FDD372"},
    "STL": {"primary": "#002F87", "secondary": "#33599F", "tertiary": "#6682B7"},
    "SJS": {"primary": "#006D75", "secondary": "#338A91", "tertiary": "#66A7AC"},
    "SEA": {"primary": "#639FB6", "secondary": "#82B2C5", "tertiary": "#A1C5D3"},
    "TBL": {"primary": "#002868", "secondary": "#335386", "tertiary": "#667EA4"},
    "TOR": {"primary": "#00205B", "secondary": "#334D7C", "tertiary": "#66799D"},
    "VAN": {"primary": "#00205B", "secondary": "#334D7C", "tertiary": "#66799D"},
    "VGK": {"primary": "#B4975A", "secondary": "#C3AC7B", "tertiary": "#D2C09C"},
    "WSH": {"primary": "#C8102E", "secondary": "#D34058", "tertiary": "#DE7082"},
    "WPJ": {"primary": "#041E42", "secondary": "#364B68", "tertiary": "#68788E"},
    "UTA": {}
  },
  nba: {
    "ATL": {"primary":	"#E03A3E", "secondary":	"#E66165", "tertiary":	"#EC898B"},
    "BOS": {"primary":	"#007A33", "secondary":	"#33955C", "tertiary":	"#66AF85"},
    "BRO": {"primary":	"#000000", "secondary":	"#333333", "tertiary":	"#666666"},
    "CHA": {"primary":	"#1D1160", "secondary":	"#4A4180", "tertiary":	"#7770A0"},
    "CHI": {"primary":	"#CE1141", "secondary":	"#D84167", "tertiary":	"#E2708D"},
    "CLE": {"primary":	"#860038", "secondary":	"#9E3360", "tertiary":	"#B66688"},
    "DAL": {"primary":	"#0053bc", "secondary":	"#3375C9", "tertiary":	"#6698D7"},
    "DEN": {"primary":	"#0E2240", "secondary":	"#3E4E66", "tertiary":	"#6E7A8C"},
    "DET": {"primary":	"#C8102E", "secondary":	"#D34058", "tertiary":	"#DE7082"},
    "GSW": {"primary":	"#1D428A", "secondary":	"#4A68A1", "tertiary":	"#778EB9"},
    "HOU": {"primary":	"#CE1141", "secondary":	"#D84167", "tertiary":	"#D16180"},
    "IND": {"primary":	"#002D62", "secondary":	"#335781", "tertiary":	"#6681A1"},
    "LAC": {"primary":	"#C8102E", "secondary":	"#D34058", "tertiary":	"#DE7082"},
    "LAL": {"primary":	"#552583", "secondary":	"#77519C", "tertiary":	"#997CB5"},
    "MEM": {"primary":	"#5D76A9", "secondary":	"#7D91BA", "tertiary":	"#9EADCB"},
    "MIA": {"primary":	"#98002E", "secondary":	"#AD3358", "tertiary":	"#C16682"},
    "MIL": {"primary":	"#00471B", "secondary":	"#336C49", "tertiary":	"#669176"},
    "MIN": {"primary":	"#0C2340", "secondary":	"#3D4F66", "tertiary":	"#6D7B8C"},
    "NOP": {"primary":	"#0C2340", "secondary":	"#3D4F66", "tertiary":	"#6D7B8C"},
    "NYK": {"primary":	"#F58426", "secondary":	"#F79D51", "tertiary":	"#FAC497"},
    "OKL": {"primary":	"#007AC1", "secondary":	"#3395CD", "tertiary":	"#66AFDA"},
    "ORL": {"primary":	"#0077C0", "secondary":	"#3392CD", "tertiary":	"#66ADD9"},
    "PHI": {"primary":	"#006BB6", "secondary":	"#3389C5", "tertiary":	"#66A6D3"},
    "PHX": {"primary":	"#1D1160", "secondary":	"#4A4180", "tertiary":	"#7770A0"},
    "POR": {"primary":	"#E03A3E", "secondary":	"#E66165", "tertiary":	"#EC898B"},
    "SAC": {"primary":	"#5A2D81", "secondary":	"#7B579A", "tertiary":	"#9C81B3"},
    "SAS": {"primary":	"#C4CED4", "secondary":	"#B5C2C9", "tertiary":	"#DCE2E5"},
    "TOR": {"primary":	"#CE1141", "secondary":	"#D84167", "tertiary":	"#E2708D"},
    "UTA": {"primary":	"#002B5C", "secondary":	"#33557D", "tertiary":	"#66809D"},
    "WAS": {"primary":	"#002B5C", "secondary":	"#33557D", "tertiary":	"#66809D"}
  },
  wnba: {
    "ATL": {"primary": "rgba(65,143,223,1)", "secondary": "rgba(65,143,223,1)", "tertiary": "rgba(65,143,223,1)"},
    "CHI": {"primary": "rgba(230,46,80,1)", "secondary": "rgba(230,46,80,1)", "tertiary": "rgba(230,46,80,1)"},
    "CON": {"primary": "rgba(108,122,140,1)", "secondary": "rgba(108,122,140,1)", "tertiary": "rgba(108,122,140,1)"},
    "DAL": {"primary": "rgba(110,122,140,1)", "secondary": "rgba(110,122,140,1)", "tertiary": "rgba(110,122,140,1)"},
    "IND": {"primary": "rgba(102,129,160,1)", "secondary": "rgba(102,129,160,1)", "tertiary": "rgba(102,129,160,1)"},
    "LAS": {"primary": "rgba(136,102,168,1)", "secondary": "rgba(136,102,168,1)", "tertiary": "rgba(136,102,168,1)"},
    "LVA": {"primary": "rgba(123,123,123,1)", "secondary": "rgba(123,123,123,1)", "tertiary": "rgba(123,123,123,1)"},
    "MIN": {"primary": "rgba(109,123,140,1)", "secondary": "rgba(109,123,140,1)", "tertiary": "rgba(109,123,140,1)"},
    "NYL": {"primary": "rgba(134,205,188,1)", "secondary": "rgba(134,205,188,1)", "tertiary": "rgba(134,205,188,1)"},
    "PHO": {"primary": "rgba(229,96,32,1)", "secondary": "rgba(229,96,32,1)", "tertiary": "rgba(229,96,32,1)"},
    "SEA": {"primary": "rgba(97,125,104,1)", "secondary": "rgba(97,125,104,1)", "tertiary": "rgba(97,125,104,1)"},
    "WAS": {"primary": "rgba(225,58,62,1)", "secondary": "rgba(225,58,62,1)", "tertiary": "rgba(225,58,62,1)"}
  },
  nfl: {
    "ARI": {"primary":	"#97233F", "secondary":	"#AC4F65", "tertiary":	"#C07B8C"},
    "ATL": {"primary":	"#A71930", "secondary":	"#B94759", "tertiary":	"#CA7583"},
    "BAL": {"primary":	"#241773", "secondary":	"#50458F", "tertiary":	"#7C74AB"},
    "BUF": {"primary":	"#00338D", "secondary":	"#335CA4", "tertiary":	"#6685BB"},
    "CAR": {"primary":	"#0085CA", "secondary":	"#40A3D7", "tertiary":	"#73BCE2"},
    "CHI": {"primary":	"#0B162A", "secondary":	"#3C4555", "tertiary":	"#6D737F"},
    "CIN": {"primary":	"#FB4F14", "secondary":	"#FC7243", "tertiary":	"#FD9572"},
    "CLE": {"primary":	"#FF3C00", "secondary":	"#FF6333", "tertiary":	"#FF8A66"},
    "DAL": {"primary":	"#041E42", "secondary":	"#364B68", "tertiary":	"#68788E"},
    "DEN": {"primary":	"#002244", "secondary":	"#334E69", "tertiary":	"#667A8F"},
    "DET": {"primary":	"#0076B6", "secondary":	"#3391C5", "tertiary":	"#66ADD3"},
    "GB":  {"primary":	"#203731", "secondary":	"#4D5F5A", "tertiary":	"#798783"},
    "HOU": {"primary":	"#03202F", "secondary":	"#354D59", "tertiary":	"#687982"},
    "IND": {"primary":	"#002C5F", "secondary":	"#33567F", "tertiary":	"#66809F"},
    "JAX": {"primary":	"#006778", "secondary":	"#338593", "tertiary":	"#66A4AE"},
    "KC":  {"primary":	"#E31837", "secondary":	"#E9465F", "tertiary":	"#EE7487"},
    "LAC": {"primary":	"#0080C6", "secondary":	"#3399D1", "tertiary":	"#66B3DD"},
    "LA":  {"primary":	"#003594", "secondary":	"#335DA9", "tertiary":	"#6686BF"},
    "LV":  {"primary":	"#000000", "secondary":	"#333333", "tertiary":	"#666666"},
    "MIA": {"primary":	"#008E97", "secondary":	"#33A5AC", "tertiary":	"#5BB1B8"},
    "MIN": {"primary":	"#4F2683", "secondary":	"#72519C", "tertiary":	"#957DB5"},
    "NE":  {"primary":	"#002244", "secondary":	"#334E69", "tertiary":	"#667A8F"},
    "NO":  {"primary":	"#D3BC8D", "secondary":	"#DECDAA", "tertiary":	"#E7DAC0"},
    "NYG": {"primary":	"#0B2265", "secondary":	"#3C4E84", "tertiary":	"#6D7AA3"},
    "NYJ": {"primary":	"#125740", "secondary":	"#417966", "tertiary":	"#719A8C"},
    "PHI": {"primary":	"#004C54", "secondary":	"#337076", "tertiary":	"#669498"},
    "PIT": {"primary":	"#FFB612", "secondary":	"#F0A600", "tertiary":	"#FFD371"},
    "SF":  {"primary":	"#AA0000", "secondary":	"#BB3333", "tertiary":	"#CC6666"},
    "SEA": {"primary":	"#002244", "secondary":	"#334E69", "tertiary":	"#667A8F"},
    "TB":  {"primary":	"#D50A0A", "secondary":	"#DE3B3B", "tertiary":	"#E66C6C"},
    "TEN": {"primary":	"#4B92DB", "secondary":	"#6FA8E2", "tertiary":	"#93BEE9"},
    "WAS": {"primary":	"#773141", "secondary": "#925A67", "tertiary":  "#AD838D"}
  },
  mlb: {
    "ARI": {
      "primary": "#a71930",
      "secondary": "#b94759",
      "tertiary": "#ca7583"
    },
    "ATL": {
      "primary": "#ce1141",
      "secondary": "#d84167",
      "tertiary": "#e2708d"
    },
    "BAL": {
      "primary": "#df4601",
      "secondary": "#e56b34",
      "tertiary": "#ec9067"
    },
    "BOS": {
      "primary": "#bd3039",
      "secondary": "#ca5961",
      "tertiary": "#d78388"
    },
    "CHC": {
      "primary": "#0e3386",
      "secondary": "#3e5c9e",
      "tertiary": "#6e85b6"
    },
    "CIN": {
      "primary": "#c6011f",
      "secondary": "#d1344c",
      "tertiary": "#dd6779"
    },
    "CLE": {
      "primary": "#06203f",
      "secondary": "#384d65",
      "tertiary": "#6a798c"
    },
    "COL": {
      "primary": "#33006f",
      "secondary": "#5c338c",
      "tertiary": "#8566a9"
    },
    "CWS": {
      "primary": "#27251f",
      "secondary": "#52514c",
      "tertiary": "#7d7c79"
    },
    "DET": {
      "primary": "#0c2340",
      "secondary": "#3d4f66",
      "tertiary": "#6d7b8c"
    },
    "HOU": {
      "primary": "#002d62",
      "secondary": "#335781",
      "tertiary": "#6681a1"
    },
    "KC": {
      "primary": "#004687",
      "secondary": "#336b9f",
      "tertiary": "#6690b7"
    },
    "LAA": {
      "primary": "#003263",
      "secondary": "#335b82",
      "tertiary": "#6684a1"
    },
    "LAD": {
      "primary": "#005a9c",
      "secondary": "#337bb0",
      "tertiary": "#669cc3"
    },
    "MIA": {
      "primary": "#00a3e0",
      "secondary": "#33b5e6",
      "tertiary": "#66c8ec"
    },
    "MIL": {
      "primary": "#12284b",
      "secondary": "#41536f",
      "tertiary": "#717e93"
    },
    "MIN": {
      "primary": "#002b5c",
      "secondary": "#33557d",
      "tertiary": "#66809d"
    },
    "NYM": {
      "primary": "#002d72",
      "secondary": "#33578e",
      "tertiary": "#6681aa"
    },
    "NYY": {
      "primary": "#0c2340",
      "secondary": "#3d4f66",
      "tertiary": "#6d7b8c"
    },
    "OAK": {
      "primary": "#003831",
      "secondary": "#33605a",
      "tertiary": "#668883"
    },
    "PHI": {
      "primary": "#e81828",
      "secondary": "#ed4653",
      "tertiary": "#f1747e"
    },
    "PIT": {
      "primary": "#fdb827",
      "secondary": "#fec652",
      "tertiary": "#fed47d"
    },
    "SD": {
      "primary": "#2f241d",
      "secondary": "#59504a",
      "tertiary": "#827c77"
    },
    "SEA": {
      "primary": "#0c2c56",
      "secondary": "#3d5678",
      "tertiary": "#6d809a"
    },
    "SF": {
      "primary": "#fd5a1e",
      "secondary": "#fe7b4b",
      "tertiary": "#fe9c78"
    },
    "STL": {
      "primary": "#c41e3a",
      "secondary": "#d04b61",
      "tertiary": "#dc7889"
    },
    "TB": {
      "primary": "#092c5c",
      "secondary": "#3a567d",
      "tertiary": "#6b809d"
    },
    "TEX": {
      "primary": "#003278",
      "secondary": "#335b93",
      "tertiary": "#6684ae"
    },
    "TOR": {
      "primary": "#134a8e",
      "secondary": "#426ea5",
      "tertiary": "#7192bb"
    },
    "WSH": {
      "primary": "#ab0003",
      "secondary": "#bc3335",
      "tertiary": "#cd6668"
    }
  },
  bundesliga: {
    "LEV": {
      "primary": "#e32221",
      "secondary": "#e94f4e",
      "tertiary": "#ee7c7c"
    },
    "BMG": {
      "primary": "#000000",
      "secondary": "#363636",
      "tertiary": "#696969"
    },
    "BOC": {
      "primary": "#005ca9",
      "secondary": "#0077db",
      "tertiary": "#0f92ff"
    },
    "BSC": {
      "primary": "#004d9e",
      "secondary": "#0066d1",
      "tertiary": "#057fff"
    },
    "BVB": {
      "primary": "#fde100",
      "secondary": "#ffee66",
      "tertiary": "#fff9cc"
    },
    "FCA": {
      "primary": "#ba3733",
      "secondary": "#cf534f",
      "tertiary": "#da7a77"
    },
    "UNI": {
      "primary": "#eb1923",
      "secondary": "#ef4850",
      "tertiary": "#f3777d"
    },
    "KOE": {
      "primary": "#ed1c24",
      "secondary": "#f14b52",
      "tertiary": "#f57b7f"
    },
    "M05": {
      "primary": "#c3141e",
      "secondary": "#e8212c",
      "tertiary": "#ed4f58"
    },
    "BMU": {
      "primary": "#dc052d",
      "secondary": "#fa1a43",
      "tertiary": "#fb4b6c"
    },
    "RBL": {
      "primary": "#dd013f",
      "secondary": "#fe1557",
      "tertiary": "#fe487c"
    },
    "SCH": {
      "primary": "#004b9c",
      "secondary": "#0065d1",
      "tertiary": "#057dff"
    },
    "SCF": {
      "primary": "#000000",
      "secondary": "#363636",
      "tertiary": "#696969"
    },
    "SGE": {
      "primary": "#e1000f",
      "secondary": "#ff1424",
      "tertiary": "#ff4754"
    },
    "SVW": {
      "primary": "#00a768",
      "secondary": "#15c180",
      "tertiary": "#3fcf99"
    },
    "TSG": {
      "primary": "#1961b5",
      "secondary": "#1f78e0",
      "tertiary": "#4c93e6"
    },
    "VFB": {
      "primary": "#e32219",
      "secondary": "#ea4a43",
      "tertiary": "#ef7671"
    },
    "WOB": {
      "primary": "#65b32e",
      "secondary": "#7ecf44",
      "tertiary": "#9ad96d"
    },
    "FCH": {
      "primary": "#e2001a",
      "secondary": "#e6263c",
      "tertiary": "#eb4d5f"
    },
    "D98": {
      "primary": "#1961a9",
      "secondary": "#3c79b6",
      "tertiary": "#5e90c3"
    }
  },
  epl: {
    "ARS": {
      "primary": "#ef0107",
      "secondary": "#fe252a",
      "tertiary": "#fe575c"
    },
    "AVL": {
      "primary": "#670e36",
      "secondary": "#94144e",
      "tertiary": "#c11a65"
    },
    "BRI": {
      "primary": "#0057b8",
      "secondary": "#006feb",
      "tertiary": "#1f89ff"
    },
    "BOU": {
      "primary": "#da291c",
      "secondary": "#e74d41",
      "tertiary": "#ec776e"
    },
    "BRE": {
      "primary": "#e30613",
      "secondary": "#f92431",
      "tertiary": "#fb5660"
    },
    "CHE": {
      "primary": "#034694",
      "secondary": "#045fc8",
      "tertiary": "#0576fa"
    },
    "CRY": {
      "primary": "#1b458f",
      "secondary": "#2359b8",
      "tertiary": "#3571d9"
    },
    "EVE": {
      "primary": "#003399",
      "secondary": "#0044cc",
      "tertiary": "#0055ff"
    },
    "NFO": {
      "primary": "#dd0000",
      "secondary": "#ff0f0f",
      "tertiary": "#ff4242"
    },
    "FUL": {
      "primary": "#000000",
      "secondary": "#333333",
      "tertiary": "#666666"
    },
    "LEE": {
      "primary": "#ffe003",
      "secondary": "#ffed6b",
      "tertiary": "#fff9d1"
    },
    "LEI": {
      "primary": "#003090",
      "secondary": "#0041c2",
      "tertiary": "#0052f5"
    },
    "LIV": {
      "primary": "#c8102e",
      "secondary": "#ed1c3e",
      "tertiary": "#f14c66"
    },
    "MUN": {
      "primary": "#da291c",
      "secondary": "#e74d41",
      "tertiary": "#ec776e"
    },
    "MCI": {
      "primary": "#6cabdd",
      "secondary": "#97c4e7",
      "tertiary": "#c1dbf1"
    },
    "NEW": {
      "primary": "#00b6f1",
      "secondary": "#24c9ff",
      "tertiary": "#57d6ff"
    },
    "SOU": {
      "primary": "#d71920",
      "secondary": "#e83b41",
      "tertiary": "#ed686d"
    },
    "TOT": {
      "primary": "#132257",
      "secondary": "#1d3486",
      "tertiary": "#2543ac"
    },
    "WHU": {
      "primary": "#7a263a",
      "secondary": "#9f324c",
      "tertiary": "#c44060"
    },
    "WOL": {
      "primary": "#fdb913",
      "secondary": "#fdc744",
      "tertiary": "#fed676"
    },
    "BUR": {
      "primary": "#5f0041",
      "secondary": "#77265e",
      "tertiary": "#8f4d7a"
    },
    "SHU": {
      "primary": "#ec2227",
      "secondary": "#ef4347",
      "tertiary": "#f26468"
    },
    "LUT": {
      "primary": "#f94b29",
      "secondary": "#fa6649",
      "tertiary": "#fb8169"
    }
  },
  laliga: {
    "ALM": {
      "primary": "#cc0000",
      "secondary": "#e51a1a",
      "tertiary": "#d65c5c"
    },
    "ATH": {
      "primary": "#ee2523",
      "secondary": "#f25655",
      "tertiary": "#f58584"
    },
    "ATM": {
      "primary": "#272e61",
      "secondary": "#364087",
      "tertiary": "#4551ab"
    },
    "FCB": {
      "primary": "#a50044",
      "secondary": "#c1155c",
      "tertiary": "#ce3b78"
    },
    "RBB": {
      "primary": "#0bb363",
      "secondary": "#1ad57c",
      "tertiary": "#47dc95"
    },
    "CAD": {
      "primary": "#0045a7",
      "secondary": "#005bdb",
      "tertiary": "#0f72ff"
    },
    "RCC": {
      "primary": "#8ac3ee",
      "secondary": "#b8daf5",
      "tertiary": "#c9e4f7"
    },
    "ELC": {
      "primary": "#016139",
      "secondary": "#029256",
      "tertiary": "#02c574"
    },
    "ESP": {
      "primary": "#007fc8",
      "secondary": "#009ffa",
      "tertiary": "#2eb3ff"
    },
    "GET": {
      "primary": "#005999",
      "secondary": "#0077cc",
      "tertiary": "#0094ff"
    },
    "GIR": {
      "primary": "#fc0000",
      "secondary": "#ea4343",
      "tertiary": "#df8181"
    },
    "MAL": {
      "primary": "#e20613",
      "secondary": "#f91f2c",
      "tertiary": "#fa515b"
    },
    "OSA": {
      "primary": "#0a346f",
      "secondary": "#0e4b9f",
      "tertiary": "#1360ce"
    },
    "RVC": {
      "primary": "#e21921",
      "secondary": "#ea4349",
      "tertiary": "#ef7176"
    },
    "RMA": {
      "primary": "#00529f",
      "secondary": "#006cd1",
      "tertiary": "#0586ff"
    },
    "RSO": {
      "primary": "#0067b1",
      "secondary": "#0086e5",
      "tertiary": "#1a9fff"
    },
    "SEV": {
      "primary": "#f43333",
      "secondary": "#f76464",
      "tertiary": "#f99595"
    },
    "VCF": {
      "primary": "#ee3524",
      "secondary": "#f26255",
      "tertiary": "#f58e84"
    },
    "VIL": {
      "primary": "#005187",
      "secondary": "#006eb8",
      "tertiary": "#008deb"
    },
    "VLL": {
      "primary": "#921b88",
      "secondary": "#bd23b0",
      "tertiary": "#da39cd"
    },
    "GCF": {
      "primary": "#c31632",
      "secondary": "#cc3951",
      "tertiary": "#d55c70"
    },
    "LPA": {
      "primary": "#ffe400",
      "secondary": "#ffeb40",
      "tertiary": "#ffef66"
    },
    "ALA": {
      "primary": "#355bb3",
      "secondary": "#5374be",
      "tertiary": "#728cca"
    }
  },
  ligue1: {
    "ACA": {
      "primary": "#ed1d28",
      "secondary": "#f14b54",
      "tertiary": "#f47b81"
    },
    "ANG": {
      "primary": "#000000",
      "secondary": "#363636",
      "tertiary": "#696969"
    },
    "ASM": {
      "primary": "#e51b22",
      "secondary": "#ea484e",
      "tertiary": "#ef767a"
    },
    "AUX": {
      "primary": "#004ea2",
      "secondary": "#0067d6",
      "tertiary": "#0a80ff"
    },
    "STB": {
      "primary": "#ed1c24",
      "secondary": "#f14b52",
      "tertiary": "#f57b7f"
    },
    "CFA": {
      "primary": "#c50c46",
      "secondary": "#e51f5d",
      "tertiary": "#e05782"
    },
    "LIL": {
      "primary": "#e01e13",
      "secondary": "#ee433a",
      "tertiary": "#f27069"
    },
    "FCL": {
      "primary": "#f58113",
      "secondary": "#f79c45",
      "tertiary": "#f9b676"
    },
    "OL": {
      "primary": "#14387f",
      "secondary": "#1b4cac",
      "tertiary": "#225fd8"
    },
    "OM": {
      "primary": "#2faee0",
      "secondary": "#5bbfe7",
      "tertiary": "#87d0ed"
    },
    "MHS": {
      "primary": "#0067a6",
      "secondary": "#0088db",
      "tertiary": "#0fa4ff"
    },
    "FCN": {
      "primary": "#fcd405",
      "secondary": "#fddc35",
      "tertiary": "#fde568"
    },
    "NIC": {
      "primary": "#000000",
      "secondary": "#333333",
      "tertiary": "#666666"
    },
    "PSG": {
      "primary": "#004170",
      "secondary": "#005fa3",
      "tertiary": "#007cd6"
    },
    "RCL": {
      "primary": "#ec1c24",
      "secondary": "#f04c52",
      "tertiary": "#f47b80"
    },
    "SDR": {
      "primary": "#ee2223",
      "secondary": "#f25051",
      "tertiary": "#f57f80"
    },
    "REN": {
      "primary": "#e13327",
      "secondary": "#e75e55",
      "tertiary": "#ee8982"
    },
    "RCS": {
      "primary": "#009fe3",
      "secondary": "#30b1e8",
      "tertiary": "#70bbdb"
    },
    "TFC": {
      "primary": "#745990",
      "secondary": "#8e74a9",
      "tertiary": "#a894bd"
    },
    "EST": {
      "primary": "#006eb2",
      "secondary": "#008ee5",
      "tertiary": "#30a2e8"
    },
    "HAC": {
      "primary": "#79bce7",
      "secondary": "#a1d0ee",
      "tertiary": "#bcdef3"
    },
    "FCM": {
      "primary": "#731013",
      "secondary": "#883436",
      "tertiary": "#9d585a"
    }
  },
  seriea: {
    "ATA": {
      "primary": "#1e71b8",
      "secondary": "#2d8bdd",
      "tertiary": "#58a4e4"
    },
    "BFC": {
      "primary": "#1a2f48",
      "secondary": "#27476d",
      "tertiary": "#355f92"
    },
    "CRE": {
      "primary": "#ed1c24",
      "secondary": "#f14b52",
      "tertiary": "#f57b7f"
    },
    "EMP": {
      "primary": "#00579c",
      "secondary": "#0075d1",
      "tertiary": "#1e8ee6"
    },
    "FIO": {
      "primary": "#482e92",
      "secondary": "#5c3bba",
      "tertiary": "#795ccc"
    },
    "INT": {
      "primary": "#010e80",
      "secondary": "#0113b1",
      "tertiary": "#0219e4"
    },
    "JUV": {
      "primary": "#000000",
      "secondary": "#333333",
      "tertiary": "#666666"
    },
    "LAZ": {
      "primary": "#87d8f7",
      "secondary": "#b7e8fa",
      "tertiary": "#d2eff9"
    },
    "LEC": {
      "primary": "#002856",
      "secondary": "#00408a",
      "tertiary": "#0058bd"
    },
    "ACM": {
      "primary": "#fb090b",
      "secondary": "#fc3b3d",
      "tertiary": "#fd6d6f"
    },
    "MON": {
      "primary": "#ed1639",
      "secondary": "#f14662",
      "tertiary": "#f4768a"
    },
    "NAP": {
      "primary": "#003c82",
      "secondary": "#0052b2",
      "tertiary": "#006ae5"
    },
    "ROM": {
      "primary": "#970a2c",
      "secondary": "#c90d3b",
      "tertiary": "#e72352"
    },
    "SAL": {
      "primary": "#831d1c",
      "secondary": "#ac2625",
      "tertiary": "#d33331"
    },
    "SAM": {
      "primary": "#1b5497",
      "secondary": "#236cc3",
      "tertiary": "#3c86dc"
    },
    "SAS": {
      "primary": "#00a752",
      "secondary": "#16c56c",
      "tertiary": "#33db86"
    },
    "SPE": {
      "primary": "#aa9665",
      "secondary": "#bcac85",
      "tertiary": "#cec3a6"
    },
    "TOR": {
      "primary": "#8a1e03",
      "secondary": "#be2904",
      "tertiary": "#dc4018"
    },
    "UDI": {
      "primary": "#8b7d37",
      "secondary": "#af9e45",
      "tertiary": "#c2b366"
    },
    "VER": {
      "primary": "#002f6c",
      "secondary": "#00459e",
      "tertiary": "#005bd1"
    },
    "CAG": {
      "primary": "#B01028",
      "secondary": "#BC3448",
      "tertiary": "#C85869"
    },
    "FRO": {
      "primary": "#ffdd00",
      "secondary": "#ffe640",
      "tertiary": "#ffe959"
    },
    "GEN": {
      "primary": "#ae1919",
      "secondary": "#ba3c3c",
      "tertiary": "#c65e5e"
    }
  },
  "brazil-serie-a": {
    "ATL": {"primary": "rgba(0,0,0,1)", "secondary": "rgba(0,0,0,1)", "tertiary": "rgba(0,0,0,1)"},
    "BAH": {"primary": "rgba(39,98,166,1)", "secondary": "rgba(39,98,166,1)", "tertiary": "rgba(39,98,166,1)"},
    "BOT": {"primary": "rgba(0,0,0,1)", "secondary": "rgba(0,0,0,1)", "tertiary": "rgba(0,0,0,1)"},
    "BRA": {"primary": "rgba(228,19,73,1)", "secondary": "rgba(228,19,73,1)", "tertiary": "rgba(228,19,73,1)"},
    "CAP": {"primary": "rgba(228,48,49,1)", "secondary": "rgba(228,48,49,1)", "tertiary": "rgba(228,48,49,1)"},
    "COR": {"primary": "rgba(0,0,0,1)", "secondary": "rgba(0,0,0,1)", "tertiary": "rgba(0,0,0,1)"},
    "CRI": {"primary": "rgba(172,146,4,1)", "secondary": "rgba(172,146,4,1)", "tertiary": "rgba(172,146,4,1)"},
    "CRU": {"primary": "rgba(30,61,142,1)", "secondary": "rgba(30,61,142,1)", "tertiary": "rgba(30,61,142,1)"},
    "CUI": {"primary": "rgba(1,90,40,1)", "secondary": "rgba(1,90,40,1)", "tertiary": "rgba(1,90,40,1)"},
    "FLA": {"primary": "rgba(195,40,30,1)", "secondary": "rgba(195,40,30,1)", "tertiary": "rgba(195,40,30,1)"},
    "FLU": {"primary": "rgba(135,10,40,1)", "secondary": "rgba(135,10,40,1)", "tertiary": "rgba(135,10,40,1)"},
    "FOR": {"primary": "rgba(40,97,166,1)", "secondary": "rgba(40,97,166,1)", "tertiary": "rgba(40,97,166,1)"},
    "GOI": {"primary": "rgba(220,18,18,1)", "secondary": "rgba(220,18,18,1)", "tertiary": "rgba(220,18,18,1)"},
    "GPA": {"primary": "rgba(14,147,210,1)", "secondary": "rgba(14,147,210,1)", "tertiary": "rgba(14,147,210,1)"},
    "JUV": {"primary": "rgba(0,134,57,1)", "secondary": "rgba(0,134,57,1)", "tertiary": "rgba(0,134,57,1)"},
    "INT": {"primary": "rgba(229,5,15,1)", "secondary": "rgba(229,5,15,1)", "tertiary": "rgba(229,5,15,1)"},
    "PAL": {"primary": "rgba(0,100,55,1)", "secondary": "rgba(0,100,55,1)", "tertiary": "rgba(0,100,55,1)"},
    "SPA": {"primary": "rgba(237,28,36,1)", "secondary": "rgba(237,28,36,1)", "tertiary": "rgba(237,28,36,1)"},
    "VDG": {"primary": "rgba(0,0,0,1)", "secondary": "rgba(0,0,0,1)", "tertiary": "rgba(0,0,0,1)"},
    "VIT": {"primary": "rgba(224,12,2,1", "secondary": "rgba(224,12,2,1", "tertiary": "rgba(224,12,2,1"}
  },
  mls: {
    "ATL": {"primary": "rgba(128,0,10,1)", "secondary": "rgba(128,0,10,1)", "tertiary": "rgba(128,0,10,1)"},
    "ATX": {"primary": "rgba(0,177,64,1)", "secondary": "rgba(0,177,64,1)", "tertiary": "rgba(0,177,64,1)"},
    "CHA": {"primary": "rgba(26,133,200,1)", "secondary": "rgba(26,133,200,1)", "tertiary": "rgba(26,133,200,1)"},
    "CHI": {"primary": "rgba(255,0,0,1)", "secondary": "rgba(255,0,0,1)", "tertiary": "rgba(255,0,0,1)"},
    "CIN": {"primary": "rgba(240,83,35,1)", "secondary": "rgba(240,83,35,1)", "tertiary": "rgba(240,83,35,1)"},
    "CLB": {"primary": "rgba(254,221,0,1)", "secondary": "rgba(254,221,0,1)", "tertiary": "rgba(254,221,0,1)"},
    "COL": {"primary": "rgba(150,10,44,1)", "secondary": "rgba(150,10,44,1)", "tertiary": "rgba(150,10,44,1)"},
    "DAL": {"primary": "rgba(232,31,62,1)", "secondary": "rgba(232,31,62,1)", "tertiary": "rgba(232,31,62,1)"},
    "DC": {"primary": "rgba(239,62,66,1)", "secondary": "rgba(239,62,66,1)", "tertiary": "rgba(239,62,66,1)"},
    "HOU": {"primary": "rgba(255,107,0,1)", "secondary": "rgba(255,107,0,1)", "tertiary": "rgba(255,107,0,1)"},
    "LAG": {"primary": "rgba(0,36,93,1)", "secondary": "rgba(0,36,93,1)", "tertiary": "rgba(0,36,93,1)"},
    "LAN": {"primary": "rgba(195,158,109,1)", "secondary": "rgba(195,158,109,1)", "tertiary": "rgba(195,158,109,1)"},
    "MIA": {"primary": "rgba(246,180,205,1)", "secondary": "rgba(246,180,205,1)", "tertiary": "rgba(246,180,205,1)"},
    "MIN": {"primary": "rgba(140,210,244,1)", "secondary": "rgba(140,210,244,1)", "tertiary": "rgba(140,210,244,1)"},
    "MON": {"primary": "rgba(0,51,161,1)", "secondary": "rgba(0,51,161,1)", "tertiary": "rgba(0,51,161,1)"},
    "NE": {"primary": "rgba(206,14,45,1)", "secondary": "rgba(206,14,45,1)", "tertiary": "rgba(206,14,45,1)"},
    "NSC": {"primary": "rgba(236,232,58,1)", "secondary": "rgba(236,232,58,1)", "tertiary": "rgba(236,232,58,1)"},
    "NY": {"primary": "rgba(237,30,54,1)", "secondary": "rgba(237,30,54,1)", "tertiary": "rgba(237,30,54,1)"},
    "NYC": {"primary": "rgba(108,172,228,1)", "secondary": "rgba(108,172,228,1)", "tertiary": "rgba(108,172,228,1)"},
    "ORL": {"primary": "rgba(99,52,146,1)", "secondary": "rgba(99,52,146,1)", "tertiary": "rgba(99,52,146,1)"},
    "PHI": {"primary": "rgba(7,27,44,1)", "secondary": "rgba(7,27,44,1)", "tertiary": "rgba(7,27,44,1)"},
    "POR": {"primary": "rgba(0,72,43,1)", "secondary": "rgba(0,72,43,1)", "tertiary": "rgba(0,72,43,1)"},
    "RSL": {"primary": "rgba(179,8,56,1)", "secondary": "rgba(179,8,56,1)", "tertiary": "rgba(179,8,56,1)"},
    "SAI": {"primary": "rgba(236,20,89,1)", "secondary": "rgba(236,20,89,1)", "tertiary": "rgba(236,20,89,1)"},
    "SEA": {"primary": "rgba(0,85,149,1)", "secondary": "rgba(0,85,149,1)", "tertiary": "rgba(0,85,149,1)"},
    "SJ": {"primary": "rgba(0,103,177,1)", "secondary": "rgba(0,103,177,1)", "tertiary": "rgba(0,103,177,1)"},
    "SKC": {"primary": "rgba(145,176,213,1)", "secondary": "rgba(145,176,213,1)", "tertiary": "rgba(145,176,213,1)"},
    "TOR": {"primary": "rgba(184,17,55,1)", "secondary": "rgba(184,17,55,1)", "tertiary": "rgba(184,17,55,1)"},
    "VAN": {"primary": "rgba(0,36,94,1", "secondary": "rgba(0,36,94,1", "tertiary": "rgba(0,36,94,1)"}
  },
  euro: {
    "ALB": {"primary": "rgba(133,28,36,1)", "secondary": "rgba(133,28,36,1)", "tertiary": "rgba(133,28,36,1)"},
    "AUT": {"primary": "rgba(200,16,46,1)", "secondary": "rgba(200,16,46,1)", "tertiary": "rgba(200,16,46,1)"},
    "BEL": {"primary": "rgba(227,6,19,1)", "secondary": "rgba(227,6,19,1)", "tertiary": "rgba(227,6,19,1)"},
    "CHE": {"primary": "rgba(255,0,0,1)", "secondary": "rgba(255,0,0,1)", "tertiary": "rgba(255,0,0,1)"},
    "HRV": {"primary": "rgba(237,28,36,1)", "secondary": "rgba(237,28,36,1)", "tertiary": "rgba(237,28,36,1)"},
    "CZE": {"primary": "rgba(215,20,26,1)", "secondary": "rgba(215,20,26,1)", "tertiary": "rgba(215,20,26,1)"},
    "DNK": {"primary": "rgba(205,24,30,1)", "secondary": "rgba(205,24,30,1)", "tertiary": "rgba(205,24,30,1)"},
    "DEU": {"primary": "rgba(0,0,1,1)", "secondary": "rgba(0,0,1,1)", "tertiary": "rgba(0,0,1,1)"},
    "ENG": {"primary": "rgba(43,87,172,1)", "secondary": "rgba(43,87,172,1)", "tertiary": "rgba(43,87,172,1)"},
    "ESP": {"primary": "rgba(139,13,17,1)", "secondary": "rgba(139,13,17,1)", "tertiary": "rgba(139,13,17,1)"},
    "FRA": {"primary": "rgba(33,48,77,1)", "secondary": "rgba(33,48,77,1)", "tertiary": "rgba(33,48,77,1)"},
    "GEO": {"primary": "rgba(255,0,0,1)", "secondary": "rgba(255,0,0,1)", "tertiary": "rgba(255,0,0,1)"},
    "HUN": {"primary": "rgba(207,36,54,1)", "secondary": "rgba(207,36,54,1)", "tertiary": "rgba(207,36,54,1)"},
    "ITA": {"primary": "rgba(0,82,181,1)", "secondary": "rgba(0,82,181,1)", "tertiary": "rgba(0,82,181,1)"},
    "NLD": {"primary": "rgba(243,108,33,1)", "secondary": "rgba(243,108,33,1)", "tertiary": "rgba(243,108,33,1)"},
    "POL": {"primary": "rgba(221,12,57,1)", "secondary": "rgba(221,12,57,1)", "tertiary": "rgba(221,12,57,1)"},
    "PRT": {"primary": "rgba(228,37,24,1)", "secondary": "rgba(228,37,24,1)", "tertiary": "rgba(228,37,24,1)"},
    "ROU": {"primary": "rgba(179,143,0,1)", "secondary": "rgba(179,143,0,1)", "tertiary": "rgba(179,143,0,1)"},
    "SCO": {"primary": "rgba(23,35,58,1)", "secondary": "rgba(23,35,58,1)", "tertiary": "rgba(23,35,58,1)"},
    "SVN": {"primary": "rgba(0,0,191,1)", "secondary": "rgba(0,0,191,1)", "tertiary": "rgba(0,0,191,1)"},
    "SRB": {"primary": "rgba(183,46,62,1)", "secondary": "rgba(183,46,62,1)", "tertiary": "rgba(183,46,62,1)"},
    "SVK": {"primary": "rgba(4,46,134,1)", "secondary": "rgba(4,46,134,1)", "tertiary": "rgba(4,46,134,1)"},
    "TUR": {"primary": "rgba(227,10,23,1)", "secondary": "rgba(227,10,23,1)", "tertiary": "rgba(227,10,23,1)"},
    "UKR": {"primary": "rgba(166,140,0,1)", "secondary": "rgba(166,140,0,1)", "tertiary": "rgba(166,140,0,1)"},
  }
}

// TODO
// NOTE: Make sure to use RGBA format to allow for custom opacity at runtime
export const TEAM_COLOR_CODES = {
  light: {
    mlb: {
      ari: "rgba(167,25,48,1)",
      atl: "rgba(206,17,65,1)",
      bal: "rgba(223,70,1,1)",
      bos: "rgba(189,48,57,1)",
      chc: "rgba(14,51,134,1)",
      cin: "rgba(198,1,31,1)",
      cle: "rgba(6,32,63,1)",
      col: "rgba(51,0,111,1)",
      cws: "rgba(39,37,31,1)",
      det: "rgba(12,35,64,1)",
      hou: "rgba(0,45,98,1)",
      kc: "rgba(0,70,135,1)",
      laa: "rgba(0,50,99,1)",
      lad: "rgba(0,90,156,1)",
      mia: "rgba(0,163,224,1)",
      mil: "rgba(18,40,75,1)",
      min: "rgba(0,43,92,1)",
      nym: "rgba(0,45,114,1)",
      nyy: "rgba(12,35,64,1)",
      oak: "rgba(0,56,49,1)",
      phi: "rgba(232,24,40,1)",
      pit: "rgba(39,37,31,1)",
      sd: "rgba(47,36,29,1)",
      sea: "rgba(12,44,86,1)",
      sf: "rgba(253,90,30,1)",
      stl: "rgba(196,30,58,1)",
      tb: "rgba(9,44,92,1)",
      tex: "rgba(0,50,120,1)",
      tor: "rgba(19,74,142,1)",
      wsh: "rgba(171,0,3,1)"
    },
    nba: {
      atl: "rgba(224,58,62,1)",
      bos: "rgba(0,122,51,1)",
      bro: "rgba(0,0,0,1)",
      cha: "rgba(29,17,96,1)",
      chi: "rgba(206,17,65,1)",
      cle: "rgba(134,0,56,1)",
      dal: "rgba(0,83,188,1)",
      den: "rgba(14,34,64,1)",
      det: "rgba(200,16,46,1)",
      gsw: "rgba(29,66,138,1)",
      hou: "rgba(206,17,65,1)",
      ind: "rgba(0,45,98,1)",
      lac: "rgba(19,23,63,1)",
      lal: "rgba(85,37,131,1)",
      mem: "rgba(93,118,169,1)",
      mia: "rgba(152,0,46,1)",
      mil: "rgba(0,71,27,1)",
      min: "rgba(12,35,64,1)",
      nop: "rgba(12,35,64,1)",
      nyk: "rgba(245,132,38,1)",
      okl: "rgba(0,122,193,1)",
      orl: "rgba(0,119,192,1)",
      phi: "rgba(0,107,182,1)",
      phx: "rgba(29,17,96,1)",
      por: "rgba(224,58,62,1)",
      sac: "rgba(90,45,129,1)",
      sas: "rgba(196,206,212,1)",
      tor: "rgba(206,17,65,1)",
      uta: "rgba(0,43,92,1)",
      was: "rgba(0,43,92,1)"
    },
    wnba: {
      atl: "rgba(227,23,60,1)",
      chi: "rgba(65,143,223,1)",
      con: "rgba(10,34,64,1)",
      dal: "rgba(13,34,64,1)",
      ind: "rgba(0,45,97,1)",
      las: "rgba(85,37,131,1)",
      lva: "rgba(0,0,0,1)",
      min: "rgba(12,35,64,1)",
      nyl: "rgba(80,123,113,1)",
      pho: "rgba(229,96,32,1)",
      sea: "rgba(44,82,53,1)",
      was: "rgba(225,58,62,1)"
    },
    nfl: {
      ari: "rgba(151,35,63,1)",
      atl: "rgba(167,25,48,1)",
      bal: "rgba(36,23,115,1)",
      buf: "rgba(0,51,141,1)",
      car: "rgba(0,133,202,1)",
      chi: "rgba(230,64,0,1)",
      cin: "rgba(251,79,20,1)",
      cle: "rgba(255,60,0,1)",
      dal: "rgba(4,30,66,1)",
      den: "rgba(0,34,68,1)",
      det: "rgba(0,118,182,1)",
      gb: "rgba(32,55,49,1)",
      hou: "rgba(3,32,47,1)",
      ind: "rgba(0,44,95,1)",
      jax: "rgba(0,103,120,1)",
      kc: "rgba(227,24,55,1)",
      la: "rgba(0,53,148,1)",
      lac: "rgba(0,128,198,1)",
      lv: "rgba(0,0,0,1)",
      mia: "rgba(0,142,151,1)",
      min: "rgba(79,38,131,1)",
      ne: "rgba(0,34,68,1)",
      no: "rgba(211,188,141,1)",
      nyg: "rgba(11,34,101,1)",
      nyj: "rgba(18,87,64,1)",
      phi: "rgba(0,76,84,1)",
      pit: "rgba(255,182,18,1)",
      sea: "rgba(0,34,68,1)",
      sf: "rgba(170,0,0,1)",
      tb: "rgba(213,10,10,1)",
      ten: "rgba(75,146,219,1)",
      was: "rgba(90,20,20,1)"
    },
    nhl: {
      ana: "rgba(207,69,33,1)",
      ari: "rgba(140,38,51,1)",
      bos: "rgba(255,184,28,1)",
      buf: "rgba(0,48,135,1)",
      car: "rgba(204,0,0,1)",
      cbj: "rgba(0,38,84,1)",
      cgy: "rgba(210,0,28,1)",
      chi: "rgba(207,10,44,1)",
      col: "rgba(111,38,61,1)",
      dal: "rgba(0,104,71,1)",
      det: "rgba(206,17,38,1)",
      edm: "rgba(4,30,66,1)",
      flo: "rgba(200,16,46,1)",
      lak: "rgba(138,149,153,1)",
      min: "rgba(21,71,52,1)",
      mtl: "rgba(175,30,45,1)",
      njd: "rgba(206,17,38,1)",
      nsh: "rgba(255,184,28,1)",
      nyi: "rgba(0,83,155,1)",
      nyr: "rgba(0,56,168,1)",
      ott: "rgba(197,32,50,1)",
      phi: "rgba(247,73,2,1)",
      pit: "rgba(252,181,20,1)",
      sea: "rgba(99,159,182,1)",
      sjs: "rgba(3,108,138,1)",
      stl: "rgba(0,47,135,1)",
      tbl: "rgba(0,40,104,1)",
      tor: "rgba(0,32,91,1)",
      van: "rgba(0,32,91,1)",
      vgk: "rgba(180,151,90,1)",
      wpj: "rgba(4,30,66,1)",
      wsh: "rgba(200,16,46,1)",
      uta: "rgba(83,146,201,1)"
    },
    bundesliga: {
      "lev": "rgba(227,34,33,1)",
      "bmg": "rgba(0,0,0,1)",
      "boc": "rgba(0,92,169,1)",
      "bsc": "rgba(0,77,158,1)",
      "bvb": "rgba(253,225,0,1)",
      "fca": "rgba(186,55,51,1)",
      "uni": "rgba(235,25,35,1)",
      "koe": "rgba(237,28,36,1)",
      "m05": "rgba(195,20,30,1)",
      "bmu": "rgba(220,5,45,1)",
      "rbl": "rgba(221,1,63,1)",
      "sch": "rgba(0,75,156,1)",
      "scf": "rgba(0,0,0,1)",
      "sge": "rgba(225,0,15,1)",
      "svw": "rgba(0,167,104,1)",
      "tsg": "rgba(25,97,181,1)",
      "vfb": "rgba(227,34,25,1)",
      "wob": "rgba(101,179,46,1)",
      "d98": "rgba(0,79,159,1)",
      "fch": "rgba(226,0,26,1)",
      "hok": "rgba(0,87,156,1)",
      "stp": "rgba(227,6,19,1)"
    },
    epl: {
      "ars": "rgba(239,1,7,1)",
      "avl": "rgba(103,14,54,1)",
      "bri": "rgba(0,87,184,1)",
      "bou": "rgba(218,41,28,1)",
      "bre": "rgba(227,6,19,1)",
      "che": "rgba(3,70,148,1)",
      "cry": "rgba(27,69,143,1)",
      "eve": "rgba(0,51,153,1)",
      "nfo": "rgba(221,0,0,1)",
      "ful": "rgba(0,0,0,1)",
      "lee": "rgba(255,224,3,1)",
      "lei": "rgba(0,48,144,1)",
      "liv": "rgba(200,16,46,1)",
      "mun": "rgba(218,41,28,1)",
      "mci": "rgba(108,171,221,1)",
      "new": "rgba(0,182,241,1)",
      "sou": "rgba(215,25,32,1)",
      "tot": "rgba(19,34,87,1)",
      "whu": "rgba(122,38,58,1)",
      "wol": "rgba(253,185,19,1)",
      "bur": "rgba(95,0,65,1)",
      "lut": "rgba(249,75,41,1)",
      "shu": "rgba(236,34,39,1)",
      "ips": "rgba(3,51,160,1)"
    },
    laliga: {
      "alm": "rgba(204,0,0,1)",
      "ath": "rgba(238,37,35,1)",
      "atm": "rgba(39,46,97,1)",
      "fcb": "rgba(165,0,68,1)",
      "rbb": "rgba(11,179,99,1)",
      "cad": "rgba(0,69,167,1)",
      "rcc": "rgba(138,195,238,1)",
      "elc": "rgba(1,97,57,1)",
      "esp": "rgba(30,107,192,1)",
      "get": "rgba(0,89,153,1)",
      "gir": "rgba(252,0,0,1)",
      "mal": "rgba(226,6,19,1)",
      "osa": "rgba(10,52,111,1)",
      "rvc": "rgba(226,25,33,1)",
      "rma": "rgba(0,82,159,1)",
      "rso": "rgba(0,103,177,1)",
      "sev": "rgba(244,51,51,1)",
      "vcf": "rgba(238,53,36,1)",
      "vil": "rgba(0,81,135,1)",
      "vll": "rgba(146,27,136,1)",
      "ala": "rgba(2,50,160,1)",
      "gcf": "rgba(195,22,50,1)",
      "lpa": "rgba(255,228,0,1)",
      "leg": "rgba(0,87,184,1)"
    },
    ligue1: {
      "aca": "rgba(237,29,40,1)",
      "ang": "rgba(180,139,75,1)",
      "asm": "rgba(229,27,34,1)",
      "aux": "rgba(0,78,162,1)",
      "stb": "rgba(237,28,36,1)",
      "cfa": "rgba(197,12,70,1)",
      "lil": "rgba(224,30,19,1)",
      "fcl": "rgba(245,129,19,1)",
      "ol": "rgba(20,56,127,1)",
      "om": "rgba(47,174,224,1)",
      "mhs": "rgba(0,103,166,1)",
      "fcn": "rgba(252,212,5,1)",
      "nic": "rgba(0,0,0,1)",
      "psg": "rgba(0,65,112,1)",
      "rcl": "rgba(236,28,36,1)",
      "sdr": "rgba(238,34,35,1)",
      "ren": "rgba(225,51,39,1)",
      "rcs": "rgba(0,159,227,1)",
      "tfc": "rgba(116,89,144,1)",
      "est": "rgba(0,110,178,1)",
      "fcm": "rgba(115,16,19,1)",
      "hac": "rgba(121,188,231,1)",
      "ste": "rgba(29,153,91,1)"
    },
    seriea: {
      "ata": "rgba(30,113,184,1)",
      "bfc": "rgba(26,47,72,1)",
      "cre": "rgba(237,28,36,1)",
      "emp": "rgba(0,87,156,1)",
      "fio": "rgba(72,46,146,1)",
      "int": "rgba(1,14,128,1)",
      "juv": "rgba(0,0,0,1)",
      "laz": "rgba(135,216,247,1)",
      "lec": "rgba(0,40,86,1)",
      "acm": "rgba(251,9,11,1)",
      "mon": "rgba(237,22,57,1)",
      "nap": "rgba(0,60,130,1)",
      "rom": "rgba(151,10,44,1)",
      "sal": "rgba(131,29,28,1)",
      "sam": "rgba(27,84,151,1)",
      "sas": "rgba(0,167,82,1)",
      "spe": "rgba(170,150,101,1)",
      "tor": "rgba(138,30,3,1)",
      "udi": "rgba(139,125,55,1)",
      "ver": "rgba(0,47,108,1)",
      "cag": "rgba(176,16,40,1)",
      "fro": "rgba(255,221,0,1)",
      "gen": "rgba(174,25,25,1)",
      "com": "rgba(29,59,98,1)",
      "par": "rgba(41,53,109,1)",
      "ven": "rgba(214,104,41,1)"
    },
    "brazil-serie-a": {
      "atl": "rgba(0,0,0,1)",
      "bah": "rgba(39,98,166,1)",
      "bot": "rgba(0,0,0,1)",
      "bra": "rgba(228,19,73,1)",
      "cap": "rgba(228,48,49,1)",
      "cor": "rgba(0,0,0,1)",
      "cri": "rgba(172,146,4,1)",
      "cru": "rgba(30,61,142,1)",
      "cui": "rgba(1,90,40,1)",
      "fla": "rgba(195,40,30,1)",
      "flu": "rgba(135,10,40,1)",
      "for": "rgba(40,97,166,1)",
      "goi": "rgba(220,18,18,1)",
      "gpa": "rgba(14,147,210,1)",
      "juv": "rgba(0,134,57,1)",
      "int": "rgba(229,5,15,1)",
      "pal": "rgba(0,100,55,1)",
      "spa": "rgba(237,28,36,1)",
      "vdg": "rgba(0,0,0,1)",
      "vit": "rgba(224,12,2,1)"
    },
    mls: {
      "atl": "rgba(128,0,10,1)",
      "atx": "rgba(0,177,64,1)",
      "cha": "rgba(26,133,200,1)",
      "chi": "rgba(255,0,0,1)",
      "cin": "rgba(240,83,35,1)",
      "clb": "rgba(254,221,0,1)",
      "col": "rgba(150,10,44,1)",
      "dal": "rgba(232,31,62,1)",
      "dc": "rgba(239,62,66,1)",
      "hou": "rgba(255,107,0,1)",
      "lag": "rgba(0,36,93,1)",
      "lan": "rgba(195,158,109,1)",
      "mia": "rgba(246,180,205,1)",
      "min": "rgba(140,210,244,1)",
      "mon": "rgba(0,51,161,1)",
      "ne": "rgba(206,14,45,1)",
      "nsc": "rgba(236,232,58,1)",
      "ny": "rgba(237,30,54,1)",
      "nyc": "rgba(108,172,228,1)",
      "orl": "rgba(99,52,146,1)",
      "phi": "rgba(7,27,44,1)",
      "por": "rgba(0,72,43,1)",
      "rsl": "rgba(179,8,56,1)",
      "sai": "rgba(236,20,89,1)",
      "sea": "rgba(0,85,149,1)",
      "sj": "rgba(0,103,177,1)",
      "skc": "rgba(145,176,213,1)",
      "tor": "rgba(184,17,55,1)",
      "van": "rgba(0,36,94,1)"
    },
    euro: {
      "alb": "rgba(133,28,36,1)",
      "aut": "rgba(200,16,46,1)",
      "bel": "rgba(227,6,19,1)",
      "che": "rgba(255,0,0,1)",
      "hrv": "rgba(237,28,36,1)",
      "cze": "rgba(215,20,26,1)",
      "dnk": "rgba(205,24,30,1)",
      "deu": "rgba(0,0,1,1)",
      "eng": "rgba(43,87,172,1)",
      "esp": "rgba(139,13,17,1)",
      "fra": "rgba(33,48,77,1)",
      "geo": "rgba(255,0,0,1)",
      "hun": "rgba(207,36,54,1)",
      "ita": "rgba(0,82,181,1)",
      "nld": "rgba(243,108,33,1)",
      "pol": "rgba(221,12,57,1)",
      "prt": "rgba(228,37,24,1)",
      "rou": "rgba(179,143,0,1)",
      "sco": "rgba(23,35,58,1)",
      "svn": "rgba(0,0,191,1)",
      "srb": "rgba(183,46,62,1)",
      "svk": "rgba(4,46,134,1)",
      "tur": "rgba(227,10,23,1)",
      "ukr": "rgba(166,140,0,1)",
    }
  },
  dark: {
    mlb: {
      ari: "rgba(229,57,77,1)",
      atl: "rgba(229,57,91,1)",
      bal: "rgba(237,78,6,1)",
      bos: "rgba(221,63,71,1)",
      chc: "rgba(111,130,192,1)",
      cin: "rgba(229,32,44,1)",
      cle: "rgba(102,124,154,1)",
      col: "rgba(165,89,178,1)",
      cws: "rgba(125,122,115,1)",
      det: "rgba(105,128,155,1)",
      hou: "rgba(111,139,182,1)",
      kc: "rgba(36,132,201,1)",
      laa: "rgba(102,126,163,1)",
      lad: "rgba(4,123,190,1)",
      mia: "rgba(45,177,228,1)",
      mil: "rgba(111,132,164,1)",
      min: "rgba(109,136,177,1)",
      nym: "rgba(114,140,194,1)",
      nyy: "rgba(105,128,155,1)",
      oak: "rgba(23,144,140,1)",
      phi: "rgba(247,39,41,1)",
      pit: "rgba(125,122,115,1)",
      sd: "rgba(144,133,124,1)",
      sea: "rgba(111,137,173,1)",
      sf: "rgba(253,114,65,1)",
      stl: "rgba(227,47,72,1)",
      tb: "rgba(111,136,177,1)",
      tex: "rgba(106,128,180,1)",
      tor: "rgba(39,120,191,1)",
      wsh: "rgba(230,53,29,1)"
    },
    nba: {
      atl: "rgba(239,67,62,1)",
      bos: "rgba(29,157,73,1)",
      bro: "rgba(123,123,123,1)",
      cha: "rgba(116,118,180,1)",
      chi: "rgba(229,57,91,1)",
      cle: "rgba(230,63,90,1)",
      dal: "rgba(0,130,237,1)",
      den: "rgba(106,126,155,1)",
      det: "rgba(231,39,61,1)",
      gsw: "rgba(53,128,206,1)",
      hou: "rgba(229,57,91,1)",
      ind: "rgba(111,139,182,1)",
      lac: "rgba(102,104,130,1)",
      lal: "rgba(176,86,184,1)",
      mem: "rgba(122,143,184,1)",
      mia: "rgba(215,55,75,1)",
      mil: "rgba(31,135,65,1)",
      min: "rgba(105,128,155,1)",
      nop: "rgba(105,128,155,1)",
      nyk: "rgba(250,171,51,1)",
      okl: "rgba(0,139,213,1)",
      orl: "rgba(0,135,212,1)",
      phi: "rgba(11,123,200,1)",
      phx: "rgba(116,118,180,1)",
      por: "rgba(239,67,62,1)",
      sac: "rgba(169,111,185,1)",
      sas: "rgba(231,235,239,1)",
      tor: "rgba(229,57,91,1)",
      uta: "rgba(109,136,177,1)",
      was: "rgba(109,136,177,1)"
    },
    wnba: {
      atl: "rgba(65,143,223,1)",
      chi: "rgba(230,46,80,1)",
      con: "rgba(108,122,140,1)",
      dal: "rgba(110,122,140,1)",
      ind: "rgba(102,129,160,1)",
      las: "rgba(136,102,168,1)",
      lva: "rgba(123,123,123,1)",
      min: "rgba(109,123,140,1)",
      nyl: "rgba(134,205,188,1)",
      pho: "rgba(229,96,32,1)",
      sea: "rgba(97,125,104,1)",
      was: "rgba(225,58,62,1)"
    },
    nfl: {
      ari: "rgba(215,71,93,1)",
      atl: "rgba(229,57,77,1)",
      bal: "rgba(122,126,195,1)",
      buf: "rgba(110,131,197,1)",
      car: "rgba(5,153,222,1)",
      chi: "rgba(230,64,0,1)",
      cin: "rgba(255,84,24,1)",
      cle: "rgba(255,66,5,1)",
      dal: "rgba(102,122,156,1)",
      den: "rgba(101,126,157,1)",
      det: "rgba(10,168,236,1)",
      gb: "rgba(90,132,120,1)",
      hou: "rgba(88,124,148,1)",
      ind: "rgba(110,137,179,1)",
      jax: "rgba(26,136,166,1)",
      kc: "rgba(245,38,61,1)",
      la: "rgba(111,132,203,1)",
      lac: "rgba(2,147,218,1)",
      lv: "rgba(123,123,123,1)",
      mia: "rgba(0,161,175,1)",
      min: "rgba(169,86,186,1)",
      ne: "rgba(101,126,157,1)",
      no: "rgba(211,188,141,1)",
      nyg: "rgba(112,129,183,1)",
      nyj: "rgba(33,132,105,1)",
      phi: "rgba(2,129,150,1)",
      pit: "rgba(255,198,25,1)",
      sea: "rgba(101,126,157,1)",
      sf: "rgba(229,53,26,1)",
      tb: "rgba(243,41,25,1)",
      ten: "rgba(93,162,224,1)",
      was: "rgba(183,111,93,1)"
    },
    nhl: {
      ana: "rgba(207,69,33,1)",
      ari: "rgba(204,73,81,1)",
      bos: "rgba(255,197,34,1)",
      buf: "rgba(108,127,192,1)",
      car: "rgba(228,32,12,1)",
      cbj: "rgba(106,131,170,1)",
      cgy: "rgba(241,34,42,1)",
      chi: "rgba(238,37,57,1)",
      col: "rgba(213,78,102,1)",
      dal: "rgba(15,136,99,1)",
      det: "rgba(237,41,53,1)",
      edm: "rgba(102,122,156,1)",
      flo: "rgba(231,39,61,1)",
      lak: "rgba(138,149,153,1)",
      min: "rgba(44,143,117,1)",
      mtl: "rgba(222,55,69,1)",
      njd: "rgba(237,41,53,1)",
      nsh: "rgba(255,197,34,1)",
      nyi: "rgba(0,132,209,1)",
      nyr: "rgba(109,128,209,1)",
      ott: "rgba(228,49,65,1)",
      phi: "rgba(255,77,7,1)",
      pit: "rgba(252,196,27,1)",
      sea: "rgba(124,178,199,1)",
      sjs: "rgba(3,108,138,1)",
      stl: "rgba(108,127,192,1)",
      tbl: "rgba(111,134,186,1)",
      tor: "rgba(111,134,186,1)",
      van: "rgba(107,126,175,1)",
      vgk: "rgba(203,173,95,1)",
      wpj: "rgba(102,122,156,1)",
      wsh: "rgba(231,39,61,1)",
      uta: "rgba(83,146,201,1)"
    },
    bundesliga: {
      "lev": "rgba(227,34,33,1)",
      "bmg": "rgba(123,123,123,1)",
      "boc": "rgba(0,92,169,1)",
      "bsc": "rgba(14,106,189,1)",
      "bvb": "rgba(253,225,0,1)",
      "fca": "rgba(186,55,51,1)",
      "uni": "rgba(235,25,35,1)",
      "koe": "rgba(237,28,36,1)",
      "m05": "rgba(195,20,30,1)",
      "bmu": "rgba(220,5,45,1)",
      "rbl": "rgba(221,1,63,1)",
      "sch": "rgba(14,105,187,1)",
      "scf": "rgba(123,123,123,1)",
      "sge": "rgba(225,0,15,1)",
      "svw": "rgba(0,167,104,1)",
      "tsg": "rgba(25,97,181,1)",
      "vfb": "rgba(227,34,25,1)",
      "wob": "rgba(101,179,46,1)",
      "d98": "rgba(25,97,169,1)",
      "fch": "rgba(226,0,26,1)",
      "hok": "rgba(38,112,171,1)",
      "stp": "rgba(227,6,19,1)"
    },
    epl: {
      "ars": "rgba(239,1,7,1)",
      "avl": "rgba(180,24,71,1)",
      "bri": "rgba(0,87,184,1)",
      "bou": "rgba(218,41,28,1)",
      "bre": "rgba(227,6,19,1)",
      "che": "rgba(17,100,179,1)",
      "cry": "rgba(38,99,174,1)",
      "eve": "rgba(78,105,197,1)",
      "nfo": "rgba(221,0,0,1)",
      "ful": "rgba(123,123,123,1)",
      "lee": "rgba(255,224,3,1)",
      "lei": "rgba(77,110,177,1)",
      "liv": "rgba(200,16,46,1)",
      "mun": "rgba(218,41,28,1)",
      "mci": "rgba(108,171,221,1)",
      "new": "rgba(0,182,241,1)",
      "sou": "rgba(215,25,32,1)",
      "tot": "rgba(112,128,173,1)",
      "whu": "rgba(197,61,68,1)",
      "wol": "rgba(253,185,19,1)",
      "bur": "rgba(135,64,113,1)",
      "lut": "rgba(249,75,41,1)",
      "shu": "rgba(236,34,39,1)",
      "ips": "rgba(58,100,163,1)"
    },
    laliga: {
      "alm": "rgba(204,0,0,1)",
      "ath": "rgba(238,37,35,1)",
      "atm": "rgba(72,90,154,1)",
      "fcb": "rgba(185,7,72,1)",
      "rbb": "rgba(11,179,99,1)",
      "cad": "rgba(3,100,198,1)",
      "rcc": "rgba(138,195,238,1)",
      "elc": "rgba(8,129,78,1)",
      "esp": "rgba(30,107,192,1)",
      "get": "rgba(4,121,187,1)",
      "gir": "rgba(252,0,0,1)",
      "mal": "rgba(226,6,19,1)",
      "osa": "rgba(74,103,158,1)",
      "rvc": "rgba(226,25,33,1)",
      "rma": "rgba(0,114,193,1)",
      "rso": "rgba(0,103,177,1)",
      "sev": "rgba(244,51,51,1)",
      "vcf": "rgba(238,53,36,1)",
      "vil": "rgba(6,112,169,1)",
      "vll": "rgba(168,73,160,1)",
      "ala": "rgba(53,91,179,1)",
      "gcf": "rgba(195,22,50,1)",
      "lpa": "rgba(255,228,0,1)",
      "leg": "rgba(51,121,198,1)"
    },
    ligue1: {
      "aca": "rgba(237,29,40,1)",
      "ang": "rgba(180,139,75,1)",
      "asm": "rgba(229,27,34,1)",
      "aux": "rgba(64,122,185,1)",
      "stb": "rgba(237,28,36,1)",
      "cfa": "rgba(197,12,70,1)",
      "lil": "rgba(224,30,19,1)",
      "fcl": "rgba(245,129,19,1)",
      "ol": "rgba(80,107,173,1)",
      "om": "rgba(47,174,224,1)",
      "mhs": "rgba(0,103,166,1)",
      "fcn": "rgba(252,212,5,1)",
      "nic": "rgba(123,123,123,1)",
      "psg": "rgba(27,109,160,1)",
      "rcl": "rgba(236,28,36,1)",
      "sdr": "rgba(238,34,35,1)",
      "ren": "rgba(225,51,39,1)",
      "rcs": "rgba(0,159,227,1)",
      "tfc": "rgba(116,89,144,1)",
      "est": "rgba(0,110,178,1)",
      "fcm": "rgba(150,76,78,1)",
      "hac": "rgba(121,188,231,1)",
      "ste": "rgba(29,153,91,1)"
    },
    seriea: {
      "ata": "rgba(30,113,184,1)",
      "bfc": "rgba(76,105,142,1)",
      "cre": "rgba(237,28,36,1)",
      "emp": "rgba(0,118,190,1)",
      "fio": "rgba(126,92,183,1)",
      "int": "rgba(42,64,253,1)",
      "juv": "rgba(123,123,123,1)",
      "laz": "rgba(135,216,247,1)",
      "lec": "rgba(73,107,156,1)",
      "acm": "rgba(251,9,11,1)",
      "mon": "rgba(237,22,57,1)",
      "nap": "rgba(22,105,179,1)",
      "rom": "rgba(180,28,60,1)",
      "sal": "rgba(191,56,50,1)",
      "sam": "rgba(33,117,186,1)",
      "sas": "rgba(0,167,82,1)",
      "spe": "rgba(170,150,101,1)",
      "tor": "rgba(183,52,24,1)",
      "udi": "rgba(139,125,55,1)",
      "ver": "rgba(42,91,164,1)",
      "cag": "rgba(180,28,51,1)",
      "fro": "rgba(255,221,0,1)",
      "gen": "rgba(178,37,37,1)",
      "com": "rgba(97,118,145,1)",
      "par": "rgba(95,104,146,1)",
      "ven": "rgba(238,115,46,1)"
    },
    "brazil-serie-a": {
      "atl": "rgba(102,102,102,1)",
      "bah": "rgba(39,98,166,1)",
      "bot": "rgba(102,102,102,1)",
      "bra": "rgba(228,19,73,1)",
      "cap": "rgba(228,48,49,1)",
      "cor": "rgba(102,102,102,1)",
      "cri": "rgba(245,208,6,1)",
      "cru": "rgba(75,100,165,1)",
      "cui": "rgba(52,123,83,1)",
      "fla": "rgba(195,40,30,1)",
      "flu": "rgba(159,59,83,1)",
      "for": "rgba(40,97,166,1)",
      "goi": "rgba(220,18,18,1)",
      "gpa": "rgba(14,147,210,1)",
      "juv": "rgba(0,134,57,1)",
      "int": "rgba(229,5,15,1)",
      "pal": "rgba(38,123,85,1)",
      "spa": "rgba(237,28,36,1)",
      "vdg": "rgba(102,102,102,1)",
      "vit": "rgba(224,12,2,1)"
    },
    mls: {
      "atl": "rgba(160,64,71,1)",
      "atx": "rgba(0,177,64,1)",
      "cha": "rgba(26,133,200,1)",
      "chi": "rgba(255,0,0,1)",
      "cin": "rgba(240,83,35,1)",
      "clb": "rgba(254,221,0,1)",
      "col": "rgba(166,47,76,1)",
      "dal": "rgba(232,31,62,1)",
      "dc": "rgba(239,62,66,1)",
      "hou": "rgba(255,107,0,1)",
      "lag": "rgba(64,91,134,1)",
      "lan": "rgba(195,158,109,1)",
      "mia": "rgba(246,180,205,1)",
      "min": "rgba(140,210,244,1)",
      "mon": "rgba(51,92,180,1)",
      "ne": "rgba(206,14,45,1)",
      "nsc": "rgba(236,232,58,1)",
      "ny": "rgba(237,30,54,1)",
      "nyc": "rgba(108,172,228,1)",
      "orl": "rgba(115,72,157,1)",
      "phi": "rgba(81,95,107,1)",
      "por": "rgba(51,109,85,1)",
      "rsl": "rgba(179,8,56,1)",
      "sai": "rgba(236,20,89,1)",
      "sea": "rgba(13,94,154,1)",
      "sj": "rgba(0,103,177,1)",
      "skc": "rgba(145,176,213,1)",
      "tor": "rgba(184,17,55,1)",
      "van": "rgba(64,91,134,1)"
    },
    euro: {
      "alb": "rgba(151,62,69,1)",
      "aut": "rgba(200,16,46,1)",
      "bel": "rgba(227,6,19,1)",
      "che": "rgba(255,0,0,1)",
      "hrv": "rgba(237,28,36,1)",
      "cze": "rgba(215,20,26,1)",
      "dnk": "rgba(205,24,30,1)",
      "deu": "rgba(89,89,90,1)",
      "eng": "rgba(43,87,172,1)",
      "esp": "rgba(168,74,77,1)",
      "fra": "rgba(100,110,130,1)",
      "geo": "rgba(255,0,0,1)",
      "hun": "rgba(207,36,54,1)",
      "ita": "rgba(38,108,192,1)",
      "nld": "rgba(243,108,33,1)",
      "pol": "rgba(221,12,57,1)",
      "prt": "rgba(228,37,24,1)",
      "rou": "rgba(255,204,0,1)",
      "sco": "rgba(93,101,117,1)",
      "svn": "rgba(77,77,210,1)",
      "srb": "rgba(183,46,62,1)",
      "svk": "rgba(79,109,170,1)",
      "tur": "rgba(227,10,23,1)",
      "ukr": "rgba(255,215,0,1)",
    }
  }
}

export const SCORE_PATHS = {
  nfl: "cumulativeStats.%type%.points",
  nba: "cumulativeStats.%type%.points",
  wnba: "cumulativeStats.%type%.points",
  nhl: "cumulativeStats.%type%.goals",
  mlb: "cumulativeStats.%type%.runs",
  bundesliga: "cumulativeStats.%type%.goals",
  epl: "cumulativeStats.%type%.goals",
  laliga: "cumulativeStats.%type%.goals",
  ligue1: "cumulativeStats.%type%.goals",
  mls: "cumulativeStats.%type%.goals",
  "brazil-serie-a": "cumulativeStats.%type%.goals",
  seriea: "cumulativeStats.%type%.goals",
  euro: "cumulativeStats.%type%.goals"
}

// Note: the 'START' field in gamelogStats should stay with the same name, if it's changed make sure to update the switch in betting-workstation gamelog
const SOCCER_PLAYER_PROPS = {
  'ANYTIME_GOAL_SCORER': {
    fields: ['goals'],
    defensiveTeamFields: ['averageStats.defensive.goals'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 0,
    supportingStats : {
      "GP": "gamesPlayed",
      "G": "averageStats.goals",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "MINS": "cumulativeStats.minutesPlayed",
      // "CC": "cumulativeStats.chancesCreated",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "POS": {
        ranked: true,
        teamFields: ['averageStats.defensive.ballPossession'],
        sortingOrder: 'desc'
      }
    }
  },
  'ONE_OR_MORE_ASSISTS': {
    fields: ['assists'],
    defensiveTeamFields: ['averageStats.defensive.assists'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 0.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "AST": "averageStats.assists",
      "G": "averageStats.goals",
      "SHO": "averageStats.totalShots",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "AST": "cumulativeStats.assists",
      "G": "cumulativeStats.goals",
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      }
    }
  },
  'TWO_OR_MORE_ASSISTS': {
    fields: ['assists'],
    defensiveTeamFields: ['averageStats.defensive.assists'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 1.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "AST": "averageStats.assists",
      "G": "averageStats.goals",
      "SHO": "averageStats.totalShots",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "AST": "cumulativeStats.assists",
      "G": "cumulativeStats.goals",
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      }
    }
  },
  'ONE_OR_MORE_SHOTS_ON_TARGET': {
    fields: ['shotsOnTarget'],
    defensiveTeamFields: ['averageStats.defensive.shotsOnTarget'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 0.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SOT": "averageStats.shotsOnTarget",
      "SHO": "averageStats.totalShots",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SOT": "cumulativeStats.shotsOnTarget",
      "SHO": "cumulativeStats.totalShots",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'TWO_OR_MORE_SHOTS_ON_TARGET': {
    fields: ['shotsOnTarget'],
    defensiveTeamFields: ['averageStats.defensive.shotsOnTarget'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 1.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SOT": "averageStats.shotsOnTarget",
      "SHO": "averageStats.totalShots",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SOT": "cumulativeStats.shotsOnTarget",
      "SHO": "cumulativeStats.totalShots",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'THREE_OR_MORE_SHOTS_ON_TARGET': {
    fields: ['shotsOnTarget'],
    defensiveTeamFields: ['averageStats.defensive.shotsOnTarget'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 2.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SOT": "averageStats.shotsOnTarget",
      "SHO": "averageStats.totalShots",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SOT": "cumulativeStats.shotsOnTarget",
      "SHO": "cumulativeStats.totalShots",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'FOUR_OR_MORE_SHOTS_ON_TARGET': {
    fields: ['shotsOnTarget'],
    defensiveTeamFields: ['averageStats.defensive.shotsOnTarget'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 3.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SOT": "averageStats.shotsOnTarget",
      "SHO": "averageStats.totalShots",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SOT": "cumulativeStats.shotsOnTarget",
      "SHO": "cumulativeStats.totalShots",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'ONE_OR_MORE_SHOTS': {
    fields: ['totalShots'],
    defensiveTeamFields: ['averageStats.defensive.shotsTotal'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 0.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'TWO_OR_MORE_SHOTS': {
    fields: ['totalShots'],
    defensiveTeamFields: ['averageStats.defensive.shotsTotal'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 1.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'THREE_OR_MORE_SHOTS': {
    fields: ['totalShots'],
    defensiveTeamFields: ['averageStats.defensive.shotsTotal'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 2.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'FOUR_OR_MORE_SHOTS': {
    fields: ['totalShots'],
    defensiveTeamFields: ['averageStats.defensive.shotsTotal'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 3.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'FIVE_OR_MORE_SHOTS': {
    fields: ['totalShots'],
    defensiveTeamFields: ['averageStats.defensive.shotsTotal'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 4.5,
    supportingStats : {
      "GP": "gamesPlayed",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "G": "averageStats.goals",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "MINS": "cumulativeStats.minutesPlayed",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "AST": {
        ranked: true,
        teamFields: ['averageStats.defensive.assists'],
        sortingOrder: 'asc'
      }
    }
  },
  'GOAL_OR_ASSIST': {
    fields: ['goals', 'assists'],
    defensiveTeamFields: ['averageStats.defensive.goals', 'averageStats.defensive.assists'],
    defensiveSortingOrder: 'asc',
    defaultLineValue: 0,
    supportingStats : {
      "GP": "gamesPlayed",
      "G": "averageStats.goals",
      "SHO": "averageStats.totalShots",
      "SOT": "averageStats.shotsOnTarget",
      "MINS": "averageStats.minutesPlayed"
    },
    gamelogStats: {
      "G": "cumulativeStats.goals",
      "AST": "cumulativeStats.assists",
      "SHO": "cumulativeStats.totalShots",
      "SOT": "cumulativeStats.shotsOnTarget",
      "MINS": "cumulativeStats.minutesPlayed",
      // "CC": "cumulativeStats.chancesCreated",
      "DRI": "cumulativeStats.dribblesCompleted",
      "PAS": "cumulativeStats.totalPasses",
      "START": "cumulativeStats.started"
    },
    opposingSupportingStats: {
      "GP": {
        ranked: false,
        teamFields: ['gamesPlayed']
      },
      "G": {
        ranked: true,
        teamFields: ['averageStats.defensive.goals'],
        sortingOrder: 'asc'
      },
      "SHO": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsTotal'],
        sortingOrder: 'asc'
      },
      "SOT": {
        ranked: true,
        teamFields: ['averageStats.defensive.shotsOnTarget'],
        sortingOrder: 'asc'
      },
      "POS": {
        ranked: true,
        teamFields: ['averageStats.defensive.ballPossession'],
        sortingOrder: 'desc'
      }
    }
  }
}

export const PLAYER_PROPS_INFO = {
  nfl: {
    'ANYTIME_TOUCHDOWN': {
      fields: ['receivingTouchdowns', 'rushingTouchdowns'],
      defensiveTeamFields: ['averageStats.defensive.totalTouchdowns'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed",
        "REC TD": "averageStats.receivingTouchdowns",
        "RUSH TD": "averageStats.rushingTouchdowns",
        "REC YDS": "averageStats.receivingYards",
        "RUSH YDS": "averageStats.rushingYards"
      },
      gamelogStats: {
        "REC TD": "cumulativeStats.receivingTouchdowns",
        "RUSH TD": "cumulativeStats.rushingTouchdowns",
        "REC YDS": "cumulativeStats.receivingYards",
        "RUSH YDS": "cumulativeStats.rushingYards",
        "RZ TGT": "cumulativeStats.redzoneTargets",
        "RZ ATT": "cumulativeStats.redzoneRushingAttempts"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PASS TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        },
        "RUSH TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingTouchdowns'],
          sortingOrder: 'asc'
        },
        "PASS YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "RUSH YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingYards'],
          sortingOrder: 'asc'
        }
      }
    },
    'PASSING_YARDS': {
      fields: ['passingYards'],
      defensiveTeamFields: ['averageStats.defensive.passingYards'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 250.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "PASS YDS": "averageStats.passingYards",
        "PASS ATT": "averageStats.passingAttempts",
        "PASS COMPL": "averageStats.passingCompletions",
        "PASS TD": "averageStats.passingTouchdowns"
      },
      gamelogStats: {
        "PASS YDS": "cumulativeStats.passingYards",
        "PASS ATT": "cumulativeStats.passingAttempts",
        "PASS COMPL": "cumulativeStats.passingCompletions",
        "PASS TD": "cumulativeStats.passingTouchdowns",
        "INT": "cumulativeStats.interceptions",
        "RZ ATT": "cumulativeStats.redzonePassingAttempts",
        "RZ COMPL": "cumulativeStats.redzoneCompletions",
        "RZ TD": "cumulativeStats.redzonePassingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PASS YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "PASS ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "PASS COMPL": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        },
        "PASS TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        }
      }
    },
    'PASSING_TOUCHDOWNS': {
      fields: ['passingTouchdowns'],
      defensiveTeamFields: ['averageStats.defensive.passingTouchdowns'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 1.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "PASS TD": "averageStats.passingTouchdowns",
        "PASS YDS": "averageStats.passingYards",
        "PASS ATT": "averageStats.passingAttempts",
        "PASS COMPL": "averageStats.passingCompletions",
      },
      gamelogStats: {
        "PASS TD": "cumulativeStats.passingTouchdowns",
        "PASS ATT": "cumulativeStats.passingAttempts",
        "PASS YDS": "cumulativeStats.passingYards",
        "PASS COMPL": "cumulativeStats.passingCompletions",
        "INT": "cumulativeStats.interceptions",
        "RZ ATT": "cumulativeStats.redzonePassingAttempts",
        "RZ COMPL": "cumulativeStats.redzoneCompletions",
        "RZ TD": "cumulativeStats.redzonePassingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PASS TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        },
        "PASS YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "PASS ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "PASS COMPL": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        }
      }
    },
    'PASSING_COMPLETIONS': {
      fields: ['passingCompletions'],
      defensiveTeamFields: ['averageStats.defensive.passingCompletions'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 17.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "PASS COMPL": "averageStats.passingCompletions",
        "PASS YDS": "averageStats.passingYards",
        "PASS ATT": "averageStats.passingAttempts",
        "PASS TD": "averageStats.passingTouchdowns"
      },
      gamelogStats: {
        "PASS COMPL": "cumulativeStats.passingCompletions",
        "PASS ATT": "cumulativeStats.passingAttempts",
        "PASS YDS": "cumulativeStats.passingYards",
        "PASS TD": "cumulativeStats.passingTouchdowns",
        "INT": "cumulativeStats.interceptions",
        "RZ ATT": "cumulativeStats.redzonePassingAttempts",
        "RZ COMPL": "cumulativeStats.redzoneCompletions",
        "RZ TD": "cumulativeStats.redzonePassingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PASS COMPL": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        },
        "PASS YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "PASS ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "PASS TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        }
      }
    },
    'PASSING_ATTEMPTS': {
      fields: ['passingAttempts'],
      defensiveTeamFields: ['averageStats.defensive.passingAttempts'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 28.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "PASS ATT": "averageStats.passingAttempts",
        "PASS YDS": "averageStats.passingYards",
        "PASS COMPL": "averageStats.passingCompletions",
        "PASS TD": "averageStats.passingTouchdowns"
      },
      gamelogStats: {
        "PASS ATT": "cumulativeStats.passingAttempts",
        "PASS YDS": "cumulativeStats.passingYards",
        "PASS COMPL": "cumulativeStats.passingCompletions",
        "PASS TD": "cumulativeStats.passingTouchdowns",
        "INT": "cumulativeStats.interceptions",
        "RZ ATT": "cumulativeStats.redzonePassingAttempts",
        "RZ COMPL": "cumulativeStats.redzoneCompletions",
        "RZ TD": "cumulativeStats.redzonePassingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PASS ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "PASS YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "PASS COMPL": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        },
        "PASS TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        }
      }
    },
    'INTERCEPTIONS': {
      fields: ['interceptions'],
      defensiveTeamFields: ['averageStats.defensive.interceptions'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "INT": "averageStats.interceptions",
        "PASS ATT": "averageStats.passingAttempts",
        "PASS COMPL": "averageStats.passingCompletions",
        "PASS TD": "averageStats.passingTouchdowns"
      },
      gamelogStats: {
        "INT": "cumulativeStats.interceptions",
        "PASS ATT": "cumulativeStats.passingAttempts",
        "PASS COMPL": "cumulativeStats.passingCompletions",
        "PASS YDS": "cumulativeStats.passingYards",
        "PASS TD": "cumulativeStats.passingTouchdowns",
        "RZ ATT": "cumulativeStats.redzonePassingAttempts",
        "RZ COMPL": "cumulativeStats.redzoneCompletions",
        "RZ TD": "cumulativeStats.redzonePassingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "INT": {
          ranked: true,
          teamFields: ['averageStats.defensive.interceptions'],
          sortingOrder: 'desc'
        },
        "PASS ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "PASS COMPL": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        },
        "PASS TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        }
      }
    },
    'RECEIVING_YARDS': {
      fields: ['receivingYards'],
      defensiveTeamFields: ['averageStats.defensive.receivingYards'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 55.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "REC YDS": "averageStats.receivingYards",
        "TGT": "averageStats.targets",
        "REC": "averageStats.receptions",
        "REC TD": "averageStats.receivingTouchdowns"
      },
      gamelogStats: {
        "REC YDS": "cumulativeStats.receivingYards",
        "TGT": "cumulativeStats.targets",
        "REC": "cumulativeStats.receptions",
        "REC TD": "cumulativeStats.receivingTouchdowns",
        "RZ TGT": "cumulativeStats.redzoneTargets",
        "RZ REC": "cumulativeStats.redzoneReceptions",
        "RZ TD": "cumulativeStats.redzoneReceivingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "REC YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "REC": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        },
        "TGT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "3D%": {
          ranked: true,
          teamFields: ['averageStats.defensive.thirdDownConversionPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'RECEPTIONS': {
      fields: ['receptions'],
      defensiveTeamFields: ['averageStats.defensive.receptions'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 3.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "REC": "averageStats.receptions",
        "REC YDS": "averageStats.receivingYards",
        "TGT": "averageStats.targets",
        "REC TD": "averageStats.receivingTouchdowns"
      },
      gamelogStats: {
        "REC": "cumulativeStats.receptions",
        "TGT": "cumulativeStats.targets",
        "REC YDS": "cumulativeStats.receivingYards",
        "REC TD": "cumulativeStats.receivingTouchdowns",
        "RZ TGT": "cumulativeStats.redzoneTargets",
        "RZ REC": "cumulativeStats.redzoneReceptions",
        "RZ TD": "cumulativeStats.redzoneReceivingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "REC": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        },
        "REC YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "REC TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingTouchdowns'],
          sortingOrder: 'asc'
        },
        "3D%": {
          ranked: true,
          teamFields: ['averageStats.defensive.thirdDownConversionPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'RUSHING_YARDS': {
      fields: ['rushingYards'],
      defensiveTeamFields: ['averageStats.defensive.rushingYards'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 55.5,
      supportingStats: {
        "GP": "gamesPlayed",
        "RUSH YDS": "averageStats.rushingYards",
        "RUSH ATT": "averageStats.rushingAttempts",
        "RUSH TD": "averageStats.rushingTouchdowns",
        "1D": "averageStats.rushingFirstDowns"
      },
      gamelogStats: {
        "RUSH YDS": "cumulativeStats.rushingYards",
        "RUSH ATT": "cumulativeStats.rushingAttempts",
        "RUSH TD": "cumulativeStats.rushingTouchdowns",
        "REC YDS": "cumulativeStats.receivingYards",
        "REC": "cumulativeStats.receptions",
        "TGT": "cumulativeStats.targets",
        "RZ RUSH": "cumulativeStats.redzoneRushingAttempts",
        "RZ TD": "cumulativeStats.redzoneRushingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "RUSH YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingYards'],
          sortingOrder: 'asc'
        },
        "RUSH ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingAttempts'],
          sortingOrder: 'asc'
        },
        "RUSH TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingTouchdowns'],
          sortingOrder: 'asc'
        },
        "1D": {
          ranked: true,
          teamFields: ['averageStats.defensive.firstDowns'],
          sortingOrder: 'asc'
        }
      }
    },
    'RUSHING_ATTEMPTS': {
      fields: ['rushingAttempts'],
      defensiveTeamFields: ['averageStats.defensive.rushingAttempts'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 10.5,
      supportingStats: {
        "GP": "gamesPlayed",
        "RUSH ATT": "averageStats.rushingAttempts",
        "RUSH YDS": "averageStats.rushingYards",
        "RUSH TD": "averageStats.rushingTouchdowns",
        "1D": "averageStats.rushingFirstDowns"
      },
      gamelogStats: {
        "RUSH ATT": "cumulativeStats.rushingAttempts",
        "RUSH YDS": "cumulativeStats.rushingYards",
        "RUSH TD": "cumulativeStats.rushingTouchdowns",
        "REC YDS": "cumulativeStats.receivingYards",
        "REC": "cumulativeStats.receptions",
        "TGT": "cumulativeStats.targets",
        "RZ RUSH": "cumulativeStats.redzoneRushingAttempts",
        "RZ TD": "cumulativeStats.redzoneRushingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "RUSH ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingAttempts'],
          sortingOrder: 'asc'
        },
        "RUSH YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingYards'],
          sortingOrder: 'asc'
        },
        "RUSH TD": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingTouchdowns'],
          sortingOrder: 'asc'
        },
        "1D": {
          ranked: true,
          teamFields: ['averageStats.defensive.firstDowns'],
          sortingOrder: 'asc'
        }
      }
    },
    'RUSHING_PLUS_RECEIVING_YARDS': {
      fields: ['rushingYards', 'receivingYards'],
      defensiveTeamFields: ['averageStats.defensive.rushingYards', 'averageStats.defensive.receivingYards'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed",
        "RUSH YDS": "averageStats.rushingYards",
        "REC YDS": "averageStats.receivingYards",
        "RUSH ATT": "averageStats.rushingAttempts",
        "REC": "averageStats.receptions"
      },
      gamelogStats: {
        "RUSH YDS": "cumulativeStats.rushingYards",
        "REC YDS": "cumulativeStats.receivingYards",
        "RUSH ATT": "cumulativeStats.rushingAttempts",
        "TGT": "cumulativeStats.targets",
        "REC": "cumulativeStats.receptions",
        "REC TD": "cumulativeStats.receivingTouchdowns",
        "RUSH TD": "cumulativeStats.rushingTouchdowns"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "RUSH YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingYards'],
          sortingOrder: 'asc'
        },
        "REC YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "RUSH ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingAttempts'],
          sortingOrder: 'asc'
        },
        "REC": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingCompletions'],
          sortingOrder: 'asc'
        }
      }
    },
    'PASSING_PLUS_RUSHING_YARDS': {
      fields: ['passingYards', 'rushingYards'],
      defensiveTeamFields: ['averageStats.defensive.passingYards', 'averageStats.defensive.rushingYards'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed",
        "PASS YDS": "averageStats.passingYards",
        "RUSH YDS": "averageStats.rushingYards",
        "PASS ATT": "averageStats.passingAttempts",
        "RUSH ATT": "averageStats.rushingAttempts"
      },
      gamelogStats: {
        "PASS YDS": "cumulativeStats.passingYards",
        "RUSH YDS": "cumulativeStats.rushingYards",
        "PASS ATT": "cumulativeStats.passingAttempts",
        "PASS COMPL": "cumulativeStats.passingCompletions",
        "RUSH ATT": "cumulativeStats.rushingAttempts",
        "PASS TD": "cumulativeStats.passingTouchdowns",
        "RUSH TD": "cumulativeStats.rushingTouchdowns",
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PASS YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingYards'],
          sortingOrder: 'asc'
        },
        "RUSH YDS": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingYards'],
          sortingOrder: 'asc'
        },
        "PASS ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.passingAttempts'],
          sortingOrder: 'asc'
        },
        "RUSH ATT": {
          ranked: true,
          teamFields: ['averageStats.defensive.rushingAttempts'],
          sortingOrder: 'asc'
        }
      }
    },
    'EXTRA_POINTS_MADE': {
      fields: ['extraPointsMade'],
      defensiveTeamFields: [],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed"
      },
      gamelogStats: {
        "XPM": "cumulativeStats.extraPointsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        }
      }
    },
    'FIELD_GOALS_MADE': {
      fields: ['fieldGoalsMade'],
      defensiveTeamFields: [],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed"
      },
      gamelogStats: {
        "FGM": "cumulativeStats.fieldGoalsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        }
      }
    }
  },
  nba: {
    'POINTS': {
      fields: ['points'],
      defensiveTeamFields: ['averageStats.defensive.points'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "FG%": "averageStats.fieldGoalPercentage",
        "FT%": "averageStats.freeThrowPercentage",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "3PTM": "cumulativeStats.threePointsMade",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_1Q': {
      fields: ['points1q'],
      defensiveTeamFields: ['averageStats.defensive.points1q'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "1Q PTS": "averageStats.points1q",
        "1Q AST": "averageStats.assists1q",
        "1Q REB": "averageStats.rebounds1q",
        "1Q MIN": "averageStats.minutesPlayed1q"
      },
      gamelogStats: {
        "1Q PTS": "cumulativeStats.points1q",
        "1Q AST": "cumulativeStats.assists1q",
        "1Q REB": "cumulativeStats.rebounds1q",
        "1Q MIN": "cumulativeStats.minutesPlayed1q",
        "1Q FGA": "cumulativeStats.fieldGoalAttempts1q",
        "1Q 3PTA": "cumulativeStats.threePointAttempts1q",
        "1Q FTA": "cumulativeStats.freeThrowAttempts1q"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "1Q PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points1q'],
          sortingOrder: 'asc'
        },
        "1Q AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists1q'],
          sortingOrder: 'asc'
        },
        "1Q REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds1q'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS': {
      fields: ['assists'],
      defensiveTeamFields: ['averageStats.defensive.assists'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "AST": "averageStats.assists",
        "PTS": "averageStats.points",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "AST": "cumulativeStats.assists",
        "PTS": "cumulativeStats.points",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "3PTM": "cumulativeStats.threePointsMade",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS_1Q': {
      fields: ['assists1q'],
      defensiveTeamFields: ['averageStats.defensive.assists1q'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "1Q AST": "averageStats.assists1q",
        "1Q PTS": "averageStats.points1q",
        "1Q REB": "averageStats.rebounds1q",
        "1Q MIN": "averageStats.minutesPlayed1q"
      },
      gamelogStats: {
        "1Q AST": "cumulativeStats.assists1q",
        "1Q PTS": "cumulativeStats.points1q",
        "1Q REB": "cumulativeStats.rebounds1q",
        "1Q MIN": "cumulativeStats.minutesPlayed1q",
        "1Q FGA": "cumulativeStats.fieldGoalAttempts1q",
        "1Q 3PTA": "cumulativeStats.threePointAttempts1q",
        "1Q FTA": "cumulativeStats.freeThrowAttempts1q"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "1Q PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points1q'],
          sortingOrder: 'asc'
        },
        "1Q AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists1q'],
          sortingOrder: 'asc'
        },
        "1Q REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds1q'],
          sortingOrder: 'asc'
        }
      }
    },
    'REBOUNDS': {
      fields: ['rebounds'],
      defensiveTeamFields: ['averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "REB": "averageStats.rebounds",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "REB": "cumulativeStats.rebounds",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "MIN": "cumulativeStats.minutesPlayed",
        "3PTM": "cumulativeStats.threePointsMade",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'REBOUNDS_1Q': {
      fields: ['rebounds1q'],
      defensiveTeamFields: ['averageStats.defensive.rebounds1q'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "1Q REB": "averageStats.rebounds1q",
        "1Q PTS": "averageStats.points1q",
        "1Q AST": "averageStats.assists1q",
        "1Q MIN": "averageStats.minutesPlayed1q"
      },
      gamelogStats: {
        "1Q REB": "cumulativeStats.rebounds1q",
        "1Q AST": "cumulativeStats.assists1q",
        "1Q PTS": "cumulativeStats.points1q",
        "1Q MIN": "cumulativeStats.minutesPlayed1q",
        "1Q FGA": "cumulativeStats.fieldGoalAttempts1q",
        "1Q 3PTA": "cumulativeStats.threePointAttempts1q",
        "1Q FTA": "cumulativeStats.freeThrowAttempts1q"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "1Q PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points1q'],
          sortingOrder: 'asc'
        },
        "1Q AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists1q'],
          sortingOrder: 'asc'
        },
        "1Q REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds1q'],
          sortingOrder: 'asc'
        }
      }
    },
    'THREE_POINTS_MADE': {
      fields: ['threePointsMade'],
      defensiveTeamFields: ['averageStats.defensive.threePointsMade'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 2.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "3PTM": "averageStats.threePointsMade",
        "3PTA": "averageStats.threePointAttempts",
        "3PT%": "averageStats.threePointPercentage",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "3PTM": "cumulativeStats.threePointsMade",
        "3PTA": "cumulativeStats.threePointAttempts",
        "3PT%": "cumulativeStats.threePointPercentage",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "3PTM": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointsMade'],
          sortingOrder: 'asc'
        },
        "3PTA": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointAttempts'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    // Opposing team's offensive steals means how many times they get stolen, we should use that with asc instead (same for blocks and most likely same for TO)
    // If we change it, make sure to also update opposing supporting stats
    'STEALS': {
      fields: ['steals'],
      defensiveTeamFields: ['averageStats.defensive.steals'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 1.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "STL": "averageStats.steals",
        "TO": "averageStats.turnovers",
        "BLK": "averageStats.blocks",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "STL": "cumulativeStats.steals",
        "TO": "cumulativeStats.turnovers",
        "BLK": "cumulativeStats.blocks",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "STL": {
          ranked: true,
          teamFields: ['averageStats.defensive.steals'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'TURNOVERS': {
      fields: ['turnovers'],
      defensiveTeamFields: ['averageStats.defensive.turnovers'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 1.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "TO": "averageStats.turnovers",
        "BLK": "averageStats.blocks",
        "STL": "averageStats.steals",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "TO": "cumulativeStats.turnovers",
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "TO": {
          ranked: true,
          teamFields: ['averageStats.defensive.turnovers'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'BLOCKS': {
      fields: ['blocks'],
      defensiveTeamFields: ['averageStats.defensive.blocks'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "BLK": "averageStats.blocks",
        "TO": "averageStats.turnovers",
        "STL": "averageStats.steals",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "BLK": "cumulativeStats.blocks",
        "TO": "cumulativeStats.turnovers",
        "STL": "cumulativeStats.steals",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS_PLUS_REBOUNDS': {
      fields: ['assists', 'rebounds'],
      defensiveTeamFields: ['averageStats.defensive.assists', 'averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_PLUS_ASSISTS': {
      fields: ['points', 'assists'],
      defensiveTeamFields: ['averageStats.defensive.points', 'averageStats.defensive.assists'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_PLUS_REBOUNDS': {
      fields: ['points', 'rebounds'],
      defensiveTeamFields: ['averageStats.defensive.points', 'averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_PLUS_ASSISTS_PLUS_REBOUNDS': {
      fields: ['points', 'assists', 'rebounds'],
      defensiveTeamFields: ['averageStats.defensive.points', 'averageStats.defensive.assists', 'averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'STEALS_PLUS_BLOCKS': {
      fields: ['steals', 'blocks'],
      defensiveTeamFields: ['averageStats.defensive.steals', 'averageStats.defensive.blocks'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "STL": "averageStats.steals",
        "BLK": "averageStats.blocks",
        "TO": "averageStats.turnovers",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
        "TO": "cumulativeStats.turnovers",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "STL": {
          ranked: true,
          teamFields: ['averageStats.defensive.steals'],
          sortingOrder: 'asc'
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        },
        "TO": {
          ranked: true,
          teamFields: ['averageStats.defensive.turnovers'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'DOUBLE_DOUBLE': {
      fields: ['doubleDouble'],
      defensiveTeamFields: [],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "DD": "averageStats.doubleDouble",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
      },
      gamelogStats: {
        "DD": "cumulativeStats.doubleDouble",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        }
      }
    },
    'TRIPLE_DOUBLE': {
      fields: ['tripleDouble'],
      defensiveTeamFields: [],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "TD": "averageStats.tripleDouble",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
      },
      gamelogStats: {
        "TD": "cumulativeStats.tripleDouble",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        }
      }
    }
  },
  wnba: {
    'POINTS': {
      fields: ['points'],
      defensiveTeamFields: ['averageStats.defensive.points'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "FG%": "averageStats.fieldGoalPercentage",
        "FT%": "averageStats.freeThrowPercentage",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "3PTM": "cumulativeStats.threePointsMade",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_1Q': {
      fields: ['points1q'],
      defensiveTeamFields: ['averageStats.defensive.points1q'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "1Q PTS": "averageStats.points1q",
        "1Q AST": "averageStats.assists1q",
        "1Q REB": "averageStats.rebounds1q",
        "1Q MIN": "averageStats.minutesPlayed1q"
      },
      gamelogStats: {
        "1Q PTS": "cumulativeStats.points1q",
        "1Q AST": "cumulativeStats.assists1q",
        "1Q REB": "cumulativeStats.rebounds1q",
        "1Q MIN": "cumulativeStats.minutesPlayed1q",
        "1Q FGA": "cumulativeStats.fieldGoalAttempts1q",
        "1Q 3PTA": "cumulativeStats.threePointAttempts1q",
        "1Q FTA": "cumulativeStats.freeThrowAttempts1q"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "1Q PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points1q'],
          sortingOrder: 'asc'
        },
        "1Q AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists1q'],
          sortingOrder: 'asc'
        },
        "1Q REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds1q'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS': {
      fields: ['assists'],
      defensiveTeamFields: ['averageStats.defensive.assists'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "AST": "averageStats.assists",
        "PTS": "averageStats.points",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "AST": "cumulativeStats.assists",
        "PTS": "cumulativeStats.points",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "3PTM": "cumulativeStats.threePointsMade",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS_1Q': {
      fields: ['assists1q'],
      defensiveTeamFields: ['averageStats.defensive.assists1q'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "1Q AST": "averageStats.assists1q",
        "1Q PTS": "averageStats.points1q",
        "1Q REB": "averageStats.rebounds1q",
        "1Q MIN": "averageStats.minutesPlayed1q"
      },
      gamelogStats: {
        "1Q AST": "cumulativeStats.assists1q",
        "1Q PTS": "cumulativeStats.points1q",
        "1Q REB": "cumulativeStats.rebounds1q",
        "1Q MIN": "cumulativeStats.minutesPlayed1q",
        "1Q FGA": "cumulativeStats.fieldGoalAttempts1q",
        "1Q 3PTA": "cumulativeStats.threePointAttempts1q",
        "1Q FTA": "cumulativeStats.freeThrowAttempts1q"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "1Q PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points1q'],
          sortingOrder: 'asc'
        },
        "1Q AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists1q'],
          sortingOrder: 'asc'
        },
        "1Q REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds1q'],
          sortingOrder: 'asc'
        }
      }
    },
    'REBOUNDS': {
      fields: ['rebounds'],
      defensiveTeamFields: ['averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "REB": "averageStats.rebounds",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "REB": "cumulativeStats.rebounds",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "MIN": "cumulativeStats.minutesPlayed",
        "3PTM": "cumulativeStats.threePointsMade",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'REBOUNDS_1Q': {
      fields: ['rebounds1q'],
      defensiveTeamFields: ['averageStats.defensive.rebounds1q'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "1Q REB": "averageStats.rebounds1q",
        "1Q PTS": "averageStats.points1q",
        "1Q AST": "averageStats.assists1q",
        "1Q MIN": "averageStats.minutesPlayed1q"
      },
      gamelogStats: {
        "1Q REB": "cumulativeStats.rebounds1q",
        "1Q AST": "cumulativeStats.assists1q",
        "1Q PTS": "cumulativeStats.points1q",
        "1Q MIN": "cumulativeStats.minutesPlayed1q",
        "1Q FGA": "cumulativeStats.fieldGoalAttempts1q",
        "1Q 3PTA": "cumulativeStats.threePointAttempts1q",
        "1Q FTA": "cumulativeStats.freeThrowAttempts1q"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "1Q PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points1q'],
          sortingOrder: 'asc'
        },
        "1Q AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists1q'],
          sortingOrder: 'asc'
        },
        "1Q REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds1q'],
          sortingOrder: 'asc'
        }
      }
    },
    'THREE_POINTS_MADE': {
      fields: ['threePointsMade'],
      defensiveTeamFields: ['averageStats.defensive.threePointsMade'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 2.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "3PTM": "averageStats.threePointsMade",
        "3PTA": "averageStats.threePointAttempts",
        "3PT%": "averageStats.threePointPercentage",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "3PTM": "cumulativeStats.threePointsMade",
        "3PTA": "cumulativeStats.threePointAttempts",
        "3PT%": "cumulativeStats.threePointPercentage",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "3PTM": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointsMade'],
          sortingOrder: 'asc'
        },
        "3PTA": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointAttempts'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    // Opposing team's offensive steals means how many times they get stolen, we should use that with asc instead (same for blocks and most likely same for TO)
    // If we change it, make sure to also update opposing supporting stats
    'STEALS': {
      fields: ['steals'],
      defensiveTeamFields: ['averageStats.defensive.steals'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 1.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "STL": "averageStats.steals",
        "TO": "averageStats.turnovers",
        "BLK": "averageStats.blocks",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "STL": "cumulativeStats.steals",
        "TO": "cumulativeStats.turnovers",
        "BLK": "cumulativeStats.blocks",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "STL": {
          ranked: true,
          teamFields: ['averageStats.defensive.steals'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'TURNOVERS': {
      fields: ['turnovers'],
      defensiveTeamFields: ['averageStats.defensive.turnovers'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 1.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "TO": "averageStats.turnovers",
        "BLK": "averageStats.blocks",
        "STL": "averageStats.steals",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "TO": "cumulativeStats.turnovers",
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "TO": {
          ranked: true,
          teamFields: ['averageStats.defensive.turnovers'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'BLOCKS': {
      fields: ['blocks'],
      defensiveTeamFields: ['averageStats.defensive.blocks'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0.5,
      supportingStats : {
        "GP": "gamesPlayed",
        "BLK": "averageStats.blocks",
        "TO": "averageStats.turnovers",
        "STL": "averageStats.steals",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "BLK": "cumulativeStats.blocks",
        "TO": "cumulativeStats.turnovers",
        "STL": "cumulativeStats.steals",
        "MIN": "cumulativeStats.minutesPlayed",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "FG%": "cumulativeStats.fieldGoalPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        },
        "FT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.freeThrowPercentage'],
          sortingOrder: 'asc'
        },
        "3PT%": {
          ranked: true,
          teamFields: ['averageStats.defensive.threePointPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS_PLUS_REBOUNDS': {
      fields: ['assists', 'rebounds'],
      defensiveTeamFields: ['averageStats.defensive.assists', 'averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_PLUS_ASSISTS': {
      fields: ['points', 'assists'],
      defensiveTeamFields: ['averageStats.defensive.points', 'averageStats.defensive.assists'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_PLUS_REBOUNDS': {
      fields: ['points', 'rebounds'],
      defensiveTeamFields: ['averageStats.defensive.points', 'averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POINTS_PLUS_ASSISTS_PLUS_REBOUNDS': {
      fields: ['points', 'assists', 'rebounds'],
      defensiveTeamFields: ['averageStats.defensive.points', 'averageStats.defensive.assists', 'averageStats.defensive.rebounds'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage",
        "FGA": "cumulativeStats.fieldGoalAttempts",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "FTA": "cumulativeStats.freeThrowAttempts",
        "FTM": "cumulativeStats.freeThrowsMade"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'STEALS_PLUS_BLOCKS': {
      fields: ['steals', 'blocks'],
      defensiveTeamFields: ['averageStats.defensive.steals', 'averageStats.defensive.blocks'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "STL": "averageStats.steals",
        "BLK": "averageStats.blocks",
        "TO": "averageStats.turnovers",
        "MIN": "averageStats.minutesPlayed"
      },
      gamelogStats: {
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
        "TO": "cumulativeStats.turnovers",
        "MIN": "cumulativeStats.minutesPlayed",
        "FG%": "cumulativeStats.fieldGoalPercentage",
        "FT%": "cumulativeStats.freeThrowPercentage",
        "3PT%": "cumulativeStats.threePointPercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "STL": {
          ranked: true,
          teamFields: ['averageStats.defensive.steals'],
          sortingOrder: 'asc'
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        },
        "TO": {
          ranked: true,
          teamFields: ['averageStats.defensive.turnovers'],
          sortingOrder: 'asc'
        },
        "FG%": {
          ranked: true,
          teamFields: ['averageStats.defensive.fieldGoalPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'DOUBLE_DOUBLE': {
      fields: ['doubleDouble'],
      defensiveTeamFields: [],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "DD": "averageStats.doubleDouble",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
      },
      gamelogStats: {
        "DD": "cumulativeStats.doubleDouble",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        }
      }
    },
    'TRIPLE_DOUBLE': {
      fields: ['tripleDouble'],
      defensiveTeamFields: [],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "TD": "averageStats.tripleDouble",
        "PTS": "averageStats.points",
        "AST": "averageStats.assists",
        "REB": "averageStats.rebounds",
      },
      gamelogStats: {
        "TD": "cumulativeStats.tripleDouble",
        "PTS": "cumulativeStats.points",
        "AST": "cumulativeStats.assists",
        "REB": "cumulativeStats.rebounds",
        "MIN": "cumulativeStats.minutesPlayed",
        "STL": "cumulativeStats.steals",
        "BLK": "cumulativeStats.blocks",
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "PTS": {
          ranked: true,
          teamFields: ['averageStats.defensive.points'],
          sortingOrder: 'asc'
        },
        "AST": {
          ranked: true,
          teamFields: ['averageStats.defensive.assists'],
          sortingOrder: 'asc'
        },
        "REB": {
          ranked: true,
          teamFields: ['averageStats.defensive.rebounds'],
          sortingOrder: 'asc'
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.defensive.blocks'],
          sortingOrder: 'asc'
        }
      }
    }
  },
  nhl: {
    'POINTS': {
      fields: ['points'],
      defensiveTeamFields: ['averageStats.defensive.goals'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PTS": "averageStats.points",
        "G": "averageStats.goals",
        "A": "averageStats.assists",
        "TOI": "averageStats.timeOnIce"
      },
      gamelogStats: {
        "PTS": "cumulativeStats.points",
        "G": "cumulativeStats.goals",
        "A": "cumulativeStats.assists",
        "TOI": "cumulativeStats.timeOnIce",
        "SOG": "cumulativeStats.shotsOnGoal",
        "SAT": "cumulativeStats.shotAttempts",
        "PPP": "cumulativeStats.powerplayPoints",
        "PPG": "cumulativeStats.powerplayGoals"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "GA": {
          ranked: true,
          teamFields: ['averageStats.defensive.goals'],
          sortingOrder: 'asc'
        },
        "SOG-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotsOnGoal'],
          sortingOrder: 'asc'
        },
        "SAT-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotAttempts'],
          sortingOrder: 'asc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.defensive.corsiPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'ASSISTS': {
      fields: ['assists'],
      defensiveTeamFields: ['averageStats.defensive.goals'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "A": "averageStats.assists",
        "PTS": "averageStats.points",
        "SOG": "averageStats.shotsOnGoal",
        "TOI": "averageStats.timeOnIce"
      },
      gamelogStats: {
        "A": "cumulativeStats.assists",
        "G": "cumulativeStats.goals",
        "PTS": "cumulativeStats.points",
        "TOI": "cumulativeStats.timeOnIce",
        "SOG": "cumulativeStats.shotsOnGoal",
        "SAT": "cumulativeStats.shotAttempts",
        "PPP": "cumulativeStats.powerplayPoints",
        "PPG": "cumulativeStats.powerplayGoals"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "GA": {
          ranked: true,
          teamFields: ['averageStats.defensive.goals'],
          sortingOrder: 'asc'
        },
        "SOG-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotsOnGoal'],
          sortingOrder: 'asc'
        },
        "SAT-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotAttempts'],
          sortingOrder: 'asc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.defensive.corsiPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'SHOTS_ON_GOAL': {
      fields: ['shotsOnGoal'],
      defensiveTeamFields: ['averageStats.defensive.shotsOnGoal'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "SOG": "averageStats.shotsOnGoal",
        "SAT": "averageStats.shotAttempts",
        "G": "averageStats.goals",
        "TOI": "averageStats.timeOnIce"
      },
      gamelogStats: {
        "SOG": "cumulativeStats.shotsOnGoal",
        "SAT": "cumulativeStats.shotAttempts",
        "PTS": "cumulativeStats.points",
        "TOI": "cumulativeStats.timeOnIce",
        "G": "cumulativeStats.goals",
        "A": "cumulativeStats.assists",
        "PPP": "cumulativeStats.powerplayPoints",
        "PPG": "cumulativeStats.powerplayGoals"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "GA": {
          ranked: true,
          teamFields: ['averageStats.defensive.goals'],
          sortingOrder: 'asc'
        },
        "SOG-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotsOnGoal'],
          sortingOrder: 'asc'
        },
        "SAT-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotAttempts'],
          sortingOrder: 'asc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.defensive.corsiPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'POWERPLAY_POINTS': {
      fields: ['powerplayPoints'],
      defensiveTeamFields: ['averageStats.defensive.powerplayPercentage'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "PPP": "averageStats.powerplayPoints",
        "G": "averageStats.goals",
        "A": "averageStats.assists",
        "TOI": "averageStats.timeOnIce"
      },
      gamelogStats: {
        "PPP": "cumulativeStats.powerplayPoints",
        "PPG": "cumulativeStats.powerplayGoals",
        "PTS": "cumulativeStats.points",
        "TOI": "cumulativeStats.timeOnIce",
        "G": "cumulativeStats.goals",
        "A": "cumulativeStats.assists",
        "SOG": "cumulativeStats.shotsOnGoal",
        "SAT": "cumulativeStats.shotAttempts"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "GA": {
          ranked: true,
          teamFields: ['averageStats.defensive.goals'],
          sortingOrder: 'asc'
        },
        "SOG-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotsOnGoal'],
          sortingOrder: 'asc'
        },
        "SAT-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotAttempts'],
          sortingOrder: 'asc'
        },
        "SH%": {
          ranked: true,
          teamFields: ['averageStats.offensive.penaltyKillPercentage'],
          sortingOrder: 'desc'
        }
      }
    },
    'ANYTIME_GOAL_SCORER': {
      fields: ['goals'],
      defensiveTeamFields: ['averageStats.defensive.goals'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "G": "averageStats.goals",
        "A": "averageStats.assists",
        "PTS": "averageStats.points",
        "TOI": "averageStats.timeOnIce"
      },
      gamelogStats: {
        "G": "cumulativeStats.goals",
        "A": "cumulativeStats.assists",
        "PTS": "cumulativeStats.points",
        "TOI": "cumulativeStats.timeOnIce",
        "SOG": "cumulativeStats.shotsOnGoal",
        "SAT": "cumulativeStats.shotAttempts",
        "PPP": "cumulativeStats.powerplayPoints",
        "PPG": "cumulativeStats.powerplayGoals"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "GA": {
          ranked: true,
          teamFields: ['averageStats.defensive.goals'],
          sortingOrder: 'asc'
        },
        "SOG-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotsOnGoal'],
          sortingOrder: 'asc'
        },
        "SAT-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotAttempts'],
          sortingOrder: 'asc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.defensive.corsiPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'BLOCKED_SHOTS': {
      fields: ['defensiveBlocks'],
      defensiveTeamFields: ['averageStats.offensive.offensiveBlocks'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "GP": "gamesPlayed",
        "BLK": "averageStats.defensiveBlocks",
        "SOG": "averageStats.shotsOnGoal",
        "PTS": "averageStats.points",
        "TOI": "averageStats.timeOnIce"
      },
      gamelogStats: {
        "BLK": "cumulativeStats.defensiveBlocks",
        "SOG": "cumulativeStats.shotsOnGoal",
        "PTS": "cumulativeStats.points",
        "TOI": "cumulativeStats.timeOnIce",
        "SAT": "cumulativeStats.shotAttempts",
        "A": "cumulativeStats.assists",
        "PPP": "cumulativeStats.powerplayPoints"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "BLK": {
          ranked: true,
          teamFields: ['averageStats.offensive.offensiveBlocks'],
          sortingOrder: 'asc'
        },
        "SOG-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotsOnGoal'],
          sortingOrder: 'asc'
        },
        "SAT-A": {
          ranked: true,
          teamFields: ['averageStats.defensive.shotAttempts'],
          sortingOrder: 'asc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.defensive.corsiPercentage'],
          sortingOrder: 'asc'
        }
      }
    },
    'GOALS_ALLOWED': {
      fields: ['goalsAllowed'],
      defensiveTeamFields: ['averageStats.offensive.goals'],
      defensiveSortingOrder: 'desc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed",
        "GA": "averageStats.goalsAllowed",
        "SA": "averageStats.shotsAgainst",
        "SV": "averageStats.shotsSaved",
        "SV%": "averageStats.savePercentage"
      },
      gamelogStats: {
        "GA": "cumulativeStats.goalsAllowed",
        "SA": "cumulativeStats.shotsAgainst",
        "SV": "cumulativeStats.shotsSaved",
        "SV%": "cumulativeStats.savePercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "G": {
          ranked: true,
          teamFields: ['averageStats.offensive.goals'],
          sortingOrder: 'desc'
        },
        "SOG": {
          ranked: true,
          teamFields: ['averageStats.offensive.shotsOnGoal'],
          sortingOrder: 'desc'
        },
        "SAT": {
          ranked: true,
          teamFields: ['averageStats.offensive.shotAttempts'],
          sortingOrder: 'desc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.offensive.corsiPercentage'],
          sortingOrder: 'desc'
        }
      }
    },
    'SAVES': {
      fields: ['shotsSaved'],
      defensiveTeamFields: ['averageStats.offensive.shotsOnGoal'],
      defensiveSortingOrder: 'desc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed",
        "SV": "averageStats.shotsSaved",
        "SA": "averageStats.shotsAgainst",
        "SV%": "averageStats.savePercentage",
        "GA": "averageStats.goalsAllowed"
      },
      gamelogStats: {
        "SV": "cumulativeStats.shotsSaved",
        "SA": "cumulativeStats.shotsAgainst",
        "SV%": "cumulativeStats.savePercentage",
        "GA": "cumulativeStats.goalsAllowed"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "G": {
          ranked: true,
          teamFields: ['averageStats.offensive.goals'],
          sortingOrder: 'desc'
        },
        "SOG": {
          ranked: true,
          teamFields: ['averageStats.offensive.shotsOnGoal'],
          sortingOrder: 'desc'
        },
        "SAT": {
          ranked: true,
          teamFields: ['averageStats.offensive.shotAttempts'],
          sortingOrder: 'desc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.offensive.corsiPercentage'],
          sortingOrder: 'desc'
        }
      }
    },
    'SHUTOUT': {
      fields: ['shutouts'],
      defensiveTeamFields: ['averageStats.offensive.goals'],
      defensiveSortingOrder: 'desc',
      defaultLineValue: 0,
      supportingStats: {
        "GP": "gamesPlayed",
        "SV": "averageStats.shotsSaved",
        "SA": "averageStats.shotsAgainst",
        "SV%": "averageStats.savePercentage",
        "GA": "averageStats.goalsAllowed"
      },
      gamelogStats: {
        "SO": "cumulativeStats.shutouts",
        "GA": "cumulativeStats.goalsAllowed",
        "SV%": "cumulativeStats.savePercentage"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "G": {
          ranked: true,
          teamFields: ['averageStats.offensive.goals'],
          sortingOrder: 'desc'
        },
        "SOG": {
          ranked: true,
          teamFields: ['averageStats.offensive.shotsOnGoal'],
          sortingOrder: 'desc'
        },
        "SAT": {
          ranked: true,
          teamFields: ['averageStats.offensive.shotAttempts'],
          sortingOrder: 'desc'
        },
        "CF%": {
          ranked: true,
          teamFields: ['averageStats.offensive.corsiPercentage'],
          sortingOrder: 'desc'
        }
      }
    }
  },
  mlb: {
    'PITCHER_STRIKEOUTS': {
        fields: ['pitching.strikeouts'],
        // Pitching is a def stats so it would have to be offensive here
        defensiveTeamFields: ['averageStats.offensive.strikeouts'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats: {
          "BF": "situationalStats.%situation%.pitching.battersFaced",
          "SO": "situationalStats.%situation%.pitching.strikeouts",
          "SO%": "situationalStats.%situation%.pitching.strikeoutPercentage",
          "BB%": "situationalStats.%situation%.pitching.walkPercentage",
          "AVG": "situationalStats.%situation%.pitching.battingAverageAgainst"
        },
        gamelogStats: {
          "PO": "cumulativeStats.pitching.outs",
          "SO": "cumulativeStats.pitching.strikeouts",
          "H": "cumulativeStats.pitching.hits",
          "IP": "cumulativeStats.pitching.inningsPitched",
          "ERA": "cumulativeStats.pitching.earnedRunsAverage",
          "HR": "cumulativeStats.pitching.homeRuns",
          "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst"
        },
        opposingSupportingStats: {
          "GP": {
            ranked: false,
            teamFields: ['gamesPlayed']
          },
          "SO": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.strikeouts'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "BB%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.walkPercentage'],
            sortingOrder: 'desc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.battingAverage'],
            sortingOrder: 'desc'
          }
        }
    },
    'PITCHER_EARNED_RUNS': {
        fields: ['pitching.earnedRuns'],
        defensiveTeamFields: ['averageStats.offensive.earnedRuns'],
        defensiveSortingOrder: 'desc',
        defaultLineValue: 0,
        supportingStats : {
          "BF": "situationalStats.%situation%.pitching.battersFaced",
          "R": "situationalStats.%situation%.pitching.runs",
          "SO%": "situationalStats.%situation%.pitching.strikeoutPercentage",
          "BB%": "situationalStats.%situation%.pitching.walkPercentage",
          "AVG": "situationalStats.%situation%.pitching.battingAverageAgainst"
        },
        gamelogStats: {
          "ERA": "cumulativeStats.pitching.earnedRunsAverage",
          "SO": "cumulativeStats.pitching.strikeouts",
          "H": "cumulativeStats.pitching.hits",
          "IP": "cumulativeStats.pitching.inningsPitched",
          "PO": "cumulativeStats.pitching.outs",
          "HR": "cumulativeStats.pitching.homeRuns",
          "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst"
        },
        opposingSupportingStats: {
          "GP": {
            ranked: false,
            teamFields: ['gamesPlayed']
          },
          "R": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.runs'],
            sortingOrder: 'desc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "BB%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.walkPercentage'],
            sortingOrder: 'desc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.battingAverage'],
            sortingOrder: 'desc'
          }
        }
    },
    'PITCHER_OUTS': {
        fields: ['pitching.outs'],
        defensiveTeamFields: ['averageStats.offensive.outs'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "BF": "situationalStats.%situation%.pitching.battersFaced",
          "H": "situationalStats.%situation%.pitching.hits",
          "SO%": "situationalStats.%situation%.pitching.strikeoutPercentage",
          "BB%": "situationalStats.%situation%.pitching.walkPercentage",
          "AVG": "situationalStats.%situation%.pitching.battingAverageAgainst"
        },
        gamelogStats: {
          "SO": "cumulativeStats.pitching.strikeouts",
          "H": "cumulativeStats.pitching.hits",
          "ERA": "cumulativeStats.pitching.earnedRunsAverage",
          "IP": "cumulativeStats.pitching.inningsPitched",
          "PO": "cumulativeStats.pitching.outs",
          "HR": "cumulativeStats.pitching.homeRuns",
          "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst"
        },
        opposingSupportingStats: {
          "GP": {
            ranked: false,
            teamFields: ['gamesPlayed']
          },
          "H": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.hits'],
            sortingOrder: 'desc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "BB%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.walkPercentage'],
            sortingOrder: 'desc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.battingAverage'],
            sortingOrder: 'desc'
          }
        }
    },
    'PITCHER_HITS_ALLOWED': {
        fields: ['pitching.hits'],
        defensiveTeamFields: ['averageStats.offensive.hits'],
        defensiveSortingOrder: 'desc',
        defaultLineValue: 0,
        supportingStats : {
          "BF": "situationalStats.%situation%.pitching.battersFaced",
          "H": "situationalStats.%situation%.pitching.hits",
          "SO%": "situationalStats.%situation%.pitching.strikeoutPercentage",
          "BB%": "situationalStats.%situation%.pitching.walkPercentage",
          "AVG": "situationalStats.%situation%.pitching.battingAverageAgainst"
        },
        gamelogStats: {
          "H": "cumulativeStats.pitching.hits",
          "SO": "cumulativeStats.pitching.strikeouts",
          "ERA": "cumulativeStats.pitching.earnedRunsAverage",
          "IP": "cumulativeStats.pitching.inningsPitched",
          "PO": "cumulativeStats.pitching.outs",
          "HR": "cumulativeStats.pitching.homeRuns",
          "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst"
        },
        opposingSupportingStats: {
          "GP": {
            ranked: false,
            teamFields: ['gamesPlayed']
          },
          "H": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.hits'],
            sortingOrder: 'desc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "BB%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.walkPercentage'],
            sortingOrder: 'desc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.battingAverage'],
            sortingOrder: 'desc'
          }
        }
    },
    'PITCHER_HOME_RUNS_ALLOWED': {
        fields: ['pitching.homeRuns'],
        defensiveTeamFields: ['averageStats.offensive.homeRuns'],
        defensiveSortingOrder: 'desc',
        defaultLineValue: 0,
        supportingStats : {
          "BF": "situationalStats.%situation%.pitching.battersFaced",
          "HR": "situationalStats.%situation%.pitching.homeRuns",
          "SO%": "situationalStats.%situation%.pitching.strikeoutPercentage",
          "BB%": "situationalStats.%situation%.pitching.walkPercentage",
          "AVG": "situationalStats.%situation%.pitching.battingAverageAgainst"
        },
        gamelogStats: {
          "HR": "cumulativeStats.pitching.homeRuns",
          "H": "cumulativeStats.pitching.hits",
          "SO": "cumulativeStats.pitching.strikeouts",
          "IP": "cumulativeStats.pitching.inningsPitched",
          "ERA": "cumulativeStats.pitching.earnedRunsAverage",
          "PO": "cumulativeStats.pitching.outs",
          "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst"
        },
        opposingSupportingStats: {
          "GP": {
            ranked: false,
            teamFields: ['gamesPlayed']
          },
          "HR": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.homeRuns'],
            sortingOrder: 'desc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "BB%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.walkPercentage'],
            sortingOrder: 'desc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.offensive.battingAverage'],
            sortingOrder: 'desc'
          }
        }
    },
    'PITCHER_WALKS_ALLOWED': {
      fields: ['pitching.walks'],
      defensiveTeamFields: ['averageStats.offensive.walks'],
      defensiveSortingOrder: 'desc',
      defaultLineValue: 0,
      supportingStats : {
        "BF": "situationalStats.%situation%.pitching.battersFaced",
        "BB": "situationalStats.%situation%.pitching.walks",
        "SO%": "situationalStats.%situation%.pitching.strikeoutPercentage",
        "BB%": "situationalStats.%situation%.pitching.walkPercentage",
        "AVG": "situationalStats.%situation%.pitching.battingAverageAgainst"
      },
      gamelogStats: {
        "BB": "cumulativeStats.pitching.walks",
        "H": "cumulativeStats.pitching.hits",
        "SO": "cumulativeStats.pitching.strikeouts",
        "IP": "cumulativeStats.pitching.inningsPitched",
        "ERA": "cumulativeStats.pitching.earnedRunsAverage",
        "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
        "AVG": "cumulativeStats.pitching.battingAverageAgainst"
      },
      opposingSupportingStats: {
        "GP": {
          ranked: false,
          teamFields: ['gamesPlayed']
        },
        "BB": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.offensive.walks'],
          sortingOrder: 'desc'
        },
        "SO%": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.offensive.strikeoutPercentage'],
          sortingOrder: 'asc'
        },
        "BB%": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.offensive.walkPercentage'],
          sortingOrder: 'desc'
        },
        "AVG": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.offensive.battingAverage'],
          sortingOrder: 'desc'
        }
      }
    },
    'HITTER_TOTAL_BASES': {
        fields: ['hitting.totalBases'],
        defensiveTeamFields: ['averageStats.defensive.totalBases'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "TB": "situationalStats.%situation%.hitting.totalBases",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "TB": "cumulativeStats.hitting.totalBases",
          "H": "cumulativeStats.hitting.hits",
          "R": "cumulativeStats.hitting.runs",
          "AB": "cumulativeStats.hitting.atBats",
          "HR": "cumulativeStats.hitting.homeRuns",
          "RBI": "cumulativeStats.hitting.runsBattedIn",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "TB": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.totalBases'],
            playerFields: ['situationalStats.%situation%.pitching.totalBases'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "TB": "cumulativeStats.defensive.totalBases",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "TB": "cumulativeStats.pitching.totalBases",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_HITS': {
        fields: ['hitting.hits'],
        defensiveTeamFields: ['averageStats.defensive.hits'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "H": "situationalStats.%situation%.hitting.hits",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "H": "cumulativeStats.hitting.hits",
          "R": "cumulativeStats.hitting.runs",
          "TB": "cumulativeStats.hitting.totalBases",
          "AB": "cumulativeStats.hitting.atBats",
          "HR": "cumulativeStats.hitting.homeRuns",
          "RBI": "cumulativeStats.hitting.runsBattedIn",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "H": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.hits'],
            playerFields: ['situationalStats.%situation%.pitching.hits'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "H": "cumulativeStats.defensive.hits",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "H": "cumulativeStats.pitching.hits",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_RUNS': {
        fields: ['hitting.runs'],
        defensiveTeamFields: ['averageStats.defensive.runs'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "R": "situationalStats.%situation%.hitting.runs",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "R": "cumulativeStats.hitting.runs",
          "H": "cumulativeStats.hitting.hits",
          "TB": "cumulativeStats.hitting.totalBases",
          "AB": "cumulativeStats.hitting.atBats",
          "HR": "cumulativeStats.hitting.homeRuns",
          "RBI": "cumulativeStats.hitting.runsBattedIn",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "R": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.runs'],
            playerFields: ['situationalStats.%situation%.pitching.runs'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "R": "cumulativeStats.defensive.runs",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "R": "cumulativeStats.pitching.runs",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_RUNS_BATTED_IN': {
        fields: ['hitting.runsBattedIn'],
        defensiveTeamFields: ['averageStats.defensive.runsBattedIn'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "RBI": "situationalStats.%situation%.hitting.runsBattedIn",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "RBI": "cumulativeStats.hitting.runsBattedIn",
          "R": "cumulativeStats.hitting.runs",
          "H": "cumulativeStats.hitting.hits",
          "TB": "cumulativeStats.hitting.totalBases",
          "AB": "cumulativeStats.hitting.atBats",
          "HR": "cumulativeStats.hitting.homeRuns",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "RBI": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.runsBattedIn'],
            playerFields: ['situationalStats.%situation%.pitching.runsBattedIn'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "RBI": "cumulativeStats.defensive.runsBattedIn",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "RBI": "cumulativeStats.pitching.runsBattedIn",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_HOME_RUNS': {
        fields: ['hitting.homeRuns'],
        defensiveTeamFields: ['averageStats.defensive.homeRuns'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "HR": "situationalStats.%situation%.hitting.homeRuns",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "HR": "cumulativeStats.hitting.homeRuns",
          "H": "cumulativeStats.hitting.hits",
          "TB": "cumulativeStats.hitting.totalBases",
          "AB": "cumulativeStats.hitting.atBats",
          "R": "cumulativeStats.hitting.runs",
          "RBI": "cumulativeStats.hitting.runsBattedIn",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "HR": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.homeRuns'],
            playerFields: ['situationalStats.%situation%.pitching.homeRuns'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "HR": "cumulativeStats.defensive.homeRuns",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "HR": "cumulativeStats.pitching.homeRuns",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_SINGLES': {
        fields: ['hitting.singles'],
        defensiveTeamFields: ['averageStats.defensive.singles'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "1B": "situationalStats.%situation%.hitting.singles",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "1B": "cumulativeStats.hitting.singles",
          "H": "cumulativeStats.hitting.hits",
          "R": "cumulativeStats.hitting.runs",
          "AB": "cumulativeStats.hitting.atBats",
          "TB": "cumulativeStats.hitting.totalBases",
          "HR": "cumulativeStats.hitting.homeRuns",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "1B": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.singles'],
            playerFields: ['situationalStats.%situation%.pitching.singles'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "1B": "cumulativeStats.defensive.singles",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "1B": "cumulativeStats.pitching.singles",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_DOUBLES': {
        fields: ['hitting.doubles'],
        defensiveTeamFields: ['averageStats.defensive.doubles'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "2B": "situationalStats.%situation%.hitting.doubles",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "2B": "cumulativeStats.hitting.doubles",
          "H": "cumulativeStats.hitting.hits",
          "R": "cumulativeStats.hitting.runs",
          "AB": "cumulativeStats.hitting.atBats",
          "TB": "cumulativeStats.hitting.totalBases",
          "HR": "cumulativeStats.hitting.homeRuns",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "2B": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.doubles'],
            playerFields: ['situationalStats.%situation%.pitching.doubles'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "2B": "cumulativeStats.defensive.doubles",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "2B": "cumulativeStats.pitching.doubles",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_TRIPLES': {
        fields: ['hitting.triples'],
        defensiveTeamFields: ['averageStats.defensive.triples'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "3B": "situationalStats.%situation%.hitting.triples",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage",
          "SLG": "situationalStats.%situation%.hitting.sluggingPercentage"
        },
        gamelogStats: {
          "3B": "cumulativeStats.hitting.triples",
          "H": "cumulativeStats.hitting.hits",
          "R": "cumulativeStats.hitting.runs",
          "AB": "cumulativeStats.hitting.atBats",
          "TB": "cumulativeStats.hitting.totalBases",
          "HR": "cumulativeStats.hitting.homeRuns",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "3B": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.triples'],
            playerFields: ['situationalStats.%situation%.pitching.triples'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "3B": "cumulativeStats.defensive.triples",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "3B": "cumulativeStats.pitching.triples",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    'HITTER_STOLEN_BASES': {
        fields: ['hitting.stolenBases'],
        defensiveTeamFields: ['averageStats.defensive.stolenBases'],
        defensiveSortingOrder: 'asc',
        defaultLineValue: 0,
        supportingStats : {
          "AB": "situationalStats.%situation%.hitting.atBats",
          "SB": "situationalStats.%situation%.hitting.stolenBases",
          "CS": "situationalStats.%situation%.hitting.caughtStealing",
          "AVG": "situationalStats.%situation%.hitting.battingAverage",
          "OBP": "situationalStats.%situation%.hitting.onBasePercentage"
        },
        gamelogStats: {
          "SB": "cumulativeStats.hitting.stolenBases",
          "CS": "cumulativeStats.hitting.caughtStealing",
          "H": "cumulativeStats.hitting.hits",
          "AB": "cumulativeStats.hitting.atBats",
          "R": "cumulativeStats.hitting.runs",
          "TB": "cumulativeStats.hitting.totalBases",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        },
        opposingSupportingStats: {
          "BF": {
            ranked: false,
            teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
            playerFields: ['situationalStats.%situation%.pitching.battersFaced']
          },
          "SB": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.stolenBases'],
            playerFields: ['situationalStats.%situation%.pitching.stolenBases'],
            sortingOrder: 'asc'
          },
          "SO%": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.strikeoutPercentage'],
            playerFields: ['situationalStats.%situation%.pitching.strikeoutPercentage'],
            sortingOrder: 'asc'
          },
          "AVG": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
            playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
            sortingOrder: 'asc'
          },
          "OBP": {
            ranked: true,
            teamFields: ['situationalStats.%situation%.defensive.onBasePercentage'],
            playerFields: ['situationalStats.%situation%.pitching.onBasePercentage'],
            sortingOrder: 'asc'
          }
        },
        opposingTeamGamelogSupportingStats: {
          "BF": "cumulativeStats.defensive.battersFaced", // doesn't apply put we'll put something here
          "SB": "cumulativeStats.defensive.stolenBases",
          "SO": "cumulativeStats.defensive.strikeouts",
          "AVG": "cumulativeStats.defensive.battingAverage",
          "OBP": "cumulativeStats.defensive.onBasePercentage"
        },
        opposingPitcherGamelogSupportingStats: {
          "BF": "cumulativeStats.pitching.battersFaced", // doesn't apply put we'll put something here
          "SB": "cumulativeStats.pitching.stolenBases",
          "SO": "cumulativeStats.pitching.strikeouts",
          "AVG": "cumulativeStats.pitching.battingAverageAgainst",
          "IP": "cumulativeStats.pitching.inningsPitched"
        }
    },
    // 'HITTER_STRIKEOUTS': {
    //     fields: ['hitting.strikeouts'],
    //     defensiveTeamFields: ['averageStats.defensive.strikeouts'],
    //     defensiveSortingOrder: 'asc',
    //     defaultLineValue: 0
    // }
    'HITTER_HITS_PLUS_RUNS_PLUS_RUNS_BATTED_IN': {
      fields: ['hitting.hits', 'hitting.runs', 'hitting.runsBattedIn'],
      defensiveTeamFields: ['averageStats.defensive.hits', 'averageStats.defensive.runs', 'averageStats.defensive.runsBattedIn'],
      defensiveSortingOrder: 'asc',
      defaultLineValue: 0,
      supportingStats : {
        "AB": "situationalStats.%situation%.hitting.atBats",
        "R": "situationalStats.%situation%.hitting.runs",
        "H": "situationalStats.%situation%.hitting.hits",
        "RBI": "situationalStats.%situation%.hitting.runsBattedIn",
        "AVG": "situationalStats.%situation%.hitting.battingAverage"
      },
      gamelogStats: {
        "R": "cumulativeStats.hitting.runs",
        "H": "cumulativeStats.hitting.hits",
        "RBI": "cumulativeStats.hitting.runsBattedIn",
        "AB": "cumulativeStats.hitting.atBats",
        "TB": "cumulativeStats.hitting.totalBases",
        "AVG": "cumulativeStats.hitting.battingAverage",
        "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
      },
      opposingSupportingStats: {
        "BF": {
          ranked: false,
          teamFields: ['situationalStats.%situation%.defensive.battersFaced'],
          playerFields: ['situationalStats.%situation%.pitching.battersFaced']
        },
        "R": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.defensive.runs'],
          playerFields: ['situationalStats.%situation%.pitching.runs'],
          sortingOrder: 'asc'
        },
        "H": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.defensive.hits'],
          playerFields: ['situationalStats.%situation%.pitching.hits'],
          sortingOrder: 'asc'
        },
        "RBI": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.defensive.runsBattedIn'],
          playerFields: ['situationalStats.%situation%.pitching.runsBattedIn'],
          sortingOrder: 'asc'
        },
        "AVG": {
          ranked: true,
          teamFields: ['situationalStats.%situation%.defensive.battingAverage'],
          playerFields: ['situationalStats.%situation%.pitching.battingAverageAgainst'],
          sortingOrder: 'asc'
        }
      }
    }
  },
  bundesliga: SOCCER_PLAYER_PROPS,
  epl: SOCCER_PLAYER_PROPS,
  laliga: SOCCER_PLAYER_PROPS,
  ligue1: SOCCER_PLAYER_PROPS,
  seriea: SOCCER_PLAYER_PROPS,
  "brazil-serie-a": SOCCER_PLAYER_PROPS,
  mls: SOCCER_PLAYER_PROPS,
  euro: SOCCER_PLAYER_PROPS
}

// Used to know the list of stats by which we need to sort and how to sort them
export const getSituationalProps = {
  nfl: (situation) => {return PLAYER_PROPS_INFO.nfl},
  nhl: (situation) => {return PLAYER_PROPS_INFO.nhl},
  nba: (situation) => {return PLAYER_PROPS_INFO.nba},
  wnba: (situation) => {return PLAYER_PROPS_INFO.wnba},
  bundesliga: (situation) => {return PLAYER_PROPS_INFO.bundesliga},
  epl: (situation) => {return PLAYER_PROPS_INFO.epl},
  laliga: (situation) => {return PLAYER_PROPS_INFO.laliga},
  ligue1: (situation) => {return PLAYER_PROPS_INFO.ligue1},
  seriea: (situation) => {return PLAYER_PROPS_INFO.seriea},
  "brazil-serie-a": (situation) => {return PLAYER_PROPS_INFO['brazil-serie-a']},
  mls: (situation) => {return PLAYER_PROPS_INFO.mls},
  euro: (situation) => {return PLAYER_PROPS_INFO.euro},
  mlb: (situation) => {
    var copy = _.cloneDeep(PLAYER_PROPS_INFO.mlb);
    Object.keys(copy).forEach((prop) => {
      // Updating the situation in the supporting stats
      Object.keys(copy[prop].supportingStats).forEach((supportingStat) => {
        copy[prop].supportingStats[supportingStat] = copy[prop].supportingStats[supportingStat].replace('%situation%', situation);
      })

      // Updating the situation in all the fields of the opposing supporting stats
      Object.keys(copy[prop].opposingSupportingStats).forEach((opposingSupportingStat) => {
        // For team
        copy[prop].opposingSupportingStats[opposingSupportingStat].teamFields = copy[prop].opposingSupportingStats[opposingSupportingStat].teamFields.map(x => x.replace('%situation%', situation));
        // For player
        if (copy[prop].opposingSupportingStats[opposingSupportingStat].playerFields) {
          copy[prop].opposingSupportingStats[opposingSupportingStat].playerFields = copy[prop].opposingSupportingStats[opposingSupportingStat].playerFields.map(x => x.replace('%situation%', situation));
        }
      })
    })
    return copy;
  }
};

export const getPlayerPropInfo = {
  nfl: (propID, situation) => {return PLAYER_PROPS_INFO.nfl[propID]},
  nhl: (propID, situation) => {return PLAYER_PROPS_INFO.nhl[propID]},
  nba: (propID, situation) => {return PLAYER_PROPS_INFO.nba[propID]},
  wnba: (propID, situation) => {return PLAYER_PROPS_INFO.wnba[propID]},
  bundesliga: (propID, situation) => {return PLAYER_PROPS_INFO.bundesliga[propID]},
  epl: (propID, situation) => {return PLAYER_PROPS_INFO.epl[propID]},
  laliga: (propID, situation) => {return PLAYER_PROPS_INFO.laliga[propID]},
  ligue1: (propID, situation) => {return PLAYER_PROPS_INFO.ligue1[propID]},
  seriea: (propID, situation) => {return PLAYER_PROPS_INFO.seriea[propID]},
  "brazil-serie-a": (propID, situation) => {return PLAYER_PROPS_INFO['brazil-serie-a'][propID]},
  mls: (propID, situation) => {return PLAYER_PROPS_INFO.mls[propID]},
  euro: (propID, situation) => {return PLAYER_PROPS_INFO.euro[propID]},
  mlb: (propID, situation) => {
    if (!situation) {
      return PLAYER_PROPS_INFO.mlb[propID]
    }

    // Deep copy
    var propInfo = Object.assign({}, PLAYER_PROPS_INFO.mlb[propID]);
    // Deep copy
    propInfo.supportingStats = Object.assign({}, propInfo.supportingStats);
    Object.keys(propInfo.supportingStats).forEach((stat) => {
      propInfo.supportingStats[stat] = propInfo.supportingStats[stat].replace('%situation%', situation);
    })

    // Deep copy
    propInfo.opposingSupportingStats = Object.assign({}, propInfo.opposingSupportingStats);
    Object.keys(propInfo.opposingSupportingStats).forEach((stat) => {
      // Deep copy
      propInfo.opposingSupportingStats[stat] = Object.assign({}, propInfo.opposingSupportingStats[stat]);
      if (propInfo.opposingSupportingStats[stat].playerFields) {
        propInfo.opposingSupportingStats[stat].playerFields = [...propInfo.opposingSupportingStats[stat].playerFields];
        propInfo.opposingSupportingStats[stat].playerFields = propInfo.opposingSupportingStats[stat].playerFields.map(x => x.replace('%situation%', situation));
      }
    })

    return propInfo;
  }
}

export const getGamelogSupportingStats = {
  nfl: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  nhl: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  nba: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  wnba: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  mlb: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  bundesliga: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  epl: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  laliga: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  ligue1: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  seriea: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  "brazil-serie-a": (playerPropInfo) => {return playerPropInfo.gamelogStats},
  mls: (playerPropInfo) => {return playerPropInfo.gamelogStats},
  euro: (playerPropInfo) => {return playerPropInfo.gamelogStats}
}

export const getPlayerPropDefensiveTeamStatPath = {
  nfl: (propID, position) => {return PLAYER_PROPS_INFO.nfl[propID]},
  nhl: (propID, position) => {return PLAYER_PROPS_INFO.nhl[propID]},
  nba: (propID, position) => {return PLAYER_PROPS_INFO.nba[propID]},
  wnba: (propID, position) => {return PLAYER_PROPS_INFO.wnba[propID]},
  mlb: (propID, position) => {return PLAYER_PROPS_INFO.mlb[propID]},
  bundesliga: (propID, position) => {return PLAYER_PROPS_INFO.bundesliga[propID]},
  epl: (propID, position) => {return PLAYER_PROPS_INFO.epl[propID]},
  laliga: (propID, position) => {return PLAYER_PROPS_INFO.laliga[propID]},
  ligue1: (propID, position) => {return PLAYER_PROPS_INFO.ligue1[propID]},
  seriea: (propID, position) => {return PLAYER_PROPS_INFO.seriea[propID]},
  "brazil-serie-a": (propID, position) => {return PLAYER_PROPS_INFO['brazil-serie-a'][propID]},
  mls: (propID, position) => {return PLAYER_PROPS_INFO.mls[propID]},
  euro: (propID, position) => {return PLAYER_PROPS_INFO.euro[propID]}
}

export const getPlayerPropDefaultLineValue = {
  nfl: (propID, position) => {return PLAYER_PROPS_INFO.nfl[propID].defaultLineValue},
  nhl: (propID, position) => {return PLAYER_PROPS_INFO.nhl[propID].defaultLineValue},
  nba: (propID, position) => {return PLAYER_PROPS_INFO.nba[propID].defaultLineValue},
  wnba: (propID, position) => {return PLAYER_PROPS_INFO.wnba[propID].defaultLineValue},
  mlb: (propID, position) => {return PLAYER_PROPS_INFO.mlb[propID].defaultLineValue},
  bundesliga: (propID, position) => {return PLAYER_PROPS_INFO.bundesliga[propID].defaultLineValue},
  epl: (propID, position) => {return PLAYER_PROPS_INFO.epl[propID].defaultLineValue},
  laliga: (propID, position) => {return PLAYER_PROPS_INFO.laliga[propID].defaultLineValue},
  ligue1: (propID, position) => {return PLAYER_PROPS_INFO.ligue1[propID].defaultLineValue},
  seriea: (propID, position) => {return PLAYER_PROPS_INFO.seriea[propID].defaultLineValue},
  "brazil-serie-a": (propID, position) => {return PLAYER_PROPS_INFO['brazil-serie-a'][propID].defaultLineValue},
  mls: (propID, position) => {return PLAYER_PROPS_INFO.mls[propID].defaultLineValue},
  euro: (propID, position) => {return PLAYER_PROPS_INFO.euro[propID].defaultLineValue},
}

export const GAME_TOTAL_MARKET_ID = "GAME_TOTAL";
export const GAME_TOTAL_MARKET_NAME = "Game Total";
export const SPREAD_MARKET_ID = "SPREAD";
export const SPREAD_MARKET_NAME = "Spread";
export const ALL_BETS_MARKET_NAME = "All Bet Types";
export const ALL_GAMES_FILTER_OPTION = "All Games";

// OVER_UNDER will have over and under outcomes in workstation
export const OUTCOME_TYPE_OVER_UNDER = "OVER_UNDER";
// COVER will have a single outcome for cover
export const OUTCOME_TYPE_COVER = "COVER";
// This is still a cover but we need to force a 0.5 line on these
export const OUTCOME_TYPE_COVER_YES = "COVER_YES";

export const GAME_BET_TYPE = 'game';
export const TEAM_BET_TYPE = 'team';

const SOCCER_TEAM_BETS = {
  'GAME_TOTAL': {
    type: GAME_BET_TYPE,
    displayName: 'Game Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals') 
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.goals'))
  },
  'GAME_TOTAL_CARDS': {
    type: GAME_BET_TYPE,
    displayName: 'Game Total Cards',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.cardsGiven') 
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.cardsGiven'))
  },
  'BOTH_TEAMS_TO_SCORE': {
    type: GAME_BET_TYPE,
    displayName: 'Both Teams To Score',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.bothTeamToScore'))
  },
  'TEAM_TOTAL': {
    type: TEAM_BET_TYPE,
    displayName: 'Team Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals'))
  },
  'TEAM_TOTAL_CARDS': {
    type: TEAM_BET_TYPE,
    displayName: 'Team Total Cards',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.cardsGiven'))
  },
  'DOUBLE_CHANCE': {
    type: TEAM_BET_TYPE,
    displayName: 'Double Chance',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.doubleChance'))
  },
  'WIN_TO_NIL': {
    type: TEAM_BET_TYPE,
    displayName: 'Win To Nil',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.winWithoutConceding'))
  },
  'SPREAD': {
    type: TEAM_BET_TYPE,
    displayName: 'Spread',
    outcomeType: OUTCOME_TYPE_COVER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals') 
                                          - getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.goals')) * -1
  },
  'MONEY_LINE': {
    type: TEAM_BET_TYPE,
    displayName: 'Moneyline',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals') 
                                          > getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.goals')) | 0,
  }
}

// We are using bitwise OR (|) to convert the boolean result to a 1/0 value
const BASKETBALL_TEAM_BETS = {
  'GAME_TOTAL': {
    type: GAME_BET_TYPE,
    displayName: 'Game Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points') 
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points')),
    // hitFunction: (record, line) => ((getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') 
    //                                + getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) > line) | 0,
    // pushFunction: (record, line) => ((getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') 
    //                                 + getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) === line) | 0,
    // missFucntion: (record, line) => ((getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') 
    //                                 + getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) < line) | 0,
  },
  'TEAM_TOTAL': {
    type: TEAM_BET_TYPE,
    displayName: 'Team Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points')),
    // hitFunction: (record, line) => (getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') > line) | 0,
    // pushFunction: (record, line) => (getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') === line) | 0,
    // missFucntion: (record, line) => (getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') < line) | 0,
  },
  'SPREAD': {
    type: TEAM_BET_TYPE,
    displayName: 'Spread',
    outcomeType: OUTCOME_TYPE_COVER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points') 
                                          - getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points')) * -1,
    // hitFunction: (record, line) => ((getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') + line)
    //                                 > getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) | 0,
    // pushFunction: (record, line) => ((getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') + line)
    //                               === getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) | 0,
    // missFucntion: (record, line) => ((getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') + line)
    //                                 < getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) | 0,
  },
  'MONEY_LINE': {
    type: TEAM_BET_TYPE,
    displayName: 'Moneyline',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points') 
                                          > getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points')) | 0,
    // hitFunction: (record) => (getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') 
    //                          > getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) | 0,
    // pushFunction: (record) => (getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') 
    //                         === getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) | 0,
    // missFucntion: (record) => (getDictionaryValueRawResult(record, 'cumulativeStats.offensive.points') 
    //                           < getDictionaryValueRawResult(record, 'cumulativeStats.defensive.points')) | 0,
  },
  'GAME_TOTAL_1Q': {
    type: GAME_BET_TYPE,
    displayName: '1Q Game Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') 
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points1q')),
  },
  'GAME_TOTAL_1H': {
    type: GAME_BET_TYPE,
    displayName: '1H Game Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') 
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points2q')
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points1q')
                                          + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points2q')),
  },
  'TEAM_TOTAL_1Q': {
    type: TEAM_BET_TYPE,
    displayName: '1Q Team Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q')),
  },
  'TEAM_TOTAL_1H': {
    type: TEAM_BET_TYPE,
    displayName: '1H Team Total',
    outcomeType: OUTCOME_TYPE_OVER_UNDER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') + 
                                            getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points2q'))
  },
  'SPREAD_1Q': {
    type: TEAM_BET_TYPE,
    displayName: '1Q Spread',
    outcomeType: OUTCOME_TYPE_COVER,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') 
                                          - getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points1q')) * -1,
  },
  'SPREAD_1H': {
    type: TEAM_BET_TYPE,
    displayName: '1H Spread',
    outcomeType: OUTCOME_TYPE_COVER,
    gameValueFunction: (teamGameRecord) => ((getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points2q'))
                                          - (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points1q') + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points2q'))) * -1,
  },
  'MONEY_LINE_1Q': {
    type: TEAM_BET_TYPE,
    displayName: '1Q Moneyline',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') 
                                          > getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points1q')) | 0,
  },
  'MONEY_LINE_1H': {
    type: TEAM_BET_TYPE,
    displayName: '1H Moneyline',
    outcomeType: OUTCOME_TYPE_COVER_YES,
    gameValueFunction: (teamGameRecord) => ((getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points1q') + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points2q'))
                                          > (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points1q') + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points2q'))) | 0,
  }
}

export const TEAM_BETS = {
  nfl: {
    'GAME_TOTAL': {
      type: GAME_BET_TYPE,
      displayName: 'Game Total',
      outcomeType: OUTCOME_TYPE_OVER_UNDER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points') 
                                            + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points'))
    },
    'TEAM_TOTAL': {
      type: TEAM_BET_TYPE,
      displayName: 'Team Total',
      outcomeType: OUTCOME_TYPE_OVER_UNDER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points'))
    },
    'SPREAD': {
      type: TEAM_BET_TYPE,
      displayName: 'Spread',
      outcomeType: OUTCOME_TYPE_COVER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points') 
                                            - getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points')) * -1
    },
    'MONEY_LINE': {
      type: TEAM_BET_TYPE,
      displayName: 'Moneyline',
      outcomeType: OUTCOME_TYPE_COVER_YES,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.points') 
                                            > getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.points')) | 0,
    }
  },
  nhl: {
    'GAME_TOTAL': {
      type: GAME_BET_TYPE,
      displayName: 'Game Total',
      outcomeType: OUTCOME_TYPE_OVER_UNDER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals') 
                                            + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.goals'))
    },
    'TEAM_TOTAL': {
      type: TEAM_BET_TYPE,
      displayName: 'Team Total',
      outcomeType: OUTCOME_TYPE_OVER_UNDER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals'))
    },
    'SPREAD': {
      type: TEAM_BET_TYPE,
      displayName: 'Spread',
      outcomeType: OUTCOME_TYPE_COVER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals') 
                                            - getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.goals')) * -1
    },
    'MONEY_LINE': {
      type: TEAM_BET_TYPE,
      displayName: 'Moneyline',
      outcomeType: OUTCOME_TYPE_COVER_YES,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.goals') 
                                            > getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.goals')) | 0,
    }
  },
  nba: BASKETBALL_TEAM_BETS,
  wnba: BASKETBALL_TEAM_BETS,
  mlb: {
    'GAME_TOTAL': {
      type: GAME_BET_TYPE,
      displayName: 'Game Total',
      outcomeType: OUTCOME_TYPE_OVER_UNDER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.runs') 
                                            + getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.runs'))
    },
    'TEAM_TOTAL': {
      type: TEAM_BET_TYPE,
      displayName: 'Team Total',
      outcomeType: OUTCOME_TYPE_OVER_UNDER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.runs'))
    },
    'SPREAD': {
      type: TEAM_BET_TYPE,
      displayName: 'Spread',
      outcomeType: OUTCOME_TYPE_COVER,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.runs') 
                                            - getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.runs')) * -1
    },
    'MONEY_LINE': {
      type: TEAM_BET_TYPE,
      displayName: 'Moneyline',
      outcomeType: OUTCOME_TYPE_COVER_YES,
      gameValueFunction: (teamGameRecord) => (getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.offensive.runs') 
                                            > getDictionaryValueRawResult(teamGameRecord, 'cumulativeStats.defensive.runs')) | 0,
    }
  },
  bundesliga: SOCCER_TEAM_BETS,
  epl: SOCCER_TEAM_BETS,
  laliga: SOCCER_TEAM_BETS,
  ligue1: SOCCER_TEAM_BETS,
  seriea: SOCCER_TEAM_BETS,
  "brazil-serie-a": SOCCER_TEAM_BETS,
  mls: SOCCER_TEAM_BETS,
  euro: SOCCER_TEAM_BETS
}

export const SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING = swapDictionary(SOCCER_TEAM_BETS, 'displayName')

export const TEAM_BET_TYPE_REVERSE_MAPPING = {
  nfl: swapDictionary(TEAM_BETS.nfl, 'displayName'),
  nhl: swapDictionary(TEAM_BETS.nhl, 'displayName'),
  nba: swapDictionary(TEAM_BETS.nba, 'displayName'),
  wnba: swapDictionary(TEAM_BETS.wnba, 'displayName'),
  mlb: swapDictionary(TEAM_BETS.mlb, 'displayName'),
  bundesliga: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  epl: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  laliga: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  ligue1: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  seriea: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  "brazil-serie-a": SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  mls: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING,
  euro: SOCCER_TEAM_BET_TYPE_REVERSE_MAPPING
}

const SOCCER_BET_TYPE_ID_TO_NAME = {
  'ANYTIME_GOAL_SCORER': 'Goals',
  'ONE_OR_MORE_ASSISTS': '1+ AST',
  'TWO_OR_MORE_ASSISTS': '2+ AST',
  'ONE_OR_MORE_SHOTS_ON_TARGET': '1+ SOT',
  'TWO_OR_MORE_SHOTS_ON_TARGET': '2+ SOT',
  'THREE_OR_MORE_SHOTS_ON_TARGET': '3+ SOT',
  'FOUR_OR_MORE_SHOTS_ON_TARGET': '4+ SOT',
  'ONE_OR_MORE_SHOTS': '1+ SHO',
  'TWO_OR_MORE_SHOTS': '2+ SHO',
  'THREE_OR_MORE_SHOTS': '3+ SHO',
  'FOUR_OR_MORE_SHOTS': '4+ SHO',
  'FIVE_OR_MORE_SHOTS': '5+ SHO',
  'GOAL_OR_ASSIST': 'G or AST'
};

const BASKETBALL_BET_TYPE_ID_TO_NAME = {
  'POINTS': 'Points',
  'ASSISTS': 'Assists',
  'REBOUNDS': 'Rebounds',
  'THREE_POINTS_MADE': '3PTM',
  'STEALS': 'Steals',
  'TURNOVERS': 'Turnovers',
  'BLOCKS': 'Blocks',
  'ASSISTS_PLUS_REBOUNDS': 'REB+AST',
  'POINTS_PLUS_ASSISTS': 'PTS+AST',
  'POINTS_PLUS_REBOUNDS': 'PTS+REB',
  'POINTS_PLUS_ASSISTS_PLUS_REBOUNDS': 'P+A+R',
  'STEALS_PLUS_BLOCKS': 'STL+BLK',
  'DOUBLE_DOUBLE': 'Double Double',
  'TRIPLE_DOUBLE': 'Triple Double',
  'POINTS_1Q': '1Q Points',
  'ASSISTS_1Q': '1Q Assists',
  'REBOUNDS_1Q': '1Q Rebounds'
}

// Should probably combine with the PLAYER_PROPS_INFO above and then for reverse you would extract only the name to reverse it
export const PLAYER_BET_TYPE_ID_TO_NAME = {
  nfl: {
    'ANYTIME_TOUCHDOWN': 'Anytime TD',
    'PASSING_YARDS': 'Pass Yds',
    'PASSING_TOUCHDOWNS': 'Pass TD',
    'PASSING_COMPLETIONS': 'Pass Compl',
    'PASSING_ATTEMPTS': 'Pass Att',
    'INTERCEPTIONS': 'Int',
    'RECEIVING_YARDS': 'Rec Yds',
    'RECEPTIONS': 'Receptions',
    'RUSHING_YARDS': 'Rush Yds',
    'RUSHING_ATTEMPTS': 'Rush Att',
    'RUSHING_PLUS_RECEIVING_YARDS': 'Rush + Rec Yds',
    'PASSING_PLUS_RUSHING_YARDS': 'Pass + Rush Yds',
    'EXTRA_POINTS_MADE': 'XPM',
    'FIELD_GOALS_MADE': 'FGM'
  },
  nhl: {
    'POINTS': 'Points',
    'ANYTIME_GOAL_SCORER': 'Goals',
    'ASSISTS': 'Assists',
    'SHOTS_ON_GOAL': 'Shots on Goal',
    'POWERPLAY_POINTS': 'Powerplay Pts',
    'BLOCKED_SHOTS': 'Blocked Shots',
    'GOALS_ALLOWED': 'GA',
    'SAVES': 'Saves',
    'SHUTOUT': 'Shutout'
  },
  nba: BASKETBALL_BET_TYPE_ID_TO_NAME,
  wnba: BASKETBALL_BET_TYPE_ID_TO_NAME,
  mlb: {
    'HITTER_HITS': "Hits",
    'HITTER_RUNS': "Runs",
    'HITTER_RUNS_BATTED_IN': "RBI",
    'HITTER_HOME_RUNS': "HR",
    'HITTER_TOTAL_BASES': "Total Bases",
    'HITTER_SINGLES': "Singles",
    'HITTER_DOUBLES': "Doubles",
    'HITTER_TRIPLES': "Triples",
    'HITTER_STOLEN_BASES': "Stolen Bases",
    'HITTER_HITS_PLUS_RUNS_PLUS_RUNS_BATTED_IN': "H+R+RBI",
    'PITCHER_STRIKEOUTS': "Strikeouts",
    'PITCHER_OUTS': "Pitcher Outs",
    'PITCHER_HITS_ALLOWED': "Hits Allowed",
    'PITCHER_HOME_RUNS_ALLOWED': "HR Allowed",
    'PITCHER_EARNED_RUNS': "Earned Runs",
    // 'HITTER_STRIKEOUTS': "SO"
    'PITCHER_WALKS_ALLOWED': "Walks Allowed",
  },
  bundesliga: SOCCER_BET_TYPE_ID_TO_NAME,
  epl: SOCCER_BET_TYPE_ID_TO_NAME,
  laliga: SOCCER_BET_TYPE_ID_TO_NAME,
  ligue1: SOCCER_BET_TYPE_ID_TO_NAME,
  seriea: SOCCER_BET_TYPE_ID_TO_NAME,
  "brazil-serie-a": SOCCER_BET_TYPE_ID_TO_NAME,
  mls: SOCCER_BET_TYPE_ID_TO_NAME,
  euro: SOCCER_BET_TYPE_ID_TO_NAME
}

export const PLAYER_BET_TYPES_REVERSE_MAPPING = {
  nfl: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.nfl).map(a => a.reverse())),
  nhl: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.nhl).map(a => a.reverse())),
  nba: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.nba).map(a => a.reverse())),
  wnba: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.wnba).map(a => a.reverse())),
  mlb: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.mlb).map(a => a.reverse())),
  bundesliga: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.bundesliga).map(a => a.reverse())),
  epl: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.epl).map(a => a.reverse())),
  laliga: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.laliga).map(a => a.reverse())),
  ligue1: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.ligue1).map(a => a.reverse())),
  seriea: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.seriea).map(a => a.reverse())),
  "brazil-serie-a": Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME['brazil-serie-a']).map(a => a.reverse())),
  mls: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.mls).map(a => a.reverse())),
  euro: Object.fromEntries(Object.entries(PLAYER_BET_TYPE_ID_TO_NAME.euro).map(a => a.reverse()))
}

export const POSITIONAL_PLAYER_PROPS = {
  nfl: {
    // For QB 
    PASSING: Object.keys(Object.keys(PLAYER_BET_TYPE_ID_TO_NAME.nfl)
                              .filter(prop => 
                                // This also covers PASS + RUSH
                                prop.startsWith("PASSING") || 
                                prop === "INTERCEPTIONS" || 
                                prop === "ANYTIME_TOUCHDOWN" ||
                                // This is for rushing props minus RUSH + REC
                                (prop.startsWith("RUSHING") && prop != "RUSHING_PLUS_RECEIVING_YARDS")).reduce((obj, key) => {
      obj[PLAYER_BET_TYPE_ID_TO_NAME.nfl[key]] = key;
      return obj;
    }, {})),
    // For WR, RB
    RUSHING_AND_RECEIVING: Object.keys(Object.keys(PLAYER_BET_TYPE_ID_TO_NAME.nfl)
                                .filter(prop => 
                                  prop.startsWith("REC") ||
                                  // This also covers RUSH + REC prop
                                  prop.startsWith("RUSHING") || 
                                  prop === "ANYTIME_TOUCHDOWN").reduce((obj, key) => {
      obj[PLAYER_BET_TYPE_ID_TO_NAME.nfl[key]] = key;
      return obj;
    }, {})),
    // TE is receive only
    RECEIVING: Object.keys(Object.keys(PLAYER_BET_TYPE_ID_TO_NAME.nfl)
                              .filter(prop => 
                                prop.startsWith("REC") ||
                                prop === "ANYTIME_TOUCHDOWN").reduce((obj, key) => {
                          obj[PLAYER_BET_TYPE_ID_TO_NAME.nfl[key]] = key;
                          return obj;
                          }, {}))
  },
  nhl: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.nhl),
  nba: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.nba),
  wnba: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.wnba),
  mlb: {
    PITCHING: Object.keys(Object.keys(PLAYER_BET_TYPE_ID_TO_NAME.mlb).filter(prop => prop.startsWith("PITCHER")).reduce((obj, key) => {
      obj[PLAYER_BET_TYPE_ID_TO_NAME.mlb[key]] = key;
      return obj;
    }, {})),
    HITTING: Object.keys(Object.keys(PLAYER_BET_TYPE_ID_TO_NAME.mlb).filter(prop => prop.startsWith("HITTER")).reduce((obj, key) => {
      obj[PLAYER_BET_TYPE_ID_TO_NAME.mlb[key]] = key;
      return obj;
    }, {}))
  },
  bundesliga: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.bundesliga),
  epl: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.epl),
  laliga: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.laliga),
  ligue1: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.ligue1),
  seriea: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.seriea),
  "brazil-serie-a": Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING['brazil-serie-a']),
  mls: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.mls),
  euro: Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING.euro)
}

// There are cases where null is passed as position if there are no players available, null position should handle a default list of values
export const getPlayerPositionalBetTypes = {
  nfl: (position) => {
    var props = POSITIONAL_PLAYER_PROPS.nfl.PASSING.concat(POSITIONAL_PLAYER_PROPS.nfl.RUSHING_AND_RECEIVING).concat(POSITIONAL_PLAYER_PROPS.nfl.RECEIVING).filter((value, index) => POSITIONAL_PLAYER_PROPS.nfl.PASSING.indexOf(value) === index);
    if (position === "QB") {
      props = POSITIONAL_PLAYER_PROPS.nfl.PASSING;
    }
    if (position === "RB" || position === "WR") {
      props = POSITIONAL_PLAYER_PROPS.nfl.RUSHING_AND_RECEIVING;
    }
    if (position === "TE") {
      props = POSITIONAL_PLAYER_PROPS.nfl.RECEIVING;
    }
    return props;
  },
  nhl: (position) => {return POSITIONAL_PLAYER_PROPS.nhl},
  nba: (position) => {return POSITIONAL_PLAYER_PROPS.nba},
  wnba: (position) => {return POSITIONAL_PLAYER_PROPS.wnba},
  mlb: (position) => {
    if (position === "SP" || position === "RP" || position === "P") {
      return POSITIONAL_PLAYER_PROPS.mlb.PITCHING;
    }
    return POSITIONAL_PLAYER_PROPS.mlb.HITTING;
  },
  bundesliga: (position) => {return POSITIONAL_PLAYER_PROPS.bundesliga},
  epl: (position) => {return POSITIONAL_PLAYER_PROPS.epl},
  laliga: (position) => {return POSITIONAL_PLAYER_PROPS.laliga},
  ligue1: (position) => {return POSITIONAL_PLAYER_PROPS.ligue1},
  seriea: (position) => {return POSITIONAL_PLAYER_PROPS.seriea},
  "brazil-serie-a": (position) => {return POSITIONAL_PLAYER_PROPS['brazil-serie-a']},
  mls: (position) => {return POSITIONAL_PLAYER_PROPS.mls},
  euro: (position) => {return POSITIONAL_PLAYER_PROPS.euro},
}

export const SCREENER_ONLY_PROPS = {
  nfl: ['XPM', 'FGM'],
  nhl: [],
  nba: [],
  wnba: [],
  mlb: [],
  bundesliga: [],
  epl: [],
  laliga: [],
  ligue1: [],
  seriea: [],
  "brazil-serie-a": [],
  mls: [],
  euro: []
}

// TODO: add the new bet types that are screener-only to the positional bet types for the betting screen
// We will need to fill out PLAYER_BET_TYPES_REVERSE_MAPPING and getPlayerPropInfo, see getGameLineValue in betting-utils
// Need to make sure that whatever we add doesn't end up in the betting tool for now
export const getPlayerPositionalBetTypesForScreener = {
  nfl: (position) => {
    if (position === 'K') {
      return ['XPM', 'FGM']
    }
    return getPlayerPositionalBetTypes.nfl(position).filter(x => !['XPM', 'FGM'].includes(x));
  },
  nhl: (position) => {
    // if (position === 'G') {
    //   return ['GA', 'Saves']
    // }
    // return getPlayerPositionalBetTypes.nhl(position).filter(x => !['GA', 'Saves'].includes(x));
    return getPlayerPositionalBetTypes.nhl(position);
  },
  nba: (position) => {return getPlayerPositionalBetTypes.nba(position)},
  wnba: (position) => {return getPlayerPositionalBetTypes.wnba(position)},
  mlb: (position) => {return getPlayerPositionalBetTypes.mlb(position)},
  bundesliga: (position) => {return getPlayerPositionalBetTypes.bundesliga(position)},
  epl: (position) => {return getPlayerPositionalBetTypes.epl(position)},
  laliga: (position) => {return getPlayerPositionalBetTypes.laliga(position)},
  ligue1: (position) => {return getPlayerPositionalBetTypes.ligue1(position)},
  seriea: (position) => {return getPlayerPositionalBetTypes.seriea(position)},
  "brazil-serie-a": (position) => {return getPlayerPositionalBetTypes['brazil-serie-a'](position)},
  mls: (position) => {return getPlayerPositionalBetTypes.mls(position)},
  euro: (position) => {return getPlayerPositionalBetTypes.euro(position)}
}

// Not used as of new web version, deprecated
export const LEAGUE_CURRENT_SEASON = {
  nfl: 2023,
  nhl: 2023,
  nba: 2023,
  wnba: 2024,
  mlb: 2023,
  bundesliga: 2023,
  epl: 2023,
  laliga: 2023,
  ligue1: 2023,
  seriea: 2023,
  "brazil-serie-a": 2024,
  mls: 2024,
  euro: 2024
}

// Not used as of new web version, deprecated
export const LEAGUE_CURRENT_RECORD_TYPE = {
  nfl: "PLAYOFF",
  nhl: "REGULAR",
  nba: "REGULAR",
  wnba: "REGULAR",
  mlb: "PLAYOFF",
  bundesliga: "REGULAR",
  epl: "REGULAR",
  laliga: "REGULAR",
  ligue1: "REGULAR",
  seriea: "REGULAR",
  "brazil-serie-a": "REGULAR",
  mls: "REGULAR",
  euro: "REGULAR"
}

// TODO: replace all the usages of this by the configuration default league
export const DEFAULT_ACTIVE_LEAGUE = "nfl";
export const DEFAULT_ORIGIN_REDIRECT = `/${DEFAULT_ACTIVE_LEAGUE}`;

const SOCCER_LEGEND = {
  team: {
    "OFFENSIVE": "Stats For",
    "DEFENSIVE": "Stats Against",
    "GP": "Games Played",
    "G": "Goals",
    "POS": "Ball Possession",
    "SHO": "Total Shots",
    "SOT": "Shots on Target",
    "COR": "Corner Kicks",
    "OFF": "Offsides",
    "FLS": "Fouls",
    "2YELRED": "Double Yellow Red Card",
    "YEL": "Yellow Cards",
    "RED": "Red cards"
  },
  player: {
    "GP": "Games Played",
    "G": "Goals",
    "A": "Assists",
    "SOT": "Shots on Target",
    "SHO": "Total Shots",
    "MIN": "Minutes Played",
    "CC": "Chances Created",
    "PAS": "Total Passes",
    "DRI": "Dribbles Completed",
    "LOP": "Loss of Possession",
    "TCK": "Total Tackles",
    "FLS": "Fouls Committed",
    "INT": "Interceptions",
    "YEL": "Yellow Cards",
    "2YELRED": "Double Yellow Red Cards",
    "RED": "Red Cards",
    "GA": "Goals Conceded",
    "SAV": "Shots Faced Saved",
    "SA": "Shots Faced Total",
    "PS": "Penalties Saved",
    "DSAV": "Diving Saves",
    "CS": "Clean Sheets"
  }
}

const BASKETBALL_LEGEND = {
  team: {
    "OFFENSIVE": "Stats For",
    "DEFENSIVE": "Stats Against",
    "GP": "Games Played",
    "PTS": "Points",
    "FTM": "Free Throws Made",
    "FTA": "Free Throw Attempts",
    "FT%": "Free Throw Percentage",
    "FGM": "Field Goals Made",
    "FGA": "Field Goal Attempts",
    "FG%": "Field Goal Percentage",
    "3PTM": "3 Points Made",
    "3PTA": "3 Point Attempts",
    "3PT%": "3 Point Percentage",
    "TS%": "True Shooting Percentage",
    "EFG%": "Effective Field Goal Percentage",
    "TRB": "Rebounds",
    "AST": "Assists",
    "STL": "Steals",
    "TO": "Turnovers",
    "BLK": "Blocks"
  },
  player: {
    "GP": "Games Played",
    "PTS": "Points",
    "FTM": "Free Throws Made",
    "FTA": "Free Throw Attempts",
    "FT%": "Free Throw Percentage",
    "FGM": "Field Goals Made",
    "FGA": "Field Goal Attempts",
    "FG%": "Field Goal Percentage",
    "3PTM": "3 Points Made",
    "3PTA": "3 Point Attempts",
    "3PT%": "3 Point Percentage",
    "TS%": "True Shooting Percentage",
    "EFG%": "Effective Field Goal Percentage",
    "REB": "Rebounds",
    "AST": "Assists",
    "STL": "Steals",
    "TO": "Turnovers",
    "BLK": "Blocks",
    "MIN": "Minutes Played",
    "USAGE %": "Usage Percentage"
  }
}

export const LEGENDS = {
  nfl: {
    team: {
      "OFFENSIVE": "Stats For",
      "DEFENSIVE": "Stats Against",
      "GP": "Games Played",
      "PTS": "Points",
      "S%": "Score Percentage",
      "PLAYS": "Plays",
      "YDS": "Yards",
      "TD": "Touchdowns",
      "1D": "First Downs",
      "3D%": "Third Down Conversion Percentage",
      "4D%": "Fourth Down Conversion Percentage",
      "RZ%": "Redzone Scoring Percentage",
      "ATT": "Attempts",
      "COMP": "Completions",
      "INT": "Interceptions",
      "TO": "Turnovers",
      "FUML": "Fumbles Lost",
      "SK": "Sacks"
    },
    player: {
      "GP": "Games Played",
      "PASS RTG": "Passer Rating",
      "COMP": "Completions",
      "ATT": "Attempts",
      "YDS": "Yards",
      "TD": "Touchdowns",
      "INT": "Interceptions",
      "FUML": "Fumbles Lost",
      "YDS/ATT": "Yards Per Attempt",
      "300+ YDS": "Games with over 300 Yards",
      "RZ ATT": "Redzone Attempts",
      "RZ COMPL": "Redzone Completions",
      "RZ TD": "Redzone Touchdowns",
      "LONG": "Longest",
      "100+ YDS": "Games with over 100 Yards",
      "TGT": "Targets",
      "REC": "Receptions",
      "YDS/REC": "Yards Per Reception",
      "RZ TGT": "Redzone Targets",
      "RZ REC": "Redzone Receptions",
      "PTS": "Points",
      "FGA": "Field Goal Attempts",
      "FGM": "Field Goals Made",
      "FG%": "Field Goal Percentage",
      "XPA": "Extra Point Attempts",
      "XPM": "Extra Points Made",
      "XP%": "Extra Point Percentage",
      "FG (0-19)": "FGM from 0 to 19 yards",
      "FG (20-29)": "FGM from 20 to 29 yards",
      "FG (30-39)": "FGM from 30 to 39 yards",
      "FG (40-49)": "FGM from 40 to 49 yards",
      "FG (50+)": "FGM over 50 yards"
    }
  },
  nba: BASKETBALL_LEGEND,
  wnba: BASKETBALL_LEGEND,
  nhl: {
    team: {
      "OFFENSIVE": "Stats For",
      "DEFENSIVE": "Stats Against",
      "GP": "Games Played",
      "G": "Goals",
      "SOG": "Shots On Goal",
      "SAT": "Shot Attempts",
      "CF%": "Corsi For",
      "BLK": "Blocked Shots",
      "PIM": "Penalty Minutes",
      "PPG": "Powerplay Goals",
      "PP%": "Powerplay Percentage",
      "SHG": "Shorthanded goals",
      "SH%": "Shorthanded Percentage"
    },
    player: {
      "GP": "Games Played",
      "PTS": "Points",
      "G": "Goals",
      "A": "Assists",
      "SOG": "Shots On Goal",
      "SAT": "Shot Attempts",
      "TOI": "Time On Ice",
      "+/-": "Plus / Minus",
      "PPP": "Powerplay Points",
      "SHP": "Shorthanded Points",
      "PIM": "Penalty Minutes",
      "BLK": "Blocked Shots",
      "WINS": "Wins",
      "SA": "Shots Against",
      "SAVES": "Saves",
      "SV%": "Save percentage",
      "GA": "Goals Allowed",
      "SHUTOUTS": "Shutouts"
    }
  },
  mlb: {
    team: {
      "OFFENSIVE": "Stats For",
      "DEFENSIVE": "Stats Against",
      "W": "Wins",
      "L": "Losses",
      "PCT": "Win Percentage",
      "GP": "Games Played",
      "R": "Runs",
      "H": "Hits",
      "1B": "Singles",
      "2B": "Doubles",
      "3B": "Triples",
      "TB": "Total Bases",
      "ER": "Earned Runs",
      "HR": "Home Runs",
      "RBI": "Runs Batted In",
      "BB": "Walks",
      "SO": "Strikeouts",
      "E": "Errors",
      "AVG": "Batting Average",
      "OBP": "On-Base Percentage",
      "SLG": "Slugging Percentage",
      "OPS": "On-Base plus Slugging Percentage",
      "CS": "Caught Stealing",
      "SB": "Stolen Bases"
    },
    player: {
      "GP": "Games Played",
      "IP": "Innings Pitched",
      "W": "Wins",
      "L": "Losses",
      "ERA": "Earned Run Average",
      "SV": "Saves",
      "E": "Errors",
      "H": "Hits",
      "R": "Runs",
      "HR": "Home Runs",
      "1B": "Singles",
      "2B": "Doubles",
      "3B": "Triples",
      "TB": "Total Bases",
      "ER": "Earned Runs",
      "BB": "Walks",
      "SO": "Strikeouts",
      "WHIP": "Walks and Hits per Inning Pitched",
      "AVG": "Batting Average",
      "AB": "At Bats",
      "ISO": "Isolated Power",
      "RBI": "Runs Batted In",
      "OBP": "On-Base Percentage",
      "SLG": "Slugging Percentage",
      "OPS": "On-Base plus Slugging Percentage",
      "CS": "Caught Stealing",
      "SB": "Stolen Bases"
    }
  },
  bundesliga: SOCCER_LEGEND,
  epl: SOCCER_LEGEND,
  laliga: SOCCER_LEGEND,
  ligue1: SOCCER_LEGEND,
  seriea: SOCCER_LEGEND,
  "brazil-serie-a": SOCCER_LEGEND,
  mls: SOCCER_LEGEND,
  euro: SOCCER_LEGEND
}

const SOCCER_POSITION_LEGEND = {
  "F": "Forward",
  "D": "Defender",
  "M": "Midfielder",
  "G": "Goalkeeper"
}

const BASKETBALL_POSITION_LEGEND = {
  PG: "Point Guard",
  SG: "Shooting Guard",
  C: "Center",
  PF: "Power Forward",
  SF: "Small Forward"
}

export const POSITION_LEGEND = {
  nfl: {
    QB: "Quarterback",
    RB: "Running Back",
    WR: "Wide Receiver",
    TE: "Tight End",
    K: "Kicker"
  },
  nba: BASKETBALL_POSITION_LEGEND,
  wnba: BASKETBALL_POSITION_LEGEND,
  nhl: {
    C: "Center",
    RW: "Right Wing",
    LW: "Left Wing",
    D: "Defenseman",
    G: "Goalie"
  },
  mlb: {
    "P": "Pitcher",
    "C": "Catcher",
    "1B": "First Baseman",
    "2B": "Second Baseman",
    "3B": "Third Baseman",
    "SS": "Shortstop",
    "OF": "Outfielder",
    "DH": "Designated Hitter"
  },
  bundesliga: SOCCER_POSITION_LEGEND,
  epl: SOCCER_POSITION_LEGEND,
  laliga: SOCCER_POSITION_LEGEND,
  ligue1: SOCCER_POSITION_LEGEND,
  seriea: SOCCER_POSITION_LEGEND,
  "brazil-serie-a": SOCCER_POSITION_LEGEND,
  mls: SOCCER_POSITION_LEGEND,
  euro: SOCCER_POSITION_LEGEND
}

// Input should be a dict in the format of {win, loss} and any other key needed per sport (tie, overtime loss etc)
export const buildRecordStringFn = {
  nfl: (record) => {return `${record.win}-${record.loss}-${record.tie}`},
  nhl: (record) => {return `${record.win}-${record.loss}-${record.otl}`},
  nba: (record) => {return `${record.win}-${record.loss}`},
  wnba: (record) => {return `${record.win}-${record.loss}`},
  mlb: (record) => {return `${record.win}-${record.loss}`},
  bundesliga: (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  epl: (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  laliga: (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  ligue1: (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  seriea: (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  "brazil-serie-a": (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  mls: (record) => {return `${record.win}-${record.loss}-${record.draw}`},
  euro: (record) => {return `${record.win}-${record.loss}-${record.draw}`}
}

export const SPORTSBOOK_BONUSES = {
  "CA-AB": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-BC": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-MB": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-NB": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-NL": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-NS": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-NT": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-NU": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-ON": {
    "Proline+": {
      "image": "assets/sportsbooks/proline-plus.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Bring it. Now with dynamic competitive odds.",
          "details": "19+. Gambling can be addictive, please play responsibly. Proline+ operates pursuant to an agreement with iGaming Ontario. Eligible iGames conducted and managed by iGO are only available to those physically present in the Province of Ontario. 100% of profits go back to Ontario.",
          "link": "https://wlolg.adsrv.eacdn.com/C.ashx?btag=a_1221b_51c_&affid=115&siteid=1221&adid=51&c=",
          "actionText": "Sign up"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": false,
          "title": "Sports | Casino | Poker",
          "details": "19+. Gambling can be addictive, please play responsibly. Caesars operates pursuant to an agreement with iGaming Ontario. Eligible iGames conducted and managed by iGO are only available to those physically present in the Province of Ontario.",
          "link": "http://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_6654b_2549c_8203&affid=221&siteid=6654&adid=2549&c=8203",
          "actionText": "Sign up"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": false,
          "title": "Get the most out of your favourite sports with FanDuel sportsbook",
          "details": "19+. Gambling can be addictive, please play responsibly. FanDuel operates pursuant to an agreement with iGaming Ontario. Eligible iGames conducted and managed by iGO are only available to those physically present in the Province of Ontario.",
          "link": "https://wlfanduelcanada.adsrv.eacdn.com/C.ashx?btag=a_1281b_4c_&affid=40&siteid=1281&adid=4&c=",
          "actionText": "Sign up"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Canada's Betting and Gaming Home!",
          "details": "19+. Gambling can be addictive, please play responsibly. Bet99 operates pursuant to an agreement with iGaming Ontario. Eligible iGames conducted and managed by iGO are only available to those physically present in the Province of Ontario.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Sign up"
        }
      ]
    }
  },
  "CA-PE": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-QC": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-SK": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "CA-YT": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BET99": {
      "image": "assets/sportsbooks/bet99.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Deposit match of 100% up to $600!",
          "details": "19+. Gambling can be addictive, please play responsibly.",
          "link": "https://record.canadiangamingaffiliates.com/_j_0FsRiwq64DVkvPM80nyWNd7ZgqdRLk/1/",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-AK": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-AL": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-AR": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-AZ": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 21+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5, Get $150 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. Gambling problem? Call 1-800-NEXT-STEP",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_02927213",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in Arizona to wager. Not valid for any participant of the Arizona Department of Gaming Statewide Voluntary Self-Exclusion Program. RSI AZ, LLC is licensed in the State of Arizona by the Arizona Department of Gaming to provide sports wagering services. Help for problem gambling: call 1-800-NEXT-STEP or text “NEXT-STEP” to 53342.",
          "link": "https://media.az.betrivers.com/C.ashx?btag=a_15039b_3445c_AZ&affid=2318&siteid=15039&adid=3445&c=AZ",
          "actionText": "Bet now"
        }
      ]
    },
    "UnibetUSA": {
      "image": "assets/sportsbooks/unibet.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "$100 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ New Users in AZ. Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://www.csplen.com/C.ashx?btag=a_4462b_445c_&affid=336&siteid=4462&adid=445&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-CA": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-CO": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $1, Get $365 Bonus Bets Guaranteed",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Deposit required. Bonus Bets winnings are added to Bonus Bets balance. Bonus Bet wager excluded from returns. T&Cs, time limits and exclusions apply. CO, NJ, OH & VA only. Gambling Problem? Call 1-800-Gambler. 21+",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_01524818",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old, physically located in Colorado, and not in a restricted tribal area to wager. Gambling problem? Call 1-800-522-4700.",
          "link": "https://media.co.betrivers.com/C.ashx?btag=a_15039b_752c_CO&affid=2318&siteid=15039&adid=752&c=CO",
          "actionText": "Bet now"
        }
      ]
    },
    "SI Sportsbook": {
      "image": "assets/sportsbooks/sports-illustrated.svg",
      "imageFillColor": "#e73322",
      "offers": [
        {
          "expands": true,
          "title": "Bet $20, Win $200 in Free Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Promo Code: SI200. New users in CO. 21+.",
          "link": "https://ic.aff-handler.com/C/47991?sr=1838595",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-DC": {
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-DE": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-GA": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-IA": {
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $1, Get $365 Bonus Bets Guaranteed",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Deposit required. Bonus Bets winnings are added to Bonus Bets balance. Bonus Bet wager excluded from returns. T&Cs, time limits and exclusions apply. CO, NJ, OH & VA only. Gambling Problem? Call 1-800-Gambler. 21+",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_01524818",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in IA to wager. Gambling problem? Call 1-800-BETS-OFF.",
          "link": "https://media.ia.betrivers.com/C.ashx?btag=a_16606b_1303c_IA&affid=2318&siteid=16606&adid=1303&c=IA",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-IL": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Not valid for any participant of the Illinois Gaming Board statewide voluntary self-exclusion program • Must be 21 years of age or older • If you or someone yo know has a gambling problem, crisis counseling and referral services can be accessed by calling 1-800-GAMBLER (1-800-426-2537) • Playable only in IL",
          "link": "https://media.il.betrivers.com/C.ashx?btag=a_15039b_817c_IL&affid=2318&siteid=15039&adid=817&c=IL",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-IN": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Bet $100, Get $100 in Bonus Bets Every Day for 10 days!",
          "details": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://fanatics.onelink.me/5kut/rei616aq",
          "actionText": "Bet now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5, Get $150 Bonus Bets. 21+",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. Gambling problem? Call 1-800-9-WITH-IT",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_02905540",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in IN to wager. If you or someone you know has a gambling problem and wants help, Call 1-800-9-WITH-IT (1-800-994-8448).",
          "link": "https://media.in.betrivers.com/C.ashx?btag=a_16606b_598c_IN&affid=2318&siteid=16606&adid=598&c=IN",
          "actionText": "Bet now"
        }
      ]
    },
    "UnibetUSA": {
      "image": "assets/sportsbooks/unibet.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "$100 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ New Users in IN. Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://www.csplen.com/C.ashx?btag=a_4462b_187c_&affid=336&siteid=4462&adid=187&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-KS": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-KY": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Bet $50, Get $250 in Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_11537b_2640c_8603&affid=221&siteid=11537&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-LA": {
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $1, Get $365 Bonus Bets Guaranteed",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Deposit required. Bonus Bets winnings are added to Bonus Bets balance. Bonus Bet wager excluded from returns. T&Cs, time limits and exclusions apply. CO, NJ, OH & VA only. Gambling Problem? Call 1-800-Gambler. 21+",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_02754090",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in LA to wager. If you or someone you know has a gambling problem and wants help, Call 1-800 GAMBLER. 21+.",
          "link": "https://media.la.betrivers.com/C.ashx?btag=a_16606b_3687c_LA&affid=2318&siteid=16606&adid=3687&c=LA",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-MA": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Sign Up and Get $50 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-327-5050 or visit gamblinghelplinema.org.",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/3axaqvas",
          "actionText": "Bet now"
        }
      ]
    },
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 21+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-MD": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER or visit mdgamblinghelp.org",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in MD to wager. If you or someone you know has a gambling problem and wants help, Call 1-800 GAMBLER. 21+.",
          "link": "https://media.md.betrivers.com/C.ashx?btag=a_16606b_3833c_MD&affid=2318&siteid=16606&adid=3833&c=MD",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-ME": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-MI": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": false,
          "title": "Bet $100, Get $100 in Bonus Bets Every Day for 10 days!",
          "details": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://fanatics.onelink.me/5kut/rei616aq",
          "actionText": "Bet now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet or $250 Casino Bonus",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in MI to wager. If you or someone you know has a gambling problem and wants help, call the Michigan Department of Health and Human Services Gambling Disorder Help-line at: 800-270-7117.",
          "link": "https://media.mi.betrivers.com/C.ashx?btag=a_15039b_1076c_MI&affid=2318&siteid=15039&adid=1076&c=MI",
          "actionText": "Bet now"
        }
      ]
    },
    "SI Sportsbook": {
      "image": "assets/sportsbooks/sports-illustrated.svg",
      "imageFillColor": "#e73322",
      "offers": [
        {
          "expands": true,
          "title": "Bet $20, Win $200 in Free Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Promo Code: SI200. New users in MI. 21+.",
          "link": "https://ic.aff-handler.com/C/48127?sr=1838591",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-MN": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-MO": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-MS": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-MT": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-NC": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": false,
          "title": "Bet $5, Get $150 Bonus Bets Guaranteed",
          "details": "21+ and present in NC. Gambling problem? Call 877-718-5543 or visit morethanagame.nc.gov.",
          "link": "https://www.bet365.com/olp/open-account?affiliate=365_03064938",
          "actionText": "Bet now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": false,
          "title": "Bet $5, Get $150 Bonus Bets",
          "details": "21+ and present in NC. Gambling problem? Call 877-718-5543 or visit morethanagame.nc.gov.",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": false,
          "title": "Bet $10, Get $250 Bonus Bets",
          "details": "21+ and present in NC. Gambling problem? Call 877-718-5543 or visit morethanagame.nc.gov.",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_17021b_2670c_8603&affid=221&siteid=17021&adid=2670&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": false,
          "title": "Bet $5, Get $150 Bonus Bets Instantly",
          "details": "21 +. NC Only. Gambling Problem? Call 1-877-718-5543 or visit morethanagame.nc.gov",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": false,
          "title": "Bet $5, Get $250 Bonus Bets",
          "details": "21+ and present in NC. Gambling problem? Call 877-718-5543 or visit morethanagame.nc.gov.",
          "link": "https://wlfanduelus.adsrv.eacdn.com/C.ashx?btag=a_28863b_57c_&affid=15801&siteid=28863&adid=57&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-ND": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-NE": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-NH": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-NJ": {
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $1, Get $365 Bonus Bets Guaranteed",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Deposit required. Bonus Bets winnings are added to Bonus Bets balance. Bonus Bet wager excluded from returns. T&Cs, time limits and exclusions apply. CO, NJ, OH & VA only. Gambling Problem? Call 1-800-Gambler. 21+",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_02005706",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet or $250 Casino Bonus",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in NJ to wager. If you or someone you know has a gambling problem and wants help, Call 1-800 GAMBLER. 21+.",
          "link": "https://media.nj.betrivers.com/C.ashx?btag=a_15039b_3814c_NJ&affid=2318&siteid=15039&adid=3814&c=NJ",
          "actionText": "Bet now"
        }
      ]
    },
    "UnibetUSA": {
      "image": "assets/sportsbooks/unibet.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "$100 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ New Users in NJ. Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://www.csplen.com/C.ashx?btag=a_3977b_197c_&affid=336&siteid=3977&adid=197&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-NM": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-NY": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Sign Up and Get $50 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/3axaqvas",
          "actionText": "Bet Now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Must be 21+. Playable in NY. Gambling Problem? Scan here or call 1-877-8-HOPENY or text HOPENY (467369). Standard text rates may apply.",
          "link": "https://media.nj.betrivers.com/C.ashx?btag=a_15039b_3814c_NJ&affid=2318&siteid=15039&adid=3814&c=NJ",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-OH": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $1, Get $365 Bonus Bets Guaranteed",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Deposit required. Bonus Bets winnings are added to Bonus Bets balance. Bonus Bet wager excluded from returns. T&Cs, time limits and exclusions apply. CO, NJ, OH & VA only. Gambling Problem? Call 1-800-Gambler. 21+",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_01524830",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ and present in OH. Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://media.oh.betrivers.com/C.ashx?btag=a_16606b_3865c_OH&affid=2318&siteid=16606&adid=3865&c=OH",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-OK": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-OR": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-PA": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Sign Up and Get $50 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/3axaqvas",
          "actionText": "Bet now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet or $250 Casino Bonus",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in PA to wager. If you or someone you know has a gambling problem and wants help, Call 1-800 GAMBLER. 21+.",
          "link": "https://media.pa.betrivers.com/C.ashx?btag=a_16606b_919c_PA&affid=2318&siteid=16606&adid=919&c=PA",
          "actionText": "Bet now"
        }
      ]
    },
    "UnibetUSA": {
      "image": "assets/sportsbooks/unibet.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "$100 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ New Users in PA. Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://www.csplen.com/C.ashx?btag=a_4462b_198c_&affid=336&siteid=4462&adid=198&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-RI": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-SC": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-SD": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-TN": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-TX": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-UT": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-VA": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "PLAY NOW"
        }
      ]
    },
    "bet365": {
      "image": "assets/sportsbooks/bet365.svg",
      "imageFillColor": "#027b5b",
      "offers": [
        {
          "expands": true,
          "title": "Bet $1, Get $365 Bonus Bets Guaranteed",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Deposit required. Bonus Bets winnings are added to Bonus Bets balance. Bonus Bet wager excluded from returns. T&Cs, time limits and exclusions apply. CO, NJ, OH & VA only. Gambling Problem? Call 1-800-Gambler. 21+",
          "link": "https://www.bet365.com/olp/flashpicks?affiliate=365_01524834",
          "actionText": "Bet now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    },
    "BetRivers": {
      "image": "assets/sportsbooks/betrivers.svg",
      "imageFillColor": "#19345d",
      "offers": [
        {
          "expands": true,
          "title": "$500 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "You are welcome to register from any state in the U.S., but must be 21+ years old and physically located in VA to wager. If you or someone you know has a gambling problem and wants help, Call 1-800 GAMBLER. 21+.",
          "link": "https://media.va.betrivers.com/C.ashx?btag=a_16606b_1388c_VA&affid=2318&siteid=16606&adid=1388&c=VA",
          "actionText": "Bet now"
        }
      ]
    },
    "SI Sportsbook": {
      "image": "assets/sportsbooks/sports-illustrated.svg",
      "imageFillColor": "#e73322",
      "offers": [
        {
          "expands": true,
          "title": "Bet $20, Win $200 in Free Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Promo Code: SI200. New users in VA. 21+.",
          "link": "https://ic.aff-handler.com/C/47991?sr=1838579",
          "actionText": "Bet now"
        }
      ]
    },
    "UnibetUSA": {
      "image": "assets/sportsbooks/unibet.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "$100 Second Chance Bet",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ New Users in VA. Gambling Problem? Call 1-800-GAMBLER",
          "link": "https://www.csplen.com/C.ashx?btag=a_4462b_375c_&affid=336&siteid=4462&adid=375&c",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-VT": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Sign Up and Get $50 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/3axaqvas",
          "actionText": "Bet now"
        }
      ]
    },
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-WA": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-WI": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "Chalkboard": {
      "image": "assets/sportsbooks/chalkboard.svg",
      "imageFillColor": "#141414",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://streaks.chalkboard.io/cUPZ/mexuxcfp",
          "actionText": "Play now"
        }
      ]
    }
  },
  "US-WV": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Get Up to $1000 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/72yqf4sb",
          "actionText": "Bet now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "PointsBet": {
      "image": "assets/sportsbooks/pointsbet.svg",
      "imageFillColor": "#040404",
      "offers": [
        {
          "expands": false,
          "title": "Get 10x $100 Second Chance Bets",
          "details": "21+. Terms & conditions apply.",
          "link": "https://record.pointsbetpartners.com/_AuUR-K3jo5KYcq68j8A6gGNd7ZgqdRLk/1116/",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-WY": {
    "Sleeper": {
      "image": "assets/sportsbooks/sleeper.svg",
      "imageFillColor": "#161e2c",
      "offers": [
        {
          "expands": false,
          "title": "Get up to $100 deposit bonus",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://sleeper.com/promo/LINEMATE",
          "actionText": "Play now"
        }
      ]
    },
    "Underdog": {
      "image": "assets/sportsbooks/underdog.svg",
      "imageFillColor": "#e0b404",
      "offers": [
        {
          "expands": false,
          "title": "100% deposit match up to $100",
          "details": "Must be 21+ to play. Terms & conditions apply. ",
          "link": "https://play.underdogfantasy.com/p-linemate",
          "actionText": "Play now"
        }
      ]
    },
    "PrizePicks": {
      "image": "assets/sportsbooks/prizepicks.svg",
      "imageFillColor": "#090a14",
      "offers": [
        {
          "expands": true,
          "title": "100% deposit match up to $100",
          "shortDetails": "Must be 19+ to play. Terms and conditions apply.",
          "details": "If you or someone you know has a gaming problem, get help. Crisis services and responsible gaming counseling can be accessed by calling 1-800-426-2537, 1-800-522-4700 or 1-800-NEXTSTEP for Arizona residents, or online at www.800gambler.org or www.ncpgambling.org. 19+ to play. 21+ in AZ, MA. First deposit bonus requires 1x play-through before withdrawal. Eligibility restrictions apply. See prizepicks.com/terms for full PrizePicks Terms of Service.",
          "link": "https://bit.ly/LinematePrizePicks",
          "actionText": "Play now"
        }
      ]
    },
    "DraftKings": {
      "image": "assets/sportsbooks/draftkings.svg",
      "imageFillColor": "#61b510",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 & get $200 Bonus Bets Instantly + $150 No Sweat Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://dksb.sng.link/As9kz/tc06?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&pcid=280324&psn=2826&pcn=H1&pscn=Bet5Win150&pcrn=FP6&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d296985643%26wpcid%3d280324%26wpsrc%3d2826%26wpcn%3dH1%26wpscn%3dBet5Win150%26wpcrn%3dFP6%26wpscid%3dxx%26wpcrid%3dxx",
          "actionText": "Bet now"
        }
      ]
    },
    "Caesars": {
      "image": "assets/sportsbooks/caesars.svg",
      "imageFillColor": "#0a3231",
      "offers": [
        {
          "expands": true,
          "title": "Get up to $1000 bonus bets ",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+. New users and first wager only. Must register with eligible promo code. Bet amount of qualifying wager returned only if wager is settled as a loss. Maximum bet credit $1,250; must be used within 14 days of receipt. See Caesars.com/promos for full terms. Void where prohibited. Know When To Stop Before You Start.® Gambling problem? Call 1-800-GAMBLER",
          "link": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_12749b_2640c_8603&affid=221&siteid=12749&adid=2640&c=8603",
          "actionText": "Bet now"
        }
      ]
    },
    "BetMGM": {
      "image": "assets/sportsbooks/betmgm.svg",
      "imageFillColor": "#c0a971",
      "offers": [
        {
          "expands": true,
          "title": "Get Up To $1,500 Bonus Bets",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ to wager. Please Gamble Responsibly. Gambling problem? Call 1-800-GAMBLER, Call 877-8-HOPENY or text HOPENY (467369) (NY), Call 1-800-327-5050 (MA), Call 1-800-NEXT-STEP (AZ), 1-800-522-4700 (KS, NV), 1-800-BETS-OFF (IA), 1-800-270-7117(MI).",
          "link": "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1695394",
          "actionText": "Bet now"
        }
      ]
    },
    "FanDuel": {
      "image": "assets/sportsbooks/fanduel.svg",
      "imageFillColor": "#0078ff",
      "offers": [
        {
          "expands": true,
          "title": "Bet $5 Get $200 in Bonus Bets + $100 off NFL Sunday Ticket",
          "shortDetails": "21+. Terms & conditions apply.",
          "details": "21+ in select states. First online real money wager only. $10 first deposit required. Bonus issued as non-withdrawable bonus bets that expire 14 days after receipt. Restrictions apply. See terms at sportsbook.fanduel.com. Gambling Problem? Call 1-800-GAMBLER or visit FanDuel.com/RG (CO, IA, MI, NJ, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT), 1-800-9-WITH-IT (IN), 1-800-522-4700 or visit ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit www.1800gambler.net (WV).",
          "link": "http://wlfanduel.adsrv.eacdn.com/C.ashx?btag=a_28863b_2436c_&affid=15801&siteid=28863&adid=2436&c=",
          "actionText": "Bet now"
        }
      ]
    }
  },
  "US-CT": {
    "Fanatics": {
      "image": "assets/sportsbooks/fanatics.svg",
      "imageFillColor": "#000000",
      "offers": [
        {
          "expands": true,
          "title": "Sign Up and Get $50 Bonus Bets",
          "shortDetails": "Must be 21+ Gambling Problem? Call 1-800-GAMBLER",
          "details": "Must be 21+. GAMBLING PROBLEM? Call 1-800-GAMBLER (CO/IL/KS/KY/MD/OH/MI/NC/PA/TN/VA/VT/WV), (800)-327-5050 or http://gamblinghelplinema.org (MA), Call (877-8-HOPENY) or text HOPENY (467369) (NY), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), (888) 789-7777 or http://ccpg.org (CT), or 1-800-BETS-OFF (IA), or 1-800-9-WITH-IT (IN), or www.mdgamblinghelp.org (MD), or morethanagame.nc.gov (NC), or 1800gambler.net (WV)",
          "link": "https://fanatics.onelink.me/5kut/3axaqvas",
          "actionText": "Bet now"
        }
      ]
    }
  }
}

export const LOCAL_STORAGE_LOCATION_KEY = "location";
export const LOCALIZATION_API = "localization";

export const EVICTION_SAFE_KEYS = new Set([LOCAL_STORAGE_LOCATION_KEY]);

const API_VERSIONS_TEMP = {};
API_VERSIONS_TEMP[LOCALIZATION_API] = "v1";
export const API_VERSIONS = API_VERSIONS_TEMP;

export const DEFAULT_ODDS = {
  spread: 0,
  total: 0,
  // New line style
  lines: {
    american: {
      moneyLine: 0,
      spread: 0,
      totalOver: 0,
      totalUnder: 0
    },
    decimal: {
      moneyLine: 0.0,
      spread: 0.0,
      totalOver: 0.0,
      totalUnder: 0.0
    },
    fractional: {
      moneyLine: "0",
      spread: "0",
      totalOver: "0",
      totalUnder: "0"
    }
  },
  // Deprecated old style
  moneyLineDecimal: 0,
  spreadDecimal: 0,
  totalOverDecimal: 0,
  totalUnderDecimal: 0
}

export const AVAILABLE_LINE_FORMATS = ["american", "decimal", "fractional"];
export const DEFAULT_LINE_FORMAT = "american";

// TODO: eventually have some league specific tag definitions for MLB/NFL when those launch
// Legend setion types
//  prefix => the items under that section are displayed in the format of <key>: <value>
//  heading => the items under that section are displayed in the format of <key>\n <value>
export const BETTING_TOOL_LEGEND = [
  {
    type: "heading",
    title: "tags",
    entries: [
      {key: 'Back To Back', value: 'Playing 2nd game in 2 days.'},
      {key: 'Road Trip', value: 'Playing 3rd+ consecutive away game.'},
      {key: 'Bounce Back', value: '2+ game losing streak despite above average recent play.'},
      {key: 'Regression', value: '2+ game winning streak despite below average recent play.'},
      {key: 'Back Home', value: 'Back home after 3+ away games.'},
      {key: 'Matchup Owner', value: 'Won over 75% of games against opponent (within last 2 seasons).'},
      {key: 'Good Form', value: 'Won 4 out of their last 5 games'},
      {key: 'Bad Form', value: 'Lost 4 out of their last 5 games'}
    ]
  },
  {
    type: "prefix",
    title: "grades",
    entries: [
      {key: 'A', value: 'Highly Favorable'},
      {key: 'B', value: 'Favorable'},
      {key: 'C', value: 'Unfavourable'},
      {key: 'D', value: 'Highly Unfavorable'}
    ]
  },
  {
    type: "heading",
    title: "terms",
    entries: [
      {key: 'Hit Rate', value: 'The % of times the team/player covers the line.'},
      {key: 'ATS Record', value: 'Team record in covering their pre-game spread.'},
      {key: 'O/U Record', value: 'Team record in covering the pre-game game total.'},
      {key: 'Spread', value: 'Predicted win/loss margin determined by bookmarkers.'},
      {key: 'Game Total', value: 'Predicted overall point total determined by bookmakers.'},
      {key: 'Favorite', value: 'Expected to win the game based on their odds.'},
      {key: 'Underdog', value: 'Expected to lose the game based on their odds.'},
      {key: 'Line', value: 'Value determined by bookmakers as outcome for bet type.'}
    ]
  }
];

export const ACCESS_LEVEL_SUPERUSER = 0;
export const ACCESS_LEVEL_FREE = 1;
export const ACCESS_LEVEL_TRIAL = 2;
export const ACCESS_LEVEL_PAID = 3;


const SOCCER_TEAM_MODAL_STATS = {
  "PTS": "standings.points",
  "SHO": "averageStats.offensive.shotsTotal",
  "SOT": "averageStats.offensive.shotsOnTarget",
  "POS": "averageStats.offensive.ballPossession",
  "FLS": "averageStats.offensive.fouls",
  "COR": "averageStats.offensive.cornerKicks",
}

const BASKETBALL_TEAM_MODAL_STATS = {
  "PTS": "averageStats.offensive.points",
  "FTM": "averageStats.offensive.freeThrowsMade",
  "FGM": "averageStats.offensive.fieldGoalsMade",
  "3PTM": "averageStats.offensive.threePointsMade",
  "TRB": "averageStats.defensive.rebounds",
  "AST": "averageStats.offensive.assists"
}

export const TEAM_MODAL_STATS = {
  nfl: {
    "PTS": "averageStats.offensive.points",
    "PASS YDS": "averageStats.offensive.passingYards",
    "PASS TDS": "averageStats.offensive.passingTouchdowns",
    "RUSH YDS": "averageStats.offensive.rushingYards",
    "NET YDS": "averageStats.offensive.totalYards",
    "NET TDS": "averageStats.offensive.totalTouchdowns",
    "RZ%": "averageStats.offensive.redzoneSuccessRate"
  },
  nhl: {
    "G": "averageStats.offensive.goals",
    "SOG": "averageStats.offensive.shotsOnGoal",
    "SAT": "averageStats.offensive.shotAttempts",
    "CF%": "averageStats.offensive.corsiPercentage",
    "PIM": "averageStats.offensive.penaltyMinutes",
    "PP%": "averageStats.offensive.powerplayPercentage",
    "SH%": "averageStats.offensive.penaltyKillPercentage"
  },
  nba: BASKETBALL_TEAM_MODAL_STATS,
  wnba: BASKETBALL_TEAM_MODAL_STATS,
  mlb: {
    "R": "averageStats.offensive.runs",
    "H": "averageStats.offensive.hits",
    "HR": "averageStats.offensive.homeRuns",
    "RBI": "averageStats.offensive.runsBattedIn",
    "AVG": "averageStats.offensive.battingAverage",
    "OPS": "averageStats.offensive.onBasePlusSluggingPercentage"
  },
  bundesliga: SOCCER_TEAM_MODAL_STATS,
  epl: SOCCER_TEAM_MODAL_STATS,
  laliga: SOCCER_TEAM_MODAL_STATS,
  ligue1: SOCCER_TEAM_MODAL_STATS,
  seriea: SOCCER_TEAM_MODAL_STATS,
  "brazil-serie-a": SOCCER_TEAM_MODAL_STATS,
  mls: SOCCER_TEAM_MODAL_STATS,
  euro: SOCCER_TEAM_MODAL_STATS
}

const soccerTeamLeaders = {
  offense: {
    "G": {
      field: "averageStats.offensive.goals",
      order: "DESC"
    },
    "SOT": {
      field: "averageStats.offensive.shotsOnTarget",
      order: "DESC"
    },
    // "shots off target": {
    //   field: "averageStats.offensive.shotsOffTarget",
    //   order: "DESC"
    // },
    "COR": {
      field: "averageStats.offensive.cornerKicks",
      order: "DESC"
    },
    "POS": {
      field: "averageStats.offensive.ballPossession",
      order: "DESC"
    }
  },
  defense: {
    "G": {
      field: "averageStats.defensive.goals",
      order: "ASC"
    },
    "SOT": {
      field: "averageStats.defensive.shotsOnTarget",
      order: "ASC"
    },
    // "shots off target": {
    //   field: "averageStats.defensive.shotsOffTarget",
    //   order: "ASC"
    // },
    "COR": {
      field: "averageStats.defensive.cornerKicks",
      order: "ASC"
    },
    "POS": {
      field: "averageStats.defensive.ballPossession",
      order: "ASC"
    }
  }
}

const BASKETBALL_TEAM_LEADERS = {
  offense: {
    "PTS": {
      field: "averageStats.offensive.points",
      order: "DESC"
    },
    "AST": {
      field: "averageStats.offensive.assists",
      order: "DESC"
    },
    "TRB": {
      field: "averageStats.offensive.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "averageStats.offensive.steals",
      order: "DESC"
    },
    "TO": {
      field: "averageStats.offensive.turnovers",
      order: "DESC"
    }
  },
  defense: {
    "PTS": {
      field: "averageStats.defensive.points",
      order: "ASC"
    },
    "AST": {
      field: "averageStats.defensive.assists",
      order: "ASC"
    },
    "TRB": {
      field: "averageStats.defensive.rebounds",
      order: "ASC"
    },
    "STL": {
      field: "averageStats.defensive.steals",
      order: "ASC"
    },
    "TO": {
      field: "averageStats.defensive.turnovers",
      order: "ASC"
    }
  }
}

// No longer used in new web version, deprecated
export const TEAM_LEADER_STATS = {
  nfl: {
    offense: {
      "PTS": {
        field: "averageStats.offensive.points",
        order: "DESC"
      },
      "PLAYS": {
        field: "averageStats.offensive.plays",
        order: "DESC"
      },
      "YDS": {
        field: "averageStats.offensive.totalYards",
        order: "DESC"
      },
      "TD": {
        field: "averageStats.offensive.totalTouchdowns",
        order: "DESC"
      },
      "RZ%": {
        field: "averageStats.offensive.redzoneSuccessRate",
        order: "DESC"
      }
    },
    defense: {
      "PTS": {
        field: "averageStats.defensive.points",
        order: "ASC"
      },
      "PLAYS": {
        field: "averageStats.defensive.plays",
        order: "ASC"
      },
      "YDS": {
        field: "averageStats.defensive.totalYards",
        order: "ASC"
      },
      "TD": {
        field: "averageStats.defensive.totalTouchdowns",
        order: "ASC"
      },
      "RZ%": {
        field: "averageStats.defensive.redzoneSuccessRate",
        order: "ASC"
      }
    }
  },
  nhl: {
    offense: {
      "GOALS": {
        field: "averageStats.offensive.goals",
        order: "DESC"
      },
      "SOG": {
        field: "averageStats.offensive.shotsOnGoal",
        order: "DESC"
      },
      "SAT": {
        field: "averageStats.offensive.shotAttempts",
        order: "DESC"
      },
      "CF%": {
        field: "averageStats.offensive.corsiPercentage",
        order: "DESC"
      }
    },
    defense: {
      "GOALS": {
        field: "averageStats.defensive.goals",
        order: "ASC"
      },
      "SOG": {
        field: "averageStats.defensive.shotsOnGoal",
        order: "ASC"
      },
      "SAT": {
        field: "averageStats.defensive.shotAttempts",
        order: "ASC"
      },
      "CF%": {
        field: "averageStats.defensive.corsiPercentage",
        order: "ASC"
      }
    }
  },
  nba: BASKETBALL_TEAM_LEADERS,
  wnba: BASKETBALL_TEAM_LEADERS,
  mlb: {
    offense: {
      "R": {
        field: "averageStats.offensive.runs",
        order: "DESC"
      },
      "H": {
        field: "averageStats.offensive.hits",
        order: "DESC"
      },
      "HR": {
        field: "averageStats.offensive.homeRuns",
        order: "DESC"
      },
      "AVG": {
        field: "averageStats.offensive.battingAverage",
        order: "DESC"
      },
      "OPS": {
        field: "averageStats.offensive.onBasePlusSluggingPercentage",
        order: "DESC"
      }
    },
    defense: {
      "R": {
        field: "averageStats.defensive.runs",
        order: "ASC"
      },
      "H": {
        field: "averageStats.defensive.hits",
        order: "ASC"
      },
      "HR": {
        field: "averageStats.defensive.homeRuns",
        order: "ASC"
      },
      "AVG": {
        field: "averageStats.defensive.battingAverage",
        order: "ASC"
      },
      "OPS": {
        field: "averageStats.defensive.onBasePlusSluggingPercentage",
        order: "ASC"
      }
    }
  },
  bundesliga: soccerTeamLeaders,
  epl: soccerTeamLeaders,
  laliga: soccerTeamLeaders,
  ligue1: soccerTeamLeaders,
  seriea: soccerTeamLeaders,
  "brazil-serie-a": soccerTeamLeaders,
  mls: soccerTeamLeaders,
  euro: soccerTeamLeaders
}

const soccerPlayerLeaders = {
  forwards: {
    "G": {
      field: "cumulativeStats.goals",
      order: "DESC"
    },
    "A": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "SOT": {
      field: "cumulativeStats.shotsOnTarget",
      order: "DESC"
    },
    "SHO": {
      field: "cumulativeStats.totalShots",
      order: "DESC"
    },
    // "CC": {
    //   field: "cumulativeStats.chancesCreated",
    //   order: "DESC"
    // }
  },
  midfielders: {
    "G": {
      field: "cumulativeStats.goals",
      order: "DESC"
    },
    "A": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "SOT": {
      field: "cumulativeStats.shotsOnTarget",
      order: "DESC"
    },
    "SHO": {
      field: "cumulativeStats.totalShots",
      order: "DESC"
    },
    // "CC": {
    //   field: "cumulativeStats.chancesCreated",
    //   order: "DESC"
    // }
  },
  defenders: {
    "G": {
      field: "cumulativeStats.goals",
      order: "DESC"
    },
    "A": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "SHO": {
      field: "cumulativeStats.totalShots",
      order: "DESC"
    },
    "TCK": {
      field: "cumulativeStats.totalTackles",
      order: "DESC"
    },
    "INT": {
      field: "cumulativeStats.interceptions",
      order: "DESC"
    }
  },
  goalies: {
    "GA": {
      field: "cumulativeStats.goalsConceded",
      order: "ASC"
    },
    "SAV": {
      field: "cumulativeStats.shotsFacedSaved",
      order: "DESC"
    },
    "SA": {
      field: "cumulativeStats.shotsFacedTotal",
      order: "DESC"
    },
    "PS": {
      field: "cumulativeStats.penaltiesSaved",
      order: "DESC"
    },
    "CS": {
      field: "cumulativeStats.cleanSheet",
      order: "DESC"
    }
  }
};

export const BASKETBALL_PLAYER_LEADERS = {
  players: {
    "PTS": {
      field: "cumulativeStats.points",
      order: "DESC"
    },
    "AST": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "REB": {
      field: "cumulativeStats.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "cumulativeStats.steals",
      order: "DESC"
    },
    "TO": {
      field: "cumulativeStats.turnovers",
      order: "DESC"
    }
  },
  "Point Guards": {
    "PTS": {
      field: "cumulativeStats.points",
      order: "DESC"
    },
    "AST": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "REB": {
      field: "cumulativeStats.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "cumulativeStats.steals",
      order: "DESC"
    },
    "TO": {
      field: "cumulativeStats.turnovers",
      order: "DESC"
    }
  },
  "Shooting Guards": {
    "PTS": {
      field: "cumulativeStats.points",
      order: "DESC"
    },
    "AST": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "REB": {
      field: "cumulativeStats.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "cumulativeStats.steals",
      order: "DESC"
    },
    "TO": {
      field: "cumulativeStats.turnovers",
      order: "DESC"
    }
  },
  "Small Forwards": {
    "PTS": {
      field: "cumulativeStats.points",
      order: "DESC"
    },
    "AST": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "REB": {
      field: "cumulativeStats.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "cumulativeStats.steals",
      order: "DESC"
    },
    "TO": {
      field: "cumulativeStats.turnovers",
      order: "DESC"
    }
  },
  "Power Forwards": {
    "PTS": {
      field: "cumulativeStats.points",
      order: "DESC"
    },
    "AST": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "REB": {
      field: "cumulativeStats.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "cumulativeStats.steals",
      order: "DESC"
    },
    "TO": {
      field: "cumulativeStats.turnovers",
      order: "DESC"
    }
  },
  "Centers": {
    "PTS": {
      field: "cumulativeStats.points",
      order: "DESC"
    },
    "AST": {
      field: "cumulativeStats.assists",
      order: "DESC"
    },
    "REB": {
      field: "cumulativeStats.rebounds",
      order: "DESC"
    },
    "STL": {
      field: "cumulativeStats.steals",
      order: "DESC"
    },
    "TO": {
      field: "cumulativeStats.turnovers",
      order: "DESC"
    }
  }
}

// Deprecated as of new web version
export const PLAYER_LEADER_STATS = {
  nfl: {
    passers: {
      "ATT": {
        field: "cumulativeStats.passingAttempts",
        order: "DESC"
      },
      "COMP": {
        field: "cumulativeStats.passingCompletions",
        order: "DESC"
      },
      "YDS": {
        field: "cumulativeStats.passingYards",
        order: "DESC"
      },
      "TD": {
        field: "cumulativeStats.passingTouchdowns",
        order: "DESC"
      },
      "INT": {
        field: "cumulativeStats.interceptions",
        order: "DESC"
      }
    },
    rushers: {
      "ATT": {
        field: "cumulativeStats.rushingAttempts",
        order: "DESC"
      },
      "YDS": {
        field: "cumulativeStats.rushingYards",
        order: "DESC"
      },
      "YDS/ATT": {
        field: "cumulativeStats.rushingYardsPerAttempt",
        order: "DESC"
      },
      "TD": {
        field: "cumulativeStats.rushingTouchdowns",
        order: "DESC"
      },
      "FUML": {
        field: "cumulativeStats.fumblesLost",
        order: "DESC"
      }
    },
    catchers: {
      "TGT": {
        field: "cumulativeStats.targets",
        order: "DESC"
      },
      "REC": {
        field: "cumulativeStats.receptions",
        order: "DESC"
      },
      "YDS": {
        field: "cumulativeStats.receivingYards",
        order: "DESC"
      },
      "TD": {
        field: "cumulativeStats.receivingTouchdowns",
        order: "DESC"
      },
      "FUML": {
        field: "cumulativeStats.fumblesLost",
        order: "DESC"
      }
    },
    kickers: {
      "PTS": {
        field: "cumulativeStats.pointsKicked",
        order: "DESC"
      },
      "FGA": {
        field: "cumulativeStats.fieldGoalAttempts",
        order: "DESC"
      },
      "FGM": {
        field: "cumulativeStats.fieldGoalsMade",
        order: "DESC"
      },
      "FG%": {
        field: "cumulativeStats.fieldGoalPercentage",
        order: "DESC"
      },
      "XP%": {
        field: "cumulativeStats.extraPointPercentage",
        order: "DESC"
      }
    }
  },
  nhl: {
    skaters: {
      "PTS": {
        field: "cumulativeStats.points",
        order: "DESC"
      },
      "G": {
        field: "cumulativeStats.goals",
        order: "DESC"
      },
      "A": {
        field: "cumulativeStats.assists",
        order: "DESC"
      },
      "SOG": {
        field: "cumulativeStats.shotsOnGoal",
        order: "DESC"
      },
      "+/-": {
        field: "cumulativeStats.plusMinus",
        order: "DESC"
      }
    },
    forwards: {
      "PTS": {
        field: "cumulativeStats.points",
        order: "DESC"
      },
      "G": {
        field: "cumulativeStats.goals",
        order: "DESC"
      },
      "A": {
        field: "cumulativeStats.assists",
        order: "DESC"
      },
      "SOG": {
        field: "cumulativeStats.shotsOnGoal",
        order: "DESC"
      },
      "+/-": {
        field: "cumulativeStats.plusMinus",
        order: "DESC"
      }
    },
    defensemen: {
      "PTS": {
        field: "cumulativeStats.points",
        order: "DESC"
      },
      "G": {
        field: "cumulativeStats.goals",
        order: "DESC"
      },
      "A": {
        field: "cumulativeStats.assists",
        order: "DESC"
      },
      "SOG": {
        field: "cumulativeStats.shotsOnGoal",
        order: "DESC"
      },
      "+/-": {
        field: "cumulativeStats.plusMinus",
        order: "DESC"
      }
    },
    goalies: {
      "WINS": {
        field: "cumulativeStats.wins",
        order: "DESC"
      },
      "SV%": {
        field: "cumulativeStats.savePercentage",
        order: "DESC"
      },
      "GAA": {
        field: "averageStats.goalsAllowed",
        order: "ASC"
      },
      "SHUTOUTS": {
        field: "cumulativeStats.shutouts",
        order: "DESC"
      },
      "SA": {
        field: "cumulativeStats.shotsAgainst",
        order: "DESC"
      }
    }
  },
  nba: BASKETBALL_PLAYER_LEADERS,
  wnba: BASKETBALL_PLAYER_LEADERS,
  mlb: {
    pitchers: {
      "W": {
        field: "cumulativeStats.pitching.wins",
        order: "DESC"
      },
      "L": {
        field: "cumulativeStats.pitching.losses",
        order: "DESC"
      },
      "ERA": {
        field: "cumulativeStats.pitching.earnedRunsAverage",
        order: "DESC"
      },
      "SO": {
        field: "cumulativeStats.pitching.strikeouts",
        order: "DESC"
      },
      "WHIP": {
        field: "cumulativeStats.pitching.walksAndHitsPerInningPitched",
        order: "DESC"
      }
    },
    hitters: {
      "H": {
        field: "cumulativeStats.hitting.hits",
        order: "DESC"
      },
      "R": {
        field: "cumulativeStats.hitting.runs",
        order: "DESC"
      },
      "RBI": {
        field: "cumulativeStats.hitting.runsBattedIn",
        order: "DESC"
      },
      "HR": {
        field: "cumulativeStats.hitting.homeRuns",
        order: "DESC"
      },
      "AVG": {
        field: "cumulativeStats.hitting.battingAverage",
        order: "DESC"
      }
    }
  },
  bundesliga: soccerPlayerLeaders,
  epl: soccerPlayerLeaders,
  laliga: soccerPlayerLeaders,
  ligue1: soccerPlayerLeaders,
  seriea: soccerPlayerLeaders,
  "brazil-serie-a": soccerPlayerLeaders,
  mls: soccerPlayerLeaders,
  euro: soccerPlayerLeaders
}

const soccerPlayerLeadersApiFilters = {
  forwards: "position=forward",
  midfielders: "position=midfielder",
  defenders: "position=defender",
  goalies: "position=goalkeeper"
};

const BASKETBALL_PLAYER_LEADERS_API_FILTERS = {
  players: "position=PG,SG,PF,SF,C",
  "Point Guards": "position=PG",
  "Shooting Guards": "position=SG",
  "Small Forwards": "position=SF",
  "Power Forwards": "position=PF",
  "Centers": "position=C"
}

// Deprecated as of new web version
export const PLAYER_LEADER_API_FILTERS = {
  nfl: {
    passers: "position=QB",
    rushers: "position=QB,RB,WR,TE",
    catchers: "position=RB,WR,TE",
    kickers: "position=K"
  },
  nhl: {
    skaters: "position=C,LW,RW,D",
    forwards: "position=C,LW,RW",
    defensemen: "position=D",
    goalies: "position=G"
  },
  nba: BASKETBALL_PLAYER_LEADERS_API_FILTERS,
  wnba: BASKETBALL_PLAYER_LEADERS_API_FILTERS,
  mlb: {
    pitchers: "position=P",
    hitters: "position=H"
  },
  bundesliga: soccerPlayerLeadersApiFilters,
  epl: soccerPlayerLeadersApiFilters,
  laliga: soccerPlayerLeadersApiFilters,
  ligue1: soccerPlayerLeadersApiFilters,
  seriea: soccerPlayerLeadersApiFilters,
  "brazil-serie-a": soccerPlayerLeadersApiFilters,
  mls: soccerPlayerLeadersApiFilters,
  euro: soccerPlayerLeadersApiFilters
};

// Right now we have no need to separate it per league, fields are distinct enough between them
export const FIELD_DECIMAL_PRECISION = {
  //nfl
  //nhl
  //nba
  //mlb
  "battingAverage": 3,
  "battingAverageAgainst": 3,
  "onBasePercentage": 3,
  "sluggingPercentage": 3,
  "onBasePlusSluggingPercentage": 3,
  "winPercentage": 3
}

const BASKETBALL_STANDINGS_FIELDS = {
  "GP": "gamesPlayed",
  "W": "standings.wins",
  "L": "standings.losses"
}

export const STANDINGS_FIELDS = {
  nfl: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "T": "standings.ties"
  },
  nhl: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "OTL": "standings.overtimeLosses",
    "PTS": "standings.points"
  },
  nba: BASKETBALL_STANDINGS_FIELDS,
  wnba: BASKETBALL_STANDINGS_FIELDS,
  mlb: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "PCT": "standings.winPercentage"
  },
  bundesliga: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  epl: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  laliga: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  ligue1: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  seriea: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  "brazil-serie-a": {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  mls: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  },
  euro: {
    "GP": "gamesPlayed",
    "W": "standings.wins",
    "L": "standings.losses",
    "D": "standings.draws",
    "PTS": "standings.points"
  }
}

export const PREFERENCE_THEME = "theme"
export const PREFERENCE_THEME_SYSTEM = "Match System";
export const PREFERENCE_THEME_LIGHT = "Light";
export const PREFERENCE_THEME_DARK = "Dark";
export const PREFERENCE_THEME_DEFAULT_VALUE = PREFERENCE_THEME_SYSTEM;
export const PREFERENCE_THEME_OPTIONS = [PREFERENCE_THEME_SYSTEM, PREFERENCE_THEME_LIGHT, PREFERENCE_THEME_DARK];

export const PREFERENCE_ODDS = "odds";
export const PREFERENCE_ODDS_AMERICAN = "american";
export const PREFERENCE_ODDS_DECIMAL = "decimal";
export const PREFERENCE_ODDS_DEFAULT_VALUE = PREFERENCE_ODDS_AMERICAN;
export const PREFERENCE_ODDS_OPTIONS = [PREFERENCE_ODDS_AMERICAN, PREFERENCE_ODDS_DECIMAL];

export const LOCAL_STORAGE_CONFIGURATION_KEY = 'configuration';
export const LOCAL_STORAGE_LEAGUE_KEY = 'league';
export const LOCAL_STORAGE_PREFERENCES_THEME_KEY = PREFERENCE_THEME;
export const LOCAL_STORAGE_PREFERENCES_ODDS_KEY = PREFERENCE_ODDS;
export const LOCAL_STORAGE_ANALYZE_TRENDS_V1_OPENED = "analyzeV1PreviouslyOpened";

// We could have this in the metadata of the back-end configuration
export const BETTING_LEADERBOARD_TIMEFRAMES = {
  "Season": "SEASON",
  "Head to Head": "MATCHUP",
  "Last 5": "LAST_5",
  "Last 10": "LAST_10",
  "Last 20": "LAST_20",
  "Last 30": "LAST_30"
}

// We could have this in the metadata of the back-end configuration
export const BETTING_LEADERBOARD_MIN_ODDS_OPTIONS = {
  american: ["-400", "-200", "-135", "+100"],
  decimal: ["1.25", "1.5", "1.75", "2.0"],
  // When comparing to these, will have to parse out the string and convert it to a number
  fractional: ["1/4", "1/2", "3/4", "1"]
}

export const OVER_UNDER_FILTER = "O/U";
export const MIN_ODDS_FILTER = "Min Odds";
export const BET_TYPE_FILTER = "Bet Type";
export const TIMEFRAME_FILTER = "Timeframe";
export const GAMES_FILTER = "Game";
export const SITUATION_FILTER = "Situation";
export const SPLITS_FILTER = "Splits";
export const TEAM_FILTER = "Team";
export const PLAYER_FILTER = "Player";
export const TRENDS_FILTER = "Trends";

export const ALL_TRENDS_OPTION = "All Trends";
export const RECENT_FORM_TREND_OPTION = "Recent Form";
export const HEAD_TO_HEAD_TREND_OPTION = "Head to Head";
export const HOME_AWAY_SPLIT_TREND_OPTION = "Home/Away Splits";
export const OPPONENT_RANK_TREND_OPTION = "Opponent Rank";

export const DEFAULT_SITUATIONS = {
  nfl: "all",
  nba: "all",
  wnba: "all",
  nhl: "all",
  mlb: "againstBothHands",
  bundesliga: "all",
  epl: "all",
  laliga: "all",
  ligue1: "all",
  seriea: "all",
  "brazil-serie-a": "all",
  mls: "all",
  euro: "all"
}

export const SITUATIONS = {
  nfl: {
    "all": ""
  },
  nba: {
    "all": ""
  },
  wnba: {
    "all": ""
  },
  nhl: {
    "all": ""
  },
  mlb: {
    "againstBothHands": "All",
    "againstLeftHanded": "vs. LH",
    "againstRightHanded": "vs. RH"
  },
  bundesliga: {
    "all": ""
  },
  epl: {
    "all": ""
  },
  laliga: {
    "all": ""
  },
  ligue1: {
    "all": ""
  },
  seriea: {
    "all": ""
  },
  "brazil-serie-a": {
    "all": ""
  },
  mls: {
    "all": ""
  },
  euro: {
    "all": ""
  }
}

export const BETTING_TIMEFRAME_OPTIONS = {
  nfl: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  nhl: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  nba: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  wnba: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  mlb: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  bundesliga: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  epl: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  laliga: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  ligue1: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  seriea: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  "brazil-serie-a": ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  mls: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"],
  euro: ["Season", "Matchup", "Last 5", "Last 10", "Last 20", "Last 30"]
}

export const PLAYBOOK_SNAPSHOT_STATS = (league, qualifier) => {
  switch(league) {
    case "mlb":
      return {
        "R": `${qualifier}.offensive.runs`,
        "H": `${qualifier}.offensive.hits`,
        "HR": `${qualifier}.offensive.homeRuns`,
        "AVG": `${qualifier}.offensive.battingAverage`,
        "OBP": `${qualifier}.offensive.onBasePercentage`,
        "SLG": `${qualifier}.offensive.sluggingPercentage`,
        "OPS": `${qualifier}.offensive.onBasePlusSluggingPercentage`
      };
    case "nfl":
      return {
        "PTS": `${qualifier}.offensive.points`,
        "PASS YDS": `${qualifier}.offensive.passingYards`,
        "PASS TD": `${qualifier}.offensive.passingTouchdowns`,
        "RUSH YDS": `${qualifier}.offensive.rushingYards`,
        "RUSH TD": `${qualifier}.offensive.rushingTouchdowns`,
        "NET YDS": `${qualifier}.offensive.totalYards`,
        "TOTAL TDS": `${qualifier}.offensive.totalTouchdowns`,
        "RZ%": `${qualifier}.offensive.redzoneSuccessRate`
      };
    case "nba":
    case "wnba":
      return {
        "PTS": `${qualifier}.offensive.points`,
        "AST": `${qualifier}.offensive.assists`,
        "TRB": `${qualifier}.offensive.rebounds`,
        "STL": `${qualifier}.defensive.steals`,
        "TO": `${qualifier}.defensive.turnovers`
      };
    case "nhl":
      return {
        "G": `${qualifier}.offensive.goals`,
        "SOG": `${qualifier}.offensive.shotsOnGoal`,
        "SAT": `${qualifier}.offensive.shotAttempts`,
        "CF%": `${qualifier}.offensive.corsiPercentage`,
        "PP%": `${qualifier}.offensive.powerplayPercentage`
      };
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return {
        "G": `${qualifier}.offensive.goals`,
        "POS": `${qualifier}.offensive.ballPossession`,
        "SHO": `${qualifier}.offensive.shotsTotal`,
        "SOT": `${qualifier}.offensive.shotsOnTarget`,
        "COR": `${qualifier}.offensive.cornerKicks`,
        "OFF": `${qualifier}.offensive.offsides`,
        "FLS": `${qualifier}.offensive.fouls`,
        "2YELRED": `${qualifier}.offensive.doubleYellowRedCards`,
        "YEL": `${qualifier}.offensive.yellowCards`,
        "RED": `${qualifier}.offensive.redCards`
      }
    default:
      return {};
  }
}

export const PLAYBOOK_EDGE_FINDER_STATS = (league, qualifier) => {
  switch(league) {
    case "mlb":
      return {
        "R": `${qualifier}.offensive.runs`,
        "H": `${qualifier}.offensive.hits`,
        "1B": `${qualifier}.offensive.singles`,
        "2B": `${qualifier}.offensive.doubles`,
        "3B": `${qualifier}.offensive.triples`,
        "TB": `${qualifier}.offensive.totalBases`,
        "ER": `${qualifier}.offensive.earnedRuns`,
        "HR": `${qualifier}.offensive.homeRuns`,
        "RBI": `${qualifier}.offensive.runsBattedIn`,
        "BB": `${qualifier}.offensive.walks`,
        "SO": `${qualifier}.offensive.strikeouts`,
        "E": `${qualifier}.offensive.errors`,
        "AVG": `${qualifier}.offensive.battingAverage`,
        "OBP": `${qualifier}.offensive.onBasePercentage`,
        "SLG": `${qualifier}.offensive.sluggingPercentage`,
        "OPS": `${qualifier}.offensive.onBasePlusSluggingPercentage`
      };
    case "nfl":
      return {
        "PTS": `${qualifier}.offensive.points`,
        "SCORE %": `${qualifier}.offensive.scorePercentage`,
        "PLAYS": `${qualifier}.offensive.plays`,
        "NET YDS": `${qualifier}.offensive.totalYards`,
        "PASS YDS": `${qualifier}.offensive.passingYards`,
        "PASS ATT": `${qualifier}.offensive.passingAttempts`,
        "PASS COMPL": `${qualifier}.offensive.passingCompletions`,
        "PASS TD": `${qualifier}.offensive.passingTouchdowns`,
        "RUSH YDS": `${qualifier}.offensive.rushingYards`,
        "RUSH ATT": `${qualifier}.offensive.rushingAttempts`,
        "RUSH TD": `${qualifier}.offensive.rushingTouchdowns`,
        "TOTAL TDS": `${qualifier}.offensive.totalTouchdowns`,
        "RZ%": `${qualifier}.offensive.redzoneSuccessRate`,
        "1D": `${qualifier}.offensive.firstDowns`,
        "3D%": `${qualifier}.offensive.thirdDownConversionPercentage`,
        "4D%": `${qualifier}.offensive.fourthDownConversionPercentage`,
        "INT THROWN": `${qualifier}.offensive.interceptions`,
        "TO": `${qualifier}.offensive.turnovers`,
        "SACKS": `${qualifier}.defensive.sacks`,
        "FUML": `${qualifier}.offensive.fumblesLost`
      };
    case "nba":
    case "wnba":
      return {
        "PTS": `${qualifier}.offensive.points`,
        "FT%": `${qualifier}.offensive.freeThrowPercentage`,
        "FG%": `${qualifier}.offensive.fieldGoalPercentage`,
        "3PT%": `${qualifier}.offensive.threePointPercentage`,
        "TS%": `${qualifier}.offensive.trueShootingPercentage`,
        "eFG%": `${qualifier}.offensive.effectiveFieldGoalPercentage`,
        "TRB": `${qualifier}.offensive.rebounds`,
        "AST": `${qualifier}.offensive.assists`,
        "STL": `${qualifier}.defensive.steals`,
        "TO": `${qualifier}.defensive.turnovers`,
        "BLK": `${qualifier}.defensive.blocks`
      };
    case "nhl":
      return {
        "G": `${qualifier}.offensive.goals`,
        "SOG": `${qualifier}.offensive.shotsOnGoal`,
        "SAT": `${qualifier}.offensive.shotAttempts`,
        "CF%": `${qualifier}.offensive.corsiPercentage`,
        "PP%": `${qualifier}.offensive.powerplayPercentage`,
        "PIM": `${qualifier}.offensive.penaltyMinutes`
      };
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return {
        "G": `${qualifier}.offensive.goals`,
        "POS": `${qualifier}.offensive.ballPossession`,
        "SHO": `${qualifier}.offensive.shotsTotal`,
        "SOT": `${qualifier}.offensive.shotsOnTarget`,
        "COR": `${qualifier}.offensive.cornerKicks`,
        "OFF": `${qualifier}.offensive.offsides`,
        "FLS": `${qualifier}.offensive.fouls`,
        "2YELRED": `${qualifier}.offensive.doubleYellowRedCards`,
        "YEL": `${qualifier}.offensive.yellowCards`,
        "RED": `${qualifier}.offensive.redCards`
      }
    default:
      return {};
  }
}

export const PLAYBOOK_SEASON_STATS = (league, qualifier, type) => {
  switch(league) {
    case "mlb":
      return {
        "R": `${qualifier}.${type}.runs`,
        "H": `${qualifier}.${type}.hits`,
        "1B": `${qualifier}.${type}.singles`,
        "2B": `${qualifier}.${type}.doubles`,
        "3B": `${qualifier}.${type}.triples`,
        "TB": `${qualifier}.${type}.totalBases`,
        "ER": `${qualifier}.${type}.earnedRuns`,
        "HR": `${qualifier}.${type}.homeRuns`,
        "RBI": `${qualifier}.${type}.runsBattedIn`,
        "BB": `${qualifier}.${type}.walks`,
        "SO": `${qualifier}.${type}.strikeouts`,
        "E": `${qualifier}.${type}.errors`,
        "AVG": `${qualifier}.${type}.battingAverage`,
        "OBP": `${qualifier}.${type}.onBasePercentage`,
        "SLG": `${qualifier}.${type}.sluggingPercentage`,
        "OPS": `${qualifier}.${type}.onBasePlusSluggingPercentage`
      };
    case "nfl":
      return {
        "PTS": `${qualifier}.${type}.points`,
        "SCORE %": `${qualifier}.${type}.scorePercentage`,
        "PLAYS": `${qualifier}.${type}.plays`,
        "NET YDS": `${qualifier}.${type}.totalYards`,
        "PASS YDS": `${qualifier}.${type}.passingYards`,
        "PASS ATT": `${qualifier}.${type}.passingAttempts`,
        "PASS COMPL": `${qualifier}.${type}.passingCompletions`,
        "PASS TD": `${qualifier}.${type}.passingTouchdowns`,
        "RUSH YDS": `${qualifier}.${type}.rushingYards`,
        "RUSH ATT": `${qualifier}.${type}.rushingAttempts`,
        "RUSH TD": `${qualifier}.${type}.rushingTouchdowns`,
        "TOTAL TDS": `${qualifier}.${type}.totalTouchdowns`,
        "RZ%": `${qualifier}.${type}.redzoneSuccessRate`,
        "1D": `${qualifier}.${type}.firstDowns`,
        "3D%": `${qualifier}.${type}.thirdDownConversionPercentage`,
        "4D%": `${qualifier}.${type}.fourthDownConversionPercentage`,
        "INT THROWN": `${qualifier}.${type}.interceptions`,
        "TO": `${qualifier}.${type}.turnovers`,
        "SACKS": `${qualifier}.${type}.sacks`,
        "FUML": `${qualifier}.${type}.fumblesLost`
      };
    case "nba":
    case "wnba":
      return {
        "PTS": `${qualifier}.${type}.points`,
        "FT%": `${qualifier}.${type}.freeThrowPercentage`,
        "FG%": `${qualifier}.${type}.fieldGoalPercentage`,
        "3PT%": `${qualifier}.${type}.threePointPercentage`,
        "TS%": `${qualifier}.${type}.trueShootingPercentage`,
        "eFG%": `${qualifier}.${type}.effectiveFieldGoalPercentage`,
        "TRB": `${qualifier}.${type}.rebounds`,
        "AST": `${qualifier}.${type}.assists`,
        "STL": `${qualifier}.${type}.steals`,
        "TO": `${qualifier}.${type}.turnovers`,
        "BLK": `${qualifier}.${type}.blocks`
      };
    case "nhl":
      return {
        "G": `${qualifier}.${type}.goals`,
        "SOG": `${qualifier}.${type}.shotsOnGoal`,
        "SAT": `${qualifier}.${type}.shotAttempts`,
        "CF%": `${qualifier}.${type}.corsiPercentage`,
        "PP%": `${qualifier}.${type}.powerplayPercentage`,
        "PIM": `${qualifier}.${type}.penaltyMinutes`
      };
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return {
        "G": `${qualifier}.${type}.goals`,
        "POS": `${qualifier}.${type}.ballPossession`,
        "SHO": `${qualifier}.${type}.shotsTotal`,
        "SOT": `${qualifier}.${type}.shotsOnTarget`,
        "COR": `${qualifier}.${type}.cornerKicks`,
        "OFF": `${qualifier}.${type}.offsides`,
        "FLS": `${qualifier}.${type}.fouls`,
        "2YELRED": `${qualifier}.${type}.doubleYellowRedCards`,
        "YEL": `${qualifier}.${type}.yellowCards`,
        "RED": `${qualifier}.${type}.redCards`
      }
    default:
      return {};
  }
}

export const SCREENER_TEAM_SCORING_STATS = (league, qualifier, type) => {
  switch(league) {
    case "nba":
    case "wnba":
      return {
        "PTS": `${qualifier}.${type}.points`,
        "FT%": `${qualifier}.${type}.freeThrowPercentage`,
        "FG%": `${qualifier}.${type}.fieldGoalPercentage`,
        "3PT%": `${qualifier}.${type}.threePointPercentage`,
        "TS%": `${qualifier}.${type}.trueShootingPercentage`,
        "eFG%": `${qualifier}.${type}.effectiveFieldGoalPercentage`,
        "TRB": `${qualifier}.${type}.rebounds`,
        "AST": `${qualifier}.${type}.assists`,
        "STL": `${qualifier}.${type}.steals`,
        "TO": `${qualifier}.${type}.turnovers`,
        "BLK": `${qualifier}.${type}.blocks`,
        "PACE": `${qualifier}.${type}.pace`
      };
    case "nhl":
      return {
        "G": `${qualifier}.${type}.goals`,
        "SOG": `${qualifier}.${type}.shotsOnGoal`,
        "SAT": `${qualifier}.${type}.shotAttempts`,
        "CF%": `${qualifier}.${type}.corsiPercentage`,
        "BLK": `${qualifier}.${type}.defensiveBlocks`,
        "PIM": `${qualifier}.${type}.penaltyMinutes`,
        "PPG": `${qualifier}.${type}.powerplayGoals`,
        "PP%": `${qualifier}.${type}.powerplayPercentage`
      };
    case "nfl":
      return {
        "PTS": `${qualifier}.${type}.points`,
        "Score %": `${qualifier}.${type}.scorePercentage`,
        "Plays": `${qualifier}.${type}.plays`,
        "Net Yds": `${qualifier}.${type}.totalYards`,
        "Pass Yds": `${qualifier}.${type}.passingYards`,
        "Pass Att": `${qualifier}.${type}.passingAttempts`,
        "Pass Compl": `${qualifier}.${type}.passingCompletions`,
        "Pass TD": `${qualifier}.${type}.passingTouchdowns`,
        "Rush Yds": `${qualifier}.${type}.rushingYards`,
        "Rush Att": `${qualifier}.${type}.rushingAttempts`,
        "Rush TD": `${qualifier}.${type}.rushingTouchdowns`,
        "Total TDs": `${qualifier}.${type}.totalTouchdowns`,
        "RZ%": `${qualifier}.${type}.redzoneSuccessRate`,
        "1D": `${qualifier}.${type}.firstDowns`,
        "3D": `${qualifier}.${type}.thirdDownConversionAttempts`,
        "3D%": `${qualifier}.${type}.thirdDownConversionPercentage`,
        "4D%": `${qualifier}.${type}.fourthDownConversionPercentage`,
        "INT": `${qualifier}.${type}.interceptions`,
        "TO": `${qualifier}.${type}.turnovers`,
        "Sacks": `${qualifier}.${type}.sacks`,
        "FUML": `${qualifier}.${type}.fumblesLost`
      };
    case "mlb":
      return {
        "R": `${qualifier}.${type}.runs`,
        "H": `${qualifier}.${type}.hits`,
        "1B": `${qualifier}.${type}.singles`,
        "2B": `${qualifier}.${type}.doubles`,
        "3B": `${qualifier}.${type}.triples`,
        "HR": `${qualifier}.${type}.homeRuns`,
        "RBI": `${qualifier}.${type}.runsBattedIn`,
        "BB": `${qualifier}.${type}.walks`,
        "SO": `${qualifier}.${type}.strikeouts`,
        "E": `${qualifier}.${type}.errors`,
        "AVG": `${qualifier}.${type}.battingAverage`,
        "OPS": `${qualifier}.${type}.onBasePlusSluggingPercentage`
      };
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return {
        "G": `${qualifier}.${type}.goals`,
        "POS": `${qualifier}.${type}.ballPossession`,
        "SHO": `${qualifier}.${type}.shotsTotal`,
        "SOT": `${qualifier}.${type}.shotsOnTarget`,
        "COR": `${qualifier}.${type}.cornerKicks`,
        "OFF": `${qualifier}.${type}.offsides`,
        "FLS": `${qualifier}.${type}.fouls`,
        "2YELRED": `${qualifier}.${type}.doubleYellowRedCards`,
        "YEL": `${qualifier}.${type}.yellowCards`,
        "RED": `${qualifier}.${type}.redCards`
      }
    default:
      return {};
  }
}

export const SCREENER_PLAYER_SCORING_STATS = (league, qualifier, position) => {
  switch(league) {
    case "nba":
    case "wnba":
      return {
        "PTS": `${qualifier}.points`,
        "FTA": `${qualifier}.freeThrowAttempts`,
        "FTM": `${qualifier}.freeThrowsMade`,
        "FT%": `${qualifier}.freeThrowPercentage`,
        "FGA": `${qualifier}.fieldGoalAttempts`,
        "FGM": `${qualifier}.fieldGoalsMade`,
        "FG%": `${qualifier}.fieldGoalPercentage`,
        "3PTA": `${qualifier}.threePointAttempts`,
        "3PTM": `${qualifier}.threePointsMade`,
        "3PT%": `${qualifier}.threePointPercentage`,
        "TS%": `${qualifier}.trueShootingPercentage`,
        "eFG%": `${qualifier}.effectiveFieldGoalPercentage`,
        "REB": `${qualifier}.rebounds`,
        "AST": `${qualifier}.assists`,
        "STL": `${qualifier}.steals`,
        "TO": `${qualifier}.turnovers`,
        "BLK": `${qualifier}.blocks`,
        "MIN": `${qualifier}.minutesPlayed`
      };
    case "nhl":
      if (position === "G") {
        return {
          "WINS": `${qualifier}.wins`,
          "SA": `${qualifier}.shotsAgainst`,
          "SAVES": `${qualifier}.shotsSaved`,
          "SV%": `${qualifier}.savePercentage`,
          "GA": `${qualifier}.goalsAllowed`,
          "SHUTOUTS": `${qualifier}.shutouts`
        }
      }
      return {
        "PTS": `${qualifier}.points`,
        "G": `${qualifier}.goals`,
        "A": `${qualifier}.assists`,
        "SOG": `${qualifier}.shotsOnGoal`,
        "SAT": `${qualifier}.shotAttempts`,
        "PPP": `${qualifier}.powerplayPoints`,
        "SHP": `${qualifier}.shorthandedPoints`,
        "PIM": `${qualifier}.penaltyMinutes`,
        "BLK": `${qualifier}.defensiveBlocks`,
        "TOI": `${qualifier}.timeOnIce`
      }
    case "nfl":
      const passingPlayerStatsColumns = {
          "Pass Rtg": `${qualifier}.rating`,
          "Comp": `${qualifier}.passingCompletions`,
          "Pass Att": `${qualifier}.passingAttempts`,
          "Pass Yds": `${qualifier}.passingYards`,
          "Pass TD": `${qualifier}.passingTouchdowns`,
          "INT": `${qualifier}.interceptions`,
          "Pass Yds/Att": `${qualifier}.yardsPerAttempt`,
          "RZ Pass Att": `${qualifier}.redzonePassingAttempts`,
          "RZ Pass Comp": `${qualifier}.redzoneCompletions`,
          "RZ Pass TD": `${qualifier}.redzonePassingTouchdowns`
      };

      const rushingPlayerStatsColumns = {
          "Rush Att": `${qualifier}.rushingAttempts`,
          "Rush Yds": `${qualifier}.rushingYards`,
          "Rush TD": `${qualifier}.rushingTouchdowns`,
          "Rush Yds/Att": `${qualifier}.rushingYardsPerAttempt`,
          "Long": `${qualifier}.longestRun`,
          "RZ Rush Att": `${qualifier}.redzoneRushingAttempts`,
          "RZ Rush TD": `${qualifier}.redzoneRushingTouchdowns`
      };

      const receivingPlayerStatsColumns = {
          "Tgt": `${qualifier}.targets`,
          "Rec": `${qualifier}.receptions`,
          "Rec Yds": `${qualifier}.receivingYards`,
          "Rec TD": `${qualifier}.receivingTouchdowns`,
          "Rec Yds/Rec": `${qualifier}.receivingYardsPerReception`,
          "RZ Tgt": `${qualifier}.redzoneTargets`,
          "RZ Rec": `${qualifier}.redzoneReceptions`,
          "RZ Rec TD": `${qualifier}.redzoneReceivingTouchdowns`
      };

      const kickingPlayerStatsColumns = {
          "PTS": `${qualifier}.pointsKicked`,
          "FGA": `${qualifier}.fieldGoalAttempts`,
          "FGM": `${qualifier}.fieldGoalsMade`,
          "FG%": `${qualifier}.fieldGoalPercentage`,
          "XPA": `${qualifier}.extraPointAttempts`,
          "XPM": `${qualifier}.extraPointsMade`,
          "XP%": `${qualifier}.extraPointPercentage`,
          "FG (0-19)": `${qualifier}.extraShortRangeFieldGoals`,
          "FG (20-29)": `${qualifier}.shortRangeFieldGoals`,
          "FG (30-39)": `${qualifier}.mediumRangeFieldGoals`,
          "FG (40-49)": `${qualifier}.longRangeFieldGoals`,
          "FG (50+)": `${qualifier}.extraLongRangeFieldGoals`
      };

      if (position === "QB") {
          return Object.assign({}, passingPlayerStatsColumns, rushingPlayerStatsColumns);
      }
      if (position === "RB") {
          return Object.assign({}, rushingPlayerStatsColumns, receivingPlayerStatsColumns);
      }
      if (position === "WR" || position === "TE") {
          return Object.assign({}, receivingPlayerStatsColumns, rushingPlayerStatsColumns);
      }
      if (position === "K") {
          return kickingPlayerStatsColumns;
      }
      return {};
    case "mlb":
      if (position === "SP" || position === "RP") {
          return {
            "W": `${qualifier}.pitching.wins`,
            "L": `${qualifier}.pitching.losses`,
            "IP": `${qualifier}.pitching.inningsPitched`,
            "ERA": `${qualifier}.pitching.earnedRunsAverage`,
            "SV": `${qualifier}.pitching.saves`,
            "H": `${qualifier}.pitching.hits`,
            "R": `${qualifier}.pitching.runs`,
            "HR": `${qualifier}.pitching.homeRuns`,
            "ER": `${qualifier}.pitching.earnedRuns`,
            "BB": `${qualifier}.pitching.walks`,
            "SO": `${qualifier}.pitching.strikeouts`,
            "WHIP": `${qualifier}.pitching.walksAndHitsPerInningPitched`,
            "AVG": `${qualifier}.pitching.battingAverageAgainst`
          };
      } else {
          return {
            "AB": `${qualifier}.hitting.atBats`,
            "R": `${qualifier}.hitting.runs`,
            "H": `${qualifier}.hitting.hits`,
            "1B": `${qualifier}.hitting.singles`,
            "2B": `${qualifier}.hitting.doubles`,
            "3B": `${qualifier}.hitting.triples`,
            "HR": `${qualifier}.hitting.homeRuns`,
            "ISO": `${qualifier}.hitting.isolatedPower`,
            "RBI": `${qualifier}.hitting.runsBattedIn`,
            "TB": `${qualifier}.hitting.totalBases`,
            "BB": `${qualifier}.hitting.walks`,
            "SO": `${qualifier}.hitting.strikeouts`,
            "AVG": `${qualifier}.hitting.battingAverage`,
            "OPS": `${qualifier}.hitting.onBasePlusSluggingPercentage`
          };
      }
      return {};
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      if (position.toLowerCase() === "goalkeeper") {
        return {
          "GA": `${qualifier}.goalsConceded`,
          "SAV": `${qualifier}.shotsFacedSaved`,
          "SA": `${qualifier}.shotsFacedTotal`,
          "PS": `${qualifier}.penaltiesSaved`,
          "DSAV": `${qualifier}.divingSaves`,
          "CS": `${qualifier}.cleanSheet`,
          "YEL": `${qualifier}.yellowCards`,
          "2YELRED": `${qualifier}.doubleYellowRedCards`,
          "RED": `${qualifier}.redCards`
        }
      }
      return {
        "G": `${qualifier}.goals`,
        "A": `${qualifier}.assists`,
        "SOT": `${qualifier}.shotsOnTarget`,
        "SHO": `${qualifier}.totalShots`,
        "MIN": `${qualifier}.minutesPlayed`,
        // "CC": `${qualifier}.chancesCreated`,
        "PAS": `${qualifier}.totalPasses`,
        "DRI": `${qualifier}.dribblesCompleted`,
        "LOP": `${qualifier}.lossOfPossession`,
        "TCK": `${qualifier}.totalTackles`,
        "FLS": `${qualifier}.foulsCommitted`,
        "INT": `${qualifier}.interceptions`,
        "YEL": `${qualifier}.yellowCards`,
        "2YELRED": `${qualifier}.yellowRedCards`,
        "RED": `${qualifier}.redCards`
      }
    default:
      return {};
  }
}

export const DEFAULT_MARKET_TEXT = "--";

export const DEFAULT_HIT_RECORD_ENTRY = {
  games: 0,
  hits: 0,
  misses: 0,
  pushes: 0,
  hitRate: 0
}


const SOCCER_DISCOVERY_BEST_BETS = {
  'Goals & Assists': {betTypes: ['ANYTIME_GOAL_SCORER', 'GOAL_OR_ASSIST', 'ONE_OR_MORE_ASSISTS', 'TWO_OR_MORE_ASSISTS'], icon: 'soccer-ball', type: 'player'},
  'Shots': {betTypes: ['ONE_OR_MORE_SHOTS', 'TWO_OR_MORE_SHOTS', 'THREE_OR_MORE_SHOTS', 'FOUR_OR_MORE_SHOTS', 'FIVE_OR_MORE_SHOTS', 'ONE_OR_MORE_SHOTS_ON_TARGET', 'TWO_OR_MORE_SHOTS_ON_TARGET', 'THREE_OR_MORE_SHOTS_ON_TARGET', 'FOUR_OR_MORE_SHOTS_ON_TARGET'], icon: 'target', type: 'player'},
  'Results': {betTypes: ['MONEY_LINE', 'WIN_TO_NIL', 'DOUBLE_CHANCE'], icon: 'trophy', type: 'team'},
  'Totals': {betTypes: ['GAME_TOTAL', 'TEAM_TOTAL', 'BOTH_TEAMS_TO_SCORE'], icon: 'score', type: 'team'}
}

const BASKETBALL_DISCOVERY_BEST_BETS = {
  Points: {betTypes: ['POINTS', 'POINTS_PLUS_ASSISTS', 'POINTS_PLUS_ASSISTS_PLUS_REBOUNDS', 'POINTS_PLUS_REBOUNDS'], icon: 'basketball', type: 'player'},
  Assists: {betTypes: ['ASSISTS', 'POINTS_PLUS_ASSISTS', 'POINTS_PLUS_ASSISTS_PLUS_REBOUNDS', 'ASSISTS_PLUS_REBOUNDS'], icon: 'target', type: 'player'},
  Rebounds: {betTypes: ['REBOUNDS', 'ASSISTS_PLUS_REBOUNDS', 'POINTS_PLUS_ASSISTS_PLUS_REBOUNDS', 'POINTS_PLUS_REBOUNDS'], icon: 'hoop', type: 'player'},
  '3PTM': {betTypes: ['THREE_POINTS_MADE'], icon: '3ptm', type: 'player'},
  Results: {betTypes: ['MONEY_LINE', 'SPREAD'], icon: 'trophy', type: 'team'},
  Totals: {betTypes: ['GAME_TOTAL', 'TEAM_TOTAL'], icon: 'score', type: 'team'}
}

// There might be a limitation on 10 whereIn conditions back-end, will have to be careful if we ever have a list of 10 or more
export const DISCOVERY_BEST_BETS_GROUPING = {
  mlb: {
    Hits: {betTypes: ['HITTER_HITS', 'HITTER_HITS_PLUS_RUNS_PLUS_RUNS_BATTED_IN'], icon: 'bat', type: 'player'},
    'Total Bases': {betTypes: ['HITTER_TOTAL_BASES'], icon: 'bases', type: 'player'},
    RBI: {betTypes: ['HITTER_RUNS_BATTED_IN', 'HITTER_HITS_PLUS_RUNS_PLUS_RUNS_BATTED_IN'], icon: 'baseball', type: 'player'},
    Strikeouts: {betTypes: ['PITCHER_STRIKEOUTS'], icon: 'hand', type: 'player'},
    Results: {betTypes: ['MONEY_LINE', 'SPREAD'], icon: 'trophy', type: 'team'},
    Totals: {betTypes: ['GAME_TOTAL', 'TEAM_TOTAL'], icon: 'score', type: 'team'}
  },
  nba: BASKETBALL_DISCOVERY_BEST_BETS,
  wnba: BASKETBALL_DISCOVERY_BEST_BETS,
  nfl: {
    TD: {betTypes: ['ANYTIME_TOUCHDOWN'], icon: 'touchdown', type: 'player'},
    Yards: {betTypes: ['PASSING_YARDS', 'RUSHING_YARDS'], icon: 'yards', type: 'player'},
    Receptions: {betTypes: ['RECEPTIONS'], icon: 'football', type: 'player'},
    Attempts: {betTypes: ['RUSHING_ATTEMPTS', 'PASSING_ATTEMPTS'], icon: 'helmet', type: 'player'},
    Results: {betTypes: ['MONEY_LINE', 'SPREAD'], icon: 'trophy', type: 'team'},
    Totals: {betTypes: ['GAME_TOTAL', 'TEAM_TOTAL'], icon: 'score', type: 'team'}
  },
  nhl: {
    'Goals & Assists': {betTypes: ['ANYTIME_GOAL_SCORER', 'ASSISTS'], icon: 'puck', type: 'player'},
    'Shots on Goal': {betTypes: ['SHOTS_ON_GOAL'], icon: 'target', type: 'player'},
    'Points': {betTypes: ['POINTS', 'POWERPLAY_POINTS'], icon: 'hockey-sticks', type: 'player'},
    'Powerplay Points': {betTypes: ['POWERPLAY_POINTS'], icon: 'whistle', type: 'player'},
    Results: {betTypes: ['MONEY_LINE', 'SPREAD'], icon: 'trophy', type: 'team'},
    Totals: {betTypes: ['GAME_TOTAL', 'TEAM_TOTAL'], icon: 'score', type: 'team'}
  },
  bundesliga: SOCCER_DISCOVERY_BEST_BETS,
  epl: SOCCER_DISCOVERY_BEST_BETS,
  laliga: SOCCER_DISCOVERY_BEST_BETS,
  ligue1: SOCCER_DISCOVERY_BEST_BETS,
  seriea: SOCCER_DISCOVERY_BEST_BETS,
  "brazil-serie-a": SOCCER_DISCOVERY_BEST_BETS,
  mls: SOCCER_DISCOVERY_BEST_BETS,
  euro: SOCCER_DISCOVERY_BEST_BETS
}

// The position filter is based on the back-end configuration
const SOCCER_DISCOVERY_LEADERS = [
  {title: 'Goals', subtitle: 'Forwards', filters: {position: 'Forwards', qualifier: 'Total'}, sortingColumn: 'G', sortingOrder: 'desc'},
  {title: 'Shots', subtitle: 'Outfielders', filters: {position: 'Outfielders', qualifier: 'Total'}, sortingColumn: 'SHO', sortingOrder: 'desc'},
  {title: 'Saves', subtitle: 'Goalies', filters: {position: 'Goalies', qualifier: 'Total'}, sortingColumn: 'SAV', sortingOrder: 'desc'},
  {title: 'Tackles', subtitle: 'Defenders', filters: {position: 'Defenders', qualifier: 'Total'}, sortingColumn: 'TCK', sortingOrder: 'desc'},
  {title: 'Passes', subtitle: 'Midfielders', filters: {position: 'Midfielders', qualifier: 'Total'}, sortingColumn: 'PAS', sortingOrder: 'desc'},
  {title: 'Shots on Target', subtitle: 'Outfielders', filters: {position: 'Outfielders', qualifier: 'Total'}, sortingColumn: 'SOT', sortingOrder: 'desc'},
]

const BASKETBALL_DISCOVERY_LEADERS = [
  {title: 'Points', subtitle: 'Players', filters: {qualifier: 'Total'}, sortingColumn: 'PTS', sortingOrder: 'desc'},
  {title: 'Rebounds', subtitle: 'Players', filters: {qualifier: 'Total'}, sortingColumn: 'REB', sortingOrder: 'desc'},
  {title: '3PTM', subtitle: 'Players', filters: {qualifier: 'Total'}, sortingColumn: '3PTM', sortingOrder: 'desc'},
  {title: 'Assists', subtitle: 'Players', filters: {qualifier: 'Total'}, sortingColumn: 'AST', sortingOrder: 'desc'},
  {title: 'Steals', subtitle: 'Players', filters: {qualifier: 'Total'}, sortingColumn: 'STL', sortingOrder: 'desc'},
  {title: 'Blocks', subtitle: 'Players', filters: {qualifier: 'Total'}, sortingColumn: 'BLK', sortingOrder: 'desc'}
]

export const DISCOVERY_LEADERS = {
  mlb: [
    {title: 'Home Runs', subtitle: 'Hitters', filters: {position: 'Hitters', qualifier: 'Total'}, sortingColumn: 'HR', sortingOrder: 'desc'},
    {title: 'Strikeouts', subtitle: 'Pitchers', filters: {position: 'Pitchers', qualifier: 'Total'}, sortingColumn: 'SO', sortingOrder: 'desc'},
    {title: 'Runs Batted In', subtitle: 'Hitters', filters: {position: 'Hitters', qualifier: 'Total'}, sortingColumn: 'RBI', sortingOrder: 'desc'},
    {title: 'Hits Allowed', subtitle: 'Pitchers', filters: {position: 'Pitchers', qualifier: 'Total'}, sortingColumn: 'H', sortingOrder: 'asc'},
    {title: 'Hits', subtitle: 'Hitters', filters: {position: 'Hitters', qualifier: 'Total'}, sortingColumn: 'H', sortingOrder: 'desc'},
    {title: 'Earned Runs', subtitle: 'Pitchers', filters: {position: 'Pitchers', qualifier: 'Total'}, sortingColumn: 'ER', sortingOrder: 'asc'}
  ],
  nba: BASKETBALL_DISCOVERY_LEADERS,
  wnba: BASKETBALL_DISCOVERY_LEADERS,
  nfl: [
    {title: 'Redzone Targets', subtitle: 'Wide Receivers', filters: {position: 'WR', qualifier: 'Total'}, sortingColumn: 'REC RZ TGT', sortingOrder: 'desc'},
    {title: 'Redzone Attempts', subtitle: 'Running Backs', filters: {position: 'RB', qualifier: 'Total'}, sortingColumn: 'RUSH RZ ATT', sortingOrder: 'desc'},
    {title: 'Passing Yards', subtitle: 'Quarterbacks', filters: {position: 'QB', qualifier: 'Total'}, sortingColumn: 'PASS YDS', sortingOrder: 'desc'},
    {title: 'Receiving Yards', subtitle: 'Wide Receivers', filters: {position: 'WR', qualifier: 'Total'}, sortingColumn: 'REC YDS', sortingOrder: 'desc'},
    {title: 'Rushing Yards', subtitle: 'Running Backs', filters: {position: 'RB', qualifier: 'Total'}, sortingColumn: 'RUSH YDS', sortingOrder: 'desc'},
    {title: 'Redzone Targets', subtitle: 'Tight Ends', filters: {position: 'TE', qualifier: 'Total'}, sortingColumn: 'REC RZ TGT', sortingOrder: 'desc'},
  ],
  nhl: [
    {title: 'Goals', subtitle: 'Skaters', filters: {position: 'Skaters', qualifier: 'Total'}, sortingColumn: 'G', sortingOrder: 'desc'},
    {title: 'Shots on Goal', subtitle: 'Skaters', filters: {position: 'Skaters', qualifier: 'Total'}, sortingColumn: 'SOG', sortingOrder: 'desc'},
    {title: 'Saves', subtitle: 'Goalies', filters: {position: 'G', qualifier: 'Total'}, sortingColumn: 'SAVES', sortingOrder: 'desc'},
    {title: 'Points', subtitle: 'Skaters', filters: {position: 'Skaters', qualifier: 'Total'}, sortingColumn: 'PTS', sortingOrder: 'desc'},
    {title: 'Powerplay Points', subtitle: 'Skaters', filters: {position: 'Skaters', qualifier: 'Total'}, sortingColumn: 'PPP', sortingOrder: 'desc'},
    {title: 'Blocked Shots', subtitle: 'Defense', filters: {position: 'D', qualifier: 'Total'}, sortingColumn: 'BLK', sortingOrder: 'desc'},
  ],
  bundesliga: SOCCER_DISCOVERY_LEADERS,
  epl: SOCCER_DISCOVERY_LEADERS,
  laliga: SOCCER_DISCOVERY_LEADERS,
  ligue1: SOCCER_DISCOVERY_LEADERS,
  seriea: SOCCER_DISCOVERY_LEADERS,
  "brazil-serie-a": SOCCER_DISCOVERY_LEADERS,
  mls: SOCCER_DISCOVERY_LEADERS,
  euro: SOCCER_DISCOVERY_LEADERS
}

const SOCCER_DISCOVERY_BET_BY_POSITION = [
  {title: 'Forwards', position: 'forward', icon: 'forward'},
  {title: 'Midfielders', position: 'midfielder', icon: 'midfielder'},
  {title: 'Defense', position: 'defender', icon: 'defense'}
]

const BASKETBALL_DISCOVERY_BET_BY_POSITION = [
  {title: 'Guards', position: 'PG,SG', icon: 'basketball-player-right'},
  {title: 'Forwards', position: 'PF,SF', icon: 'basketball-player-jump'},
  {title: 'Centers', position: 'C', icon: 'basketball-player-block'}
]

export const DISCOVERY_BET_BY_POSITION = {
  mlb: [
    {title: 'Pitchers', position: 'PITCHERS', icon: 'pitcher'},
    {title: 'Hitters', position: 'HITTERS', icon: 'batter'}
  ],
  nba: BASKETBALL_DISCOVERY_BET_BY_POSITION,
  nfl: [
    {title: 'QB', position: 'QB', icon: 'quarterback'},
    {title: 'RB', position: 'RB', icon: 'running-back'},
    {title: 'WR', position: 'WR', icon: 'wide-receiver'},
    {title: 'TE', position: 'TE', icon: 'tight-end'}
  ],
  nhl: [
    {title: 'Centers', position: 'C', icon: 'hockey-player-right'},
    {title: 'Wingers', position: 'LW,RW', icon: 'hockey-player-winger'},
    {title: 'Defense', position: 'D', icon: 'hockey-player-left'},
    {title: 'Goalies', position: 'G', icon: 'hockey-goalie'}
  ],
  bundesliga: SOCCER_DISCOVERY_BET_BY_POSITION,
  epl: SOCCER_DISCOVERY_BET_BY_POSITION,
  laliga: SOCCER_DISCOVERY_BET_BY_POSITION,
  ligue1: SOCCER_DISCOVERY_BET_BY_POSITION,
  seriea: SOCCER_DISCOVERY_BET_BY_POSITION,
  "brazil-serie-a": SOCCER_DISCOVERY_BET_BY_POSITION,
  mls: SOCCER_DISCOVERY_BET_BY_POSITION,
  euro: SOCCER_DISCOVERY_BET_BY_POSITION
}