import React from 'react';

import './TrendContent.scss'
import './App.scss'
import GlobalContext from './GlobalContext';
import { getOddsFromCard, getPlayerName } from '../linemate-react-common/src/util';
import { getMarketDescription } from '../linemate-react-common/src/betting-utils';
import { getTeamLogoPath } from '../react-web-utils';

const TREND_ASSET_MAPPING = {
    'HEAD_TO_HEAD': 'head-to-head',
    'RECENT_FORM': 'form',
    'AWAY_SPLIT': 'away',
    'HOME_SPLIT': 'home',
    'OPPONENT': 'opponent-rank'
}

/**
 * Props
 * - card:dict
 * - maskContent:bool   If true, switches the content and font to make it illegible
 * - league:string?     Override from the context. Necessary for dummy cards
 */
class TrendContent extends React.Component {

  static contextType = GlobalContext;

  buildBadgesStack(narratives) {
    return (
        <div className='trend-content-header-right'>
            {
                narratives.slice().reverse().map((narrative, index) =>
                    // Normally for the bordered image it would be index < narratives.length - 1 but because it's inverted we have to invert the condition as well
                    <div key={narrative} style={{'--index': index}} className={index > 0 ? "trend-content-header-right-image-with-border" : ""}>
                        <img src={`assets/badges/${TREND_ASSET_MAPPING[narrative]}.svg`} alt={narrative}/>
                    </div>
                )
            }
        </div>
    )
  }

  render() {
    const card = this.props.card;
    const subject = card.type === "player" ? getPlayerName(card.player) : card.team.code;
    const opponent = `${card.home ? "vs" : "@"} ${card.opposingTeam.code}`;
    const league = this.props.league || this.context.league;
    const marketDescription = getMarketDescription(league, card.type, card.market, card.book, card.outcome);
    const odds = getOddsFromCard(card, this.context.userAttributes);
    // Margin right is to space out the market and the odds
    const customTextStyling = this.props.maskContent ? {fontFamily: 'Flow Rounded', fontWeight: 400, fontSize: 14, lineHeight: '20px', letterSpacing: -0.09, marginRight: 8} : {}
    return (
        <>
            <div className='trend-content-header'>
                <div className='trend-content-header-left'>
                    <img src={getTeamLogoPath(league, card.team.code)} alt={card.team.code}/>
                    <div className='trend-content-header-info'>
                        <div className='trend-content-header-info-subject'>
                            <p className='text-style-body-medium'>{subject}</p>
                            <p className='text-style-caption-uppercase-normal'>{opponent}</p>
                        </div>
                        <div className='trend-content-header-info-market'>
                            <p className='text-style-label-medium' style={customTextStyling}>{marketDescription}</p>
                            <p className='text-style-label-semibold' style={customTextStyling}>{odds}</p>
                        </div>
                    </div>
                </div>
                {this.buildBadgesStack(card.narratives)}
            </div>
            <div className='trend-content-insights'>
                {
                    card.insights.map((insight) => {
                        const description = this.props.maskContent ? insight.description.replaceAll(/[a-zA-Z]/g, 'a').replace(/[0-9]/g, '0') : insight.description;
                        const annotation = this.props.maskContent ? insight.annotation.replaceAll(/[a-zA-Z]/g, 'a').replace(/[0-9]/g, '0') : insight.annotation;
                        return (
                            <div key={insight.description} className='trend-content-insight-wrapper'>
                                <p className='text-style-label-normal' style={customTextStyling}>{`\u2022 ${description}`}</p>
                                {/* Resetting the margin here seemed like the easiest option */}
                                <p className='text-style-label-tabular-medium' style={Object.assign({}, customTextStyling, {marginRight: 0})}>{annotation}</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    );
  }
}

export default TrendContent;