import React from 'react';
import TagManager from 'react-gtm-module';
import { withCookies } from "react-cookie";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { getTheme, setTheme } from '../react-web-utils';
import { auth } from '../firebase.js';
import { API_HOST } from '../react-web-constants';
import { GlobalContextProvider } from './GlobalContext';
import { CROSS_YEAR_SOCCER_LEAGUES, IN_YEAR_SOCCER_LEAGUES, SOCCER_LEAGUES, SUPPORTED_LEAGUES, SUPPORTED_LEAGUES_METADATA } from '../linemate-react-common/src/constants';
import IndexedDBWrapper from '../database';

import NavigationBar from './NavigationBar';
import Footer from './Footer';
import Home from '../pages/Home';
import AuthenticationPage from '../authentication-page';

import './App.scss'
import NotFound from '../404';
import Playbook from '../playbook';
import BettingPage from '../betting-page';
import NFLLeaderboard from '../nfl-leaderboard';
import NHLLeaderboard from '../nhl-leaderboard';
import NBALeaderboard from '../nba-leaderboard';
import MLBLeaderboard from '../mlb-leaderboard';
import SoccerLeaderboard from '../soccer-leaderboard';
import AuthenticationActionPage from '../authentication-action-page';
import PasswordResetPage from '../password-reset-page';
import VerifyEmailPage from '../verify-email-page';
import Privacy from '../privacy';
import Terms from '../terms';
import ResponsibleGamblingPage from '../responsible-gambling-page';
import Support from '../support';
import AffiliateRedirect from '../affiliate-redirect';
import PurchaseSuccessPage from '../purchase-success-page';
import Profile from '../pages/Profile';
import Download from '../pages/Download';
import Trends from '../pages/Trends';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Pricing from '../pages/Pricing';
import Landing from '../pages/Landing';
import InYearSoccerLeaderboard from '../in-year-soccer-leaderboard';
import SoccerEuroLeaderboard from '../soccer-euro-leaderboard';
import Glossary from '../glossary';

function getPath() {
  // Trim leading and trailing forward slash 
  return window.location.pathname.replace(/^\//g, '').replace(/\/$/g, '');
}

function determineLeague() {
  var league = null;
  const path = getPath();
  if (path.length > 0) {
    const firstPart = path.split('/')[0].toLowerCase();
    if (firstPart in SUPPORTED_LEAGUES_METADATA) {
      league = firstPart;
    }
  }
  return league;
}

function determineTab() {
  const path = getPath();
  if (path.length > 0) {
    const parts = path.split('/');
    if (parts.includes('trends')) {
      return 'trends';
    }
    if (parts.length === 1 && parts[0].toLowerCase() in SUPPORTED_LEAGUES_METADATA) {
      return 'home';
    }
  }
  return null;
}

const HOUR_MS_MULTIPLIER = 3600000;

const DEFAULT_LOCATION = {
  country: {
    code: null,
    name: null
  },
  region: {
    code: null,
    name: null
  }
}

// TODO: IMPORTANT NOTE: clean up old local storage stuff we no longer use since moving to IndexedDB
class App extends React.Component {

  constructor(props) {
    super(props);

    this.updateUserAttributes = this.updateUserAttributes.bind(this);
    this.loadDatabase = this.loadDatabase.bind(this);
    this.loadConfiguration = this.loadConfiguration.bind(this);
    this.loadLocation = this.loadLocation.bind(this);

    // For the time being we'll do it that way to ignore localhost traffic
    // We could add an environment variable saying that we want to track GTM events even if it's localhost, ex: process.env.REACT_APP_TRACK_GTM_EVENTS=true
    // TODO: look into trigger configuration (creating a new trigger, not the one associated with a tag) where you can say only some pages, with condition on hostname does not contain <>
    if (!window.location.hostname.toLowerCase().includes('localhost')) {
      TagManager.initialize({gtmId: 'GTM-PW3JHDD'}) 
    }
    
    setTheme(getTheme());

    this.state = {
      userAttributes: {},
      userInitialized: false,
      authStateReady: false,
      databaseWrapper: null,
      configuration: null,
      location: DEFAULT_LOCATION
    };
  }

  componentDidMount() {
    auth.authStateReady().then(() => this.setState({authStateReady: true}))
    auth.onAuthStateChanged(this.updateUserAttributes);
    const wrapper = new IndexedDBWrapper("LinemateLocalStorageDb", 1, "default");
    wrapper.openDatabase().then(() => {
      this.setState({databaseWrapper: wrapper});
      this.loadDatabase(wrapper);
    })
  }

  updateUserAttributes(user) {
    if (user) {
      user.getIdToken().then((token) => { 
        const requestInfo = {
            headers: {'Authorization': "Bearer " + token}
        };
        fetch(`${API_HOST}/api/users/current`, requestInfo).then(data => data.json())
        .then(result => {
            var attributes = result
            if (attributes.code === 404) {
              attributes = {};
            }
            this.setState({
              userAttributes: attributes,
              userInitialized: true
            })
        })
        .catch(error => {
            this.setState({
              userAttributes: {},
              userInitialized: true
            });
            console.log("Error loading user profile: " + error);
        });
      });
    } else {
      this.setState({
        userAttributes: {},
        userInitialized: true
      });
    }
  }

  // TODO: check expiration
  loadDatabase(databaseWrapper) {
    const currentTime = (new Date()).getTime();

    databaseWrapper
    .getDataById("configuration")
    .then((data) => {
      if (!data || !data.value || (data.expiration && currentTime > data.expiration)) {
        this.loadConfiguration(databaseWrapper);
      } else {
        this.setState({configuration: data.value});
      }
    })
    .catch((error) => {
      console.log(error)
    })

    databaseWrapper
    .getDataById("location")
    .then((data) => {
      if (!data || !data.value || (data.expiration && currentTime > data.expiration)) {
        this.loadLocation(databaseWrapper);
      } else {
        this.setState({location: data.value});
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  loadConfiguration(databaseWrapper) {
    fetch(`${API_HOST}/configuration.json`)
    .then((data) => data.json())
    .then((data) => {
      this.setState({configuration: data})
      databaseWrapper.addData({version: 1, expiration: (new Date()).getTime() + (1 * HOUR_MS_MULTIPLIER), value: data}, "configuration")
    });
  }

  loadLocation(databaseWrapper) {
    fetch(`${API_HOST}/api/localization/v1/getCurrentLocation`)
    .then(data => data.status === 200 ? data.json() : DEFAULT_LOCATION)
    .then(response => {
      this.setState({location: response});
      databaseWrapper.addData({version: 1, expiration: (new Date()).getTime() + (1 * HOUR_MS_MULTIPLIER), value: response}, "location")
    })
    .catch(error => {
      console.log(`Unable to get location data ${error}`)
    })
  }

  render() {
    if (!(this.state.configuration && this.state.authStateReady && this.state.userInitialized)) {
      return <></>
    }
    return (
      <GlobalContextProvider value={
        {
          league: determineLeague(),
          user: auth.currentUser,
          userAttributes: this.state.userAttributes,
          configuration: this.state.configuration,
          location: this.state.location,
          theme: getTheme(),
          tab: determineTab()
        }
      }>
        <React.StrictMode>
          <Router>
            <Switch>
                {/* This wrapper is needed to always have the footer pushed down */}
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', minHeight: '100vh'}}>
                  {/* Account related pages */}
                  <Route exact path="/authentication/action">
                    <AuthenticationActionPage userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser} />
                  </Route>

                  <Route exact path="/authentication">
                    <AuthenticationPage userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser} />
                  </Route> 

                  <Route exact path="/login">
                    <Login/>
                  </Route> 

                  <Route exact path="/signup">
                    <Signup/>
                  </Route> 

                  <Route exact path="/password-reset">
                    <PasswordResetPage userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser} />
                  </Route> 

                  <Route exact path="/verify-email">
                    <VerifyEmailPage/>
                  </Route> 

                  <Route exact path="/profile">
                    <NavigationBar/>
                    <Profile/>
                    <Footer/>
                  </Route>

                  <Route exact path="/pricing">
                    <NavigationBar/>
                    <Pricing/>
                    <Footer/>
                  </Route>

                  <Route exact path="/glossary">
                    <NavigationBar/>
                    <Glossary />
                    <Footer/>
                  </Route>

                  <Route exact path="/">
                    <NavigationBar/>
                    <Landing/>
                    <Footer/>
                  </Route>

                  {/* League-based pages */}
                  {
                    SUPPORTED_LEAGUES.map((league) => 
                      <React.Fragment key={league}>
                        {/* Old gameday redirect for backwards compatibility in case people have it saved as an easier transition. This corresponds more or less to the new home */}
                        <Route exact path={`/${league}/gameday`}>
                          <Redirect to={{pathname: `/${league}`}}/>
                        </Route>

                        {/* Home page */}
                        <Route exact path={`/${league}`}>
                          <NavigationBar/>
                          <Home/>
                          <Footer/>
                        </Route>

                        {/* Trends page */}
                        <Route key={"/" + league + "/trends"} exact path={"/" + league + "/trends"}>
                          <NavigationBar/>
                          <Trends/>
                          <Footer/>
                        </Route>

                        {/* Playbook with game id (old syntax) */}
                        <Route exact path={`/${league}/playbook/:gameID`}>
                          <NavigationBar/>
                          <Playbook activeLeague={league} userRegionCode={this.state.location.region.code} userRegionName={this.state.location.region.name} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Playbook without game id (old syntax) */}
                        <Route exact path={`/${league}/playbook`}>
                          <NavigationBar/>
                          <Playbook activeLeague={league} userRegionCode={this.state.location.region.code} userRegionName={this.state.location.region.name} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Playbook with game id (new syntax) */}
                        <Route exact path={`/${league}/summary/:gameID`}>
                          <NavigationBar/>
                          <Playbook activeLeague={league} userRegionCode={this.state.location.region.code} userRegionName={this.state.location.region.name} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Playbook without game id (new syntax) */}
                        <Route exact path={`/${league}/summary`}>
                          <NavigationBar/>
                          <Playbook activeLeague={league} userRegionCode={this.state.location.region.code} userRegionName={this.state.location.region.name} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Props with game id (old syntax)*/}
                        <Route exact path={`/${league}/betting/:gameID`}>
                          <NavigationBar/>
                          <BettingPage activeLeague={league} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Props without game id (old syntax) */}
                        <Route exact path={`/${league}/betting`}>
                          <NavigationBar/>
                          <BettingPage activeLeague={league} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Props with game id (new syntax)*/}
                        <Route exact path={`/${league}/props/:gameID`}>
                          <NavigationBar/>
                          <BettingPage activeLeague={league} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>

                        {/* Props without game id (new syntax) */}
                        <Route exact path={`/${league}/props`}>
                          <NavigationBar/>
                          <BettingPage activeLeague={league} userAttributes={this.state.userAttributes} userInitialized={this.state.userInitialized} user={auth.currentUser}/>
                          <Footer/>
                        </Route>
                      </React.Fragment>
                    )
                  }

                  {/* Leaderboards */}
                  {/* TODO: need a better implementation than this, this one is just to be able to get it out of the door quicker, at the expense of technical debt */}
                  <Route exact path="/nfl/leaderboard">
                    <NavigationBar/>
                    <NFLLeaderboard activeLeague="nfl"/>
                    <Footer/>
                  </Route>
                  <Route exact path="/nhl/leaderboard">
                    <NavigationBar/>
                    <NHLLeaderboard activeLeague="nhl"/>
                    <Footer/>
                  </Route>
                  <Route exact path="/nba/leaderboard">
                    <NavigationBar/>
                    <NBALeaderboard activeLeague="nba"/>
                    <Footer/>
                  </Route>
                  <Route exact path="/wnba/leaderboard">
                    <NavigationBar/>
                    <NBALeaderboard activeLeague="wnba"/>
                    <Footer/>
                  </Route>
                  <Route exact path="/mlb/leaderboard">
                    <NavigationBar/>
                    <MLBLeaderboard activeLeague="mlb"/>
                    <Footer/>
                  </Route>
                  {
                    Object.keys(CROSS_YEAR_SOCCER_LEAGUES).map((league) =>
                      <Route key={`/${league}/leaderboard`} exact path={`/${league}/leaderboard`}>
                        <NavigationBar/>
                        <SoccerLeaderboard activeLeague={league}/>
                        <Footer/>
                      </Route>
                    )
                  }
                  {
                    Object.keys(IN_YEAR_SOCCER_LEAGUES).map((league) =>
                      <Route key={`/${league}/leaderboard`} exact path={`/${league}/leaderboard`}>
                        <NavigationBar/>
                        <InYearSoccerLeaderboard activeLeague={league}/>
                        <Footer/>
                      </Route>
                    )
                  }
                  {
                    <Route key={`/euro/leaderboard`} exact path={`/euro/leaderboard`}>
                      <NavigationBar/>
                      <SoccerEuroLeaderboard activeLeague={"euro"}/>
                      <Footer/>
                    </Route>
                  }
                  {/* Other pages */}
                  <Route exact path="/privacy">
                    <NavigationBar/>
                    <Privacy/>
                    <Footer/>
                  </Route>

                  <Route exact path="/terms">
                    <NavigationBar/>
                    <Terms/>
                    <Footer/>
                  </Route>

                  <Route exact path="/responsible-gambling">
                    <NavigationBar/>
                    <ResponsibleGamblingPage/>
                    <Footer/>
                  </Route>

                  {/* TODO: test exact path with query params, should work since download works */}
                  <Route exact path="/affiliate">
                    <AffiliateRedirect/>
                  </Route>

                  <Route exact path="/support">
                    <NavigationBar/>
                    <Support/>
                    <Footer/>
                  </Route>

                  <Route exact path="/success">
                    <PurchaseSuccessPage/>
                  </Route>

                  <Route exact path="/download">
                    <NavigationBar/>
                    <Download/>
                    <Footer/>
                  </Route>

                  {/* Fallback */}
                  {/* <Route path="*">
                      <NavigationBar/>
                      <NotFound/>
                      <Footer/>
                  </Route> */}
                </div>
            </Switch>
          </Router>
        </React.StrictMode>
      </GlobalContextProvider>
    );
  }
}

export default withCookies(App);