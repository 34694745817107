import React from 'react';

import './Pricing.scss'
import '../components/App.scss'

import GlobalContext from '../components/GlobalContext';
import Button from '../components/Button';
import { generateDummyPlay, isDictEmpty } from '../linemate-react-common/src/util';
import Picker from '../picker';
import TrendContent from '../components/TrendContent';
import { getStripeCheckoutSessionUrl, openStripeBillingPortal } from '../react-web-utils';
import { API_HOST } from '../react-web-constants';

const plans = [
    {
        title: 'Free',
        price: 0,
        billing: null,
        features: [
            'Access to 1 trend per play'
        ],
        action: (user, userAttributes) => {
            if (isDictEmpty(userAttributes) || userAttributes.accessLevel === 1) {
                return {
                    text: 'Current plan',
                    enabled: false,
                    onClick: null,
                    type: 'secondary'
                }
            }
            return {
                text: 'Change plan',
                enabled: !userAttributes.purchasePlatform || userAttributes.purchasePlatform === "web",
                onClick: () => user.getIdToken().then((token) => openStripeBillingPortal(API_HOST, token)),
                type: 'secondary'
            }
        }
    },
    {
        title: 'Linemate+',
        price: 9.99,
        billing: {
            short: 'month',
            long: 'monthly'
        },
        features: [
            'Access to 2+ trends per play',
            'Find top trending plays everywhere',
            'Available on the web and app'
        ],
        action: (user, userAttributes) => {
            if (isDictEmpty(userAttributes)) {
                return {
                    text: 'Start free trial',
                    enabled: true,
                    // onClick: () => window.location.href = '/signup?origin=https://buy.stripe.com/eVadUi2ppgNXbv2bIK',
                    onClick: () => window.location.href = 'signup?origin=/pricing',
                    type: 'secondary'
                }
            }
            if (userAttributes.accessLevel === 3) {
                return {
                    text: 'Manage',
                    enabled: !userAttributes.purchasePlatform || userAttributes.purchasePlatform === "web",
                    onClick: () => user.getIdToken().then((token) => openStripeBillingPortal(API_HOST, token)),
                    type: 'secondary'
                }
            }
            return {
                text: 'Upgrade',
                enabled: true,
                // onClick: () => window.open(`https://buy.stripe.com/eVadUi2ppgNXbv2bIK${userAttributes.email ? `?prefilled_email=${userAttributes.email}` : ""}`, '_blank').focus(),
                onClick: () => {
                    user.getIdToken().then((token) => {
                        getStripeCheckoutSessionUrl(API_HOST, token, '1PI0fdFA68iq99Lmw7GwKjG4', null, (checkoutSessionUrl) => {
                            window.location.href = checkoutSessionUrl
                        })
                    })
                },
                type: 'secondary'
            }
        }
    },
    {
        title: 'Linemate+',
        price: 99.99,
        discountPercentage: 40,
        discountMessage: 'SUMMER DISCOUNT',
        billing: {
            short: 'year',
            long: 'annually'
        },
        features: [
            'Access to 2+ trends per play',
            'Find top trending plays everywhere',
            'Available on the web and app'
        ],
        highlighted: true,
        action: (user, userAttributes) => {
            if (isDictEmpty(userAttributes)) {
                return {
                    text: 'Start free trial',
                    enabled: true,
                    // onClick: () => window.location.href = '/signup?origin=https://buy.stripe.com/5kA4jI4xx69j2Yw4gk',
                    onClick: () => window.location.href = 'signup?origin=/pricing',
                    type: 'primary'
                }
            }
            if (userAttributes.accessLevel === 3) {
                return {
                    text: 'Manage',
                    enabled: !userAttributes.purchasePlatform || userAttributes.purchasePlatform === "web",
                    onClick: () => user.getIdToken().then((token) => openStripeBillingPortal(API_HOST, token)),
                    type: 'secondary'
                }
            }
            return {
                text: 'Upgrade',
                enabled: true,
                // onClick: () => window.open(`https://buy.stripe.com/5kA4jI4xx69j2Yw4gk${userAttributes.email ? `?prefilled_email=${userAttributes.email}` : ""}`, '_blank').focus(),
                onClick: () => {
                    user.getIdToken().then((token) => {
                        getStripeCheckoutSessionUrl(API_HOST, token, '1PL4fXFA68iq99Lmo5lRvopb', null, (checkoutSessionUrl) => {
                            window.location.href = checkoutSessionUrl
                        })
                    })
                },
                type: 'primary'
            }
        }
    }
]

const cardInsights = [
    {description: 'Hit in 2 of his last 2 games', annotation: '100%'},
    {description: 'Hit in 3 of his last 4 games vs KC', annotation: '75%'},
    {description: 'Hit in 13 of his last 14 away games', annotation: '93%'},
    {description: 'KC ranks 26th in Pass TD Against', annotation: '26th'}
]

const cardNarratives = ["RECENT_FORM", "HEAD_TO_HEAD", "AWAY_SPLIT", "OPPONENT"]

// TODO: origin
class Pricing extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.planPickerOptions = {
            free: 'Free',
            paid: 'Linemate+'
        }

        this.state = {
            planPickerSelection: Object.keys(this.planPickerOptions)[1]
        }
    }

    componentDidMount() {
        document.title = 'Pricing - Linemate';
    }

    // TODO: add the carousel of cards that we have on landing page
    render() {
        return (
            <div className='pricing-page-container'>
                <div className='pricing-page-intro'>
                    <div className='pricing-page-intro-header'>
                        <p className='text-style-label-semibold color-fig-accent'>SAVE TIME, PLAY SMARTER</p>
                        <p className='color-fig-default'>Pricing</p>
                        <p className='text-style-h-3-normal color-fig-subtle'>Upgrade to gain access to more trends and save time on your research. Start your 7-day free trial of Linemate+.</p>
                    </div>
                    <div className='pricing-page-intro-plans'>
                    {
                        plans.map((plan) =>{
                            const planAction = plan.action(this.context.user, this.context.userAttributes);
                            return (
                                <div className='pricing-page-intro-plans-plan' style={{'--plan-options-count': plans.length}} data-highlighted={plan.highlighted}>
                                    <div className='pricing-page-intro-plans-plan-title'>
                                        <p className='text-style-h-3-medium color-fig-subtle'>{plan.title}</p>
                                        <div>
                                            {plan.discountMessage && (<p className='text-style-caption-medium color-fig-accent'>{plan.discountMessage}</p>)}
                                            {
                                                plan.discountPercentage && (
                                                    <div className='pricing-page-intro-plans-plan-pricing-discounted-badge'>
                                                        <p className='text-style-caption-semibold'>-{plan.discountPercentage}%</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-pricing'>
                                        {
                                            !plan.discountPercentage && (
                                                <>
                                                    <p className='text-style-h-3-semibold color-fig-default'>USD ${plan.price}</p>
                                                    {plan.billing && (<p className='text-style-h-3-semibold color-fig-default'>/{plan.billing.short}</p>)}
                                                </>
                                            )
                                        }
                                        {
                                            plan.discountPercentage && (
                                                <div className='pricing-page-intro-plans-plan-pricing-discounted'>
                                                    <p className='text-style-h-3-semibold color-fig-default'>USD <s>${plan.price}</s></p>
                                                    <p className='text-style-h-3-semibold color-fig-default'>${(plan.price * ((100-plan.discountPercentage)/100)).toFixed(2)}/{plan.billing.short}</p>

                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-billing-term'>
                                    {
                                        plan.billing && (
                                            <>
                                                <img src='assets/currency-exchange.svg' alt="currency-exchange"/>
                                                <p className='text-style-label-normal color-fig-subtle'>Billed {plan.billing.long}</p>
                                            </>
                                        )
                                    }
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-divider'></div>
                                    <div className='pricing-page-intro-plans-plan-features'>
                                    {
                                        plan.features.map((feature) =>
                                            <div className='pricing-page-intro-plans-plan-features-feature'>
                                                <img src='assets/check.svg' alt="check"/>
                                                <p className='text-style-label-normal color-fig-default'>{feature}</p>
                                            </div>
                                        )
                                    }
                                    </div>
                                    <div className='pricing-page-intro-plans-plan-button-wrapper'>
                                        <Button text={planAction.text} typography="xl" type={planAction.type} enabled={planAction.enabled} onClick={planAction.onClick}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    {
                        this.context.userAttributes.accessLevel === 3 &&
                        this.context.userAttributes.purchasePlatform && 
                        this.context.userAttributes.purchasePlatform === "ios" && (
                            <p className='text-style-body-semibold color-fig-default'>To manage your subscription please visit the Subscriptions section of your Apple account associated with this purchase</p>
                        )
                    }
                    {
                        this.context.userAttributes.accessLevel === 3 &&
                        this.context.userAttributes.purchasePlatform && 
                        this.context.userAttributes.purchasePlatform === "android" && (
                            <p className='text-style-body-semibold color-fig-default'>To manage your subscription please visit the Subscriptions section of your Google Play Store account associated with this purchase</p>
                        )
                    }
                </div>
                <div className='pricing-page-upgrade-showcase'>
                    <p className='text-style-h-1-semibold color-fig-default'>Upgrade your trends with Linemate+</p>
                    <div className='pricing-page-upgrade-showcase-picker-wrapper'>
                        <Picker options={this.planPickerOptions} selection={this.state.planPickerSelection} selectionHandlerFn={(event) => this.setState({planPickerSelection: event.currentTarget.dataset.selection})} fullWidthThreshold={768}/>
                    </div>
                    <p className='text-style-label-normal color-fig-subtle'>Example of a Linemate+ trending play</p>
                    <div className='pricing-page-upgrade-showcase-trend-wrapper'>
                        <TrendContent 
                            league="nfl"
                            card={generateDummyPlay(
                                'player', 
                                false, 
                                this.state.planPickerSelection === 'paid' ? cardInsights : cardInsights.slice(0, 1), 
                                'over', 
                                "PASSING_TOUCHDOWNS", 
                                1.5, 
                                {american: -105,decimal: 1.95,fractional: "19/20"}, 
                                this.state.planPickerSelection === 'paid' ? cardNarratives : cardNarratives.slice(0, 1), 
                                {code: 'BUF'}, 
                                {code: 'KC'}, 
                                {firstName: 'Josh',lastName: 'Allen'}
                            )}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default Pricing;