import React from 'react';
import $ from 'jquery';
import { ResponsivePie } from '@nivo/pie';
import Dropdown from './dropdown.js';
import Filters from './filters.js';
import NoResultsFrame from './no-results-frame.js';
import {TEAM_CITY_TO_CODE, TEAM_CODE_TO_CITY, COLOR_CODES, LEAGUE_CURRENT_SEASON, BET_TYPE_FILTER, GAME_TOTAL_MARKET_NAME, SPREAD_MARKET_NAME, getPlayerPositionalBetTypesForScreener} from './linemate-react-common/src/constants.js';
import {API_HOST, RESPONSIVE_BAR_THEME} from './react-web-constants.js';
import {buildOpponentList, isDictEmpty, getDictionaryValue, addOrdinalNumberSuffix, parseDateFromString, getPlayerName,
    isHomeGame, getOpposingTeamCode, shouldBlockUserAccess, getCombinedHitRecords, buildStandingsRecordString, getCombinedStandings, getPlayerId, getTeamDisplayName, isSoccer, 
    capitalizeFirstLetter} from './linemate-react-common/src/util.js';
import {buildGraphData, getPlayerImage, getTeamLogoPath} from './react-web-utils.js';

// import 'bootstrap/dist/css/bootstrap.min.css';
import './generic.css';
import './fonts.css';
import './screener.css';
import './buttons.css';
import './screener-leaderboard.css';
import { ResponsiveBar } from '@nivo/bar';
import HitRateBox from './hit-rate-box.js';
import { buildTrendsData } from './linemate-react-common/src/betting-utils.js';
import StatCard from './stat-card.js';
import HtmlHeaders from './html-headers.js';
import InputSelection from './components/InputSelection.jsx';
import Button from './components/Button.jsx';

let pos = { top: 0, left: 0, x: 0, y: 0 };

// Can't be bothered to add and pass refs around, or to make it scroll smoothly/overtime
function getHorizontalTotalSection(statsCount, className, teamData, statsTemplate, type, activeLeague, teamCode) {
    var rows = 1;
    // if (statsCount > 8) {
    //     rows = 2;
    // }
    return (
        <div className={className}>
            <p className="font size-20 weight-600" style={{display: 'block', width: '100%', textAlign: 'left'}}>Totals</p>
            {/* Could probably use the event target here for the onscroll but whatever */}
            <div style={{height: '126px', whiteSpace: 'nowrap', overflowX: 'scroll'}} 
                onScroll={() => {
                    const element = document.querySelector(`.${className} > div`);
                    if (element.scrollLeft === 0) {
                        // Remove left indicator
                        document.querySelector(`.screener-total-left-button`).style.display = 'none';
                    } else {
                        // Put back left indicator
                        document.querySelector(`.screener-total-left-button`).style.display = 'block';
                    }
                    if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
                        // Remove right indicator
                        document.querySelector(`.screener-total-right-button`).style.display = 'none';
                    } else {
                        // Put back right indicator
                        document.querySelector(`.screener-total-right-button`).style.display = 'block';
                    }
                }}
            >
                {
                    Object.keys(statsTemplate).slice(0, statsCount).map((key, index) => (
                        <div key={`${key}-total`} className="screener-total-stat-card" 
                            style={{
                                // width: `calc((100% - (${(statsCount / rows) - 1} * 16px)) / ${statsCount / rows})`, 
                                width: '124px',
                                marginLeft: `${index % (statsCount / rows) === 0 ? 0 : 8}px`, 
                                marginRight: `${index % (statsCount / rows) === (statsCount / rows) - 1 ? 0 : 8}px`
                            }}
                        >
                            <div className="screener-total-stat-card-header" style={{background: COLOR_CODES[activeLeague][teamCode].primary}}>
                                <p className="font size-12 spaced full-width display-block align-center">{key}</p>
                            </div>
                            <p className="font size-16 weight-600 spaced full-width display-block align-center" style={{marginTop: '16px'}}>{getDictionaryValue(teamData, statsTemplate[key].replaceAll("%quantifier%", "cumulativeStats").replaceAll("%type%", type))}</p>
                        </div>
                    ))
                }
            </div>
            <div className="screener-total-left-button clickable unselectable" style={{display: 'none'}} onClick={() => {document.querySelector(`.${className} > div`).scrollBy(-140, 0)}}>
                <img src="assets/leading-icon-grey.svg" height={20} width={20} alt=""/>
            </div>
            <div className="screener-total-right-button clickable unselectable" style={statsCount <= 8 ? {display: 'none'} : {}} onClick={() => {document.querySelector(`.${className} > div`).scrollBy(140, 0)}}>
                <img src="assets/trailing-icon-grey.svg" height={20} width={20} alt=""/>
            </div>
        </div>
    )
}

// These width settings seem to work relatively well for both mobile and desktop, will leave for now (42.5, margin 5) middle 15%
// Both flex and absolute techniques work relatively well, though the mobile would be better a bit more on the outside, and the desktop a bit more inside
function getCommonMobilePerGameScoringListItem(key, teamData, leagueAverages, statsTemplate, type) {
    return (
        <div key={key} className="screener-comparative-tbl-row text-base" style={{position: 'relative'}}>
            <div className="font size-16 dark" style={{width: 'auto', marginLeft: '0', position: 'absolute', left: '20%', top: '50%', transform: 'translate(-20%, -50%)'}}>{getDictionaryValue(teamData, statsTemplate[key].replaceAll("%quantifier%", "averageStats").replaceAll("%type%", type))}</div>
            <div className="font size-12 spaced grey" style={{width: 'auto', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>{key}</div>
            <div className="font size-16 dark" style={{width: 'auto', marginRight: '0', position: 'absolute', right: '20%', top: '50%', transform: 'translate(20%, -50%)'}}>{getDictionaryValue(leagueAverages, statsTemplate[key].replaceAll("%quantifier%", "averageStats").replaceAll("%type%", type))}</div>
        </div>
    )
}

//https://htmldom.dev/drag-to-scroll/
function mouseDownHandler(e) {
    const ele = document.getElementById('screener-table-responsive');
    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';

    pos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
}

function  mouseMoveHandler(e) {
    const ele = document.getElementById('screener-table-responsive');
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
}

function mouseUpHandler() {
    const ele = document.getElementById('screener-table-responsive');
    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
}

const betTypeFilter = {
    team: () => {
        var teamFilters = {}
        
        teamFilters[BET_TYPE_FILTER] = {
            options: Object.fromEntries([GAME_TOTAL_MARKET_NAME, SPREAD_MARKET_NAME].map(x => [x, x])),
            selectedValue: GAME_TOTAL_MARKET_NAME,
            isActive: false
        }
        return teamFilters;
    },
    player: (league, playerPosition) => {
        var playerFilters = {}
        const betTypes = getPlayerPositionalBetTypesForScreener[league](playerPosition);
        playerFilters[BET_TYPE_FILTER] = {
            options: Object.fromEntries(betTypes.map(x => [x, x])),
            selectedValue: betTypes[0],
            isActive: false
        }
        return playerFilters;
    }
}

function getRecordCard(title, record, color, device) {
    return (
        <StatCard title={title} value={record} color={color} widthDivider={device === "desktop" ?  4 : null} width={device === "mobile" ? 124 : null}/>
    )
}

class Screener extends React.Component {
  constructor(props) {
    super(props);

    // Some styling
    this.largeTypeFilterStyling = {
        position: 'absolute', 
        right: '0px', 
        width: '160px', 
        top: '2px'
        // padding: '0'
    };
    this.smallTypeFilterStyling = {
        position: 'relative',
        right: '0px',
        width: '100%',
        top: '2px',
        marginTop: '26px',
        height: '36px',
        paddingTop: '4px'
    };
    const windowWidth = window.innerWidth;

    const searchParams = new URLSearchParams(window.location.search);

    var tab = "team";
    if (this.props.tab) {
        tab = this.props.tab;
    } else if (searchParams.has('tab') && ["team", "player"].includes(searchParams.get('tab').toLowerCase())) {
        tab = searchParams.get('tab').toLowerCase();
    }

    this.teamCode = TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()][Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague])[0]];
    if (this.props.teamCode) {
        this.teamCode = this.props.teamCode;
    } else if (searchParams.has('team') && searchParams.get('team').toUpperCase() in TEAM_CODE_TO_CITY[this.props.activeLeague]) {
        this.teamCode = searchParams.get('team').toUpperCase();
    }

    this.playerID = null;
    if (this.props.playerID) {
        this.playerID = this.props.playerID;
    } else if (searchParams.has('player')) {
        this.playerID = searchParams.get('player');
    }
    
    this.state = {
        // All the pieces queried from the API required by sub-components will be stored under sections in the 'data' field
        data: {
            teamData: {},
            leagueAverages: {},
            recentForm: [],
            upcomingForm: [],
            gamelog: []
        },
        bettingLineValue: 0,
        bettingTrends: {
            hits: 0,
            push: 0,
            resultAverage: 0,
            resultHomeAverage: 0,
            resultAwayAverage: 0
        },
        hitRecord: tab === "team" ? getCombinedHitRecords([]) : null,
        favouriteStandings: tab === "team" ? getCombinedStandings(this.props.activeLeague, []) : null,
        underdogStandings: tab === "team" ? getCombinedStandings(this.props.activeLeague, []) : null,
        // Leaving the position parameter as empty string should give us some default. We don't have the player data yet
        betTypes: tab === "team" ? betTypeFilter[tab]() : betTypeFilter[tab](this.props.activeLeague, ""),
        bettingGraphData: [],
        gamelogHash: "",

        teamStanding: {},
        players: [],

        tab: tab,
        statQuantifier: "cumulativeStats",

        playerFilterActive: false,
        playerFilterSelection: "",
        playerFilterOptions: [],

        teamFilterActive: false,
        teamFilterSelection: TEAM_CODE_TO_CITY[this.props.activeLeague][this.teamCode],
        teamFilterAbbreviation: this.teamCode,

        typeFilterActive: false,
        typeFilterSelection: "OFFENSIVE",
        typeFilterOptions: Object.fromEntries(["OFFENSIVE", "DEFENSIVE"].map(x => [x, x])),

        // Scoring selections styling
        typeFilterStyling: windowWidth > 768 ? this.largeTypeFilterStyling : this.smallTypeFilterStyling,

        // State to know if the filters should stick to the top
        filterSticky: false,

        filterDrawerOpen: false
    }

    this.positionFilterOptions = {
        "QB": ["PASSING", "RUSHING"],
        "RB": ["RUSHING", "RECEIVING"],
        "WR": ["RECEIVING", "RUSHING"],
        "TE": ["RECEIVING", "RUSHING"],
        "K": ["KICKING"]
    };

    this.loadStaticData = this.loadStaticData.bind(this);
    this.addClickableElement = this.addClickableElement.bind(this);
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
    this.generateFilterStyling = this.generateFilterStyling.bind(this);
    this.showFilterModal = this.showFilterModal.bind(this);

    this.scrollFunction = this.scrollFunction.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.selectStatQuantifier = this.selectStatQuantifier.bind(this);

    this.playerFilterClicked = this.playerFilterClicked.bind(this);
    this.playerFilterOptionSelected = this.playerFilterOptionSelected.bind(this);
    this.teamFilterClicked = this.teamFilterClicked.bind(this);
    this.teamFilterOptionSelected = this.teamFilterOptionSelected.bind(this);
    this.playerFilterContainerStyling = {};
    this.playerHeaderFilterContainerStyling = {};
    this.playerMobileFilterContainerStyling = {};
    this.teamFilterContainerStyling = {};
    this.teamHeaderFilterContainerStyling = {};
    this.teamMobileFilterContainerStyling = {};

    this.typeFilterClicked = this.typeFilterClicked.bind(this);
    this.typeFilterOptionSelected = this.typeFilterOptionSelected.bind(this);
    this.typeFilterContainerStyling = {} ;

    this.getUpdatedTypeFilter = this.getUpdatedTypeFilter.bind(this);
    this.dataUpdateRequested = this.dataUpdateRequested.bind(this);

    this.getLeagueFilters = this.getLeagueFilters.bind(this);
    this.getLeagueScoring = this.getLeagueScoring.bind(this);
    this.getLeagueForm = this.getLeagueForm.bind(this);
    this.getLeaguegamelog = this.getLeaguegamelog.bind(this);

    this.betTypeUpdated = this.betTypeUpdated.bind(this);
    this.lineInputChanged = this.lineInputChanged.bind(this);
    this.updateLine = this.updateLine.bind(this);

    this.tableScrolled = this.tableScrolled.bind(this);
    this.updateGradientStyling = this.updateGradientStyling.bind(this);
    this.tableRef = React.createRef();
    this.tableGradientRef = React.createRef();

    this.filterRefs = {};
    this.clickableElements = [];

    this.isDataRenderPending = false;
    // Used to determine if we should show the icons for league average on the RHS or if it should be the team/player seasonal average
    this.displayLeagueAverage = true;
    this.setDisplayLeagueAverage = this.setDisplayLeagueAverage.bind(this);

    // Adding the filters as clickable elements with refs
    for (var key of ["player", "playerHeader", "playerMobile", "team", "teamMobile", "teamHeader", "type"]) {
        if (!(key in this.filterRefs)) {
            this.filterRefs[key] = React.createRef();
            this.clickableElements.push(this.filterRefs[key]);
        }
    }
  }

  componentDidMount() {
    // document.title = 'Scouting - Linemate';

    $('.screener-minimized-header-desktop').hide();
    // TODO: setup scrollspy
    // $(".screener-mobile-nav").scrollspy({ offset: -110 }); 
    $('.screener-minimized-content-header').hide();
    $(".screener-mobile-nav").hide();
    // When the user scrolls down 50px from the top of the document, resize the header's font size
    window.addEventListener('scroll', this.scrollFunction);
    window.addEventListener('mousedown', this.handleGeneralClick);
    // window.addEventListener('resize', this.windowResized);
    this.tableScrolled();
    this.generateFilterStyling();

    this.loadStaticData(true);
  }

  componentDidUpdate(prevProps, prevState) {
      // A way to find out diff in what changed from props/state
    // Object.entries(this.props).forEach(([key, val]) =>
    //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    // );
    // if (this.state) {
    //     Object.entries(this.state).forEach(([key, val]) =>
    //     prevState[key] !== val && console.log(`State '${key}' changed`)
    //     );
    // }
    this.generateFilterStyling();
    this.tableScrolled();
  }

  updateGradientStyling() {
    // Need to re-calculate the height and top position of the gradient after the table's been updated with data
    // if (this.tableRef.current && this.tableGradientRef.current) {
    //     var tableHeight = this.tableRef.current.offsetHeight;
    //     var tableTopOffset = this.tableRef.current.offsetTop;
    //     this.tableGradientRef.current.style.height = `${tableHeight}px`;
    //     this.tableGradientRef.current.style.top = `${tableTopOffset}px`;
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleGeneralClick);
    window.removeEventListener('resize', this.windowResized);
    window.removeEventListener('scroll', this.scrollFunction);
  }

  loadStaticData(initialLoad) {
    this.isDataRenderPending = true;

    var promises = [fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/${TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()][this.state.teamFilterSelection]}`).then(data => {return data.json();})]
    if (this.playerID) {
        promises.push(fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/bySRGUID/${this.playerID}`).then(data => {return data.json();}))
    }

    // Mostly trying to re-use the same state as before but without a list of players
    Promise.all(promises)
      .then(result => {
        if (this.playerID) {
            const player = result[1];
            var playerFilterSelection = getPlayerName(player).toUpperCase();
            var players = {};
            players[playerFilterSelection] = player;

            var tempState = this.getUpdatedTypeFilter(players, player);
            const mergedState = Object.assign({}, tempState, {
                teamStanding: result[0],
                players: players,
                playerFilterOptions: [playerFilterSelection],
                playerFilterSelection: playerFilterSelection,
                betTypes: betTypeFilter.player(this.props.activeLeague, player.info.position)
            });
            this.setState(mergedState, this.forceUpdate);
        } else {
            this.setState({teamStanding: result[0]}, this.forceUpdate)
        }
      })
      .catch(error => {
        console.log("Error loading static screener data: " + error);
      });

    // var promises = [
    //     fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/teams/${TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()][this.state.teamFilterSelection]}`).then(data => {return data.json();}),
    //     // Preemptively loading the players
    //     fetch(`${API_HOST}/api/${this.props.activeLeague}/v1/players/forTeam?teamCode=${TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()][this.state.teamFilterSelection]}`).then(data => {return data.json();})
    // ];
    // Old promises pre-redesign
    // Promise.all(promises)
    //   .then(result => {
    //     const playersList = result[1];
    //     var players = {};
    //     playersList.forEach((player, index) => {
    //         players[getPlayerName(player).toUpperCase()] = player;
    //     });
    //     const playerNames = Object.keys(players);

    //     var playerFilterSelection = playerNames[0];
    //     if (initialLoad && this.playerID !== -1) {
    //         playersList.forEach((player, index) => {
    //             if (player.SRGUID === this.playerID) {
    //                 playerFilterSelection = getPlayerName(player).toUpperCase();
    //             }
    //         });
    //     }

    //     var tempState = this.getUpdatedTypeFilter(players, players[playerFilterSelection]);
    //     const mergedState = Object.assign({}, tempState, {
    //         teamStanding: result[0],
    //         players: players,
    //         playerFilterOptions: playerNames,
    //         playerFilterSelection: playerFilterSelection
    //     });
    //     if (this.state.tab === "player") {
    //         if (!isDictEmpty(players)) {
    //             mergedState.betTypes = betTypeFilter.player(this.props.activeLeague, players[playerFilterSelection].info.position);
    //         } else {
    //             mergedState.betTypes = betTypeFilter.player(this.props.activeLeague, "");
    //         }
    //     }
    //     this.setState(mergedState,this.forceUpdate);
    //   })
    //   .catch(error => {
    //     console.log("Error loading static screener data: " + error);
    //   });
  }

  setDisplayLeagueAverage(value) {
      this.displayLeagueAverage = value;
  }

  addClickableElement(element) {
    this.clickableElements.push(element);
  }

  handleGeneralClick(event) {
    const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
    var foundTarget = false;
    for(const element of this.clickableElements) {
      if (element.current && element.current.contains(event.target)) {
        foundTarget = true;
        break;
      }
    }
    if (!foundTarget && (this.state.playerFilterActive || this.state.teamFilterActive || this.state.typeFilterActive) && !scrollbarClicked) {
        this.setState({
            playerFilterActive: false,
            teamFilterActive: false,
            typeFilterActive: false
        });
    }
  }

  generateFilterStyling() {
    if (this.filterRefs["player"].current) {
        this.playerFilterContainerStyling = {
            left: `${this.filterRefs["player"].current.offsetLeft}px`,
            top: `${this.filterRefs["player"].current.offsetTop + this.filterRefs["player"].current.offsetHeight}px`,
            width: `${this.filterRefs["player"].current.offsetWidth}px`
        };
    }
    if (this.filterRefs["playerHeader"].current) {
        this.playerHeaderFilterContainerStyling = {
            left: `${this.filterRefs["playerHeader"].current.offsetLeft}px`,
            top: `${this.filterRefs["playerHeader"].current.offsetTop + this.filterRefs["playerHeader"].current.offsetHeight}px`,
            width: `${this.filterRefs["playerHeader"].current.offsetWidth}px`
        };
    }
    if (this.filterRefs["playerMobile"].current) {
        this.playerMobileFilterContainerStyling = {
            left: `${this.filterRefs["playerMobile"].current.offsetLeft}px`,
            top: `${this.filterRefs["playerMobile"].current.offsetTop + this.filterRefs["playerMobile"].current.offsetHeight}px`,
            width: `${this.filterRefs["playerMobile"].current.offsetWidth}px`
        };
    }
    if (this.filterRefs["team"].current) {
        this.teamFilterContainerStyling = {
            left: `${this.filterRefs["team"].current.offsetLeft}px`,
            top: `${this.filterRefs["team"].current.offsetTop + this.filterRefs["team"].current.offsetHeight}px`,
            width: `${this.filterRefs["team"].current.offsetWidth}px`
        };
    }
    if (this.filterRefs["teamMobile"].current) {
        this.teamMobileFilterContainerStyling = {
            left: `${this.filterRefs["teamMobile"].current.offsetLeft}px`,
            top: `${this.filterRefs["teamMobile"].current.offsetTop + this.filterRefs["teamMobile"].current.offsetHeight}px`,
            width: `${this.filterRefs["teamMobile"].current.offsetWidth}px`
        };
    }
    if (this.filterRefs["teamHeader"].current) {
        this.teamHeaderFilterContainerStyling = {
            left: `${this.filterRefs["teamHeader"].current.offsetLeft}px`,
            top: `${this.filterRefs["teamHeader"].current.offsetTop + this.filterRefs["teamHeader"].current.offsetHeight}px`,
            width: `${this.filterRefs["teamHeader"].current.offsetWidth}px`
        };
    }
    if (this.filterRefs["type"].current) {
        this.typeFilterContainerStyling = {
            left: `${this.filterRefs["type"].current.offsetLeft}px`,
            top: `${this.filterRefs["type"].current.offsetTop + this.filterRefs["type"].current.offsetHeight}px`,
            width: `${this.filterRefs["type"].current.offsetWidth}px`
        };
    }
  }

  tableScrolled() {
    if (document.getElementById("screener-table-responsive")) {
        const scrollWidth = document.getElementById("screener-table-responsive").scrollWidth;
        const clientWidth = document.getElementById("screener-table-responsive").clientWidth;
        const scrollLeft = document.getElementById("screener-table-responsive").scrollLeft
        // if ((scrollLeft + clientWidth) === scrollWidth) {
        //   this.tableGradientRef.current.style.display = 'none';
        // } else {
        //   this.tableGradientRef.current.style.display = 'block';
        // }   
    }
  }

  showFilterModal() {
    this.setState({filterDrawerOpen: true});
  }

  scrollFunction() {
    var tempState = {
        playerFilterActive: false,
        teamFilterActive: false,
        typeFilterActive: false
    };
    if ($(window).width() > 1200) {
        if (document.body.scrollTop > 225 || document.documentElement.scrollTop > 225) {
            // $('.screener-minimized-header-desktop').show();
        } else {
            // $('.screener-minimized-header-desktop').hide();
        }
    }
    if ($(window).width() <= 768) {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            $('.screener-content-header').hide();
            $('.screener-minimized-content-header').show();
            $(".screener-mobile-nav").show()
        } else {
            $('.screener-minimized-content-header').hide();
            $(".screener-mobile-nav").hide();
            $('.screener-content-header').show();
        }
    }

    // We could optimize it by checking the state first so that we don't set it again if not needed
    if (window.pageYOffset > 225) {
        tempState['filterSticky'] = true;

    } else {
        tempState['filterSticky'] = false;
    }

    // Only set state and re-render if the 'stickiness' of the filters changes to avoid rendering on every scroll
    if (this.state.filterSticky !== tempState.filterSticky) {
        this.setState(tempState, this.generateFilterStyling);
    }

  }

  windowResized() {
    // Some styling
    const windowWidth = window.innerWidth;
    this.setState({
        // Scoring selections styling
        typeFilterStyling: windowWidth > 768 ? this.largeTypeFilterStyling : this.smallTypeFilterStyling
    })
  }

  selectTab(event) {
    const selection = event.currentTarget.dataset.selection;
    var tempState = {
        tab: selection,
        data: this.state.data
    };
    // Reset the games to avoid a screw up in formatting while switching state
    if (this.state.tab !== selection) {
        tempState['data']['gamelog'] = [];
        tempState.bettingGraphData = [];
        tempState.gamelogHash = "";
    }
    if (selection === "team") {
        tempState["typeFilterOptions"] = ["OFFENSIVE", "DEFENSIVE"];
        tempState["typeFilterSelection"] = "OFFENSIVE";
        tempState.betTypes = betTypeFilter[selection]();
    } else if (selection === "player") {
        if (this.props.activeLeague === "nfl") {
            const positionFilterOptions = {
                "QB": ["PASSING", "RUSHING"],
                "RB": ["RUSHING", "RECEIVING"],
                "WR": ["RECEIVING", "RUSHING"],
                "TE": ["RECEIVING", "RUSHING"],
                "K": ["KICKING"]
            };
            if (!isDictEmpty(this.state.players)) {
                tempState["typeFilterOptions"] = positionFilterOptions[this.state.players[this.state.playerFilterSelection].info.position];
                tempState["typeFilterSelection"] = positionFilterOptions[this.state.players[this.state.playerFilterSelection].info.position][0];
            } else {
                tempState["typeFilterOptions"] = ["PASSING", "RUSHING", "RECEIVING", "KICKING"];
                tempState["typeFilterSelection"] = "PASSING";
            }
        }
        if (!isDictEmpty(this.state.players)) {
            tempState.betTypes = betTypeFilter.player(this.props.activeLeague, this.state.players[this.state.playerFilterSelection].info.position);
        } else {
            tempState.betTypes = betTypeFilter.player(this.props.activeLeague, "");
        }
    }
    this.setState(tempState);
  }

  selectStatQuantifier(event) {
      const selection = event.currentTarget.dataset.selection;
      this.isDataRenderPending = true;
      this.setState({statQuantifier: selection});
  }

  playerFilterClicked() {
    this.setState(
        {
            playerFilterActive: !this.state.playerFilterActive,
            teamFilterActive: false,
            typeFilterActive: false
        }
    )
  }

  playerFilterOptionSelected(event) {
    const selection = event.currentTarget.dataset.selection;
    this.generateFilterStyling();
    if (this.state.playerFilterSelection !== selection) {
      this.isDataRenderPending = true;
    }
    var tempState = {
        playerFilterSelection: selection,
        playerFilterActive: false
    };
    if (this.props.activeLeague === "nfl") {
        if (!isDictEmpty(this.state.players)) {
            tempState["typeFilterOptions"] = this.positionFilterOptions[this.state.players[selection].info.position];
            tempState["typeFilterSelection"] = this.positionFilterOptions[this.state.players[selection].info.position][0];
        } else {
            tempState["typeFilterOptions"] = ["PASSING", "RUSHING", "RECEIVING", "KICKING"];
            tempState["typeFilterSelection"] = "PASSING";
        }
    }
    if (!isDictEmpty(this.state.players)) {
        tempState.betTypes = betTypeFilter.player(this.props.activeLeague, this.state.players[selection].info.position);
    } else {
        tempState.betTypes = betTypeFilter.player(this.props.activeLeague, "");
    }
    this.setState(tempState
        // TBD if we need to fetch new data directly when the player is selected or if we can just leave it up to the filters data request
        // ,this.loadStaticData
    );
  }

  getUpdatedTypeFilter(playersDict, playerObject) {
    if (this.state.tab === "player" && this.props.activeLeague === "nfl") {
        if (!isDictEmpty(playersDict)) {
            return {
                typeFilterOptions: this.positionFilterOptions[playerObject.info.position],
                typeFilterSelection: this.positionFilterOptions[playerObject.info.position][0]
            };
        } else {
            return {
                typeFilterOptions: ["PASSING", "RUSHING", "RECEIVING", "KICKING"],
                typeFilterSelection: "PASSING"
            };
        }
    }
    return {};
  }

  teamFilterClicked() {
    this.setState(
        {
            playerFilterActive: false,
            teamFilterActive: !this.state.teamFilterActive,
            typeFilterActive: false
        }
    )
  }

  teamFilterOptionSelected(event) {
    const selection = event.currentTarget.dataset.selection;
    this.generateFilterStyling();
    if (this.state.teamFilterSelection !== selection) {
      this.isDataRenderPending = true;
    }
    this.setState(
        {
            teamFilterSelection: selection,
            teamFilterActive: false,
            teamFilterAbbreviation: TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()][selection]
        },
        () => this.loadStaticData(false)
        // this.loadStaticData
    )
  }

  typeFilterClicked() {
    this.setState(
        {
            teamFilterActive: false,
            opponentFilterActive: false,
            timeframeFilterActive: false,
            splitsFilterActive: false,
            typeFilterActive: !this.state.typeFilterActive
        }
    )
  }

  typeFilterOptionSelected(event) {
    const selection = event.currentTarget.dataset.selection;
    this.generateFilterStyling();
    if (this.state.typeFilterSelection !== selection) {
        this.isDataRenderPending = true;
    }
    this.setState(
        {
            typeFilterSelection: selection,
            typeFilterActive: false
        }
    )
  }

  dataUpdateRequested(dataRequestObjects) {
    var promises = [];
    dataRequestObjects.forEach((item, index) => {
        promises.push(fetch(item.url).then(data => {
            return data.json();
        }));
    });
    this.isDataRenderPending = true;
    var data = this.state.data;
    Promise.all(promises)
      .then(result => {
        result.forEach((item, index) => {
            data[dataRequestObjects[index].target] = item;
        });
        this.setState(
            {
                data: data,
                bettingGraphData: buildGraphData(this.props.activeLeague, this.state.tab, this.state.betTypes[BET_TYPE_FILTER].selectedValue, this.state.teamFilterAbbreviation, data.gamelog.slice().reverse()),
                bettingTrends: buildTrendsData(this.props.activeLeague, this.state.tab, data.gamelog, this.state.betTypes[BET_TYPE_FILTER].selectedValue, this.state.bettingLineValue),
                gamelogHash: data.gamelog.map(x => x.gameID).join("_"),
                hitRecord: this.state.tab === "team" ? getCombinedHitRecords(data.gamelog) : null,
                favouriteStandings: this.state.tab === "team" ? getCombinedStandings(this.props.activeLeague, data.gamelog.filter(game => game.odds.spread < 0)) : null,
                underdogStandings: this.state.tab === "team" ? getCombinedStandings(this.props.activeLeague, data.gamelog.filter(game => game.odds.spread > 0)) : null
            }
        );
      })
      .catch(error => {
        console.log("Error loading screener data: " + error);
      });
  }

  getLeagueFilters(league) {
      switch(league.toLowerCase()) {
        case "nfl":
            return <NFLFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "nhl":
            return <NHLFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "nba":
            return <NBAFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "wnba":
            return <WNBAFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "mlb":
            return <MLBFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
            return <CrossYearSoccerFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                    addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                    player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "brazil-serie-a":
        case "mls":
            return <InYearSoccerFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                    addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                    player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        case "euro":
            return <EuroFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.state.teamFilterAbbreviation} setDisplayLeagueAverageFn={this.setDisplayLeagueAverage}
                    addClickableElementHandler={this.addClickableElement} dataUpdateRequestHandler={this.dataUpdateRequested} tab={this.state.tab} 
                    player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        default:
            return <></>
      }
  }

  getLeagueScoring(league) {
    var result = <></>;
    switch(league.toLowerCase()) {
        case "nfl":
            result = <NFLScoring activeLeague={this.props.activeLeague} team={this.state.teamFilterAbbreviation} userAttributes={this.props.userAttributes}
                                teamStanding={this.state.teamStanding} leagueAverages={this.state.data.leagueAverages} dataUpdateRequestHandler={this.dataUpdateRequested}
                                type={this.state.typeFilterSelection.toLowerCase()} teamData={this.state.data.teamData} displayLeagueAverage={this.displayLeagueAverage}
                                tab={this.state.tab} player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        case "nhl":
            result = <NHLScoring activeLeague={this.props.activeLeague} team={this.state.teamFilterAbbreviation} userAttributes={this.props.userAttributes}
                                teamStanding={this.state.teamStanding} leagueAverages={this.state.data.leagueAverages} dataUpdateRequestHandler={this.dataUpdateRequested}
                                type={this.state.typeFilterSelection.toLowerCase()} teamData={this.state.data.teamData} displayLeagueAverage={this.displayLeagueAverage}
                                tab={this.state.tab} player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        case "nba":
        case "wnba":
            result = <NBAScoring activeLeague={this.props.activeLeague} team={this.state.teamFilterAbbreviation} userAttributes={this.props.userAttributes}
                                teamStanding={this.state.teamStanding} leagueAverages={this.state.data.leagueAverages} dataUpdateRequestHandler={this.dataUpdateRequested}
                                type={this.state.typeFilterSelection.toLowerCase()} teamData={this.state.data.teamData} displayLeagueAverage={this.displayLeagueAverage}
                                tab={this.state.tab} player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}} />
            break;
        case "mlb":
            result = <MLBScoring activeLeague={this.props.activeLeague} team={this.state.teamFilterAbbreviation} userAttributes={this.props.userAttributes}
                                teamStanding={this.state.teamStanding} leagueAverages={this.state.data.leagueAverages} dataUpdateRequestHandler={this.dataUpdateRequested}
                                type={this.state.typeFilterSelection.toLowerCase()} teamData={this.state.data.teamData} displayLeagueAverage={this.displayLeagueAverage}
                                tab={this.state.tab} player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}} />
            break;
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            result = <SoccerScoring activeLeague={this.props.activeLeague} team={this.state.teamFilterAbbreviation} userAttributes={this.props.userAttributes}
                                teamStanding={this.state.teamStanding} leagueAverages={this.state.data.leagueAverages} dataUpdateRequestHandler={this.dataUpdateRequested}
                                type={this.state.typeFilterSelection.toLowerCase()} teamData={this.state.data.teamData} displayLeagueAverage={this.displayLeagueAverage}
                                tab={this.state.tab} player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
        default:
            break;
      }
      // This is very important to trigger child components to be re-rendered without a new event
      if (this.isDataRenderPending) {
        this.forceUpdate();
      }
      this.isDataRenderPending = false;
      return result;
  }

  getLeagueForm(league) {
    switch(league.toLowerCase()) {
        case "nfl":
            return <NFLForm activeLeague={this.props.activeLeague} teamStanding={this.state.teamStanding} 
                            recentForm={this.state.data.gamelog} upcomingForm={this.state.data.upcomingForm} />
        case "nhl":
            return <NHLForm activeLeague={this.props.activeLeague} teamStanding={this.state.teamStanding} 
                            recentForm={this.state.data.gamelog} upcomingForm={this.state.data.upcomingForm} />
        case "nba":
        case "wnba":
            return <NBAForm activeLeague={this.props.activeLeague} teamStanding={this.state.teamStanding} 
                            recentForm={this.state.data.gamelog} upcomingForm={this.state.data.upcomingForm} />
        case "mlb":
            return <MLBForm activeLeague={this.props.activeLeague} teamStanding={this.state.teamStanding} 
                            recentForm={this.state.data.gamelog} upcomingForm={this.state.data.upcomingForm} />
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            return <SoccerForm activeLeague={this.props.activeLeague} teamStanding={this.state.teamStanding} 
                            recentForm={this.state.data.gamelog} upcomingForm={this.state.data.upcomingForm} />
        default:
            return <></>
    }
  }

  getLeaguegamelog(league) {
    var result = <></>;
    switch(league.toLowerCase()) {
        case "nfl":
            result = <NFLGamelog activeLeague={this.props.activeLeague} games={this.state.data.gamelog} tab={this.state.tab} updateFn={this.updateGradientStyling}
                                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        case "nhl":
            result = <NHLGamelog activeLeague={this.props.activeLeague} games={this.state.data.gamelog} tab={this.state.tab} updateFn={this.updateGradientStyling}
                                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        case "nba":
        case "wnba":
            result = <NBAGamelog activeLeague={this.props.activeLeague} games={this.state.data.gamelog} tab={this.state.tab} updateFn={this.updateGradientStyling}
                                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        case "mlb":
            result = <MLBGamelog activeLeague={this.props.activeLeague} games={this.state.data.gamelog} tab={this.state.tab} updateFn={this.updateGradientStyling}
                                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        case "mls":
        case "euro":
            result = <SoccerGamelog activeLeague={this.props.activeLeague} games={this.state.data.gamelog} tab={this.state.tab} updateFn={this.updateGradientStyling}
                                player={(this.state.tab === "player" && !isDictEmpty(this.state.players)) ? this.state.players[this.state.playerFilterSelection] : {}}/>
            break;
        default:
            break;
    }
    // This is very important to trigger child components to be re-rendered without a new event
    if (this.isDataRenderPending) {
    this.forceUpdate();
    }
    this.isDataRenderPending = false;
    return result;
  }

  // TODO: player names without first/last
  buildTitle() {
    if (isDictEmpty(this.state.teamStanding) && isDictEmpty(this.state.players)) {
        return "";
    }
    if (this.state.tab === "team") {
        return getTeamDisplayName(this.props.activeLeague, this.state.teamStanding);
    } else if (this.state.tab === "player") {
        const playerSelection = this.state.playerFilterSelection;
        const playerObject = this.state.players[playerSelection];
        return `${playerObject.info.firstName} ${playerObject.info.lastName}`;
    }
  }

  // For team it's record + standing
  buildTeamSubtitle(league) {
    switch(league.toLowerCase()) {
        case "nfl":
            return `${this.state.teamStanding.standings.wins}-${this.state.teamStanding.standings.losses}-${this.state.teamStanding.standings.ties} | 
                    ${this.state.teamStanding.standings.division} - ${addOrdinalNumberSuffix(this.state.teamStanding.standings.divisionRank)}`;
        case "nhl":
            return `${this.state.teamStanding.standings.wins}-${this.state.teamStanding.standings.losses}-${this.state.teamStanding.standings.overtimeLosses} | 
                    ${this.state.teamStanding.standings.division} - ${addOrdinalNumberSuffix(this.state.teamStanding.standings.divisionRank)}`;
        case "nba":
            return `${this.state.teamStanding.standings.wins}-${this.state.teamStanding.standings.losses} | 
                    ${this.state.teamStanding.standings.division} - ${addOrdinalNumberSuffix(this.state.teamStanding.standings.divisionRank)}`;
        case "wnba":
            return `${this.state.teamStanding.standings.wins}-${this.state.teamStanding.standings.losses} | 
                    ${addOrdinalNumberSuffix(this.state.teamStanding.standings.overallRank)}`;
        case "mlb":
            return `${this.state.teamStanding.standings.wins}-${this.state.teamStanding.standings.losses} | 
                    ${this.state.teamStanding.standings.division} - ${addOrdinalNumberSuffix(this.state.teamStanding.standings.divisionRank)}`;
        case "bundesliga":
        case "epl":
        case "laliga":
        case "ligue1":
        case "seriea":
        case "brazil-serie-a":
        // TODO: mls actually has conferences
        case "mls":
        case "euro":
            return `${this.state.teamStanding.standings.wins}-${this.state.teamStanding.standings.losses}-${this.state.teamStanding.standings.draws} | 
                    ${addOrdinalNumberSuffix(this.state.teamStanding.standings.rank)}`;
        default:
            return "";
    }
  }

  // TODO: player names without first/last
  // For player it's number + position
  //   TODO: seems like some numbers and/or positions might be missing (Cole caufield for MTL as example)
  buildPlayerSubtitle() {
    if (isDictEmpty(this.state.players)) {
        return "";
    }
    const playerSelection = this.state.playerFilterSelection;
    const playerObject = this.state.players[playerSelection];
    const number = `#${playerObject.info.number}`;
    const position = playerObject.info.position;
    return `${number} - ${position}`;
  } 
  
  buildSubtitle(league) {
    if (this.state.tab === "team") {
        return this.buildTeamSubtitle(league);
    } else if (this.state.tab === "player") {
        return this.buildPlayerSubtitle();
    }
  }

  betTypeUpdated(event, optionalCheckedItems) {
    const selectedValue = event.currentTarget.dataset.selection;
    var tempState = this.state;
    tempState.betTypes[BET_TYPE_FILTER].isActive = false;
    tempState.betTypes[BET_TYPE_FILTER].selectedValue = selectedValue;
    tempState.bettingGraphData = buildGraphData(this.props.activeLeague, this.state.tab, selectedValue, this.state.teamFilterAbbreviation, this.state.data.gamelog.slice().reverse());
    tempState.bettingTrends = buildTrendsData(this.props.activeLeague, this.state.tab, this.state.data.gamelog, selectedValue, this.state.bettingLineValue);
    this.setState(tempState);
}

updateLine(event) {
    const change = parseFloat(event.currentTarget.dataset.change);
    var tempState = this.state;
    tempState['bettingLineValue'] = tempState.bettingLineValue + change;
    tempState.bettingTrends = buildTrendsData(this.props.activeLeague, this.state.tab, this.state.data.gamelog, this.state.betTypes[BET_TYPE_FILTER].selectedValue, tempState.bettingLineValue);
    this.setState(tempState);
}

lineInputChanged(event) {
    const newValue = parseFloat(event.target.value);
    var tempState = this.state;
    tempState['bettingLineValue'] = newValue;
    tempState.bettingTrends = buildTrendsData(this.props.activeLeague, this.state.tab, this.state.data.gamelog, this.state.betTypes[BET_TYPE_FILTER].selectedValue, tempState.bettingLineValue);
    this.setState(tempState);
}

// TODO: remove dividers for all sections
render() {
    // Betting trends end
    var tableHeight = 0;
    var tableTopOffset = 0;
    if (this.tableRef.current) {
      tableHeight = this.tableRef.current.offsetHeight;
      tableTopOffset = this.tableRef.current.offsetTop;
    }
    return(
      <div className='screener-content-wrapper'>
        <HtmlHeaders canonicalRef={`https://www.linemate.io/${this.props.activeLeague}/screener`}/>
        {/* <div style={{marginTop: '0px', borderBottom: '2px solid #D1D5DB'}}>
            <div className="team-player-toggle" style={{borderBottom: 'none', maxWidth: '1152px', margin: '0 auto'}}>
                <span className={this.state.tab === "team" ? "clickable table-toggle selected-table-toggle" : "clickable table-toggle"}
                        data-selection={"team"}
                        onClick={this.selectTab}>
                    <p className="font size-14 dark unselectable">Team Scouting</p>
                </span>
                <span className={this.state.tab === "player" ? "clickable table-toggle selected-table-toggle" : "clickable table-toggle"}
                        data-selection={"player"}
                        onClick={this.selectTab}>
                    <p className="font size-14 dark unselectable">Player Scouting</p>
                </span>
          </div>
        </div> */}
        <div className="screener-content">
            <div className="screener-content-header">
                <div className="screener-team-header-info">
                    <div className="screener-header-team-logo" style={{display: 'inline-block', verticalAlign: 'top', width: 'auto'}}>
                        {
                            this.state.tab === "team" ? 
                            <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamFilterAbbreviation)} height={64} width={64} alt=""/> :
                            <>
                                {getPlayerImage(this.state.players[this.state.playerFilterSelection], 64, 24)}
                                <img className="team-badge rounded-icon" style={{top: '35px', left: '35px', backgroundColor: '#FFFFFF', padding: '6px'}} src={getTeamLogoPath(this.props.activeLeague, this.state.teamFilterAbbreviation)} height={36} width={36} alt=""/>
                            </>
                        }
                    </div>
                    <div className="screener-team-info">
                        {
                            (!isDictEmpty(this.state.teamStanding) || !isDictEmpty(this.state.players)) && (
                                <>
                                    <h3>{this.buildTitle()}</h3>
                                    <div className="team-standings">{this.buildSubtitle(this.props.activeLeague)}</div>
                                </>
                            )
                        }
                    </div>
                </div>
                    <div className="screener-header-team-filter" style={this.state.tab === "player" ? {verticalAlign: 'top'} : {verticalAlign: 'middle'}}>
                        {/* <span   ref={this.filterRefs["team"]} onClick={this.teamFilterClicked}
                                className="clickable filter-button unselectable" style={{position: 'absolute', width: '200px', right: '0', padding: '4px 16px 4px 6px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                            <span className="font size-14 grey">TEAM: </span>
                            <span className="font size-14 dark uppercase">{this.state.teamFilterSelection}</span>
                            <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{position: 'absolute', right: '6px', top: '7px'}}/>
                        </span> */}
                        {/* <InputSelection 
                            enabled={true} 
                            name="Team" 
                            type="single" 
                            typography="md" 
                            options={TEAM_CODE_TO_CITY[this.props.activeLeague.toLowerCase()]} 
                            selection={TEAM_CITY_TO_CODE[this.props.activeLeague][this.state.teamFilterSelection]} 
                            selectionHandler={(selection) => console.log(selection)}
                        /> */}
                        {/* <Dropdown
                            name="team"
                            enabled={this.state.teamFilterActive}
                            currentSelection={this.state.teamFilterSelection}
                            options={Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()])}
                            customContainerStyling={this.teamFilterContainerStyling}
                            textStylingClasses="size-14 spaced"
                            onClickHandler={this.teamFilterOptionSelected}
                            clickableElementAddition={this.addClickableElement} /> */}

                        {/* <span   ref={this.filterRefs["player"]} onClick={this.playerFilterClicked}
                                className="clickable filter-button unselectable" 
                                style={this.state.tab === "player" ? {position: 'absolute', width: '200px', right: '0', top: '44px', padding: '4px 16px 4px 6px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'} : {display: 'none'}}>
                            <span className="font size-14 grey">PLAYER: </span>
                            <span className="font size-14 dark">{this.state.playerFilterSelection}</span>
                            <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{position: 'absolute', right: '6px', top: '7px'}}/>
                        </span> */}
                        {/* <Dropdown
                            name="Player"
                            enabled={this.state.playerFilterActive}
                            currentSelection={this.state.playerFilterSelection}
                            options={this.state.playerFilterOptions}
                            customContainerStyling={this.playerFilterContainerStyling}
                            textStylingClasses="size-14 spaced"
                            onClickHandler={this.playerFilterOptionSelected}
                            clickableElementAddition={this.addClickableElement} /> */}

                    </div>
                <div className="screener-btn-group-lg">
                    <button className="screener-btn-filter-lg" onClick={this.showFilterModal}>
                        <img src="assets/filters.svg" width="16" height="16" alt=""/> 
                        <p>Filters</p>
                    </button>
                </div>
            </div>
            <div className="screener-minimized-content-header">
                <div className="screener-team-header-info-min">
                    <div className="team-logo-min">
                        {
                            this.state.tab === "team" ? 
                            <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamFilterAbbreviation)} width="32" height="32" alt=""/> :
                            <>
                                {getPlayerImage(this.state.players[this.state.playerFilterSelection], 32, 12)}
                                <img className="team-badge rounded-icon" style={{top: '30px', left: '35px', backgroundColor: '#FFFFFF', padding: '3px'}} src={getTeamLogoPath(this.props.activeLeague, this.state.teamFilterAbbreviation)} height={18} width={18} alt=""/>
                            </>
                        }
                    </div>
                    <div className="font size-14 weight-600 dark" style={{marginLeft: '8px'}}>
                        {
                            this.state.tab === "team" ? this.state.teamFilterAbbreviation : this.buildTitle()
                        }
                    </div>
                </div>
                <div className="screener-btn-group">
                    <button className="screener-btn-filter-sm" onClick={this.showFilterModal}>
                        <img src="assets/filters.svg" width="16" height="16" alt=""/> 
                        <p>Filters</p>
                    </button>
                </div>
            </div>
            {/* Removing for now */}
            {/* <div className="screener-mobile-nav">
                <div className="screener-menu-item text-xs">
                    <div className="screener-anchor-container screener-active"><a href="#scoring">Scoring</a></div> 
                </div>
                <div className="screener-menu-item text-xs">
                    <div className="screener-anchor-container"><a href="#forms">Form</a></div>
                </div>
                <div className="screener-menu-item text-xs">
                    <div className="screener-anchor-container"><a href="#betting-trends">Betting Trends</a></div> 
                </div>
                <div className="screener-menu-item text-xs">
                    <div className="screener-anchor-container"><a href="#gamelog">Gamelog</a></div> 
                </div>
            </div> */}
            <hr className="screener-divider"/>
            <div className={this.state.filterSticky ? "screener-minimized-header-desktop" : "screener-main-filters"}>
                <div className="screener-min-header-content">
                    <div style={this.state.filterSticky ? {display: 'inline', marginRight: '24px', position: 'relative'} : {display: 'none'}}>
                        {   
                            this.state.tab === "team" ? 
                            <img src={getTeamLogoPath(this.props.activeLeague, this.state.teamFilterAbbreviation)} height={48} width={48} alt=""/> :
                            <>
                                {getPlayerImage(this.state.players[this.state.playerFilterSelection], 48, 18)}
                                <img className="team-badge rounded-icon" style={{top: '24px', left: '30px', backgroundColor: '#FFFFFF', padding: '3px'}} src={getTeamLogoPath(this.props.activeLeague, this.state.teamFilterAbbreviation)} height={26} width={26} alt=""/>
                            </>
                        }
                    </div>
                    {/* <span   ref={this.filterRefs["teamHeader"]} onClick={this.teamFilterClicked}
                            className="clickable filter-button unselectable" style={this.state.filterSticky ? {} : {display: 'none'}}>
                        <span className="font size-14 grey">TEAM: </span>
                        <span className="font size-14 dark">{this.state.teamFilterSelection}</span>
                        <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px'}}/>
                    </span>
                    <Dropdown
                        name="team"
                        enabled={this.state.teamFilterActive}
                        currentSelection={this.state.teamFilterSelection}
                        options={Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()])}
                        customContainerStyling={this.teamHeaderFilterContainerStyling}
                        textStylingClasses="size-14 spaced"
                        onClickHandler={this.teamFilterOptionSelected}
                        clickableElementAddition={this.addClickableElement} /> */}

                    {/* <span   ref={this.filterRefs["playerHeader"]} onClick={this.playerFilterClicked}
                            className="clickable filter-button unselectable" 
                            style={(this.state.tab === "player" && this.state.filterSticky) ? {} : {display: 'none'}}>
                        <span className="font size-14 grey">PLAYER: </span>
                        <span className="font size-14 dark">{this.state.playerFilterSelection}</span>
                        <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px'}}/>
                    </span>
                    <Dropdown
                        name="Player"
                        enabled={this.state.playerFilterActive}
                        currentSelection={this.state.playerFilterSelection}
                        options={this.state.playerFilterOptions}
                        customContainerStyling={this.playerHeaderFilterContainerStyling}
                        textStylingClasses="size-14 spaced"
                        onClickHandler={this.playerFilterOptionSelected}
                        clickableElementAddition={this.addClickableElement} /> */}
                    {this.getLeagueFilters(this.props.activeLeague)}
                </div>
            </div>
            <div className="screener-main-content" style={shouldBlockUserAccess(this.props.userAttributes) ? {marginBottom: '200px'} : {}}>
                {
                    (this.state.data.gamelog.length === 0 || this.state.data.teamData.code === 404 || isDictEmpty(this.state.data.teamData)) ? 
                        <NoResultsFrame text={"There is no result within the search criteria."} />
                    :
                    <>
                        <div className="screener-scoring-header" style={(this.props.activeLeague !== "nfl" && this.state.tab === "player") ? {height: '40px'} : {}}>
                            <p className="font size-30 weight-700">
                                Scoring
                            </p>
                            {/* Mobile */}
                            <p className={`font size-16 weight-600 spaced ${(this.state.tab === "team" || this.props.activeLeague.toLowerCase() === "nfl") ? "screener-scoring-games-count-team" : "screener-scoring-games-count-player"}`}>
                                {`${this.state.data.teamData.code === 404 || isDictEmpty(this.state.data.teamData) ? "0" : this.state.data.teamData.gamesPlayed} GAMES`}
                            </p>
                            <div>
                                {/* Desktop */}
                                <p className={`font size-16 weight-600 spaced ${(this.state.tab === "team" || this.props.activeLeague.toLowerCase() === "nfl") ? "screener-scoring-games-count-team" : "screener-scoring-games-count-player"}`}>
                                    {`${this.state.data.teamData.code === 404 || isDictEmpty(this.state.data.teamData) ? "0" : this.state.data.teamData.gamesPlayed} GAMES`}
                                </p>
                                <p className='text-style-label-medium'>
                                    Type
                                </p>
                                <div>
                                    <InputSelection 
                                        enabled={true} 
                                        type="single" 
                                        typography="md" 
                                        options={this.state.typeFilterOptions} 
                                        selection={this.state.typeFilterSelection} 
                                        selectionHandler={(selection) => this.typeFilterOptionSelected({currentTarget: {dataset: {selection: selection}}})}
                                    />
                                </div>
                            </div>
                            {/* <span ref={this.filterRefs["type"]} onClick={this.typeFilterClicked}
                                    className="clickable filter-button unselectable screener-type-filter" 
                                    style={(this.props.activeLeague !== "nfl" && this.state.tab === "player") ? {display: 'none'} :this.state.typeFilterStyling}>
                                <span className="font size-14 grey">TYPE: </span>
                                <span className="font size-14 dark">{this.state.typeFilterSelection}</span>
                                <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px'}}/>
                            </span>
                            <Dropdown
                                name="type"
                                enabled={this.state.typeFilterActive}
                                currentSelection={this.state.typeFilterSelection}
                                options={this.state.typeFilterOptions}
                                customContainerStyling={this.typeFilterContainerStyling}
                                textStylingClasses="size-14 spaced"
                                onClickHandler={this.typeFilterOptionSelected}
                                clickableElementAddition={this.addClickableElement} /> */}
                        </div>
                        <div className="screener-scoring-section">
                            {this.getLeagueScoring(this.props.activeLeague)}
                        </div>
                        <h3 id="forms">Form</h3>
                        <div className="screener-forms-section">
                            {this.getLeagueForm(this.props.activeLeague)}
                        </div>
                        <p id="betting-trends" className="font size-30 weight-700" style={{display: 'inline-block', width: '50%', textAlign: 'left', marginBottom: '16px'}}>
                            Betting Trends
                        </p>
                        <p id="betting-trends" className="font size-16 weight-600 spaced" style={{display: 'inline-block', width: '50%', textAlign: 'right', marginBottom: '16px'}}>
                            {`${this.state.data.gamelog.length} GAMES`}
                        </p>
                        <div className="screener-betting-trends-section">
                            {
                                this.state.tab === "player" || isSoccer(this.props.activeLeague) ? <></> :
                                <>
                                    <div style={{height: '28px', marginTop: '24px', marginBottom: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <p className="font size-20 wight-600 screener-betting-trends-section-records">Records</p>
                                    </div>
                                    <div className="screener-betting-trends-section-team-record-desktop">
                                        {getRecordCard("Favourite", buildStandingsRecordString(this.props.activeLeague, this.state.favouriteStandings), COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "desktop")}
                                        {getRecordCard("Underdog", buildStandingsRecordString(this.props.activeLeague, this.state.underdogStandings), COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "desktop")} 
                                        {getRecordCard("At the Spread", `${this.state.hitRecord.againstTheSpread.hits}-${this.state.hitRecord.againstTheSpread.misses}-${this.state.hitRecord.againstTheSpread.pushes}`, COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "desktop")}
                                        {getRecordCard("Over / Under", `${this.state.hitRecord.totalOver.hits}-${this.state.hitRecord.totalOver.misses}-${this.state.hitRecord.totalOver.pushes}`, COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "desktop")}
                                    </div>
                                    <div className="screener-betting-trends-section-team-record-mobile">
                                        {getRecordCard("Favourite", buildStandingsRecordString(this.props.activeLeague, this.state.favouriteStandings), COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "mobile")}
                                        {getRecordCard("Underdog", buildStandingsRecordString(this.props.activeLeague, this.state.underdogStandings), COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "mobile")} 
                                        {getRecordCard("At the Spread", `${this.state.hitRecord.againstTheSpread.hits}-${this.state.hitRecord.againstTheSpread.misses}-${this.state.hitRecord.againstTheSpread.pushes}`, COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "mobile")}
                                        {getRecordCard("Over / Under", `${this.state.hitRecord.totalOver.hits}-${this.state.hitRecord.totalOver.misses}-${this.state.hitRecord.totalOver.pushes}`, COLOR_CODES[this.props.activeLeague][this.state.teamFilterAbbreviation].primary, "mobile")}
                                    </div>
                                    {/* Divisor */}
                                    <div className='screener-betting-trends-section-divisor' style={{width: '100%', height: '0px', marginTop: '24px', marginBottom: '28px'}}></div>
                                </>
                            }
                            <div className="screener-betting-trends-hit-rate-section" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <p className="font size-20 wight-600 grey">Hit Rate</p>
                                <div>
                                    <p className='text-style-label-medium'>Bet Type</p>
                                    <div>
                                        <InputSelection 
                                            enabled={true} 
                                            type="single" 
                                            typography="md" 
                                            options={this.state.betTypes[BET_TYPE_FILTER].options} 
                                            selection={this.state.betTypes[BET_TYPE_FILTER].selectedValue} 
                                            selectionHandler={(selection) => this.betTypeUpdated({currentTarget: {dataset: {selection: selection}}})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='screener-betting-trends-hit-rate-section-wrapper'>
                                <HitRateBox 
                                    activeLeague={this.props.activeLeague}
                                    tab={this.state.tab}
                                    teamCode={this.state.teamFilterAbbreviation}
                                    lineValue={this.state.bettingLineValue}
                                    betType={this.state.betTypes[BET_TYPE_FILTER].selectedValue}
                                    playerRecord={this.state.tab === "player" ? this.state.players[this.state.playerFilterSelection] : null}
                                    updateLine={this.updateLine}
                                    lineChangeHandler={this.lineInputChanged}
                                    hits={this.state.bettingTrends.hits}
                                    misses={this.state.data.gamelog.length - this.state.bettingTrends.hits - this.state.bettingTrends.push}
                                    pushes={this.state.bettingTrends.push}
                                    gamesCount={this.state.data.gamelog.length}
                                    average={this.state.bettingTrends.resultAverage}
                                >
                                    <div style={{width: '100%', height: '420px'}}>
                                        <ResponsiveBar
                                            theme={RESPONSIVE_BAR_THEME}
                                            // The tab being part of the key is in case switching from team to player and the player has played all the games, the hash would be the same
                                            key={this.state.gamelogHash + this.state.tab}
                                            data={this.state.bettingGraphData}
                                            keys={[this.state.betTypes[BET_TYPE_FILTER].selectedValue]}
                                            indexBy="key"
                                            margin={{ top: 60, right: 37.5, bottom: 25, left: 50 }}
                                            padding={0.35}
                                            innerPadding={10}
                                            groupMode="grouped"
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: true }}
                                            valueFormat={{ format: '', enabled: false }}
                                            colors={({ id, data }) => String(data[`color`])}
                                            defs={[]}
                                            fill={[]}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={null}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: '',
                                                legendPosition: 'middle',
                                                legendOffset: -40
                                            }}
                                            enableLabel={false}
                                            labelSkipWidth={12}
                                            labelSkipHeight={12}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-right',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: 30,
                                                    translateY: -50,
                                                    itemsSpacing: 4,
                                                    itemWidth: 100,
                                                    itemHeight: 32,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 12,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            markers={[
                                                {
                                                    axis: 'y',
                                                    value: this.state.bettingLineValue,
                                                    lineStyle: { stroke: 'var(--color-opposite-border-default)', strokeWidth: 2 }
                                                },
                                            ]}
                                            motionConfig={{ mass: 1, tension: 170, friction: 26, clamp: false, precision: 0.01, velocity: 0 }}
                                        />
                                    </div>
                                </HitRateBox>
                            </div>
                            {/* Divisor */}
                            <div className='screener-betting-trends-section-divisor' style={{width: '100%', height: '0px', marginTop: '24px', marginBottom: '40px'}}></div>
                        </div>
                        <h3 id="gamelog">Gamelog</h3>
                        <div className="screener-gamelog-section">
                            <div ref={this.tableRef} id="screener-table-responsive" className="screener-table-responsive" onMouseDown={mouseDownHandler} onScroll={this.tableScrolled}>
                                {this.getLeaguegamelog(this.props.activeLeague)}
                            </div>
                            {/* Going to remove the gradient for now cant be bothered to do a theme-based gradient */}
                            {/* <div ref={this.tableGradientRef} style={{position: 'absolute', display: 'block', zIndex: '1', height: `${tableHeight - 24}px`, width: `${90}px`, background: 'linear-gradient(90deg, rgba(249, 250, 251, 0) -45.83%, #F9FAFB 100%)', right: '2%', top: `${tableTopOffset + 24}px`}}></div> */}
                        </div>
                    </>
                }
            </div>
            {
                // Similar to some other cases, don't render this at all if drawer is not decided to be open
                // Will avoid issues with duplicate filters. In this case in doesn't affect the functionality of the first filter but it causes duplicate API requests
                !this.state.filterDrawerOpen ? <></> :
                <>
                    <div className="screener-modal-overlay" onClick={() => {this.setState({filterDrawerOpen: false})}}></div>
                    <div className="screener-filters-modal">
                        <div className="screener-modal-header">
                            <h3>Filters</h3>
                            <div className="screener-close-modal" onClick={() => {this.setState({filterDrawerOpen: false})}}>
                                <img src="assets/playbook/close-major.svg" width="20" height="20" alt=""/>
                            </div>
                        </div>
                        <div className="screener-modal-body">
                            {/* <span   ref={this.filterRefs["teamMobile"]} onClick={this.teamFilterClicked}
                                    className="clickable filter-button unselectable" style={{position: 'relative', width: 'auto', padding: '4px 6px 4px 6px'}}>
                                <span className="font size-14 grey">TEAM: </span>
                                <span className="font size-14 dark">{this.state.teamFilterSelection}</span>
                                <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px'}}/>
                            </span>
                            <Dropdown
                                name="team"
                                enabled={this.state.teamFilterActive}
                                currentSelection={this.state.teamFilterSelection}
                                options={Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()])}
                                customContainerStyling={this.teamMobileFilterContainerStyling}
                                textStylingClasses="size-14 spaced"
                                onClickHandler={this.teamFilterOptionSelected}
                                clickableElementAddition={this.addClickableElement} />
                            <span   ref={this.filterRefs["playerMobile"]} onClick={this.playerFilterClicked}
                                className="clickable filter-button unselectable" 
                                style={this.state.tab === "player" ? {} : {display: 'none'}}>
                                <span className="font size-14 grey">PLAYER: </span>
                                <span className="font size-14 dark">{this.state.playerFilterSelection}</span>
                                <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px'}}/>
                            </span>
                            <Dropdown
                                name="Player"
                                enabled={this.state.playerFilterActive}
                                currentSelection={this.state.playerFilterSelection}
                                options={this.state.playerFilterOptions}
                                customContainerStyling={this.playerMobileFilterContainerStyling}
                                textStylingClasses="size-14 spaced"
                                onClickHandler={this.playerFilterOptionSelected}
                                clickableElementAddition={this.addClickableElement} />
                            <hr className="screener-divider"/> */}
                            {this.getLeagueFilters(this.props.activeLeague)}
                        </div>
                        <div className="screener-modal-footer">
                            <div className="screener-modal-btn-group">
                                <div>
                                    <Button text="Cancel" typography="md" type="secondary" enabled={true} onClick={() => {this.setState({filterDrawerOpen: false})}}/>
                                </div>
                                <div>
                                    <Button text="Apply Filters" typography="md" type="primary" enabled={true} onClick={() => {this.setState({filterDrawerOpen: false})}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
      </div>
    );
  }
}

class NFLFilters extends React.Component {
    constructor(props) {
        super(props);
        const currentWeek = 5;

        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        const weekCount = 18;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                season: {
                    isActive: false,
                    options: Object.fromEntries(["23-24 SEASON", "22-23 SEASON", "21-22 SEASON"].map(x => [x, x])),
                    selectedValue: "23-24 SEASON"
                },
                week: {
                    isActive: false,
                    options: Object.fromEntries([...Array(weekCount).keys()].map(i => i + 1).map(x => `WEEK ${x}`).map(x => [x, x])),
                    selectedValues: ["ALL"],
                    type: "multipleSelection"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        };
        this.defaultFilterValues = {
            opponent: "ALL",
            season: this.state.filters.season.selectedValue,
            week: ["ALL"],
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    // Adding another argument here for multiple selection checkboxes to provide the latest values at all times
    //  This is needed as a workaround to a yet unknown issue with just using state + event dataset which would sometimes end up being outdated
    filterOptionSelected(event, optionalCheckedItems) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        if (filters[selectedFilter].type === "multipleSelection") {
            filters[selectedFilter].selectedValues = optionalCheckedItems;
        } else {
            filters[selectedFilter].selectedValue = selectedValue;
        }
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultSeason = LEAGUE_CURRENT_SEASON.nfl;
        const defaultWeek = 0;
        const defaultSplits = "ALL";

        var season = "";
        const seasonFilterValue = filters.season.selectedValue;
        switch(seasonFilterValue) {
            case "23-24 SEASON":
                season = 2023;
                break;
            case "22-23 SEASON":
                season = 2022;
                break;
            case "21-22 SEASON":
                season = 2021;
                break;
            default:
                break;
        }

        var week = 0;
        const weekFilterValues = filters.week.selectedValues;
        // console.log("Week filter values", weekFilterValues);
        // The check for empty string is because that's what we would possibly get if there were no items
        if (weekFilterValues.indexOf("ALL") === -1 && weekFilterValues.indexOf("") === -1 && weekFilterValues.length > 0) {
            const weeksList = [];
            weekFilterValues.forEach((value) => {
              let regexpDigit = /week\s(\d+)/gi
              let regexpMatch = regexpDigit.exec(value);
              weeksList.push(regexpMatch[1]);
            });
            // console.log("Weeks list: ", weeksList);
            week = weeksList.join(",");
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/nfl/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/nfl/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&season=${season}&week=${week}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && week === defaultWeek && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/nfl/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );   
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/nfl/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&season=${season}&week=${defaultWeek}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/nfl/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=SEASON_${season}&week=${week}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/nfl/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${opponent}&season=${season}&week=${week}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && week === defaultWeek && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/nfl/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the player's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/nfl/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${defaultOpponent}&season=${season}&week=${defaultWeek}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/nfl/v1/games/filteredForPlayer?SRGUID=${this.props.player.SRGUID}&opponent=${opponent}&season=${season}&week=${week}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        // return (
        //     <Filters filters={this.state.filters} addClickableElementHandler={this.props.addClickableElementHandler} selectionHandler={this.filterOptionSelected} />
        // )
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? "multiple": "single"}
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={'type' in this.state.filters[filter] && this.state.filters[filter].type === "multipleSelection" ? this.state.filters[filter].selectedValues : this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}}, selection)}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

class NHLFilters extends React.Component {
    constructor(props) {
        super(props);

        this.currentSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague];
        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                timeframe: {
                    isActive: false,
                    options: Object.fromEntries(["23-24 SEASON", "22-23 SEASON", "21-22 SEASON", "LAST 5 GAMES", "LAST 10 GAMES"].map(x => [x, x])),
                    selectedValue: "23-24 SEASON"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        }
        this.defaultFilterValues = {
            opponent: "ALL",
            timeframe: this.state.filters.timeframe.selectedValue,
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                // We don't have any multi-selection filters here but if we do add it I feel like this would be forgotten
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    filterOptionSelected(event) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        filters[selectedFilter].selectedValue = selectedValue;
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultTimeframe = "SEASON_2023";
        const defaultSplits = "ALL";

        var timeframe = "";
        var season = "";
        const timeframeFilterValue = filters.timeframe.selectedValue;
        switch(timeframeFilterValue) {
            case "23-24 SEASON":
                timeframe = "SEASON_2023";
                season = 2023;
                break;
            case "22-23 SEASON":
                timeframe = "SEASON_2022";
                season = 2022;
                break;
            case "21-22 SEASON":
                timeframe = "SEASON_2021";
                season = 2021;
                break;
            case "LAST 5 GAMES":
                timeframe = "LAST_5";
                season = this.currentSeason;
                break;
            case "LAST 10 GAMES":
                timeframe = "LAST_10";
                season = this.currentSeason;
                break;
            default:
                break;
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?SRGUID=${this.props.player.SRGUID}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        // return (
        //     <Filters filters={this.state.filters} addClickableElementHandler={this.props.addClickableElementHandler} selectionHandler={this.filterOptionSelected} />
        // )
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}})}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

// TODO: need to separate the NHL filter from a sort of 'common' filter because of the timeframe filters 
class NBAFilters extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <NHLFilters activeLeague={this.props.activeLeague} teamFilterSelection={this.props.teamFilterSelection} tab={this.props.tab} 
                        player={this.props.player} addClickableElementHandler={this.props.addClickableElementHandler} 
                        dataUpdateRequestHandler={this.props.dataUpdateRequestHandler} setDisplayLeagueAverageFn={this.props.setDisplayLeagueAverageFn}/>
        )
    }
}

class WNBAFilters extends React.Component {
    constructor(props) {
        super(props);

        this.currentSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague];
        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                timeframe: {
                    isActive: false,
                    options: Object.fromEntries(["2024 SEASON", "2023 SEASON", "2022 SEASON", "LAST 5 GAMES", "LAST 10 GAMES"].map(x => [x, x])),
                    selectedValue: "2024 SEASON"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        }
        this.defaultFilterValues = {
            opponent: "ALL",
            timeframe: this.state.filters.timeframe.selectedValue,
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                // We don't have any multi-selection filters here but if we do add it I feel like this would be forgotten
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    filterOptionSelected(event) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        filters[selectedFilter].selectedValue = selectedValue;
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultTimeframe = "SEASON_2024";
        const defaultSplits = "ALL";

        var timeframe = "";
        var season = "";
        const timeframeFilterValue = filters.timeframe.selectedValue;
        switch(timeframeFilterValue) {
            case "2024 SEASON":
                timeframe = "SEASON_2024";
                season = 2024;
                break;
            case "2023 SEASON":
                timeframe = "SEASON_2023";
                season = 2023;
                break;
            case "2022 SEASON":
                timeframe = "SEASON_2022";
                season = 2022;
                break;
            case "LAST 5 GAMES":
                timeframe = "LAST_5";
                season = this.currentSeason;
                break;
            case "LAST 10 GAMES":
                timeframe = "LAST_10";
                season = this.currentSeason;
                break;
            default:
                break;
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?SRGUID=${this.props.player.SRGUID}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}})}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

class MLBFilters extends React.Component {
    constructor(props) {
        super(props);

        this.currentSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague];
        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                timeframe: {
                    isActive: false,
                    options: Object.fromEntries(["2024 SEASON", "2023 SEASON", "2022 SEASON", "LAST 5 GAMES", "LAST 10 GAMES"].map(x => [x, x])),
                    selectedValue: "2024 SEASON"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        }
        this.defaultFilterValues = {
            opponent: "ALL",
            timeframe: this.state.filters.timeframe.selectedValue,
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                // We don't have any multi-selection filters here but if we do add it I feel like this would be forgotten
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    filterOptionSelected(event) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        filters[selectedFilter].selectedValue = selectedValue;
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultTimeframe = "SEASON_2024";
        const defaultSplits = "ALL";

        var timeframe = "";
        var season = "";
        const timeframeFilterValue = filters.timeframe.selectedValue;
        switch(timeframeFilterValue) {
            case "2024 SEASON":
                timeframe = "SEASON_2024";
                season = 2024;
                break;
            case "2023 SEASON":
                timeframe = "SEASON_2023";
                season = 2023;
                break;
            case "2022 SEASON":
                timeframe = "SEASON_2022";
                season = 2022;
                break;
            case "LAST 5 GAMES":
                timeframe = "LAST_5";
                season = this.currentSeason;
                break;
            case "LAST 10 GAMES":
                timeframe = "LAST_10";
                season = this.currentSeason;
                break;
            default:
                break;
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${this.props.player.SRGUID}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?SRGUID=${this.props.player.SRGUID}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        // return (
        //     <Filters filters={this.state.filters} addClickableElementHandler={this.props.addClickableElementHandler} selectionHandler={this.filterOptionSelected} />
        // )
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}})}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

class CrossYearSoccerFilters extends React.Component {
    constructor(props) {
        super(props);

        this.currentSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague];
        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                timeframe: {
                    isActive: false,
                    options: Object.fromEntries(["24-25 SEASON", "23-24 SEASON", "22-23 SEASON", "LAST 5 GAMES", "LAST 10 GAMES"].map(x => [x, x])),
                    selectedValue: "24-25 SEASON"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        }
        this.defaultFilterValues = {
            opponent: "ALL",
            timeframe: this.state.filters.timeframe.selectedValue,
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                // We don't have any multi-selection filters here but if we do add it I feel like this would be forgotten
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    filterOptionSelected(event) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        filters[selectedFilter].selectedValue = selectedValue;
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultTimeframe = "SEASON_2024";
        const defaultSplits = "ALL";

        var timeframe = "";
        var season = "";
        const timeframeFilterValue = filters.timeframe.selectedValue;
        switch(timeframeFilterValue) {
            case "24-25 SEASON":
                timeframe = "SEASON_2024";
                season = 2021;
                break;
            case "23-24 SEASON":
                timeframe = "SEASON_2023";
                season = 2020;
                break;
            case "22-23 SEASON":
                timeframe = "SEASON_2022";
                season = 2022;
                break;
            case "LAST 5 GAMES":
                timeframe = "LAST_5";
                season = this.currentSeason;
                break;
            case "LAST 10 GAMES":
                timeframe = "LAST_10";
                season = this.currentSeason;
                break;
            default:
                break;
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${getPlayerId(this.props.player)}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${getPlayerId(this.props.player)}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?SRGUID=${getPlayerId(this.props.player)}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        // return (
        //     <Filters filters={this.state.filters} addClickableElementHandler={this.props.addClickableElementHandler} selectionHandler={this.filterOptionSelected} />
        // )
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}})}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

class InYearSoccerFilters extends React.Component {
    constructor(props) {
        super(props);

        this.currentSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague];
        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                timeframe: {
                    isActive: false,
                    options: Object.fromEntries(["2024 SEASON", "LAST 5 GAMES", "LAST 10 GAMES"].map(x => [x, x])),
                    selectedValue: "2024 SEASON"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        }
        this.defaultFilterValues = {
            opponent: "ALL",
            timeframe: this.state.filters.timeframe.selectedValue,
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                // We don't have any multi-selection filters here but if we do add it I feel like this would be forgotten
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    filterOptionSelected(event) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        filters[selectedFilter].selectedValue = selectedValue;
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultTimeframe = "SEASON_2024";
        const defaultSplits = "ALL";

        var timeframe = "";
        var season = "";
        const timeframeFilterValue = filters.timeframe.selectedValue;
        switch(timeframeFilterValue) {
            case "2024 SEASON":
                timeframe = "SEASON_2024";
                season = 2024;
                break;
            case "2023 SEASON":
                timeframe = "SEASON_2023";
                season = 2023;
                break;
            case "2022 SEASON":
                timeframe = "SEASON_2022";
                season = 2022;
                break;
            case "LAST 5 GAMES":
                timeframe = "LAST_5";
                season = this.currentSeason;
                break;
            case "LAST 10 GAMES":
                timeframe = "LAST_10";
                season = this.currentSeason;
                break;
            default:
                break;
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${getPlayerId(this.props.player)}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${getPlayerId(this.props.player)}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?SRGUID=${getPlayerId(this.props.player)}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        // return (
        //     <Filters filters={this.state.filters} addClickableElementHandler={this.props.addClickableElementHandler} selectionHandler={this.filterOptionSelected} />
        // )
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}})}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

class EuroFilters extends React.Component {
    constructor(props) {
        super(props);

        this.currentSeason = LEAGUE_CURRENT_SEASON[this.props.activeLeague];
        this.currentTeam = this.props.teamFilterSelection;
        this.currentPlayer = this.props.player || {};
        this.currentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            this.currentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        this.currentTab = this.props.tab;
        // Options don't need to be in the state but for simplicity sake we'll keep it in here
        this.state = {
            filters: {
                opponent: {
                    isActive: false,
                    options: Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.props.teamFilterSelection], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x])),
                    selectedValue: "ALL"
                },
                timeframe: {
                    isActive: false,
                    options: Object.fromEntries(["Euro 2024", "LAST 5 GAMES", "LAST 10 GAMES"].map(x => [x, x])),
                    selectedValue: "Euro 2024"
                },
                splits: {
                    isActive: false,
                    options: Object.fromEntries(["HOME+AWAY", "HOME", "AWAY"].map(x => [x, x])),
                    selectedValue: "HOME+AWAY"
                }
            }
        }
        this.defaultFilterValues = {
            opponent: "ALL",
            timeframe: this.state.filters.timeframe.selectedValue,
            splits: "HOME+AWAY"
        };
        this.filterOptionSelected = this.filterOptionSelected.bind(this);
        this.generateDataUpdateRequest = this.generateDataUpdateRequest.bind(this);
    }

    componentDidMount() {
        this.generateDataUpdateRequest(this.state.filters);
    }

    componentDidUpdate() {
        this.currentPlayer = this.props.player || {};
        var tempCurrentPlayerName = "";
        if (!isDictEmpty(this.currentPlayer)) {
            tempCurrentPlayerName = `${this.currentPlayer.info.firstName} ${this.currentPlayer.info.lastName}`;
        }
        // Need to be careful about updating state within the componentDidUpdate, but here it should only happen once
        if (this.currentTeam !== this.props.teamFilterSelection || this.currentTab !== this.props.tab || this.currentPlayerName !== tempCurrentPlayerName) {
            var filters = this.state.filters;
            // Reset all the filter values to their default state
            Object.keys(filters).map((key) => {
                // We don't have any multi-selection filters here but if we do add it I feel like this would be forgotten
                if (filters[key].type === "multipleSelection") {
                    filters[key].selectedValues = this.defaultFilterValues[key];
                } else {
                    filters[key].selectedValue = this.defaultFilterValues[key];
                }
            });
            // Rebuild the opponent list when the team changes to remove itself from the list
            filters.opponent.options = Object.fromEntries(["ALL"].concat(buildOpponentList(TEAM_CODE_TO_CITY[this.props.activeLeague][this.currentTeam], Object.keys(TEAM_CITY_TO_CODE[this.props.activeLeague.toLowerCase()]))).map(x => [x, x]));
            this.currentTeam = this.props.teamFilterSelection;
            this.currentTab = this.props.tab;
            this.currentPlayerName = tempCurrentPlayerName;
            this.setState({filters: filters});
            this.generateDataUpdateRequest(filters);
        }
    }

    filterOptionSelected(event) {
        const selectedFilter = event.currentTarget.dataset.name;
        const selectedValue = event.currentTarget.dataset.selection;
        var filters = this.state.filters;
        filters[selectedFilter].isActive = false;
        filters[selectedFilter].selectedValue = selectedValue;
        this.setState({filters: filters});
        this.generateDataUpdateRequest(filters);
    }

    generateDataUpdateRequest(filters) {
        // TODO: url encode
        // TODO: we can use a query params builder
        const defaultOpponent = "ALL";
        const defaultTimeframe = "SEASON_2024";
        const defaultSplits = "ALL";

        var timeframe = "";
        var season = "";
        const timeframeFilterValue = filters.timeframe.selectedValue;
        switch(timeframeFilterValue) {
            case "Euro 2024":
                timeframe = "SEASON_2024";
                season = 2024;
                break;
            case "Euro 2021":
                timeframe = "SEASON_2021";
                season = 2023;
                break;
            case "Euro 2016":
                timeframe = "SEASON_2016";
                season = 2022;
                break;
            case "LAST 5 GAMES":
                timeframe = "LAST_5";
                season = this.currentSeason;
                break;
            case "LAST 10 GAMES":
                timeframe = "LAST_10";
                season = this.currentSeason;
                break;
            default:
                break;
        }

        var split = "ALL";
        if (filters.splits.selectedValue !== "HOME+AWAY") {
            split = filters.splits.selectedValue;
        }

        var opponent = TEAM_CITY_TO_CODE[this.props.activeLeague][filters.opponent.selectedValue];
        if (filters.opponent.selectedValue === "ALL") {
            opponent = "ALL";
        }

        var dataRequestObjects = [
            {
                url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/upcomingForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&split=${split}`,
                target: "upcomingForm"
            }
        ];

        if (this.props.tab === "team") {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/averages?season=${season}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/teams/filtered/${this.props.teamFilterSelection}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForTeam?teamCode=${this.props.teamFilterSelection}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        } else if (this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${getPlayerId(this.props.player)}?opponent=${opponent}&timeframe=${timeframe}&split=${split}`,
                    target: "teamData"
                }
            );
            if (opponent === defaultOpponent && split === defaultSplits) {
                this.props.setDisplayLeagueAverageFn(true);
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/averages?season=${season}&position=${this.props.player.info.position}`,
                        target: "leagueAverages"
                    }
                );
            } else {
                this.props.setDisplayLeagueAverageFn(false);
                // Same as teamData for the season, essentially setting the average as the team's seasonal average
                dataRequestObjects.push(
                    {
                        url: `${API_HOST}/api/${this.props.activeLeague}/v1/players/filteredBySRGUID/${getPlayerId(this.props.player)}?opponent=${defaultOpponent}&timeframe=${season === this.currentSeason ? defaultTimeframe : timeframe}&split=${defaultSplits}`,
                        target: "leagueAverages"
                    }
                );
            }
            dataRequestObjects.push(
                {
                    url: `${API_HOST}/api/${this.props.activeLeague}/v1/games/filteredForPlayer?SRGUID=${getPlayerId(this.props.player)}&opponent=${opponent}&timeframe=${timeframe}&split=${split}&sortingOrder=DESC`,
                    target: "gamelog"
                }
            );
        }

        this.props.dataUpdateRequestHandler(dataRequestObjects);
    }

    render() {
        // return (
        //     <Filters filters={this.state.filters} addClickableElementHandler={this.props.addClickableElementHandler} selectionHandler={this.filterOptionSelected} />
        // )
        return (
            <>
            {
                Object.keys(this.state.filters).map((filter) => 
                    <div key={filter} className='screener-header-filter' style={{'--filters-count': Object.keys(this.state.filters).length}}>
                        <p className='text-style-label-medium'>{capitalizeFirstLetter(filter)}</p>
                        <div>
                            <InputSelection 
                                enabled={true} 
                                type="single" 
                                typography="md" 
                                options={this.state.filters[filter].options} 
                                selection={this.state.filters[filter].selectedValue} 
                                selectionHandler={(selection) => this.filterOptionSelected({currentTarget: {dataset: {name: filter, selection: selection}}})}
                            />
                        </div>
                    </div>
                )
            }
            </>
        )
    }
}

class NFLScoring extends React.Component {
    constructor(props) {
        super(props);

        this.teamScoringStatsTemplate = {
            // Note: changed PF to PTS since PF is points for (doesn't really work in the offensive/defensive scheme)
            "PTS": "%quantifier%.%type%.points",
            "Score %": "%quantifier%.%type%.scorePercentage",
            "Plays": "%quantifier%.%type%.plays",
            "Net Yds": "%quantifier%.%type%.totalYards",
            "Pass Yds": "%quantifier%.%type%.passingYards",
            "Pass Att": "%quantifier%.%type%.passingAttempts",
            "Pass Compl": "%quantifier%.%type%.passingCompletions",
            "Pass TD": "%quantifier%.%type%.passingTouchdowns",
            "Rush Yds": "%quantifier%.%type%.rushingYards",
            "Rush Att": "%quantifier%.%type%.rushingAttempts",
            "Rush TD": "%quantifier%.%type%.rushingTouchdowns",
            "Total TDs": "%quantifier%.%type%.totalTouchdowns",
            "RZ%": "%quantifier%.%type%.redzoneSuccessRate",
            "1D": "%quantifier%.%type%.firstDowns",
            "3D": "%quantifier%.%type%.thirdDownConversionAttempts",
            "3D%": "%quantifier%.%type%.thirdDownConversionPercentage",
            "4D%": "%quantifier%.%type%.fourthDownConversionPercentage",
            "INT": "%quantifier%.%type%.interceptions",
            "TO": "%quantifier%.%type%.turnovers",
            "Sacks": "%quantifier%.%type%.sacks",
            "FUML": "%quantifier%.%type%.fumblesLost"
        };

        this.passingPlayerScoringStatsTemplate = {
            "Pass Rtg": "%quantifier%.rating",
            "Comp": "%quantifier%.passingCompletions",
            "Att": "%quantifier%.passingAttempts",
            "Yds": "%quantifier%.passingYards",
            "TD": "%quantifier%.passingTouchdowns",
            "INT": "%quantifier%.interceptions",
            "Yds/Att": "%quantifier%.yardsPerAttempt",
            // "300+ Yds": "%quantifier%.threeHundredYardsPassing",
            "RZ Att": "%quantifier%.redzonePassingAttempts",
            "RZ Compl": "%quantifier%.redzoneCompletions",
            "RZ TD": "%quantifier%.redzonePassingTouchdowns"
        };

        this.rushingPlayerScoringStatsTemplate = {
            "Att": "%quantifier%.rushingAttempts",
            "Yds": "%quantifier%.rushingYards",
            "TD": "%quantifier%.rushingTouchdowns",
            "Yds/Att": "%quantifier%.rushingYardsPerAttempt",
            "Long": "%quantifier%.longestRun",
            // "100+ Yds": "%quantifier%.hundredYardsRushing",
            "RZ Att": "%quantifier%.redzoneRushingAttempts",
            "RZ TD": "%quantifier%.redzoneRushingTouchdowns",
        };

        this.receivingPlayerScoringStatsTemplate = {
            "Tgt": "%quantifier%.targets",
            "Rec": "%quantifier%.receptions",
            "Yds": "%quantifier%.receivingYards",
            "TD": "%quantifier%.receivingTouchdowns",
            "Yds/Rec": "%quantifier%.receivingYardsPerReception",
            // "100+ Yds": "%quantifier%.hundredYardsReceiving",
            "RZ Tgt": "%quantifier%.redzoneTargets",
            "RZ Rec": "%quantifier%.redzoneReceptions",
            "RZ TD": "%quantifier%.redzoneReceivingTouchdowns"
        };

        this.kickingPlayerScoringStatsTemplate = {
            "PTS": "%quantifier%.pointsKicked",
            "FGA": "%quantifier%.fieldGoalAttempts",
            "FGM": "%quantifier%.fieldGoalsMade",
            "FG%": "%quantifier%.fieldGoalPercentage",
            "XPA": "%quantifier%.extraPointAttempts",
            "XPM": "%quantifier%.extraPointsMade",
            "XP%": "%quantifier%.extraPointPercentage",
            "FG (0-19)": "%quantifier%.extraShortRangeFieldGoals",
            "FG (20-29)": "%quantifier%.shortRangeFieldGoals",
            "FG (30-39)": "%quantifier%.mediumRangeFieldGoals",
            "FG (40-49)": "%quantifier%.longRangeFieldGoals",
            "FG (50+)": "%quantifier%.extraLongRangeFieldGoals"
        };

        this.statsTemplate = {};
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.type === "passing") {
                this.statsTemplate = this.passingPlayerScoringStatsTemplate;
            }
            if (this.props.type === "rushing") {
                this.statsTemplate = this.rushingPlayerScoringStatsTemplate;
            }
            if (this.props.type === "receiving") {
                this.statsTemplate = this.receivingPlayerScoringStatsTemplate;
            }
            if (this.props.type === "kicking") {
                this.statsTemplate = this.kickingPlayerScoringStatsTemplate;
            }
        }
    }

    componentDidUpdate() {
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.type === "passing") {
                this.statsTemplate = this.passingPlayerScoringStatsTemplate;
            }
            if (this.props.type === "rushing") {
                this.statsTemplate = this.rushingPlayerScoringStatsTemplate;
            }
            if (this.props.type === "receiving") {
                this.statsTemplate = this.receivingPlayerScoringStatsTemplate;
            }
            if (this.props.type === "kicking") {
                this.statsTemplate = this.kickingPlayerScoringStatsTemplate;
            }
        }
    }

    render() {
        return (
            <CommonScoring  teamData={this.props.teamData} statsTemplate={this.statsTemplate} type={this.props.type} tab={this.props.tab} player={this.props.player}
                            teamStanding={this.props.teamStanding} activeLeague={this.props.activeLeague} displayLeagueAverage={this.props.displayLeagueAverage}
                            team={this.props.team} leagueAverages={this.props.leagueAverages} statsCount={Object.keys(this.statsTemplate).length} />
        )
    }
}

class NHLScoring extends React.Component {
    constructor(props) {
        super(props);

        this.teamScoringStatsTemplate = {
            "G": "%quantifier%.%type%.goals",
            "SOG": "%quantifier%.%type%.shotsOnGoal",
            "SAT": "%quantifier%.%type%.shotAttempts",
            "CF%": "%quantifier%.%type%.corsiPercentage",
            "BLK": "%quantifier%.%type%.defensiveBlocks",
            "PIM": "%quantifier%.%type%.penaltyMinutes",
            // "HDC": "%quantifier%.%type%.highDangerChances",
            // "xG": "%quantifier%.%type%.expectedGoals"
            "PPG": "%quantifier%.%type%.powerplayGoals",
            "PP%": "%quantifier%.%type%.powerplayPercentage",
        };

        // this.teamSpecialTeamsStatsTemplate = {
        //     "PPG": "%quantifier%.%type%.powerplayGoals",
        //     "PP%": "%quantifier%.%type%.powerplayPercentage",
        // };

        this.skatersScoringStatsTemplate = {
            "PTS": "%quantifier%.points",
            "G": "%quantifier%.goals",
            "A": "%quantifier%.assists",
            "SOG": "%quantifier%.shotsOnGoal",
            "SAT": "%quantifier%.shotAttempts",
            // "+/-": "%quantifier%.plusMinus",
            "PPP": "%quantifier%.powerplayPoints",
            "SHP": "%quantifier%.shorthandedPoints",
            "PIM": "%quantifier%.penaltyMinutes",
            "BLK": "%quantifier%.defensiveBlocks",
            "TOI": "%quantifier%.timeOnIce"
            // "HDC": "",
            // "xG": ""
        };

        this.goaliesStatsTemplate = {
            "WINS": "%quantifier%.wins",
            "SA": "%quantifier%.shotsAgainst",
            "SAVES": "%quantifier%.shotsSaved",
            "SV%": "%quantifier%.savePercentage",
            "GA": "%quantifier%.goalsAllowed",
            "SHUTOUTS": "%quantifier%.shutouts"
        };

        this.statsTemplate = {};
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "RW" || this.props.player.info.position === "LW" || this.props.player.info.position === "C" || this.props.player.info.position === "D") {
                this.statsTemplate = this.skatersScoringStatsTemplate;
            }
            if (this.props.player.info.position === "G") {
                this.statsTemplate = this.goaliesStatsTemplate;
            }
        }
    }

    componentDidUpdate() {
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "RW" || this.props.player.info.position === "LW" || this.props.player.info.position === "C" || this.props.player.info.position === "D") {
                this.statsTemplate = this.skatersScoringStatsTemplate;
            }
            if (this.props.player.info.position === "G") {
                this.statsTemplate = this.goaliesStatsTemplate;
            }
        }
    }

    render() {
        return (
            <CommonScoring  teamData={this.props.teamData} statsTemplate={this.statsTemplate} type={this.props.type} tab={this.props.tab} player={this.props.player}
                            teamStanding={this.props.teamStanding} activeLeague={this.props.activeLeague} displayLeagueAverage={this.props.displayLeagueAverage}
                            team={this.props.team} leagueAverages={this.props.leagueAverages} statsCount={Object.keys(this.statsTemplate).length} />
        )
    }
}

class NBAScoring extends React.Component {
    constructor(props) {
        super(props);

        this.teamScoringStatsTemplate = {
            "PTS": "%quantifier%.%type%.points", // Same as NFL, changing PF to PTS
            "FT%": "%quantifier%.%type%.freeThrowPercentage",
            "FG%": "%quantifier%.%type%.fieldGoalPercentage",
            "3PT%": "%quantifier%.%type%.threePointPercentage",
            "TS%": "%quantifier%.%type%.trueShootingPercentage",
            "eFG%": "%quantifier%.%type%.effectiveFieldGoalPercentage",
            "TRB": "%quantifier%.%type%.rebounds",
            "AST": "%quantifier%.%type%.assists",
            "STL": "%quantifier%.%type%.steals",
            "TO": "%quantifier%.%type%.turnovers",
            "BLK": "%quantifier%.%type%.blocks",
            "PACE": "%quantifier%.%type%.pace"
        };

        this.playerScoringStatsTemplate = {
            "PTS": "%quantifier%.points",
            "FTA": "%quantifier%.freeThrowAttempts",
            "FTM": "%quantifier%.freeThrowsMade",
            "FT%": "%quantifier%.freeThrowPercentage",
            "FGA": "%quantifier%.fieldGoalAttempts",
            "FGM": "%quantifier%.fieldGoalsMade",
            "FG%": "%quantifier%.fieldGoalPercentage",
            "3PTA": "%quantifier%.threePointAttempts",
            "3PTM": "%quantifier%.threePointsMade",
            "3PT%": "%quantifier%.threePointPercentage",
            "TS%": "%quantifier%.trueShootingPercentage",
            "eFG%": "%quantifier%.effectiveFieldGoalPercentage",
            "REB": "%quantifier%.rebounds",
            "AST": "%quantifier%.assists",
            "STL": "%quantifier%.steals",
            "TO": "%quantifier%.turnovers",
            "BLK": "%quantifier%.blocks",
	    "MIN": "%quantifier%.minutesPlayed"
        };

        this.statsTemplate = {};
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            this.statsTemplate = this.playerScoringStatsTemplate;
        }
    }

    componentDidUpdate() {
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            this.statsTemplate = this.playerScoringStatsTemplate;
        }
    }

    render() {
        return (
            <CommonScoring  teamData={this.props.teamData} statsTemplate={this.statsTemplate} type={this.props.type} tab={this.props.tab} player={this.props.player}
                            teamStanding={this.props.teamStanding} activeLeague={this.props.activeLeague} displayLeagueAverage={this.props.displayLeagueAverage}
                            team={this.props.team} leagueAverages={this.props.leagueAverages} statsCount={Object.keys(this.statsTemplate).length} />
        )
    }
}

class MLBScoring extends React.Component {
    constructor(props) {
        super(props);

        this.teamScoringStatsTemplate = {
            "R": "%quantifier%.%type%.runs",
            "H": "%quantifier%.%type%.hits",
            "1B": "%quantifier%.%type%.singles",
            "2B": "%quantifier%.%type%.doubles",
            "3B": "%quantifier%.%type%.triples",
            "HR": "%quantifier%.%type%.homeRuns",
            "RBI": "%quantifier%.%type%.runsBattedIn",
            "BB": "%quantifier%.%type%.walks",
            "SO": "%quantifier%.%type%.strikeouts",
            "E": "%quantifier%.%type%.errors",
            "AVG": "%quantifier%.%type%.battingAverage",
            "OPS": "%quantifier%.%type%.onBasePlusSluggingPercentage"
        };

        this.pitchersScoringStatsTemplate = {
            "W": "%quantifier%.pitching.wins",
            "L": "%quantifier%.pitching.losses",
            "IP": "%quantifier%.pitching.inningsPitched",
            "ERA": "%quantifier%.pitching.earnedRunsAverage",
            "SV": "%quantifier%.pitching.saves",
            "H": "%quantifier%.pitching.hits",
            "R": "%quantifier%.pitching.runs",
            "HR": "%quantifier%.pitching.homeRuns",
            "ER": "%quantifier%.pitching.earnedRuns",
            "BB": "%quantifier%.pitching.walks",
            "SO": "%quantifier%.pitching.strikeouts",
            "WHIP": "%quantifier%.pitching.walksAndHitsPerInningPitched",
            "AVG": "%quantifier%.pitching.battingAverageAgainst"
        };

        this.hittersStatsTemplate = {
            "AB": "%quantifier%.hitting.atBats",
            "R": "%quantifier%.hitting.runs",
            "H": "%quantifier%.hitting.hits",
            "1B": "%quantifier%.hitting.singles",
            "2B": "%quantifier%.hitting.doubles",
            "3B": "%quantifier%.hitting.triples",
            "HR": "%quantifier%.hitting.homeRuns",
            "ISO": "%quantifier%.hitting.isolatedPower",
            "RBI": "%quantifier%.hitting.runsBattedIn",
            "TB": "%quantifier%.hitting.totalBases",
            "BB": "%quantifier%.hitting.walks",
            "SO": "%quantifier%.hitting.strikeouts",
            "AVG": "%quantifier%.hitting.battingAverage",
            "OPS": "%quantifier%.hitting.onBasePlusSluggingPercentage"
        };

        this.statsTemplate = {};
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "SP" || this.props.player.info.position === "RP") {
                this.statsTemplate = this.pitchersScoringStatsTemplate;
            } else {
                this.statsTemplate = this.hittersStatsTemplate;
            }
        }
    }

    componentDidUpdate() {
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "SP" || this.props.player.info.position === "RP") {
                this.statsTemplate = this.pitchersScoringStatsTemplate;
            } else {
                this.statsTemplate = this.hittersStatsTemplate;
            }
        }
    }

    render() {
        return (
            <CommonScoring  teamData={this.props.teamData} statsTemplate={this.statsTemplate} type={this.props.type} tab={this.props.tab} player={this.props.player}
                            teamStanding={this.props.teamStanding} activeLeague={this.props.activeLeague} displayLeagueAverage={this.props.displayLeagueAverage}
                            team={this.props.team} leagueAverages={this.props.leagueAverages} statsCount={Object.keys(this.statsTemplate).length} />
        )
    }
}

class SoccerScoring extends React.Component {
    constructor(props) {
        super(props);

        this.teamScoringStatsTemplate = {
            "G": "%quantifier%.%type%.goals",
            "POS": "%quantifier%.%type%.ballPossession",
            "SHO": "%quantifier%.%type%.shotsTotal",
            "SOT": "%quantifier%.%type%.shotsOnTarget",
            "COR": "%quantifier%.%type%.cornerKicks",
            "OFF": "%quantifier%.%type%.offsides",
            "FLS": "%quantifier%.%type%.fouls",
            "2YELRED": "%quantifier%.%type%.doubleYellowRedCards",
            "YEL": "%quantifier%.%type%.yellowCards",
            "RED": "%quantifier%.%type%.redCards"
        };

        this.outfieldersScoringStatsTemplate = {
            "G": "%quantifier%.goals",
            "A": "%quantifier%.assists",
            "SOT": "%quantifier%.shotsOnTarget",
            "SHO": "%quantifier%.totalShots",
            "MIN": "%quantifier%.minutesPlayed",
            // "CC": "%quantifier%.chancesCreated",
            "PAS": "%quantifier%.totalPasses",
            "DRI": "%quantifier%.dribblesCompleted",
            "LOP": "%quantifier%.lossOfPossession",
            "TCK": "%quantifier%.totalTackles",
            "FLS": "%quantifier%.foulsCommitted",
            "INT": "%quantifier%.interceptions",
            "YEL": "%quantifier%.yellowCards",
            "2YELRED": "%quantifier%.yellowRedCards",
            "RED": "%quantifier%.redCards"
        };

        this.goaliesStatsTemplate = {
            "GA": "%quantifier%.goalsConceded",
            "SAV": "%quantifier%.shotsFacedSaved",
            "SA": "%quantifier%.shotsFacedTotal",
            "PS": "%quantifier%.penaltiesSaved",
            "DSAV": "%quantifier%.divingSaves",
            "CS": "%quantifier%.cleanSheet",
            "YEL": "%quantifier%.yellowCards",
            "2YELRED": "%quantifier%.doubleYellowRedCards",
            "RED": "%quantifier%.redCards"
        };

        this.statsTemplate = {};
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "goalkeeper") {
                this.statsTemplate = this.goaliesStatsTemplate;
            } else {
                this.statsTemplate = this.outfieldersScoringStatsTemplate;
            }
        }
    }

    componentDidUpdate() {
        if (this.props.tab === "team") {
            this.statsTemplate = this.teamScoringStatsTemplate;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "goalkeeper") {
                this.statsTemplate = this.goaliesStatsTemplate;
            } else {
                this.statsTemplate = this.outfieldersScoringStatsTemplate;
            }
        }
    }

    render() {
        return (
            <CommonScoring  teamData={this.props.teamData} statsTemplate={this.statsTemplate} type={this.props.type} tab={this.props.tab} player={this.props.player}
                            teamStanding={this.props.teamStanding} activeLeague={this.props.activeLeague} displayLeagueAverage={this.props.displayLeagueAverage}
                            team={this.props.team} leagueAverages={this.props.leagueAverages} statsCount={Object.keys(this.statsTemplate).length} />
        )
    }
}

class CommonScoring extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (isDictEmpty(this.props.teamData) || isDictEmpty(this.props.teamStanding)) {
            return (<></>)
        }
        return (
            <>  
                <div className="screener-per-game-offence">
                    {/* This is a copy of the mobile one with some adjustments, should consider extracting the functionality and making it configurable to some extent */}
                    <p className="font size-20 weight-600" style={{display: 'block', width: '100%', textAlign: 'left', marginTop: '24px'}}>Per Game</p>
                    <div className="screener-comparative-table-body">
                        {
                            isDictEmpty(this.props.teamData) || isDictEmpty(this.props.teamStanding) ?
                            <></>:
                            <>
                            <div className="screener-comparative-tbl-header">
                                <div className="screener-tbl-header-away" style={{backgroundColor: COLOR_CODES[this.props.activeLeague][this.props.teamStanding.code].primary}}>
                                    {
                                        this.props.displayLeagueAverage ?
                                        <span className="font size-20 weight-600">Season Average</span> :
                                        <span className="font size-20 weight-600">Average</span>
                                    }
                                </div>
                                <div className="screener-tbl-header-home">
                                    {
                                        this.props.displayLeagueAverage ? 
                                        <span className="font size-20 weight-600">League Average</span> : 
                                        <span className="font size-20 weight-600">Season Average</span>
                                    }
                                </div>
                            </div>
                            {
                                Object.keys(this.props.statsTemplate).slice(0, this.props.statsCount).map((key) => (
                                    <React.Fragment key={key}>{getCommonMobilePerGameScoringListItem(key, this.props.teamData, this.props.leagueAverages, this.props.statsTemplate, this.props.type)}</React.Fragment>
                                ))
                            }
                            </>
                        }
                    </div>
                </div>
                <div className="screener-per-game-offence-mobile">
                    {/* This is a copy of the mobile one with some adjustments, should consider extracting the functionality and making it configurable to some extent */}
                    <p className="font size-20 weight-600" style={{display: 'block', width: '100%', textAlign: 'left', marginTop: '24px'}}>Per Game</p>
                    <div className="screener-comparative-table-body">
                        {
                            isDictEmpty(this.props.teamData) || isDictEmpty(this.props.teamStanding) ?
                            <></>:
                            <>
                            <div className="screener-comparative-tbl-header">
                                <div className="screener-tbl-header-away" style={{backgroundColor: COLOR_CODES[this.props.activeLeague][this.props.teamStanding.code].primary}}>
                                    {
                                        this.props.displayLeagueAverage ?
                                        <span className="font size-16 weight-600 spaced">Season Avg.</span> : 
                                        <span className="font size-16 weight-600 spaced">Avg.</span>
                                    }
                                </div>
                                <div className="screener-tbl-header-home">
                                {
                                        this.props.displayLeagueAverage ?
                                        <span className="font size-16 weight-600 spaced">League Avg.</span> : 
                                        <span className="font size-16 weight-600 spaced">Season Avg.</span>
                                    }
                                </div>
                            </div>
                            {
                                Object.keys(this.props.statsTemplate).slice(0, this.props.statsCount).map((key) => (
                                    <React.Fragment key={key}>{getCommonMobilePerGameScoringListItem(key, this.props.teamData, this.props.leagueAverages, this.props.statsTemplate, this.props.type)}</React.Fragment>
                                ))
                            }
                            </>
                        }
                    </div>
                    {/* {getTotalSection(this.props.statsCount, "screener-total-offence-mobile", this.props.teamData, this.props.statsTemplate, this.props.type)} */}
                </div>
                {getHorizontalTotalSection(this.props.statsCount, "screener-total-offence", this.props.teamData, this.props.statsTemplate, this.props.type, this.props.activeLeague, this.props.teamStanding.code)}
            </>
        )
    }
}

class NFLForm extends React.Component {
    constructor(props) {
        super(props);

        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentFormTies = 0;
        // For the color, use WIN = primary color, LOSS = faded primary color, TIE = double faded primary color
        // Need to use hex to HSL for the fading and then back to hex to add to the pie chart data
        this.formPieChartData = [
            {
                id: "W",
                label: "W",
                value: this.recentFormWins,
                color: "#FFFFFF"
            },
            {
                id: "L",
                label: "L",
                value: this.recentFormLosses,
                color: "#FFFFFF"
            },
            {
                id: "TIE",
                label: "TIE",
                value: this.recentFormTies,
                color: "#FFFFFF"
            }
        ];

        this.winField = "win";
        this.currentTeamPointsField = "cumulativeStats.offensive.points";
        this.opposingTeamPointsField = "cumulativeStats.defensive.points";

        // Props-based data
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        // Used for the coloring
        this.teamStanding = this.props.teamStanding || {};
        this.setup();
    }

    componentDidUpdate() {
        this.setup();
    }

    setup() {
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        this.teamStanding = this.props.teamStanding || {};
        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentFormTies = 0;
        this.recentForm.forEach((gameResult, index) => {
            if (gameResult.win === 1) {
                this.recentFormWins += 1;
            }
            if (gameResult.loss === 1) {
                this.recentFormLosses += 1;
            }
            if (gameResult.tie === 1) {
                this.recentFormTies += 1;
            }
        });
        this.formPieChartData[0].value = this.recentFormWins;
        this.formPieChartData[1].value = this.recentFormLosses;
        this.formPieChartData[2].value = this.recentFormTies;
        if (!isDictEmpty(this.teamStanding)) {
            this.formPieChartData[0].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].primary;
            this.formPieChartData[1].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].secondary;
            this.formPieChartData[2].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].tertiary;
        }
    }

    render() {
        return (
            <>
                <div className="screener-pie-chart">
                    {
                        this.recentForm.length > 0 ?
                        <ResponsivePie
                        data={this.formPieChartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={2}
                        cornerRadius={6}
                        activeOuterRadiusOffset={20}
                        colors={{ datum: 'data.color' }}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        enableArcLinkLabels={false}
                        arcLabelsTextColor="#ffffff"
                        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 10 ] ] }}
                        // arcLabelsTextColor={["#7D8DAB", "#00205B", "#4B618B"]}
                        // arcLabelsTextColor={{ datum: 'data.color' }}
                        defs={[]}
                        fill={[]}
                        motionConfig="wobbly"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -50,
                                translateY: -40,
                                itemsSpacing: 8,
                                itemWidth: 100,
                                itemHeight: 32,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                        />
                        :
                        <></>
                    }
                </div>
                <hr className="screener-divider"/>
                <div className="screener-forms-card-list">
                    <div className="screener-recent-form">
                        <h5>Recent Form</h5>
                        <div className="screener-game-card-group">
                            {
                                this.recentForm.length > 0 ?
                                <>
                                {
                                    this.recentForm.slice(0, 5).map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {
                                                    (game.win ? "WIN" : 
                                                    (game.loss ? "LOSS": "TIE"))
                                                    +
                                                    " - "
                                                    +
                                                    parseDateFromString(game.timestamp).formattedDayMonthShort
                                                    // Note: here testing adding the year just in case since we're showing for the last 2-3 years and it can be confusing
                                                    + ", " + (parseDateFromString(game.timestamp).year % 100)
                                                }
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{game.isHome ? "VS. " : "@ "}{game.opposingTeam}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                {/* game.score/opposingScore is for the format of the player game record, the one with the off/def stats is team */}
                                                <span className="text-base">
                                                    {
                                                        "score" in game ? game.score : game.cumulativeStats.offensive.points
                                                    }
                                                     - 
                                                    {
                                                        "opposingScore" in game ? game.opposingScore : game.cumulativeStats.defensive.points
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="screener-upcoming-games">
                        <h5>Upcoming Games</h5>
                        <div className="screener-game-card-group">
                            {
                                (this.upcomingForm.length > 0 && !isDictEmpty(this.teamStanding))?
                                <>
                                {
                                    this.upcomingForm.map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {parseDateFromString(game.timestamp).formattedDayMonthShort}
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, getOpposingTeamCode(game, this.teamStanding.code))} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{isHomeGame(game, this.teamStanding.code) ? "VS. " : "@ "}{getOpposingTeamCode(game, this.teamStanding.code)}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                <span className="text-base">{parseDateFromString(game.timestamp).formattedTime}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class NHLForm extends React.Component {
    constructor(props) {
        super(props);

        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentFormOvertimeLosses = 0;
        // For the color, use WIN = primary color, LOSS = faded primary color, OTL = double faded primary color
        // Need to use hex to HSL for the fading and then back to hex to add to the pie chart data
        this.formPieChartData = [
            {
                id: "W",
                label: "W",
                value: this.recentFormWins,
                color: "#FFFFFF"
            },
            {
                id: "L",
                label: "L",
                value: this.recentFormLosses,
                color: "#FFFFFF"
            },
            {
                id: "OTL",
                label: "OTL",
                value: this.recentFormOvertimeLosses,
                color: "#FFFFFF"
            }
        ];

        this.winField = "win";
        this.currentTeamScore = "cumulativeStats.offensive.goals";
        this.opposingTeamscore = "cumulativeStats.defensive.goals";

        // Props-based data
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        // Used for the coloring
        this.teamStanding = this.props.teamStanding || {};
        this.setup();
    }

    componentDidUpdate() {
        this.setup();
    }

    setup() {
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        this.teamStanding = this.props.teamStanding || {};
        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentFormOvertimeLosses = 0;
        this.recentForm.forEach((gameResult, index) => {
            if (gameResult.win === 1) {
                this.recentFormWins += 1;
            }
            if (gameResult.loss === 1) {
                this.recentFormLosses += 1;
            }
            if (gameResult.overtimeLoss === 1) {
                this.recentFormOvertimeLosses += 1;
            }
        });
        this.formPieChartData[0].value = this.recentFormWins;
        this.formPieChartData[1].value = this.recentFormLosses;
        this.formPieChartData[2].value = this.recentFormOvertimeLosses;
        if (!isDictEmpty(this.teamStanding)) {
            this.formPieChartData[0].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].primary;
            this.formPieChartData[1].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].secondary;
            this.formPieChartData[2].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].tertiary;
        }
    }

    render() {
        return (
            <>
                <div className="screener-pie-chart">
                    {
                        this.recentForm.length > 0 ?
                        <ResponsivePie
                        data={this.formPieChartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={2}
                        cornerRadius={6}
                        activeOuterRadiusOffset={20}
                        colors={{ datum: 'data.color' }}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        enableArcLinkLabels={false}
                        arcLabelsTextColor="#ffffff"
                        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 10 ] ] }}
                        // arcLabelsTextColor={["#7D8DAB", "#00205B", "#4B618B"]}
                        // arcLabelsTextColor={{ datum: 'data.color' }}
                        defs={[]}
                        fill={[]}
                        motionConfig="wobbly"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -50,
                                translateY: -40,
                                itemsSpacing: 8,
                                itemWidth: 100,
                                itemHeight: 32,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                        />
                        :
                        <></>
                    }
                </div>
                <hr className="screener-divider"/>
                <div className="screener-forms-card-list">
                    <div className="screener-recent-form">
                        <h5>Recent Form</h5>
                        <div className="screener-game-card-group">
                            {
                                this.recentForm.length > 0 ?
                                <>
                                {
                                    this.recentForm.slice(0, 5).map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {
                                                    (game.win === 1 ? "WIN" : 
                                                    (game.loss === 1 ? "LOSS": "OTL"))
                                                    +
                                                    " - "
                                                    +
                                                    parseDateFromString(game.timestamp).formattedDayMonthShort
                                                }
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{game.isHome ? "VS. " : "@ "}{game.opposingTeam}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                {/* game.score/opposingScore is for the format of the player game record, the one with the off/def stats is team */}
                                                <span className="text-base">
                                                    {
                                                        "score" in game ? game.score : game.cumulativeStats.offensive.goals
                                                    }
                                                     - 
                                                    {
                                                        "opposingScore" in game ? game.opposingScore : game.cumulativeStats.defensive.goals
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="screener-upcoming-games">
                        <h5>Upcoming Games</h5>
                        <div className="screener-game-card-group">
                            {
                                (this.upcomingForm.length > 0 && !isDictEmpty(this.teamStanding))?
                                <>
                                {
                                    this.upcomingForm.map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {parseDateFromString(game.timestamp).formattedDayMonthShort}
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, getOpposingTeamCode(game, this.teamStanding.code))} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{isHomeGame(game, this.teamStanding.code) ? "VS. " : "@ "}{getOpposingTeamCode(game, this.teamStanding.code)}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                <span className="text-base">{parseDateFromString(game.timestamp).formattedTime}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class NBAForm extends React.Component {
    constructor(props) {
        super(props);

        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        // For the color, use WIN = primary color, LOSS = faded primary color
        // Need to use hex to HSL for the fading and then back to hex to add to the pie chart data
        this.formPieChartData = [
            {
                id: "W",
                label: "W",
                value: this.recentFormWins,
                color: "#FFFFFF"
            },
            {
                id: "L",
                label: "L",
                value: this.recentFormLosses,
                color: "#FFFFFF"
            }
        ];

        this.winField = "win";
        this.currentTeamScore = "cumulativeStats.offensive.points";
        this.opposingTeamscore = "cumulativeStats.defensive.points";

        // Props-based data
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        // Used for the coloring
        this.teamStanding = this.props.teamStanding || {};
        this.setup();
    }

    componentDidUpdate() {
        this.setup();
    }

    setup() {
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        this.teamStanding = this.props.teamStanding || {};
        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentForm.forEach((gameResult, index) => {
            if (gameResult.win === 1) {
                this.recentFormWins += 1;
            }
            if (gameResult.loss === 1) {
                this.recentFormLosses += 1;
            }
        });
        this.formPieChartData[0].value = this.recentFormWins;
        this.formPieChartData[1].value = this.recentFormLosses;
        if (!isDictEmpty(this.teamStanding)) {
            this.formPieChartData[0].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].primary;
            this.formPieChartData[1].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].secondary;
        }
    }

    render() {
        return (
            <>
                <div className="screener-pie-chart">
                    {
                        this.recentForm.length > 0 ?
                        <ResponsivePie
                        data={this.formPieChartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={2}
                        cornerRadius={6}
                        activeOuterRadiusOffset={20}
                        colors={{ datum: 'data.color' }}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        enableArcLinkLabels={false}
                        arcLabelsTextColor="#ffffff"
                        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 10 ] ] }}
                        // arcLabelsTextColor={["#7D8DAB", "#00205B", "#4B618B"]}
                        // arcLabelsTextColor={{ datum: 'data.color' }}
                        defs={[]}
                        fill={[]}
                        motionConfig="wobbly"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -50,
                                translateY: -40,
                                itemsSpacing: 8,
                                itemWidth: 100,
                                itemHeight: 32,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                        />
                        :
                        <></>
                    }
                </div>
                <hr className="screener-divider"/>
                <div className="screener-forms-card-list">
                    <div className="screener-recent-form">
                        <h5>Recent Form</h5>
                        <div className="screener-game-card-group">
                            {
                                this.recentForm.length > 0 ?
                                <>
                                {
                                    this.recentForm.slice(0, 5).map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {
                                                    (game.win === 1 ? "WIN" : "LOSS")
                                                    +
                                                    " - "
                                                    +
                                                    parseDateFromString(game.timestamp).formattedDayMonthShort
                                                }
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{game.isHome ? "VS. " : "@ "}{game.opposingTeam}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                {/* game.score/opposingScore is for the format of the player game record, the one with the off/def stats is team */}
                                                <span className="text-base">
                                                    {
                                                        "score" in game ? game.score : game.cumulativeStats.offensive.points
                                                    }
                                                     - 
                                                    {
                                                        "opposingScore" in game ? game.opposingScore : game.cumulativeStats.defensive.points
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="screener-upcoming-games">
                        <h5>Upcoming Games</h5>
                        <div className="screener-game-card-group">
                            {
                                (this.upcomingForm.length > 0 && !isDictEmpty(this.teamStanding))?
                                <>
                                {
                                    this.upcomingForm.map((game,index) =>
                                        <div key={game.id} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {parseDateFromString(game.timestamp).formattedDayMonthShort}
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, getOpposingTeamCode(game, this.teamStanding.code))} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{isHomeGame(game, this.teamStanding.code) ? "VS. " : "@ "}{getOpposingTeamCode(game, this.teamStanding.code)}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                <span className="text-base">{parseDateFromString(game.timestamp).formattedTime}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class MLBForm extends React.Component {
    constructor(props) {
        super(props);

        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.formPieChartData = [
            {
                id: "W",
                label: "W",
                value: this.recentFormWins,
                color: "#FFFFFF"
            },
            {
                id: "L",
                label: "L",
                value: this.recentFormLosses,
                color: "#FFFFFF"
            }
        ];

        this.winField = "win";
        this.currentTeamScore = "cumulativeStats.offensive.runs";
        this.opposingTeamscore = "cumulativeStats.defensive.runs";

        // Props-based data
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        // Used for the coloring
        this.teamStanding = this.props.teamStanding || {};
        this.setup();
    }

    componentDidUpdate() {
        this.setup();
    }

    setup() {
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        this.teamStanding = this.props.teamStanding || {};
        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentForm.forEach((gameResult, index) => {
            if (gameResult.win === 1) {
                this.recentFormWins += 1;
            }
            if (gameResult.loss === 1) {
                this.recentFormLosses += 1;
            }
        });
        this.formPieChartData[0].value = this.recentFormWins;
        this.formPieChartData[1].value = this.recentFormLosses;
        if (!isDictEmpty(this.teamStanding)) {
            this.formPieChartData[0].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].primary;
            this.formPieChartData[1].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].secondary;
        }
    }

    render() {
        return (
            <>
                <div className="screener-pie-chart">
                    {
                        this.recentForm.length > 0 ?
                        <ResponsivePie
                        data={this.formPieChartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={2}
                        cornerRadius={6}
                        activeOuterRadiusOffset={20}
                        colors={{ datum: 'data.color' }}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        enableArcLinkLabels={false}
                        arcLabelsTextColor="#ffffff"
                        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 10 ] ] }}
                        // arcLabelsTextColor={["#7D8DAB", "#00205B", "#4B618B"]}
                        // arcLabelsTextColor={{ datum: 'data.color' }}
                        defs={[]}
                        fill={[]}
                        motionConfig="wobbly"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -50,
                                translateY: -40,
                                itemsSpacing: 8,
                                itemWidth: 100,
                                itemHeight: 32,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                        />
                        :
                        <></>
                    }
                </div>
                <hr className="screener-divider"/>
                <div className="screener-forms-card-list">
                    <div className="screener-recent-form">
                        <h5>Recent Form</h5>
                        <div className="screener-game-card-group">
                            {
                                this.recentForm.length > 0 ?
                                <>
                                {
                                    this.recentForm.slice(0, 5).map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {
                                                    (game.win === 1 ? "WIN" : 
                                                    (game.loss === 1 ? "LOSS": "OTL"))
                                                    +
                                                    " - "
                                                    +
                                                    parseDateFromString(game.timestamp).formattedDayMonthShort
                                                }
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{game.isHome ? "VS. " : "@ "}{game.opposingTeam}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                {/* game.score/opposingScore is for the format of the player game record, the one with the off/def stats is team */}
                                                <span className="text-base">
                                                    {
                                                        "score" in game ? game.score : getDictionaryValue(game, this.currentTeamScore)
                                                    }
                                                     - 
                                                    {
                                                        "opposingScore" in game ? game.opposingScore : getDictionaryValue(game, this.opposingTeamscore)
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="screener-upcoming-games">
                        <h5>Upcoming Games</h5>
                        <div className="screener-game-card-group">
                            {
                                (this.upcomingForm.length > 0 && !isDictEmpty(this.teamStanding))?
                                <>
                                {
                                    this.upcomingForm.map((game,index) =>
                                        <div key={game.id} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {parseDateFromString(game.timestamp).formattedDayMonthShort}
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, getOpposingTeamCode(game, this.teamStanding.code))} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{isHomeGame(game, this.teamStanding.code) ? "VS. " : "@ "}{getOpposingTeamCode(game, this.teamStanding.code)}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                <span className="text-base">{parseDateFromString(game.timestamp).formattedTime}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

class SoccerForm extends React.Component {
    constructor(props) {
        super(props);

        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentFormDraw = 0;
        // For the color, use WIN = primary color, LOSS = faded primary color, OTL = double faded primary color
        // Need to use hex to HSL for the fading and then back to hex to add to the pie chart data
        this.formPieChartData = [
            {
                id: "W",
                label: "W",
                value: this.recentFormWins,
                color: "#FFFFFF"
            },
            {
                id: "L",
                label: "L",
                value: this.recentFormLosses,
                color: "#FFFFFF"
            },
            {
                id: "D",
                label: "D",
                value: this.recentFormDraw,
                color: "#FFFFFF"
            }
        ];

        this.winField = "win";
        this.currentTeamScore = "cumulativeStats.offensive.goals";
        this.opposingTeamscore = "cumulativeStats.defensive.goals";

        // Props-based data
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        // Used for the coloring
        this.teamStanding = this.props.teamStanding || {};
        this.setup();
    }

    componentDidUpdate() {
        this.setup();
    }

    setup() {
        this.recentForm = this.props.recentForm || [];
        this.upcomingForm = this.props.upcomingForm || [];
        this.teamStanding = this.props.teamStanding || {};
        this.recentFormWins = 0;
        this.recentFormLosses = 0;
        this.recentFormDraw = 0;
        this.recentForm.forEach((gameResult, index) => {
            if (gameResult.win === 1) {
                this.recentFormWins += 1;
            }
            if (gameResult.loss === 1) {
                this.recentFormLosses += 1;
            }
            if (gameResult.draw === 1) {
                this.recentFormDraw += 1;
            }
        });
        this.formPieChartData[0].value = this.recentFormWins;
        this.formPieChartData[1].value = this.recentFormLosses;
        this.formPieChartData[2].value = this.recentFormDraw;
        if (!isDictEmpty(this.teamStanding)) {
            this.formPieChartData[0].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].primary;
            this.formPieChartData[1].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].secondary;
            this.formPieChartData[2].color = COLOR_CODES[this.props.activeLeague][this.teamStanding.code].tertiary;
        }
    }

    render() {
        return (
            <>
                <div className="screener-pie-chart">
                    {
                        this.recentForm.length > 0 ?
                        <ResponsivePie
                        data={this.formPieChartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={2}
                        cornerRadius={6}
                        activeOuterRadiusOffset={20}
                        colors={{ datum: 'data.color' }}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        enableArcLinkLabels={false}
                        arcLabelsTextColor="#ffffff"
                        // arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 10 ] ] }}
                        // arcLabelsTextColor={["#7D8DAB", "#00205B", "#4B618B"]}
                        // arcLabelsTextColor={{ datum: 'data.color' }}
                        defs={[]}
                        fill={[]}
                        motionConfig="wobbly"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'row',
                                justify: false,
                                translateX: -50,
                                translateY: -40,
                                itemsSpacing: 8,
                                itemWidth: 100,
                                itemHeight: 32,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                        />
                        :
                        <></>
                    }
                </div>
                <hr className="screener-divider"/>
                <div className="screener-forms-card-list">
                    <div className="screener-recent-form">
                        <h5>Recent Form</h5>
                        <div className="screener-game-card-group">
                            {
                                this.recentForm.length > 0 ?
                                <>
                                {
                                    this.recentForm.slice(0, 5).map((game,index) =>
                                        <div key={game.gameID} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {
                                                    (game.win === 1 ? "WIN" : 
                                                    (game.loss === 1 ? "LOSS": "DRAW"))
                                                    +
                                                    " - "
                                                    +
                                                    parseDateFromString(game.timestamp).formattedDayMonthShort
                                                }
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{game.isHome ? "VS. " : "@ "}{game.opposingTeam}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                {/* game.score/opposingScore is for the format of the player game record, the one with the off/def stats is team */}
                                                <span className="text-base">
                                                    {
                                                        "score" in game ? game.score : game.cumulativeStats.offensive.goals
                                                    }
                                                     - 
                                                    {
                                                        "opposingScore" in game ? game.opposingScore : game.cumulativeStats.defensive.goals
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                    <div className="screener-upcoming-games">
                        <h5>Upcoming Games</h5>
                        <div className="screener-game-card-group">
                            {
                                (this.upcomingForm.length > 0 && !isDictEmpty(this.teamStanding))?
                                <>
                                {
                                    this.upcomingForm.map((game,index) =>
                                        <div key={game.id} className="screener-game-card">
                                            <div className="screener-card-header text-xs-upper">
                                                {parseDateFromString(game.timestamp).formattedDayMonthShort}
                                            </div>
                                            <div className="screener-card-content">
                                                <div className="screener-team-logo">
                                                <img src={getTeamLogoPath(this.props.activeLeague, getOpposingTeamCode(game, this.teamStanding.code))} height={64} width={64} alt=""/>
                                                </div>
                                                <div className="screener-team-name text-xs-semi text-meta-subdued">{isHomeGame(game, this.teamStanding.code) ? "VS. " : "@ "}{getOpposingTeamCode(game, this.teamStanding.code)}</div>
                                            </div>
                                            <div className="screener-card-footer card-footer">
                                                <span className="text-base">{parseDateFromString(game.timestamp).formattedTime}</span>
                                            </div>
                                        </div>
                                    )
                                }
                                </> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// Having custom gamelog components might be a bit overkill but the separation of concern makes things easier to manage and customize
class NFLGamelog extends React.Component {
    constructor(props) {
        super(props);
        
        this.statColumns = {};
        this.teamStatsColumns = {
            "offensive": {
                "YDS": "cumulativeStats.offensive.totalYards",
                "TDS": "cumulativeStats.offensive.totalTouchdowns",
                "RZ%": "cumulativeStats.offensive.redzoneSuccessRate",
                "1D": "cumulativeStats.offensive.firstDowns",
                "3D%": "cumulativeStats.offensive.thirdDownConversionPercentage",
                "4D%": "cumulativeStats.offensive.fourthDownConversionPercentage"
            },
            "passing": {
                "YDS": "cumulativeStats.offensive.passingYards",
                "TD": "cumulativeStats.offensive.passingTouchdowns"
            },
            "rushing": {
                "YDS": "cumulativeStats.offensive.rushingYards",
                "TD": "cumulativeStats.offensive.rushingTouchdowns"
            },
            "errors": {
                "INT": "cumulativeStats.offensive.interceptions",
                "TO": "cumulativeStats.offensive.turnovers",
                "SACKS": "cumulativeStats.offensive.sacks",
                "FUML": "cumulativeStats.offensive.fumblesLost"
            }
        };

        this.passingPlayerStatsColumns = {
            "Pass Rtg": "cumulativeStats.rating",
            "Comp": "cumulativeStats.passingCompletions",
            "ATT": "cumulativeStats.passingAttempts",
            "YDS": "cumulativeStats.passingYards",
            "td": "cumulativeStats.passingTouchdowns",
            "INT": "cumulativeStats.interceptions",
            "YDS/Att": "cumulativeStats.yardsPerAttempt",
            // "300+ Yds": "cumulativeStats.threeHundredYardsPassing",
            "RZ ATT": "cumulativeStats.redzonePassingAttempts",
            "RZ Compl": "cumulativeStats.redzoneCompletions",
            "RZ td": "cumulativeStats.redzonePassingTouchdowns"
        };

        this.rushingPlayerStatsColumns = {
            "Att": "cumulativeStats.rushingAttempts",
            "Yds": "cumulativeStats.rushingYards",
            "TD": "cumulativeStats.rushingTouchdowns",
            "Yds/Att": "cumulativeStats.rushingYardsPerAttempt",
            "Long": "cumulativeStats.longestRun",
            // "100+ Yds": "cumulativeStats.hundredYardsRushing",
            "RZ Att": "cumulativeStats.redzoneRushingAttempts",
            "RZ TD": "cumulativeStats.redzoneRushingTouchdowns",
        };

        // NOTE: using capitalization (which will all end up as upper case anyway) to trick it into allowing us to use multiple of the same key
        this.receivingPlayerStatsColumns = {
            "Tgt": "cumulativeStats.targets",
            "Rec": "cumulativeStats.receptions",
            "YDS": "cumulativeStats.receivingYards",
            "td": "cumulativeStats.receivingTouchdowns",
            "Yds/Rec": "cumulativeStats.receivingYardsPerReception",
            // "100+ YDS": "cumulativeStats.hundredYardsReceiving",
            "RZ Tgt": "cumulativeStats.redzoneTargets",
            "RZ Rec": "cumulativeStats.redzoneReceptions",
            "RZ td": "cumulativeStats.redzoneReceivingTouchdowns"
        };

        this.kickingPlayerStatsColumns = {
            "PTS": "cumulativeStats.pointsKicked",
            "FGA": "cumulativeStats.fieldGoalAttempts",
            "FGM": "cumulativeStats.fieldGoalsMade",
            "FG%": "cumulativeStats.fieldGoalPercentage",
            "XPA": "cumulativeStats.extraPointAttempts",
            "XPM": "cumulativeStats.extraPointsMade",
            "XP%": "cumulativeStats.extraPointPercentage",
            "FG (0-19)": "cumulativeStats.extraShortRangeFieldGoals",
            "FG (20-29)": "cumulativeStats.shortRangeFieldGoals",
            "FG (30-39)": "cumulativeStats.mediumRangeFieldGoals",
            "FG (40-49)": "cumulativeStats.longRangeFieldGoals",
            "FG (50+)": "cumulativeStats.extraLongRangeFieldGoals"
        };

        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "QB") {
                // this.statColumns = Object.assign({}, this.passingPlayerStatsColumns, this.rushingPlayerStatsColumns);
                this.statColumns = {
                    "passing": this.passingPlayerStatsColumns,
                    "rushing": this.rushingPlayerStatsColumns
                };
            }
            if (this.props.player.info.position === "RB") {
                // this.statColumns = Object.assign({}, this.rushingPlayerStatsColumns, this.receivingPlayerStatsColumns);
                this.statColumns = {
                    "rushing": this.rushingPlayerStatsColumns,
                    "receiving": this.receivingPlayerStatsColumns
                };
            }
            if (this.props.player.info.position === "WR" || this.props.player.info.position === "TE") {
                // this.statColumns = Object.assign({}, this.receivingPlayerStatsColumns, this.rushingPlayerStatsColumns);
                this.statColumns = {
                    "receiving": this.receivingPlayerStatsColumns,
                    "rushing": this.rushingPlayerStatsColumns
                };
            }
            if (this.props.player.info.position === "K") {
                // this.statColumns = this.kickingPlayerStatsColumns;
                this.statColumns = {
                    "kicking": this.kickingPlayerStatsColumns
                };
            }
        }

        this.tableRef = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)){
            if (this.props.player.info.position === "QB") {
                this.statColumns = {
                    "passing": this.passingPlayerStatsColumns,
                    "rushing": this.rushingPlayerStatsColumns
                };
            }
            if (this.props.player.info.position === "RB") {
                this.statColumns = {
                    "rushing": this.rushingPlayerStatsColumns,
                    "receiving": this.receivingPlayerStatsColumns
                };
            }
            if (this.props.player.info.position === "WR" || this.props.player.info.position === "TE") {
                this.statColumns = {
                    "receiving": this.receivingPlayerStatsColumns,
                    "rushing": this.rushingPlayerStatsColumns
                };
            }
            if (this.props.player.info.position === "K") {
                this.statColumns = {
                    "kicking": this.kickingPlayerStatsColumns
                };
            }
        }
        this.props.updateFn();
    }

    render() {
        var columnCount = 0;
        Object.keys(this.statColumns).map((group) => {
            columnCount += Object.keys(this.statColumns[group]).length;
        });
        const dateColumnWidth = 7;
        const opponentColumnWidth = 7;
        const resultColumnWidth = 8;
        const remainingColumnWidth = (100 - dateColumnWidth - opponentColumnWidth - resultColumnWidth) / columnCount;

        const dateColumnWidthInPixels = 60;
        const opponentColumnWidthInPixels = 100;
        const resultColumnWidthInPixels = 115;
        const statColumnWidthInPixels = 75;
        var totalTableWidth = dateColumnWidthInPixels + opponentColumnWidthInPixels + (columnCount * statColumnWidthInPixels);
        if (this.props.tab === "team") {
            totalTableWidth += resultColumnWidthInPixels;
        }
        const dateColumnStyling = {
            width: `${dateColumnWidthInPixels}px`,
            left: '0',
            position: 'sticky',
            zIndex: '30'
        };

        const opponentColumnStyling = {
            width: `${opponentColumnWidthInPixels}px`,
            left: `${dateColumnWidthInPixels}px`,
            position: 'sticky',
            zIndex: '30'
        };

        const resultColumnStyling = {
            width: `${resultColumnWidthInPixels}px`,
            zIndex: '1'
        };

        const statColumnStyling = {
            width: `${statColumnWidthInPixels}px`,
            zIndex: '1'
        };
        return (
                <table ref={this.tableRef} className="screener-table screener-leaderboard-table" style={{minWidth: `${totalTableWidth}px`}}>
                    <colgroup>
                        {/* Date */}
                        <col style={dateColumnStyling}/>
                        {/* Opponent */}
                        <col style={opponentColumnStyling}/>
                        {/* Result */}
                        {
                            this.props.tab === "player" ? <></> : <col style={resultColumnStyling}/>
                        }
                        {
                            Object.keys(this.statColumns).map((group) =>
                                <React.Fragment key={group}>
                                {
                                    Object.keys(this.statColumns[group]).map((key) => (
                                        <th key={key} style={statColumnStyling}>{key}</th>
                                    ))
                                }
                                </React.Fragment>
                            )
                        }
                    </colgroup>
                    <thead>
                        <tr className="screener-table-header text-xs-upper text-subdued-dark">
                            <th colspan="2"style={Object.assign({borderBottom: 0},dateColumnStyling)}></th>
                            {
                                this.props.tab === "team" ?
                                <th colspan="1" style={{borderBottom: 0}}></th> :
                                <></>
                            }
                            {
                                Object.keys(this.statColumns).map((group) => (
                                    <th key={group} colspan={Object.keys(this.statColumns[group]).length} style={{borderBottom: 0, borderLeft: '1px solid var(--color-border-default)'}}>
                                        <p className="font size-12 grey spaced" style={{width: '100%', textAlign: 'center', display: 'block'}}>
                                            {group}
                                        </p>
                                    </th>
                                ))
                            }
                        </tr>
                        <tr className="screener-table-header text-xs-upper text-subdued-dark">
                            <th style={dateColumnStyling}>Date</th>
                            <th style={opponentColumnStyling}>opponent</th>
                            {
                                this.props.tab === "player" ? <></> : <th style={resultColumnStyling}>result</th>
                            }
                            {
                                Object.keys(this.statColumns).map((group) =>
                                    <React.Fragment key={group}>
                                    {
                                        Object.keys(this.statColumns[group]).map((key, index) => (
                                            <th key={key} style={index === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyling) : statColumnStyling}>{key}</th>
                                        ))
                                    }
                                    </React.Fragment>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.games.length === 0 ? <></> :
                            <>
                            {
                                this.props.games.map((game, index) => 
                                    <tr key={game.gameID}>
                                        <td className="date" style={dateColumnStyling}>
                                            {
                                                String(parseDateFromString(game.timestamp).month).padStart(2, '0') + "/" + 
                                                String(parseDateFromString(game.timestamp).day).padStart(2, '0')
                                            }
                                        </td>
                                        <td style={opponentColumnStyling}>
                                            <div className="opponent">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height="32" width="32" alt="" style={{marginRight: "5%"}}/>
                                                <div style={{display: 'inline-block'}}>{game.opposingTeam}</div>
                                            </div>
                                        </td>
                                        {
                                            this.props.tab === "player" ? <></> :
                                            <td style={resultColumnStyling}>{(game.cumulativeStats.offensive.points > game.cumulativeStats.defensive.points ? "W" : (game.cumulativeStats.offensive.points < game.cumulativeStats.defensive.points ? "L": "T")) + " • " + game.cumulativeStats.offensive.points + "-" + game.cumulativeStats.defensive.points}</td>
                                        }
                                        {
                                            Object.keys(this.statColumns).map((group) =>
                                                <React.Fragment key={group}> 
                                                {
                                                    Object.keys(this.statColumns[group]).map((key, index) => (
                                                        <td key={key} style={index === 0 ? Object.assign({borderLeft: '1px solid var(--color-border-default)'}, statColumnStyling) : statColumnStyling}>{getDictionaryValue(game, this.statColumns[group][key])}</td>
                                                    ))
                                                }
                                                </React.Fragment>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </>
                        }
                    </tbody>
                </table>
        )
    }
}

class NHLGamelog extends React.Component {
    constructor(props) {
        super(props);
        
        this.statColumns = {};
        this.teamStatsColumns = {
            // TODO: review with Matt -- removing since it's redundant to save space
            // "G": "%quantifier%.%type%.goals",
            "SOG": "cumulativeStats.offensive.shotsOnGoal",
            "SAT": "cumulativeStats.offensive.shotAttempts",
            "CF%": "cumulativeStats.offensive.corsiPercentage",
            "PP%": "cumulativeStats.offensive.powerplayPercentage",
            "SH%": "cumulativeStats.offensive.penaltyKillPercentage",
            "BLK": "cumulativeStats.offensive.defensiveBlocks",
            "PIM": "cumulativeStats.offensive.penaltyMinutes"
            // TODO: HDC / xG
        };

        this.goalieStatsColumns = {
            "GA": "cumulativeStats.goalsAllowed",
            "SA": "cumulativeStats.shotsAgainst",
            "SAVES": "cumulativeStats.shotsSaved",
            "SV%": "cumulativeStats.savePercentage"
        };

        this.skatersStatsColumns = {
            "G": "cumulativeStats.goals",
            "A": "cumulativeStats.assists",
            "PTS": "cumulativeStats.points",
            "TOI": "cumulativeStats.timeOnIce",
            "SOG": "cumulativeStats.shotsOnGoal",
            "SAT": "cumulativeStats.shotAttempts",
            // "+/-": "cumulativeStats.plusMinus",
            "PPP": "cumulativeStats.powerplayPoints",
            "SHP": "cumulativeStats.shorthandedPoints",
            "PIM": "cumulativeStats.penaltyMinutes",
            "BLK": "cumulativeStats.defensiveBlocks"//,
            // "HDC": "",
            // "xG": ""
        };

        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            if (this.props.player.info.position === "G") {
                this.statColumns = this.goalieStatsColumns;
            } else {
                this.statColumns = this.skatersStatsColumns;
            }
        }

        this.tableRef = React.createRef();

        // Props-based data
        this.games = this.props.games || [];
    }

    componentDidUpdate() {
        this.games = this.props.games || [];
        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            if (this.props.player.info.position === "G") {
                this.statColumns = this.goalieStatsColumns;
            } else {
                this.statColumns = this.skatersStatsColumns;
            }
        }
        this.props.updateFn();
    }

    render() {
        const columnCount = Object.keys(this.statColumns).length;
        const dateColumnWidth = 7;
        const opponentColumnWidth = 8;
        const resultColumnWidth = 10;
        const remainingColumnWidth = (100 - dateColumnWidth - opponentColumnWidth - resultColumnWidth) / columnCount;

        const dateColumnWidthInPixels = 60;
        const opponentColumnWidthInPixels = 100;
        const resultColumnWidthInPixels = 115;
        const statColumnWidthInPixels = 75;
        var totalTableWidth = dateColumnWidthInPixels + opponentColumnWidthInPixels + (columnCount * statColumnWidthInPixels);
        if (this.props.tab === "team") {
            totalTableWidth += resultColumnWidthInPixels;
        }
        const dateColumnStyling = {
            width: `${dateColumnWidthInPixels}px`,
            left: '0',
            position: 'sticky',
            zIndex: '30'
        };

        const opponentColumnStyling = {
            width: `${opponentColumnWidthInPixels}px`,
            left: `${dateColumnWidthInPixels}px`,
            position: 'sticky',
            zIndex: '30'
        };

        const resultColumnStyling = {
            width: `${resultColumnWidthInPixels}px`,
            zIndex: '1'
        };

        const statColumnStyling = {
            width: `${statColumnWidthInPixels}px`,
            zIndex: '1'
        };
        
        return (
                <table ref={this.tableRef} className="screener-table screener-leaderboard-table" style={{minWidth: `${totalTableWidth}px`}}>
                    <colgroup>
                        {/* Date */}
                        <col style={dateColumnStyling}/>
                        {/* Opponent */}
                        <col style={opponentColumnStyling}/>
                        {/* Result */}
                        {
                            this.props.tab === "player" ? <></> : <col style={resultColumnStyling}/>
                        }
                        {
                            Object.keys(this.statColumns).map((key) => (
                                <col key={key} style={statColumnStyling}/>
                            ))
                        }
                    </colgroup>
                    <thead>
                        <tr className="screener-table-header text-xs-upper text-subdued-dark">
                            <th style={dateColumnStyling}>Date</th>
                            <th style={opponentColumnStyling}>opponent</th>
                            {
                                this.props.tab === "player" ? <></> : <th style={resultColumnStyling}>result</th>
                            }
                            {
                                Object.keys(this.statColumns).map((key) => (
                                    <th key={key} style={statColumnStyling}>{key}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.games.length === 0 ? <></> :
                            <>
                            {
                                this.games.map((game, index) => 
                                    <tr key={game.gameID}>
                                        <td className="date" style={dateColumnStyling}>
                                            {
                                                String(parseDateFromString(game.timestamp).month).padStart(2, '0') + "/" + 
                                                String(parseDateFromString(game.timestamp).day).padStart(2, '0')
                                            }
                                        </td>
                                        <td style={opponentColumnStyling}>
                                            <div className="opponent">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height="32" width="32" alt="" style={{marginRight: "5%"}}/>
                                                <div style={{display: 'inline-block'}}>{game.opposingTeam}</div>
                                            </div>
                                        </td>
                                        {
                                            this.props.tab === "player" ? <></> :
                                            <td style={resultColumnStyling}>{(game.win === 1 ? "W" : (game.loss === 1 ? "L": "OTL")) + " • " + game.cumulativeStats.offensive.goals + "-" + game.cumulativeStats.defensive.goals}</td>
                                        }
                                        {
                                            Object.keys(this.statColumns).map((key) => (
                                                <td key={key} style={statColumnStyling}>{getDictionaryValue(game, this.statColumns[key])}</td>
                                            ))
                                        }
                                    </tr>
                                )
                            }
                            </>
                        }
                    </tbody>
                </table>
        )
    }
}

class NBAGamelog extends React.Component {
    constructor(props) {
        super(props);
        
        this.statColumns = {};
        this.teamStatsColumns = {
            // Also removing to save space
            // "PTS": "cumulativeStats.offensive.points",
            "FT%": "cumulativeStats.offensive.freeThrowPercentage",
            "FG%": "cumulativeStats.offensive.fieldGoalPercentage",
            "3PT%": "cumulativeStats.offensive.threePointPercentage",
            "TS%": "cumulativeStats.offensive.trueShootingPercentage",
            "eFG%": "cumulativeStats.offensive.effectiveFieldGoalPercentage",
            "TRB": "cumulativeStats.offensive.rebounds",
            "AST": "cumulativeStats.offensive.assists",
            "STL": "cumulativeStats.defensive.steals",
            "TO": "cumulativeStats.defensive.turnovers",
            "BLK": "cumulativeStats.defensive.blocks",
            "PACE": "cumulativeStats.offensive.pace"
        };

        this.playerStatsColumns = {
            "PTS": "cumulativeStats.points",
            "FTA": "cumulativeStats.freeThrowAttempts",
            "FTM": "cumulativeStats.freeThrowsMade",
            "FT%": "cumulativeStats.freeThrowPercentage",
            "FGA": "cumulativeStats.fieldGoalAttempts",
            "FGM": "cumulativeStats.fieldGoalsMade",
            "FG%": "cumulativeStats.fieldGoalPercentage",
            "3PTA": "cumulativeStats.threePointAttempts",
            "3PTM": "cumulativeStats.threePointsMade",
            "3PT%": "cumulativeStats.threePointPercentage",
            "TS%": "cumulativeStats.trueShootingPercentage",
            "eFG%": "cumulativeStats.effectiveFieldGoalPercentage",
            "REB": "cumulativeStats.rebounds",
            "AST": "cumulativeStats.assists",
            "STL": "cumulativeStats.steals",
            "TO": "cumulativeStats.turnovers",
            "BLK": "cumulativeStats.blocks"
        };


        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player"){
            this.statColumns = this.playerStatsColumns;
        }

        this.tableRef = React.createRef();

        // Props-based data
        this.games = this.props.games || [];
    }

    componentDidUpdate() {
        this.games = this.props.games || [];
        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player"){
            this.statColumns = this.playerStatsColumns;
        }
        this.props.updateFn();
    }

    render() {
        const columnCount = Object.keys(this.statColumns).length;
        const dateColumnWidth = 7;
        const opponentColumnWidth = 9;
        const resultColumnWidth = 10;
        const remainingColumnWidth = (100 - dateColumnWidth - opponentColumnWidth - resultColumnWidth) / columnCount;

        const dateColumnWidthInPixels = 60;
        const opponentColumnWidthInPixels = 100;
        const resultColumnWidthInPixels = 115;
        const statColumnWidthInPixels = 75;
        var totalTableWidth = dateColumnWidthInPixels + opponentColumnWidthInPixels + (columnCount * statColumnWidthInPixels);
        if (this.props.tab === "team") {
            totalTableWidth += resultColumnWidthInPixels;
        }
        const dateColumnStyling = {
            width: `${dateColumnWidthInPixels}px`,
            left: '0',
            position: 'sticky',
            zIndex: '30'
        };

        const opponentColumnStyling = {
            width: `${opponentColumnWidthInPixels}px`,
            left: `${dateColumnWidthInPixels}px`,
            position: 'sticky',
            zIndex: '30'
        };

        const resultColumnStyling = {
            width: `${resultColumnWidthInPixels}px`,
            zIndex: '1'
        };

        const statColumnStyling = {
            width: `${statColumnWidthInPixels}px`,
            zIndex: '1'
        };
        return (
                <table ref={this.tableRef} className="screener-table screener-leaderboard-table"  style={{minWidth: `${totalTableWidth}px`}}>
                    <colgroup>
                        {/* Date */}
                        <col style={dateColumnStyling}/>
                        {/* Opponent */}
                        <col style={opponentColumnStyling}/>
                        {/* Result */}
                        {
                            this.props.tab === "player" ? <></> : <col style={resultColumnStyling}/>
                        }
                        {
                            Object.keys(this.statColumns).map((key) => (
                                <col key={key} style={statColumnStyling}/>
                            ))
                        }
                    </colgroup>
                    <thead>
                        <tr className="screener-table-header text-xs-upper text-subdued-dark">
                            <th style={dateColumnStyling}>Date</th>
                            <th style={opponentColumnStyling}>opponent</th>
                            {
                                this.props.tab === "player" ? <></> : <th style={resultColumnStyling}>result</th>
                            }
                            {
                                Object.keys(this.statColumns).map((key) => (
                                    <th key={key} style={statColumnStyling}>{key}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.games.length === 0 ? <></> :
                            <>
                            {
                                this.games.map((game, index) => 
                                    <tr key={game.gameID}>
                                        <td className="date" style={dateColumnStyling}>
                                            {
                                                String(parseDateFromString(game.timestamp).month).padStart(2, '0') + "/" + 
                                                String(parseDateFromString(game.timestamp).day).padStart(2, '0')
                                            }
                                        </td>
                                        <td style={opponentColumnStyling}>
                                            <div className="opponent">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height="32" width="32" alt="" style={{marginRight: "5%"}}/>
                                                <div style={{display: 'inline-block'}}>{game.opposingTeam}</div>
                                            </div>
                                        </td>
                                        {
                                            this.props.tab === "player" ? <></> :
                                            <td style={resultColumnStyling}>{(game.win === 1 ? "W" : "L") + " • " + game.cumulativeStats.offensive.points + "-" + game.cumulativeStats.defensive.points}</td>
                                        }
                                        {
                                            Object.keys(this.statColumns).map((key) => (
                                                <td key={key} style={statColumnStyling}>{getDictionaryValue(game, this.statColumns[key])}</td>
                                            ))
                                        }
                                    </tr>
                                )
                            }
                            </>
                        }
                    </tbody>
                </table>
        )
    }
}

class MLBGamelog extends React.Component {
    constructor(props) {
        super(props);
        
        this.statColumns = {};
        this.teamStatsColumns = {
            "R": "cumulativeStats.offensive.runs",
            "H": "cumulativeStats.offensive.hits",
            "1B": "cumulativeStats.offensive.singles",
            "2B": "cumulativeStats.offensive.doubles",
            "3B": "cumulativeStats.offensive.triples",
            "HR": "cumulativeStats.offensive.homeRuns",
            "RBI": "cumulativeStats.offensive.runsBattedIn",
            "BB": "cumulativeStats.offensive.walks",
            "SO": "cumulativeStats.offensive.strikeouts",
            "E": "cumulativeStats.offensive.errors",
            "AVG": "cumulativeStats.offensive.battingAverage",
            "OPS": "cumulativeStats.offensive.onBasePlusSluggingPercentage"
        };

        this.pitchersStatsColumns = {
            "W": "cumulativeStats.pitching.wins",
            "L": "cumulativeStats.pitching.losses",
            "IP": "cumulativeStats.pitching.inningsPitched",
            "ERA": "cumulativeStats.pitching.earnedRunsAverage",
            "SV": "cumulativeStats.pitching.saves",
            "H": "cumulativeStats.pitching.hits",
            "R": "cumulativeStats.pitching.runs",
            "HR": "cumulativeStats.pitching.homeRuns",
            "ER": "cumulativeStats.pitching.earnedRuns",
            "BB": "cumulativeStats.pitching.walks",
            "SO": "cumulativeStats.pitching.strikeouts",
            "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched",
            "AVG": "cumulativeStats.pitching.battingAverageAgainst"
        };

        this.hittersStatsColumns = {
            "AB": "cumulativeStats.hitting.atBats",
            "R": "cumulativeStats.hitting.runs",
            "H": "cumulativeStats.hitting.hits",
            "1B": "cumulativeStats.hitting.singles",
            "2B": "cumulativeStats.hitting.doubles",
            "3B": "cumulativeStats.hitting.triples",
            "HR": "cumulativeStats.hitting.homeRuns",
            "ISO": "cumulativeStats.hitting.isolatedPower",
            "RBI": "cumulativeStats.hitting.runsBattedIn",
            "TB": "cumulativeStats.hitting.totalBases",
            "BB": "cumulativeStats.hitting.walks",
            "SO": "cumulativeStats.hitting.strikeouts",
            "AVG": "cumulativeStats.hitting.battingAverage",
            "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        };

        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            if (this.props.player.info.position === "SP" || this.props.player.info.position === "RP") {
                this.statColumns = this.pitchersStatsColumns;
            } else {
                this.statColumns = this.hittersStatsColumns;
            }
        }

        this.tableRef = React.createRef();

        // Props-based data
        this.games = this.props.games || [];
    }

    componentDidUpdate() {
        this.games = this.props.games || [];
        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            if (this.props.player.info.position === "SP" || this.props.player.info.position === "RP") {
                this.statColumns = this.pitchersStatsColumns;
            } else {
                this.statColumns = this.hittersStatsColumns;
            }
        }
        this.props.updateFn();
    }

    render() {
        const columnCount = Object.keys(this.statColumns).length;
        const dateColumnWidth = 7;
        const opponentColumnWidth = 8;
        const resultColumnWidth = 10;
        const remainingColumnWidth = (100 - dateColumnWidth - opponentColumnWidth - resultColumnWidth) / columnCount;

        const dateColumnWidthInPixels = 60;
        const opponentColumnWidthInPixels = 100;
        const resultColumnWidthInPixels = 115;
        const statColumnWidthInPixels = 75;
        var totalTableWidth = dateColumnWidthInPixels + opponentColumnWidthInPixels + (columnCount * statColumnWidthInPixels);
        if (this.props.tab === "team") {
            totalTableWidth += resultColumnWidthInPixels;
        }
        const dateColumnStyling = {
            width: `${dateColumnWidthInPixels}px`,
            left: '0',
            position: 'sticky',
            zIndex: '30'
        };

        const opponentColumnStyling = {
            width: `${opponentColumnWidthInPixels}px`,
            left: `${dateColumnWidthInPixels}px`,
            position: 'sticky',
            zIndex: '30'
        };

        const resultColumnStyling = {
            width: `${resultColumnWidthInPixels}px`,
            zIndex: '1'
        };

        const statColumnStyling = {
            width: `${statColumnWidthInPixels}px`,
            zIndex: '1'
        };
        return (
                <table ref={this.tableRef} className="screener-table screener-leaderboard-table" style={{minWidth: `${totalTableWidth}px`}}>
                    <colgroup>
                        {/* Date */}
                        <col style={dateColumnStyling}/>
                        {/* Opponent */}
                        <col style={opponentColumnStyling}/>
                        {/* Result */}
                        {
                            this.props.tab === "player" ? <></> : <col style={resultColumnStyling}/>
                        }
                        {
                            Object.keys(this.statColumns).map((key) => (
                                <col key={key} style={statColumnStyling}/>
                            ))
                        }
                    </colgroup>
                    <thead>
                        <tr className="screener-table-header text-xs-upper text-subdued-dark">
                            <th style={dateColumnStyling}>Date</th>
                            <th style={opponentColumnStyling}>opponent</th>
                            {
                                this.props.tab === "player" ? <></> : <th style={resultColumnStyling}>result</th>
                            }
                            {
                                Object.keys(this.statColumns).map((key) => (
                                    <th key={key} style={statColumnStyling}>{key}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.games.length === 0 ? <></> :
                            <>
                            {
                                this.games.map((game, index) => 
                                    <tr key={game.gameID}>
                                        <td className="date" style={dateColumnStyling}>
                                            {
                                                String(parseDateFromString(game.timestamp).month).padStart(2, '0') + "/" + 
                                                String(parseDateFromString(game.timestamp).day).padStart(2, '0')
                                            }
                                        </td>
                                        <td style={opponentColumnStyling}>
                                            <div className="opponent">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height="32" width="32" alt="" style={{marginRight: "5%"}}/>
                                                <div style={{display: 'inline-block'}}>{game.opposingTeam}</div>
                                            </div>
                                        </td>
                                        {
                                            this.props.tab === "player" ? <></> :
                                            <td style={resultColumnStyling}>{(game.win === 1 ? "W" : (game.loss === 1 ? "L": "OTL")) + " • " + game.cumulativeStats.offensive.runs + "-" + game.cumulativeStats.defensive.runs}</td>
                                        }
                                        {
                                            Object.keys(this.statColumns).map((key) => (
                                                <td key={key} style={statColumnStyling}>{getDictionaryValue(game, this.statColumns[key])}</td>
                                            ))
                                        }
                                    </tr>
                                )
                            }
                            </>
                        }
                    </tbody>
                </table>
        )
    }
}

class SoccerGamelog extends React.Component {
    constructor(props) {
        super(props);
        
        this.statColumns = {};
        this.teamStatsColumns = {
            "G": "cumulativeStats.offensive.goals",
            "POS": "cumulativeStats.offensive.ballPossession",
            "SHO": "cumulativeStats.offensive.shotsTotal",
            "SOT": "cumulativeStats.offensive.shotsOnTarget",
            "COR": "cumulativeStats.offensive.cornerKicks",
            "OFF": "cumulativeStats.offensive.offsides",
            "FLS": "cumulativeStats.offensive.fouls",
            "2YELRED": "cumulativeStats.offensive.doubleYellowRedCards",
            "YEL": "cumulativeStats.offensive.yellowCards",
            "RED": "cumulativeStats.offensive.redCards"
        };

        this.goalieStatsColumns = {
            "GA": "cumulativeStats.goalsConceded",
            "SAV": "cumulativeStats.shotsFacedSaved",
            "SA": "cumulativeStats.shotsFacedTotal",
            "PS": "cumulativeStats.penaltiesSaved",
            "DSAV": "cumulativeStats.divingSaves",
            "CS": "cumulativeStats.cleanSheet",
            "YEL": "cumulativeStats.yellowCards",
            "2YELRED": "cumulativeStats.doubleYellowRedCards",
            "RED": "cumulativeStats.redCards"
        };

        this.outfieldersStatsColumns = {
            "G": "cumulativeStats.goals",
            "A": "cumulativeStats.assists",
            "SOT": "cumulativeStats.shotsOnTarget",
            "SHO": "cumulativeStats.totalShots",
            "MIN": "cumulativeStats.minutesPlayed",
            // "CC": "cumulativeStats.chancesCreated",
            "PAS": "cumulativeStats.totalPasses",
            "DRI": "cumulativeStats.dribblesCompleted",
            "LOP": "cumulativeStats.lossOfPossession",
            "TCK": "cumulativeStats.totalTackles",
            "FLS": "cumulativeStats.foulsCommitted",
            "INT": "cumulativeStats.interceptions",
            "YEL": "cumulativeStats.yellowCards",
            "2YELRED": "cumulativeStats.yellowRedCards",
            "RED": "cumulativeStats.redCards"
        };

        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            if (this.props.player.info.position === "goalkeeper") {
                this.statColumns = this.goalieStatsColumns;
            } else {
                this.statColumns = this.outfieldersStatsColumns;
            }
        }

        this.tableRef = React.createRef();

        // Props-based data
        this.games = this.props.games || [];
    }

    componentDidUpdate() {
        this.games = this.props.games || [];
        if (this.props.tab === "team") {
            this.statColumns = this.teamStatsColumns;
        } else if(this.props.tab === "player" && !isDictEmpty(this.props.player)) {
            if (this.props.player.info.position === "goalkeeper") {
                this.statColumns = this.goalieStatsColumns;
            } else {
                this.statColumns = this.outfieldersStatsColumns;
            }
        }
        this.props.updateFn();
    }

    render() {
        const columnCount = Object.keys(this.statColumns).length;
        const dateColumnWidth = 7;
        const opponentColumnWidth = 8;
        const resultColumnWidth = 10;
        const remainingColumnWidth = (100 - dateColumnWidth - opponentColumnWidth - resultColumnWidth) / columnCount;

        const dateColumnWidthInPixels = 60;
        const opponentColumnWidthInPixels = 100;
        const resultColumnWidthInPixels = 115;
        const statColumnWidthInPixels = 75;
        var totalTableWidth = dateColumnWidthInPixels + opponentColumnWidthInPixels + (columnCount * statColumnWidthInPixels);
        if (this.props.tab === "team") {
            totalTableWidth += resultColumnWidthInPixels;
        }
        const dateColumnStyling = {
            width: `${dateColumnWidthInPixels}px`,
            left: '0',
            position: 'sticky',
            zIndex: '30'
        };

        const opponentColumnStyling = {
            width: `${opponentColumnWidthInPixels}px`,
            left: `${dateColumnWidthInPixels}px`,
            position: 'sticky',
            zIndex: '30'
        };

        const resultColumnStyling = {
            width: `${resultColumnWidthInPixels}px`,
            zIndex: '1'
        };

        const statColumnStyling = {
            width: `${statColumnWidthInPixels}px`,
            zIndex: '1'
        };
        
        return (
                <table ref={this.tableRef} className="screener-table screener-leaderboard-table" style={{minWidth: `${totalTableWidth}px`}}>
                    <colgroup>
                        {/* Date */}
                        <col style={dateColumnStyling}/>
                        {/* Opponent */}
                        <col style={opponentColumnStyling}/>
                        {/* Result */}
                        {
                            this.props.tab === "player" ? <></> : <col style={resultColumnStyling}/>
                        }
                        {
                            Object.keys(this.statColumns).map((key) => (
                                <col key={key} style={statColumnStyling}/>
                            ))
                        }
                    </colgroup>
                    <thead>
                        <tr className="screener-table-header text-xs-upper text-subdued-dark">
                            <th style={dateColumnStyling}>Date</th>
                            <th style={opponentColumnStyling}>opponent</th>
                            {
                                this.props.tab === "player" ? <></> : <th style={resultColumnStyling}>result</th>
                            }
                            {
                                Object.keys(this.statColumns).map((key) => (
                                    <th key={key} style={statColumnStyling}>{key}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.games.length === 0 ? <></> :
                            <>
                            {
                                this.games.map((game, index) => 
                                    <tr key={game.gameID}>
                                        <td className="date" style={dateColumnStyling}>
                                            {
                                                String(parseDateFromString(game.timestamp).month).padStart(2, '0') + "/" + 
                                                String(parseDateFromString(game.timestamp).day).padStart(2, '0')
                                            }
                                        </td>
                                        <td style={opponentColumnStyling}>
                                            <div className="opponent">
                                                <img src={getTeamLogoPath(this.props.activeLeague, game.opposingTeam)} height="32" width="32" alt="" style={{marginRight: "5%"}}/>
                                                <div style={{display: 'inline-block'}}>{game.opposingTeam}</div>
                                            </div>
                                        </td>
                                        {
                                            this.props.tab === "player" ? <></> :
                                            <td style={resultColumnStyling}>{(game.win === 1 ? "W" : (game.loss === 1 ? "L": "D")) + " • " + game.cumulativeStats.offensive.goals + "-" + game.cumulativeStats.defensive.goals}</td>
                                        }
                                        {
                                            Object.keys(this.statColumns).map((key) => (
                                                <td key={key} style={statColumnStyling}>{getDictionaryValue(game, this.statColumns[key])}</td>
                                            ))
                                        }
                                    </tr>
                                )
                            }
                            </>
                        }
                    </tbody>
                </table>
        )
    }
}

export default Screener;
