import React from 'react';

import './SearchResult.scss'
import './App.scss'
import GlobalContext from './GlobalContext';
import { API_HOST } from '../react-web-constants';
import { capitalizeFirstLetter, getFullPlayerName, getPlayerId, getTeamDisplayName } from '../linemate-react-common/src/util';
import { getTeamLogoPath } from '../react-web-utils';
import TrendsWorkstation from './TrendsWorkstation';
import Screener from '../screener';

/**
 * Props:
 * - onBack:fn
 */
class SearchResult extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.textInputChangeHandler = this.textInputChangeHandler.bind(this);
        this.search = this.search.bind(this);

        this.state = {
            searchActive: false,
            typingTimeout: 0,
            searchResults: {
                props: [],
                profile: []
            },
            selection: {
                section: null,
                entry: null
            },
            mobileEntrySelected: false
        }
    }

    componentDidMount() {
        // Auto-focus the input when the search opens
        document.getElementsByTagName('input')[0].focus();
    }

    textInputChangeHandler(e) {
        const searchTerm = e.target.value;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({typingTimeout: setTimeout(() => this.search(searchTerm), 250)})
    }

    search(inputSearchTerm) {
        // TODO: on app inputSearchTerm is used but should be searchTerm
        if (inputSearchTerm != null && inputSearchTerm.trim().length > 1) { 
            const searchTerm = inputSearchTerm.trim().toLowerCase();

            const playerSearchPromise = new Promise((resolve, reject) => {
                fetch(`${API_HOST}/api/${this.context.league}/v1/players/search?searchTerm=${searchTerm}`)
                .then(result => result.json())
                .then(result => resolve(result.map(x => ({type: "player", player: x, teamCode: x.teamCode, text: getFullPlayerName(x), activeGameInfo: x.activeGameInfo}))))
            })

            const teamSearchPromise = new Promise((resolve, reject) => {
                fetch(`${API_HOST}/api/${this.context.league}/v1/teams/search?searchTerm=${searchTerm}`)
                .then(result => result.json())
                .then(result => resolve(result.map(x => ({type: "team", teamCode: x.code, text: getTeamDisplayName(this.context.league, x), activeGameInfo: x.activeGameInfo}))))
            })

            Promise.all([playerSearchPromise, teamSearchPromise])
            .then((results) => {
                const propsSearchResults = [];
                const profileSearchResults = [];

                const overallPlayerResults = results[0];
                overallPlayerResults.forEach((entry) => {
                    profileSearchResults.push(entry);
                    if (entry.activeGameInfo && entry.activeGameInfo.hasProps) {
                        propsSearchResults.push(entry);
                    }
                })
                
                const overallTeamResults = results[1];
                overallTeamResults.forEach((entry) => {
                    profileSearchResults.push(entry);
                    if (entry.activeGameInfo && entry.activeGameInfo.hasProps) {
                        propsSearchResults.push(entry);
                    }
                })
                
                this.setState({
                    searchResults: {
                        props: propsSearchResults,
                        profile: profileSearchResults
                    }
                })
            })
        } else {
            this.setState({
                searchResults:  {
                    props: [],
                    profile: []
                }
            })
        }
    }

    render() {
        return (
        <div className='search-result-container'>
            <div className={`search-result-results-list ${this.state.mobileEntrySelected ? "" : "search-result-results-list-mobile-active"}`}>
                <div className='search-result-results-list-header'>
                    <img src="assets/arrow-back.svg" alt="back" onClick={this.props.onBack}/>
                    <div className='search-result-results-list-header-input-wrapper'>
                        <img src='assets/search.svg' alt="search"/>
                        <input className='text-style-label-normal' onInput={this.textInputChangeHandler} placeholder='Search any player or team...'/>
                    </div>
                </div>
                {/* TODO: sticky headers if possible */}
                <div className='search-result-results-list-content'>
                {
                    Object.keys(this.state.searchResults).map((key) => 
                        <React.Fragment key={key}>
                        {
                            this.state.searchResults[key].length > 0 && (
                                <>
                                    <div className='search-result-results-list-content-section-header'>
                                        <p className='text-style-label-normal color-fig-default'>{capitalizeFirstLetter(key)}</p>
                                        <p className='text-style-label-normal color-fig-subtle'>{this.state.searchResults[key].length}</p>
                                    </div>
                                    {
                                        this.state.searchResults[key].map((result, index) =>
                                            <div key={result.text} 
                                                 className='search-result-entry' 
                                                 onClick={() => this.setState({selection: {section: key, entry: result}, mobileEntrySelected: true})} data-selected={this.state.selection && key === this.state.selection.section && result.text === this.state.selection.entry.text} data-last={index === this.state.searchResults[key].length - 1}
                                            >
                                                <img src={getTeamLogoPath(this.context.league, result.teamCode)} alt={result.teamCode}/>
                                                <p className='text-style-body-medium color-fig-default'>{result.text}</p>
                                            </div>
                                        )
                                    }
                                </>
                            )  
                        }
                        </React.Fragment>
                    )
                }
                </div>
            </div>
            <div className={`search-result-selection ${this.state.mobileEntrySelected ? "search-result-selection-mobile-active" : ""}`}>
            {
                this.state.selection.section && this.state.selection.section === 'props' && (
                    <TrendsWorkstation 
                        key={`${this.state.selection.entry.type}-${this.state.selection.entry.teamCode}-${this.state.selection.entry.type === "player" ? getPlayerId(this.state.selection.entry.player.info) : null}`} 
                        type={this.state.selection.entry.type}
                        gameId={this.state.selection.entry.activeGameInfo.gameId}
                        home={this.state.selection.entry.activeGameInfo.home}
                        team={this.state.selection.entry.activeGameInfo.team}
                        opposingTeam={this.state.selection.entry.activeGameInfo.opposingTeam}
                        markets={this.state.selection.entry.activeGameInfo ? this.state.selection.entry.activeGameInfo.markets : null}
                        player={this.state.selection.entry.type === "player" ? this.state.selection.entry.player : null}
                        backAction={() => this.setState({mobileEntrySelected: false})}
                    />
                )
            }
            {
                this.state.selection.section && this.state.selection.section === 'profile' && (
                    <>
                        <img src="assets/arrow-back.svg" alt="back" onClick={() => this.setState({mobileEntrySelected: false})}/>
                        <Screener 
                            key={`${this.state.selection.entry.type}-${this.state.selection.entry.teamCode}-${this.state.selection.entry.type === "player" ? getPlayerId(this.state.selection.entry.player.info) : null}`} 
                            activeLeague={this.context.league} 
                            userAttributes={this.context.userAttributes} 
                            tab={this.state.selection.entry.type} 
                            teamCode={this.state.selection.entry.teamCode} 
                            playerID={this.state.selection.entry.type === "player" ? getPlayerId(this.state.selection.entry.player.info) : null}
                        />
                    </>
                )
            }
            {
                !this.state.selection.section && Object.keys(this.state.searchResults).map(x => this.state.searchResults[x]).some(x => x.length > 0) && (
                    <div className='search-result-selection-no-selection'>
                        <div>
                            <p className='text-style-body-medium color-fig-subtle'>Select a search result to start analyzing</p>
                        </div>
                    </div>
                )
            }
            {
                !this.state.selection.section && Object.keys(this.state.searchResults).map(x => this.state.searchResults[x]).every(x => x.length === 0) && (
                    <div className='search-result-selection-no-selection-initial'></div>
                )
            }
            </div>
        </div>
        );
    }
}

export default SearchResult;