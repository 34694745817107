import InitialsImage from './initials-image.js';
import { compress, decompress } from 'lz-string';
import { LOCAL_STORAGE_LOCATION_KEY, EVICTION_SAFE_KEYS, COLOR_CODES, GAME_TOTAL_MARKET_NAME, SPREAD_MARKET_NAME, TEAM_COLOR_CODES} from './linemate-react-common/src/constants.js';
import { decimalPrecision, getPlayerName, isDictEmpty, parseDateFromString } from './linemate-react-common/src/util.js';
import { getGameLineValue } from './linemate-react-common/src/betting-utils.js';
import { result } from 'lodash';
import { LOGOS_ASSETS_BASE } from './react-web-constants.js';

export function getPlayerImage(player, size, fontSize) {
  var firstLetter = "";
  var secondLetter = "";
  if (!isDictEmpty(player)) {
    firstLetter = player.info.firstName.charAt(0);
    secondLetter = player.info.lastName.charAt(0);
  }
  // if (isDictEmpty(player) || player.imageURL == null) {
  return (
      <div style={{display: 'inline-block', verticalAlign: 'middle', height: `${size}px`, width: `${size}px`}}>
          <InitialsImage  firstLetter={firstLetter}
                          secondLetter={secondLetter}
                          fontSize={fontSize}/>
      </div>
  )
  // }
  // return <img class="rounded-icon" style={{backgroundColor: '#F3F4F6', objectFit: 'cover'}} src={player.imageURL} height={size} width={size} alt=""/>
}



// https://stackoverflow.com/questions/4391575/how-to-find-the-size-of-localstorage
export function getLocalStorageSize() {
  var _lsTotal = 0,
    _xLen, _x;
  for (_x in localStorage) {
      if (!localStorage.hasOwnProperty(_x)) {
          continue;
      }
      _xLen = ((localStorage[_x].length + _x.length) * 2);
      _lsTotal += _xLen;
      console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
  };
  return (_lsTotal / 1024).toFixed(2) + " KB";
}
  
export function cleanupLocalStorage() {
  Object.keys(localStorage).map((key) => {
    try {
      const cacheEntry = JSON.parse(decompress(localStorage[key]));
      const cacheEntryExpiry = cacheEntry.expiry;
      const currentTime = (new Date()).getTime();
      if (currentTime > cacheEntryExpiry) {
        // Entry exists in cache but is expired
        localStorage.removeItem(key);
      }
    } catch (err) {
      console.log(err);
      console.log(`Error parsing local storage entry with key ${key}`)
    }
  })
}
  
  
  
// TODO: we should apply the versioning to the fetch proxy as well
export function getLocalStorageLocation(version) {
  if (!window.localStorage[LOCAL_STORAGE_LOCATION_KEY]) {
    return {};
  }

  try {
    const cacheEntry = JSON.parse(decompress(window.localStorage[LOCAL_STORAGE_LOCATION_KEY]));
    const cacheEntryExpiry = cacheEntry.expiry;
    const cacheEntryVersion = cacheEntry.version;
    const currentTime = (new Date()).getTime();
    if (currentTime > cacheEntryExpiry || !cacheEntryVersion || cacheEntryVersion !== version) {
      // Entry exists in cache but is expired or outdated in terms of version
      window.localStorage.removeItem(LOCAL_STORAGE_LOCATION_KEY);
      return {};
    }
    return cacheEntry.value;
  } catch (err) {
    console.log(err);
    console.log(`Error parsing local storage location`)
    return {};
  }
}
  
/**
 * 
 * @param {string} key                Key in local storage under which the data will be saved
 * @param {Object} data               Data to be saved, assumed to be in json/dictionary format
 * @param {string} version            Version of the data. Futureproofing backwards compatibility in case we switch the data format
 * @param {Long} ttl                  The time to live of the entry. The current time will be appended to this
 * @param {Boolean} shouldMakeSpace   Whether or not we should make space for this entry should the local storage be full
 * @param {Number} iteration          The recursive iteration at which we are if we need to make space. This is capped at an arbitrary number of 5
 */
export function saveLocalStorage(key, data, version, ttl, shouldMakeSpace, iteration) {
  const currentTime = (new Date()).getTime();
  try {
    window.localStorage.setItem(key, compress(JSON.stringify({version: version, expiry: currentTime + ttl, value: data})));
  } catch (e) {
    if ((e.code === "22" || e.code === "1024") && shouldMakeSpace && iteration < 5) {
      evictFromLocalStorage(EVICTION_SAFE_KEYS);
      saveLocalStorage(key, data, version, ttl, shouldMakeSpace, iteration + 1);
    }
  }
}

// Primarily designed to create space for the most important key(s)
// Input is expected to be a set of strings corresponding to keys that should not be evicted
// Will find the first key in local storage not matching a key from the 'evictionSafeKeys' set
// This should only be called after the local storage has been cleaned up and expired entries have already been evicted
export function evictFromLocalStorage(evictionSafeKeys) {
  Object.keys(localStorage).map((key) => {
    if (!evictionSafeKeys.has(key)) {
      localStorage.removeItem(key);
    }
  })
}

// TODO: look at other places using the color and switch to the new one
// Builds graph data for the Nivo bar graph
export function buildGraphData(league, type, betType, teamCode, games) {
  var trendsGraphData = [];
  games.forEach((game) => {
      var gameEntry = {};
      gameEntry["key"] = `${game.opposingTeam} (${String(parseDateFromString(game.timestamp).month).padStart(2, '0')}/${String(parseDateFromString(game.timestamp).day).padStart(2, '0')}/${String(parseDateFromString(game.timestamp).year)})`;
      // Would be cool if we tracked double header on a team-game level but I guess we forgot to? Will use the game ID instead
      // Standard game id is 3 pieces <date>-<away>-<home>, for MLB with double header we have <date>-<away>-<home>-<game number>
      const gameIdParts = game.gameID.split("-");
      if (league === "mlb" && gameIdParts.length > 3) {
          const gameNumber = gameIdParts[3];
          gameEntry["key"] += ` (${gameNumber})`;
      }
      gameEntry[`color`] = TEAM_COLOR_CODES[getTheme()][league][teamCode.toLowerCase()];
      
      gameEntry[betType] = getGameLineValue(league, type, game, betType);
      trendsGraphData.push(gameEntry);
  })
  return trendsGraphData;
}

export function getPlayerTitle(player, league, includePosition, includeHandedness) {
  if (!player) {
      return "";
  }
  var title = `${getPlayerName(player)}`;
  if (includePosition) {
      title += ` - ${player.info.position}`;
  }
  if (league !== "mlb" || !includeHandedness) {
      return title;
  }
  // Here we could write RHB/LHB or RHP/LHP but we won't write the B or P to match the picker which is harder to include the B or P in the current design
  if (player.info.position === "P" || player.info.position === "SP" || player.info.position === "RP") {
      title += ` (${player.info.pitchingHand}H)`;
  } else {
      title += ` (${player.info.battingHand}H)`;
  }
  return title;
}

export function getTeamLogoPath(league, teamCode) {
  // Stupid workaround for windows reserved file names
  if (teamCode.toLowerCase() === 'aux') {
    return `${LOGOS_ASSETS_BASE}/${league.toLowerCase()}/aux1.svg`
  }

  if (teamCode.toLowerCase() === 'con') {
    return `${LOGOS_ASSETS_BASE}/${league.toLowerCase()}/con1.svg`
  }
  return `${LOGOS_ASSETS_BASE}/${league.toLowerCase()}/${teamCode.toLowerCase()}.svg`
}

export function getLeagueLogoPath(league) {
  return `${LOGOS_ASSETS_BASE}/${league.toLowerCase()}/league.svg`
}

export function toggleTheme() {
  if (getTheme() === 'dark'){
      return setTheme('light');
  } else {
      return setTheme('dark');
  }
}

export function setTheme(themeName) {
  localStorage.setItem('theme', themeName);
  document.documentElement.className = `theme-${themeName}`;
  return themeName;
}

export function getTheme() {
  const theme = localStorage.getItem('theme');
  if (theme) {
    return theme;
  }
  const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  return isDark ? "dark" : "light";
}

export function openStripeBillingPortal(API_HOST, userToken) {
  const requestInfo = {
      headers: {'Authorization': "Bearer " + userToken}
  };
  fetch(`${API_HOST}/api/payment/v1/portal`, requestInfo).then(data => data.json())
  .then(result => {
      if (result.code && result.code > 200) {
          console.log("Error fetching stripe url");
      } else {
          window.open(result.redirect, '_blank').focus();
      }
  })
  .catch(error => {
      console.log("Error fetching stripe url", error);
  });
}

export function getStripeCheckoutSessionUrl(API_HOST, userToken, priceId, origin, callback) {
  const requestInfo = {
      headers: {
          'Authorization': "Bearer " + userToken
      }
  };
  var url = `${API_HOST}/api/payment/v1/checkout?id=${priceId}`;
  if (origin && origin != "") {
      url += `&origin=${origin}`;
  }
  fetch(url, requestInfo).then(data => {return data.json();})
  .then((result) => callback(result.redirect))
  .catch(error => {
      console.log("Error getting checkout session: " + error);
  });
}