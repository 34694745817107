import React from 'react';

import './Home.scss'
import '../components/App.scss'
import { base64Encode, buildQueryParams, buildTeamsMap, hasPremiumAccess, isSoccer, parseDate } from '../linemate-react-common/src/util';
import { getAllTeams, getGamedaySchedule } from '../linemate-react-common/src/gameday-utils';
import { API_HOST } from '../react-web-constants';

import MatchupCard from '../components/MatchupCard';
import GlobalContext from '../components/GlobalContext';
import Trend from '../components/Trend';
import Button from '../components/Button';
import { BET_TYPE_FILTER, DISCOVERY_LEADERS } from '../linemate-react-common/src/constants';
import AffiliatesList from '../components/AffiliatesList';

class Home extends React.Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.getTrendSection = this.getTrendSection.bind(this);

    this.state = {
      loaded: false,
      currentGames: [],
      upcomingGames: [],
      teams: {},
      trendPreviews: {
        trends: [],
        markets: [],
        positions: []
      }
    }
  }

  componentDidMount() {
    document.title = 'Home - Linemate';
    Promise.all(
      getGamedaySchedule(API_HOST, this.context.league, this.context.configuration.metadata.leagues[this.context.league].recordType, false).concat(
        getAllTeams(API_HOST, this.context.league),
        fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/cards/preview?premium=${hasPremiumAccess(this.context.userAttributes)}`).then((data) => data.json())
      )
    )
    .then((result) => {
      this.setState({
        loaded: true,
        currentGames: result[0],
        upcomingGames: result[1],
        teams: buildTeamsMap(result[2]),
        trendPreviews: result[3]
      })
    });
  }

  // The filtersToExclude is not ideal since it assumes the same filter for the whole section.
  // For the trending plays with safe/risky/recent/h2h some should have no filters to exclude and some should have the trends
  //  but to simplify the implementation right now we won't exlude anything for those
  getTrendSection(title, trendPreviewKey, filtersToExclude) {
    if (this.state.trendPreviews[trendPreviewKey].length === 0) {
      return <></>
    }
    return (
      <>
        <div className='home-page-discover-title home-page-padding'>
          <p className='text-style-h-2-semibold home-page-discover-title-desktop'>{title}</p>
          <p className='text-style-h-3-semibold home-page-discover-title-mobile'>{title}</p>
        </div>
        <div className='home-page-discover-trends-wrapper home-page-padding'>
        {
          this.state.trendPreviews[trendPreviewKey].map((trend) => 
            <Trend loaded={this.state.loaded} title={trend.title} subtitle={trend.subtitle} cards={trend.cards} endpoint={trend.endpoint} type={trend.type} filtersToExclude={filtersToExclude}/>
          )
        }
        </div>
      </>
    )
  }

  render() {
    const date = parseDate(new Date());
    // The !this.state.loaded is just so that we default to 'today/this week' while loading
    const currentGames = this.state.currentGames.length > 0 || !this.state.loaded;
    const games = currentGames ? this.state.currentGames : this.state.upcomingGames;
    var title = "";
    if (currentGames) {
      title = isSoccer(this.context.league) || this.context.league === "nfl" ? "This Week's Matchups" : "Today's Matchups";
    } else {
      title = "Upcoming Matchups";
    }
    const timeframe = (isSoccer(this.context.league) || this.context.league === "nfl") && games && games.length > 0 && games[0].title ? games[0].title : date.formattedDayMonthShort;
    const discoverTitle = "Discover Trending Plays"
    const discoverPropsTitle = "Trends by Prop";
    const discoverPositionTitle = "Trends by Position";
    return (
      <div className='home-page-container'>
        {/* Placeholder to get the 3 column grid */}
        <div></div>
        <div className='home-page-content'>
          {
            games && games.length > 0 && (
              <div className='home-page-title home-page-padding'>
                <p className='text-style-h-1-semibold home-page-title-text-desktop'>{title}</p>
                <p className='text-style-h-2-semibold home-page-title-text-mobile'>{title}</p>
                {
                  currentGames && (
                    <>
                      <p className='text-style-h-1-medium home-page-title-date-desktop'>{timeframe}</p>
                      <p className='text-style-h-2-medium home-page-title-date-mobile'>{timeframe}</p>
                    </>
                  )
                }
              </div>
            )
          }
          <div className='home-page-matchup-wrapper home-page-padding'>
          {
            games.map((game) =>
              <MatchupCard game={game} teams={{home: this.state.teams[game.homeTeamData.info.code], away: this.state.teams[game.awayTeamData.info.code]}}/>
            )
          }
          </div>
          {/* Trends */}
          {this.getTrendSection(discoverTitle, 'trends')}
          <div className='home-page-analyze-props'>
            <p className='text-style-h-2-semibold'>Looking to analyze every play in detail?</p>
            <p className='text-style-h-4-semibold'>Looking to analyze every play in detail?</p>
            <div className='home-page-analyze-props-button'>
              {/* TODO: add icon, look into doing children */}
              <Button text='Explore our Prop Tool' typography='lg' type='primary' enabled={true} onClick={() => window.location.href = `${this.context.league}/props`}/>
            </div>
          </div>
          {/* Trends by market/prop */}
          {this.getTrendSection(discoverPropsTitle, 'markets', [BET_TYPE_FILTER])}
          {
            !hasPremiumAccess(this.context.userAttributes) && (
              <>
                <div className='home-page-linemate-plus-ad-desktop'>
                  <div className='home-page-linemate-plus-ad-desktop-left'>
                    <p>Save Time, Play Smarter with Linemate+</p>
                    <div>
                      <Button text="Subscribe today" type="secondary" typography="lg" enabled={true} onClick={() => window.location.href = `/pricing`}/>
                    </div>
                  </div>
                  <div className='home-page-linemate-plus-ad-desktop-right'>
                    <img src='assets/linemate-plus-trends-ad.png' alt="lm+"/>
                  </div>
                </div>
                <div className='home-page-linemate-plus-ad-mobile'>
                  <img src='assets/linemate-plus-trends-ad.png' alt="lm+"/>
                  <p>Save Time, Play Smarter with Linemate+</p>
                  <div>
                    <Button text="Subscribe today" type="secondary" typography="lg" enabled={true} onClick={() => window.location.href = `/pricing`}/>
                  </div>
                </div>
              </>
            )
          }
          {/* Trends by position */}
          {this.getTrendSection(discoverPositionTitle, 'positions')}
          {/* Leaderboard */}
          {
            <div className='home-page-leaders'>
              <div className='home-page-leaders-header'>
                <p className='text-style-h-2-semibold color-fig-default'>Leaderboard</p>
                <a className='text-style-body-normal color-fig-accent' href={`${this.context.league}/leaderboard`}>View All</a>
              </div>
              <div className='home-page-leaders-groups'>
              {
                DISCOVERY_LEADERS[this.context.league].map((group) => 
                  {
                    var leaderboardParams = {
                      tab: "player",
                      // TODO: some of the stats between web and app are named differently and screws up the preset
                      stat: group.sortingColumn,
                      order: group.sortingOrder,
                      // Timeframe filters stay the same, only adjusting position/type of stats
                      // filters: {}
                    };
                    if (group.filters.position) {
                      leaderboardParams.filters = base64Encode(JSON.stringify({position: group.filters.position.toUpperCase()})); 
                    }
                    return (
                      <a className='home-page-leaders-group' href={`${this.context.league}/leaderboard?${buildQueryParams(leaderboardParams)}`} style={{'--groups-count': DISCOVERY_LEADERS[this.context.league].length}}>
                        <div className='home-page-leaders-group-top'>
                          <p className='text-style-label-semibold color-fig-default'>{group.title}</p>
                        </div>
                        <div className='home-page-leaders-group-bottom'>
                          <p className='text-style-label-medium color-fig-subtle'>PLAYERS</p>
                        </div>
                      </a>
                    )
                  }
                )
              }
              </div>
            </div>
          }
        </div>
        {/* 
          Note: this has a similar problem as we had in the trends page where that column goes too far down lower than the middle column content
          Not going to bother fixing it for now since we shouldn't have that many book offers that would cause it
          However in the case where it is needed, refer to the Trends page where the height is dynamically set based on the middle column
         */}
        <div className='home-page-affiliates-sidebar-column'>
          <div>
            <AffiliatesList />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;