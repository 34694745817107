import React from "react";
import { decimalPrecision } from './linemate-react-common/src/util.js';

import './screener.css';

// TODO: look into making the list as component too where scroll is handled
class StatCard extends React.Component {
    render() {
        return (
            <div class="screener-total-stat-card" style={this.props.width ? {width: this.props.width} : {width: `calc((100% - (${this.props.widthDivider - 1} * 16px)) / ${this.props.widthDivider})`}}>
                <div class="screener-total-stat-card-header" style={{background: this.props.color}}>
                    <p class="font size-12 spaced white full-width display-block align-center">{this.props.title}</p>
                </div>
                <p class="font size-16 weight-600 grey spaced full-width display-block align-center" style={{marginTop: '12px'}}>{decimalPrecision(this.props.value, 1)}</p>
            </div>
        )
    }
}

export default StatCard;